import { useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { fetchApiPost } from "@/lib/api/utils";
import { KnowledgeApi, useAddAssignment, useGetAssignableUsers } from "@/lib/api/knowledge";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { GenerateLoadingOrError } from "@/lib/utils";
import { Combobox } from "@/components/ui/combobox";

interface AssignTaskDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  taskId: string;
}


export function AssignTaskDialog({
  open,
  onOpenChange,
  taskId
}: AssignTaskDialogProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assigneeId, setAssigneeId] = useState("");
  const [trainerId, setTrainerId] = useState("");
  const [backupTrainerId, setBackupTrainerId] = useState("");
  const addAssignmentMutation = useAddAssignment();
  const assignableUsers = useGetAssignableUsers();

  const assignableUsersList = useMemo(() => {
    const users = assignableUsers.data?.data || [];
    users.sort((a: any, b: any) => (a.name || "").localeCompare(b.name || ""));
    return users;
  }, [assignableUsers.data?.data]);

  const loadingOrErrorUi = GenerateLoadingOrError([assignableUsers]);

  if (loadingOrErrorUi) {
    return (
      <div className="flex flex-1 flex-col justify-start px-6 py-6 lg:px-8">
        {loadingOrErrorUi}
      </div>
    );
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!assigneeId) {
      toast({
        title: "Error",
        description: "Please select an assignee for this task.",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const result = await addAssignmentMutation({
        knowledge_item_id: taskId,
        assignee_id: assigneeId,
        trainer_id: trainerId || undefined,
        backup_trainer_id: backupTrainerId || undefined,
        status: "IN_PROGRESS",
      });

      if (!result.success) {
        toast({
          title: "Failed to assign task",
          description: "Please try again.",
          variant: "destructive",
        });
        return;
      }

      toast({
        title: "Task assigned",
        description: "The task has been assigned successfully.",
      });

      // Close dialog and reset form
      onOpenChange(false);
    } catch (error) {
      console.error("Error assigning task:", error);
      toast({
        title: "Error",
        description: "Failed to assign task. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Assign Task</DialogTitle>
            <DialogDescription>
              Assign this task to a team member.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="assignee" className="text-right">
                Assignee
              </Label>
              <div className="col-span-3">
                <Select
                  value={assigneeId}
                  onValueChange={setAssigneeId}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select an assignee" />
                  </SelectTrigger>
                  <SelectContent className="max-h-[200px] overflow-y-scroll">
                    {assignableUsersList.map((user: { id: string, name: string }) => (
                      <SelectItem key={`assignee-${user.id}`} value={user.id}>
                        {user.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="trainer" className="text-right">
                Trainer
              </Label>
              <div className="col-span-3">
                <Select
                  value={trainerId}
                  onValueChange={setTrainerId}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a trainer" />
                  </SelectTrigger>
                  <SelectContent className="max-h-[200px] overflow-y-scroll">
                    {assignableUsersList.map((user: { id: string, name: string }) => (
                      <SelectItem key={`trainer-${user.id}`} value={user.id}>
                        {user.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="trainer" className="text-right">
                Backup Trainer
              </Label>
              <div className="col-span-3">
                <Select
                  value={backupTrainerId}
                  onValueChange={setBackupTrainerId}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a backup trainer (optional)" />
                  </SelectTrigger>
                  <SelectContent className="max-h-[200px] overflow-y-scroll">
                    {assignableUsersList.map((user: { id: string, name: string }) => (
                      <SelectItem key={`backup-trainer-${user.id}`} value={user.id}>
                        {user.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button
              type="button"
              variant="outline"
              onClick={() => onOpenChange(false)}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Assigning..." : "Assign"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
} 