import { useStytchMember } from "@stytch/react/b2b";
import { fetchKnowledgeItemsPage, KnowledgeApi, useGetCommonBlocks, useGetKnowledgeTags, useKnowledgeItemsPaginated } from "@/lib/api/knowledge";
import { cn, GenerateLoadingOrError } from "@/lib/utils";
import { ArrowLeft, PlusCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useMemo, useState } from "react";
import { KnowledgeTaskCard } from "./components/KnowledgeTaskCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useNavigate } from "react-router-dom";
import { Loader } from "@/components/ui/loader";
import { useQueryClient } from "@tanstack/react-query";
import { TagsManagementView } from "./components/TagsManagementView";
import { KnowledgeCommonBlockCard } from "./components/KnowledgeCommonBlockCard";

function generateFilterFromTab(tab: string) {
    switch (tab) {
        case "assigned":
            return "assigned";
        case "owned":
            return "owned";
        case "all":
            return "all";
        case "common-blocks":
            return "common-blocks";
        default:
            return "all";
    }
}

export function KnowledgeTasksDashboard() {
    const { member } = useStytchMember();
    const navigate = useNavigate();
    const [tab, setTab] = useState("assigned");
    const [page, setPage] = useState(1);
    const knowledgeItemsRes = useKnowledgeItemsPaginated({
        params: {
            page,
            filter: generateFilterFromTab(tab),
        },
        queryFn: fetchKnowledgeItemsPage,
        enabled: tab !== "tags",
    });

    const user_id = member?.trusted_metadata.db_user_id;
    const member_role = member?.trusted_metadata.role;

    const loadingOrErrorUi = GenerateLoadingOrError(tab !== 'tags' ? [knowledgeItemsRes] : []);

    if (loadingOrErrorUi && tab !== 'tags') {
        return (
            <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Tasks
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

    const knowledgeItems = tab !== 'tags' ? (knowledgeItemsRes?.data?.data?.data || []) : [];

    const tasks = knowledgeItems.filter((item: KnowledgeApi.KnowledgeItem) => item.item_type === "task");
    const commonBlocks = knowledgeItems.filter((item: KnowledgeApi.KnowledgeItem) => item.item_type === "common-block");
    const assignedTasks = tasks.filter((item: KnowledgeApi.KnowledgeItem) => {
        return item?.assignments?.some((assignment: KnowledgeApi.KnowledgeAssignment) => assignment.assignee_id === user_id);
    });
    const ownedTasks = tasks.filter((item: KnowledgeApi.KnowledgeItem) => item.owner_id === user_id);
    const allTasks = tasks;

    const isAdmin = member_role === "admin";


    const PaginationControls = ({ res }: { res: typeof knowledgeItemsRes }) => {
        const isLoading = res.isLoading;
        const info = res?.data?.data?.pagination || {};

        return (
            <div className="flex items-center justify-between mt-4">
                <Button
                    onClick={() => setPage(page - 1)}
                    disabled={page <= 1 || isLoading}
                >
                    {isLoading ? <Loader className="w-4 h-4" /> : <span>Previous</span>}
                </Button>

                <span>
                    Page {info.currentPage || 1} of {info.totalPages || 1}
                </span>

                <Button
                    onClick={() => setPage(page + 1)}
                    disabled={page >= (info.totalPages || 1) || isLoading}
                >
                    {isLoading ? <Loader className="w-4 h-4" /> : <span>Next</span>}
                </Button>
            </div>);
    }

    const onTabChange = (value: string) => {
        setTab(value);
        setPage(1);
    };

    console.warn("tab", tasks, commonBlocks, tab, generateFilterFromTab(tab))

    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 lg:px-8">
            <div className="flex items-center justify-between mb-6">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Knowledge Center
                </h1>
                {tab !== 'tags' && (
                    <Button
                        onClick={() => navigate("/tasks/create")}
                        className="flex items-center gap-2"
                    >
                        <PlusCircle className="h-4 w-4" />
                        Create Task
                    </Button>
                )}
            </div>

            <Tabs value={tab} onValueChange={onTabChange} className="space-y-6">
                <TabsList>
                    <TabsTrigger value="assigned">Assigned To Me</TabsTrigger>
                    <TabsTrigger value="owned">Owned Tasks</TabsTrigger>
                    {isAdmin && <TabsTrigger value="all">All Tasks</TabsTrigger>}
                    {isAdmin && <TabsTrigger value="tags">Tags</TabsTrigger>}
                    {<TabsTrigger value="common-blocks">Common Blocks</TabsTrigger>}
                </TabsList>

                <TabsContent value="assigned" className="space-y-4">
                    {knowledgeItemsRes.isLoading && tab === 'assigned' ? <Loader /> : assignedTasks.length > 0 ? (
                        <>
                            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                                {assignedTasks.map((task: KnowledgeApi.KnowledgeItem) => (
                                    <KnowledgeTaskCard
                                        key={`assigned-${task.id}`}
                                        task={task}
                                        isAssigned={true}
                                    />
                                ))}
                            </div>
                            <PaginationControls res={knowledgeItemsRes} />
                        </>
                    ) : (
                        <div className="border rounded-lg p-6 bg-background text-center">
                            <h3 className="font-medium mb-2">No assigned tasks</h3>
                            <p className="text-sm text-muted-foreground">
                                You don't have any tasks assigned to you yet.
                            </p>
                        </div>
                    )}
                </TabsContent>

                <TabsContent value="owned" className="space-y-4">
                    {knowledgeItemsRes.isLoading && tab === 'owned' ? <Loader /> : ownedTasks.length > 0 ? (
                        <>
                            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                                {ownedTasks.map((task: KnowledgeApi.KnowledgeItem) => (
                                    <KnowledgeTaskCard
                                        key={`owned-${task.id}`}
                                        task={task}
                                        isAssigned={false}
                                        assignmentCount={task.assignmentsCount || 0}
                                    />
                                ))}
                            </div>
                            <PaginationControls res={knowledgeItemsRes} />
                        </>
                    ) : (
                        <div className="border rounded-lg p-6 bg-background text-center">
                            <h3 className="font-medium mb-2">No owned tasks</h3>
                            <p className="text-sm text-muted-foreground">
                                You haven't created any tasks yet.
                            </p>
                        </div>
                    )}
                </TabsContent>
                {isAdmin && (
                    <TabsContent value="all" className="space-y-4">
                        <AllTasksView currentPage={page} onPageChange={setPage} />
                    </TabsContent>
                )}
                {isAdmin && (
                    <TabsContent value="tags" className="space-y-4">
                        <TagsManagementView />
                    </TabsContent>
                )}
                <TabsContent value="common-blocks" className="space-y-4">
                    {knowledgeItemsRes.isLoading && tab === 'common-blocks' ? <Loader /> : commonBlocks.length > 0 ? (
                        <>
                            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                                {commonBlocks.map((task: KnowledgeApi.KnowledgeItem) => (
                                    <KnowledgeTaskCard
                                        key={`common-${task.id}`}
                                        task={task}
                                        isAssigned={false}
                                        assignmentCount={task.assignmentsCount || 0}
                                    />
                                ))}
                            </div>
                            <PaginationControls res={knowledgeItemsRes} />
                        </>
                    ) : (
                        <div className="border rounded-lg p-6 bg-background text-center">
                            <h3 className="font-medium mb-2">No Common Blocks</h3>
                            <p className="text-sm text-muted-foreground">
                                You haven't created any common blocks yet.
                            </p>
                        </div>
                    )}
                </TabsContent>
            </Tabs>
        </section>
    );
}

function AllTasksView({ currentPage, onPageChange }: { currentPage: number; onPageChange: (page: number) => void }) {
    const queryClient = useQueryClient();
    const [activeTagId, setActiveTagId] = useState<"no-tag" | string | null>(null);
    const tagsRes = useGetKnowledgeTags();
    const knowledgeItemsRes = useKnowledgeItemsPaginated({
        keyOverride: "all-tasks",
        params: {
            page: currentPage,
            filter: "all",
            activeTagId: activeTagId === "no-tag" ? undefined : activeTagId || undefined,
            emptyTags: activeTagId === "no-tag",
        },
        queryFn: fetchKnowledgeItemsPage,
        enabled: activeTagId === "no-tag" || !!activeTagId,
    });

    const tasks = (knowledgeItemsRes?.data?.data?.data || []);


    const tags = useMemo(() => {
        return [
            {
                id: "no-tag",
                name: "No Tag",
            },
            ...(tagsRes?.data?.data || [])
        ];
    }, [tagsRes]);

    const loadingOrErrorUi = GenerateLoadingOrError([activeTagId ? knowledgeItemsRes : undefined, tagsRes].filter(Boolean));

    if (loadingOrErrorUi) {
        return (loadingOrErrorUi);
    }

    const handleTagSelect = (tagId: string) => {
        setActiveTagId(tagId);
        onPageChange(1);
    };

    const handleClearTag = () => {
        setActiveTagId(null);
        onPageChange(1);
    }

    if (!activeTagId && activeTagId !== "no-tag") {
        return (
            <div className="border rounded-lg p-6 bg-background text-center">
                <h3 className="font-medium mb-3">Select a tag to view tasks</h3>
                <div className="flex flex-wrap gap-2 justify-start">
                    {tags.map((tag: KnowledgeApi.KnowledgeTaskTag) => (
                        <Button
                            key={tag.id}
                            variant="outline"
                            size="sm"
                            onClick={() => handleTagSelect(tag.id)}
                            className="px-3 py-1 h-auto"
                        >
                            {tag.name}
                        </Button>
                    ))}
                    {tags.length === 0 && (
                        <p className="text-sm text-muted-foreground">No tags available</p>
                    )}
                </div>
            </div>
        );
    }

    if (!knowledgeItemsRes.isLoading && !knowledgeItemsRes.isFetching && !tasks?.length) {
        return (<div className="p-6 bg-background flex flex-col ">
            <div className="flex flex-row items-center justify-start">
                <Button variant="ghost" size="sm" className="w-[120px]" onClick={handleClearTag}><ArrowLeft className="h-4 w-4 mr-1" /> Clear Tag</Button>
                <h3 className="font-medium text-center ml-2">No tasks found for this tag</h3>
            </div>
        </div>)
    }

    const PaginationControls = () => {
        const isLoading = knowledgeItemsRes.isLoading || knowledgeItemsRes.isFetching;
        const info = knowledgeItemsRes?.data?.data?.pagination || {};

        return (
            <div className="flex items-center justify-between mt-4">
                <Button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage <= 1 || isLoading}
                    className={cn(isLoading ? "flex items-center justify-center" : "")}
                >
                    {isLoading ? <Loader className="w-4 h-4" /> : <span>Previous</span>}
                </Button>

                <span>
                    Page {info.currentPage || 1} of {info.totalPages || 1}
                </span>

                <Button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage >= (info.totalPages || 1) || isLoading}
                    className={cn(isLoading ? "flex items-center justify-center" : "")}
                >
                    {isLoading ? <Loader className="w-4 h-4 px-2" /> : <span>Next</span>}
                </Button>
            </div>);
    };

    return (
        <>
            <Button variant="ghost" size="sm" className="w-[120px]" onClick={handleClearTag}><ArrowLeft className="h-4 w-4 mr-1" /> Clear Tag</Button>
            {(knowledgeItemsRes.isLoading || knowledgeItemsRes.isFetching) && (
                <div className="flex items-center justify-center p-4">
                    <Loader className="w-6 h-6" />
                </div>
            )}
            {
                !knowledgeItemsRes.isFetching && !knowledgeItemsRes.isLoading && tasks.length > 0 && (
                    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                        {tasks.map((task: KnowledgeApi.KnowledgeItem) => (
                            <KnowledgeTaskCard
                                key={`all-${task.id}`}
                                task={task}
                                isAssigned={false}
                            />
                        ))}
                    </div>
                )}
            <PaginationControls />
        </>
    );
}

