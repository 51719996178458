import moment, { Moment } from "moment-timezone";
import Decimal from "decimal.js";
import { CommonDestinationTimezone } from "../utils";
import { Commodities } from "./commodity";

export function reduceArrayTo(items: any[], col?: string): Record<string, any> {
  return items.reduce((acc, item) => {
    const value = col ? item[col] : item;
    acc[value] = value;
    return acc;
  }, {})
}

export const optionLetterToMonthNumber: Record<string, number> = {
  "F": 1,
  "G": 2,
  "H": 3,
  "J": 4,
  "K": 5,
  "M": 6,
  "N": 7,
  "Q": 8,
  "U": 9,
  "V": 10,
  "X": 11,
  "Z": 12,
};

export const monthNumberToOptionLetter: Record<number, string> = {
  1: "F",
  2: "G",
  3: "H",
  4: "J",
  5: "K",
  6: "M",
  7: "N",
  8: "Q",
  9: "U",
  10: "V",
  11: "X",
  12: "Z",
};

export const futureMonths = ["F", "H", "K", "N", "Q", "U", "X", "Z"];

export const OptionMonthOptions = generateCurrentAndFutureMonthOptions(3, moment.tz(CommonDestinationTimezone).startOf("month")).filter(filterOutPastOptions()).map(item => ({ value: item, label: item }));
export const OlderOptionMonthOptions = generateCurrentAndFutureMonthOptions(1, moment.tz(CommonDestinationTimezone).subtract(1, "year").startOf("year")).map(item => ({ value: item, label: item }));

function getOptionMonthComboBase(baseOptionMonthCombo: string) {
  if (!baseOptionMonthCombo || baseOptionMonthCombo.length < 2 || baseOptionMonthCombo.length > 3) {
    throw new Error("Invalid option month format");
  }
  const optionMonthCombo = baseOptionMonthCombo.length === 3 ? baseOptionMonthCombo.slice(1, 3) : baseOptionMonthCombo;

  // Extract month letter and year
  const monthLetter = optionMonthCombo[0];
  const yearDigit = parseInt(optionMonthCombo[1]);

  // Get the month number (1-12)
  const monthNumber = optionLetterToMonthNumber[monthLetter];
  if (!monthNumber) {
    throw new Error("Invalid month letter");
  }

  // Calculate full year (assuming 20xx)
  const fullYear = `${moment.tz(CommonDestinationTimezone).format("YYYY").slice(0, 3)}${yearDigit}`;
  const firstDayOfMonthMoment = moment.tz(CommonDestinationTimezone).year(parseInt(fullYear)).month(monthNumber - 1).date(1).startOf("day");
  const endOfMonthBefore = moment(firstDayOfMonthMoment).subtract(1, "month").endOf("month");


  return {
    monthNumber,
    monthLetter,
    yearDigit,
    fullYear,
    firstDayOfMonthMoment,
    endOfMonthBefore
  }
}

export function getOptionPredictedExpirationDate(baseOptionMonthCombo: string, commodity: Commodities) {
  const { fullYear, monthNumber, endOfMonthBefore } = getOptionMonthComboBase(baseOptionMonthCombo);

  switch (commodity) {
    case Commodities.Corn:
    case Commodities.WinterWheat:
    case Commodities.MinneapolisWheat:
      // Mar,May,Jul,Sep,Dec
      if ([3, 5, 7, 9, 12].includes(monthNumber)) {
        return getQuarterlyOptionMonthExpirationDate(baseOptionMonthCombo);
      } else {
        return getWeeklyOptionMonthExpirationDate(baseOptionMonthCombo);
      }
    case Commodities.Canola:
      // Jan/Mar/May/Jul/Sep/Nov
      if ([1, 3, 5, 7, 9, 11].includes(monthNumber)) {
        return getQuarterlyOptionMonthExpirationDate(baseOptionMonthCombo);
      } else {
        return getWeeklyOptionMonthExpirationDate(baseOptionMonthCombo);
      }
    case Commodities.Soybeans:
      // Jan/Mar/May/Jul/Sep/Nov
      if ([1, 3, 5, 7, 9, 11].includes(monthNumber)) {
        return getQuarterlyOptionMonthExpirationDate(baseOptionMonthCombo);
      } else {
        return getWeeklyOptionMonthExpirationDate(baseOptionMonthCombo);
      }
    default:
      return getQuarterlyOptionMonthExpirationDate(baseOptionMonthCombo);
  }
}

export function getQuarterlyOptionMonthExpirationDate(baseOptionMonthCombo: string) {
  if (!baseOptionMonthCombo) { return undefined; }
  const { endOfMonthBefore } = getOptionMonthComboBase(baseOptionMonthCombo);
  const fridayBreakCount = 2;

  // NOTE: Usually its the third friday but some months have 5 weeks (fridays) so its actually the second to last friday
  // we are looking for.
  // Walk backwards until we find the second to last Friday
  let fridayCount = 0;
  while (fridayCount < fridayBreakCount) {
    if (endOfMonthBefore.day() === 5) { // 5 is Friday
      fridayCount++;
      if (fridayCount >= fridayBreakCount) { break; }
    }
    // NOTE: this goes here, since the last day might be a Friday
    endOfMonthBefore.subtract(1, 'day');
  }

  return endOfMonthBefore.toDate();
}

export function getWeeklyOptionMonthExpirationDate(baseOptionMonthCombo: string) {
  if (!baseOptionMonthCombo) { return undefined; }
  const { endOfMonthBefore } = getOptionMonthComboBase(baseOptionMonthCombo);
  const fridayBreakCount = 1;

  // NOTE: Usually its the third friday but some months have 5 weeks (fridays) so its actually the second to last friday
  // we are looking for.
  // Walk backwards until we find the second to last Friday
  let fridayCount = 0;
  while (fridayCount < fridayBreakCount) {
    if (endOfMonthBefore.day() === 5) { // 5 is Friday
      fridayCount++;
      if (fridayCount >= fridayBreakCount) { break; }
    }
    // NOTE: this goes here, since the last day might be a Friday
    endOfMonthBefore.subtract(1, 'day');
  }

  return endOfMonthBefore.toDate();
}

export function optionMonthAbbrevToPair(item: string) {
  const month = item.slice(0, 3);
  const year = item.slice(4, 5);
  const monthNumber = moment.tz(`2024-${month}-01T12:00:00`, "YYYY-MMM-DDTHH:mm:ss", CommonDestinationTimezone).month() + 1;
  return `${monthNumberToOptionLetter[monthNumber]}${year}`;
}

export function generateMonthOptionsFor(date: Moment) {
  const dateMonthNumber: number = date.month() + 1;
  const dateMonthYear = date.year() % 10;
  const monthLetter: string = monthNumberToOptionLetter[dateMonthNumber] || "";

  return `${monthLetter}${dateMonthYear}`;
}

export function generateCurrentAndFutureMonthOptions(year_count: number, startDate = moment.tz(CommonDestinationTimezone)) {
  const iter_list = [];
  const list = [];
  let ticker = 0;
  let date = moment.tz(startDate, CommonDestinationTimezone);


  const startMonth = date.month() + 1;
  const startYear = date.year() % 10;
  do {
    iter_list.push({ year: date.year() % 10, month: date.month() + 1, date: moment.tz(date, CommonDestinationTimezone) });
    date = date.add(1, "year");

    ticker++;
  } while (ticker < year_count);


  for (const pair of iter_list) {
    list.push(...futureMonths.map((monthLetter: string) => `${monthLetter}${pair.year}`));
    list.push(...futureMonths.map((monthLetter: string) => `O${monthLetter}${pair.year}`));
  }

  return list;
}

export function filterOutPastOptions() {
  return function filterOutPastOptionsHelper(item: string): boolean {
    // Account for short dated
    if (item.length === 3) return filterOutPastOptionsHelper(item.slice(-2));
    const date = moment.tz(CommonDestinationTimezone).startOf("month")
    const startMonth = date.month() + 1;
    const startYear = date.year() % 10;

    const assembledOptionMonth = `${monthNumberToOptionLetter[startMonth]}${startYear}`;
    return isOptionMonthPastOther(assembledOptionMonth)(item);
  }
}

// NOTE: AA053 is discretionary
export function generateAccountLabel(account_id?: string) {
  if (!account_id) return undefined;
  return ({
    "AA054": "AA054 - Dakota",
    "AA059": "AA059 - Nebraska",
    "AA061": "AA061 - Ohio",
    "AA069": "AA069 - Wisconsin",
    "AA071": "AA071 - Minnesota",
    "AA072": "AA072 - Minnesota",
    "AA073": "AA073 - Minnesota",
    "87300100": "87300100 - RJO Dakota",
    "87310100": "87310100 - RJO Nebraska",
    "87320100": "87320100 - RJO Ohio",
    "11898": "11898 - StoneX",
  })[account_id]
}

export function findMatchOption(
  options: { value: string; label: string }[],
  value: string,
) {
  return options.find(
    (item: any) =>
      item.value?.toLocaleLowerCase()?.trim() ===
      value?.toLocaleLowerCase()?.trim(),
  );
}

export function formulateOptionMonths(optionMonth?: string) {
  if (!optionMonth) return OptionMonthOptions;
  const oldFound = OlderOptionMonthOptions.find(older => older.value === optionMonth);
  if (!oldFound) return OptionMonthOptions;

  return [oldFound, ...OptionMonthOptions];
}

function isOptionMonthPastOther(comparableMonth?: string) {
  return function isOptionMonthPastOtherHelper(baseMonth: string) {
    if (!comparableMonth) return true;

    const year = parseInt(baseMonth[1]);
    const month = optionLetterToMonthNumber[baseMonth[0]];

    const comparableYear = parseInt(comparableMonth[1]);
    const comparableMonthNumber = optionLetterToMonthNumber[comparableMonth[0]];

    if (year < comparableYear) return false;
    if (year > comparableYear) return true;

    return month >= comparableMonthNumber;
  }
}
