import { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useParams, useNavigate } from "react-router-dom";
import { KnowledgeApi, useGetKnowledgeItem, useUpdateAssignmentStepCompletion } from "@/lib/api/knowledge";
import { cn, CommonDestinationTimezone, GenerateLoadingOrError } from "@/lib/utils";
import { Loader } from "@/components/ui/loader";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Checkbox } from "@/components/ui/checkbox";
import { Badge } from "@/components/ui/badge";
import { ArrowLeft, PenSquare, User, CheckCircle, Clock, UserCheck, Pencil, Eye } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { useEditor, EditorContent } from '@tiptap/react';
import { AssignTaskDialog } from "./dialogs/AssignTaskDialog";
import { useStytchMember } from "@stytch/react/b2b";
import { useToast } from "@/components/ui/use-toast";
import { AssignmentStepsModal } from "./dialogs/AssignmentStepsModal";
import { ServerError } from "@/lib/responseResults";
import { KnowledgeEditor, useKnowledgeEditor } from "./components/KnowledgeEditor";

export function KnowledgeTaskDetail() {
  const { toast } = useToast();
  const { taskId } = useParams<{ taskId: string }>();
  const { member } = useStytchMember();
  const navigate = useNavigate();
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
  const taskQuery = useGetKnowledgeItem(taskId);
  const [isSaving, setIsSaving] = useState(false);
  const updateAssignmentStepCompletion = useUpdateAssignmentStepCompletion();
  const [isStepsModalOpen, setIsStepsModalOpen] = useState(false);
  const [selectedAssignmentForSteps, setSelectedAssignmentForSteps] = useState<any | null>(null);
  const [isStepsModalEditable, setIsStepsModalEditable] = useState(false);
  const { editor, handleFileUpload, fileInputRef } = useKnowledgeEditor({ mode: "view", existingData: taskQuery.data?.data?.content, taskId: taskId });

  const loadingOrErrorUi = GenerateLoadingOrError([taskQuery]);

  if (loadingOrErrorUi) {
    return (
      <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
        <div className="flex items-center mb-6">
          <Button
            variant="ghost"
            size="sm"
            className="mr-2"
            onClick={() => navigate("/tasks")}
          >
            <ArrowLeft className="h-4 w-4 mr-1" />
            Back
          </Button>
          <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
            Task
          </h1>
        </div>
        {loadingOrErrorUi}
      </section>
    );
  }

  const user_id: string | undefined = member?.trusted_metadata.db_user_id as string | undefined;
  const task = taskQuery.data?.data;
  const assignments = task?.assignments || [];

  const currentUserAssignment = user_id ? assignments.find((a: any) => a.assignee_id === user_id) : undefined;

  if (!task) {
    return (
      <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
        <div className="flex items-center mb-6">
          <Button
            variant="ghost"
            size="sm"
            className="mr-2"
            onClick={() => navigate("/tasks")}
          >
            <ArrowLeft className="h-4 w-4 mr-1" />
            Back
          </Button>
          <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
            Task Not Found
          </h1>
        </div>
        <p>The requested task could not be found.</p>
      </section>
    );
  }

  const handleToggleStep = async (step: any, isCompleted: boolean, assignment_id: string) => {
    if (isSaving) return;
    if (!taskId || !step?.id || step.assignment_id) {
      toast({ title: "Error", description: "Missing required data", variant: "destructive" });
      return;
    }

    try {
      setIsSaving(true);
      const result = await updateAssignmentStepCompletion({
        knowledge_item_id: taskId,
        assignment_id: assignment_id,
        step_id: step.id,
        status: isCompleted
      });
      if (!result.success) {
        throw new ServerError("Failed to update step status.", {});
      }

      toast({ title: "Step status update (simulated)", description: `Step marked as ${isCompleted ? 'complete' : 'incomplete'}.` });
    } catch (error) {
      console.error("Error toggling step completion:", error);
      toast({ title: "Error", description: "Failed to update step status.", variant: "destructive" });
    } finally {
      setIsSaving(false);
    }
  };

  const openStepsModal = (assignment: any, editable: boolean) => {
    setSelectedAssignmentForSteps(assignment);
    setIsStepsModalEditable(editable);
    setIsStepsModalOpen(true);
  };

  const isOwner = task.owner_id === user_id;
  const canAssign = isOwner;
  const isTaskBlock = task.item_type === "task";
  const isCommonBlock = task.item_type === "common-block";

  const tags = task.Tags || [];
  const assigneeSteps = task.stepsData?.steps || [];
  const currentAssigneeAssignmentId = (assigneeSteps?.length || 0) > 0 ? task?.stepsData?.assignment_id || "" : undefined;
  const isAssigneeFullyCompleted = task.assignments.every((a: any) => a.status === 'COMPLETED');
  const trainerAssignments = task.stepsData?.assignments || [];
  const stepsViewType = task.stepsData?.type || "";
  const assignmentId = task.stepsData?.assignment_id || "";
  const isCurrentUserTrainer = user_id && (task.Owner?.id === user_id);
  const canViewStepModal = (stepsViewType === "owner" && isOwner) || (stepsViewType === "trainer" && isCurrentUserTrainer);
  const canEditStepModal = (stepsViewType === "trainer" && isCurrentUserTrainer);

  return (
    <section className="flex min-h-full flex-1 flex-col justify-start px-6 lg:px-8">
      <div className="flex items-center mb-6">
        <Button
          variant="ghost"
          size="sm"
          className="mr-2"
          onClick={() => navigate("/tasks")}
        >
          <ArrowLeft className="h-4 w-4 mr-1" />
          Back
        </Button>
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          {task.title}
        </h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-6">
          {isTaskBlock && (
            <>
              <div>
                <p className="text-muted-foreground">{task.description}</p>
              </div>
              <Separator className="my-1" />
            </>
          )}

          <div>
            <KnowledgeEditor mode="view" existingData={task.content} editor={editor} handleFileUpload={handleFileUpload} fileInputRef={fileInputRef} />
          </div>
        </div>

        <div className="space-y-6">
          {isCommonBlock && (
              <div className="flex flex-col border rounded-md p-4 bg-card gap-2">
                  <div className="flex flex-row gap-2">
                    <Button
                      variant="outline"
                      onClick={() => navigate(`/tasks/${taskId}/edit`)}
                      className=""
                      size="sm"
                    >
                      <Pencil className="h-4 w-4 mr-2" />
                      Edit Task
                    </Button>
                    </div>
                  <div className="flex flex-wrap items-center gap-2" key={`assign-permissions-and-tags`}>
                    {task.SpecialPermission && (
                      <div key={`special-permission-${task.SpecialPermission.id}`}>
                        <Badge variant="secondary">
                          {task.SpecialPermission.name}
                        </Badge>
                      </div>
                    )}

                    {task.SpecialPermission && tags.length > 0 && (
                      <div key={`permission-tag-divider`}>
                        <Separator className="my-2" orientation="vertical" />
                      </div>
                    )}

                    {tags?.length > 0 && (
                      <div className="flex flex-wrap gap-1" key={`tags-${tags.length}`}>
                        {tags.map((tag: KnowledgeApi.KnowledgeTaskTag) => (
                          <Badge variant="outline" key={`tags-${taskId}-tag-${tag.id}`}>
                            {tag.name}
                          </Badge>
                        ))}
                      </div>
                    )}
                  </div>
                  </div>
          )}
          {isTaskBlock && canAssign &&
            (
              <div className="border rounded-md p-4 bg-card">
                <h3 className="font-medium mb-4">Task Actions</h3>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-row gap-2">
                    <Button
                      variant="outline"
                      onClick={() => navigate(`/tasks/${taskId}/edit`)}
                      className=""
                      size="sm"
                    >
                      <Pencil className="h-4 w-4 mr-2" />
                      Edit Task
                    </Button>
                    <Button
                      onClick={() => setIsAssignDialogOpen(true)}
                      className="flex-grow"
                      size="sm"
                    >
                      <User className="h-4 w-4 mr-2" />
                      Assign Task
                    </Button>
                  </div>

                  <div className="flex flex-wrap items-center gap-2" key={`assign-permissions-and-tags`}>
                    {task.SpecialPermission && (
                      <div key={`special-permission-${task.SpecialPermission.id}`}>
                        <Badge variant="secondary">
                          {task.SpecialPermission.name}
                        </Badge>
                      </div>
                    )}

                    {task.SpecialPermission && tags.length && (
                      <div key={`permission-tag-divider`}>
                        <Separator className="my-2" orientation="vertical" />
                      </div>
                    )}

                    {tags?.length > 0 && (
                      <div className="flex flex-wrap gap-1" key={`tags-${tags.length}`}>
                        {tags.map((tag: KnowledgeApi.KnowledgeTaskTag) => (
                          <Badge variant="outline" key={`tags-${taskId}-tag-${tag.id}`}>
                            {tag.name}
                          </Badge>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          }

          {isTaskBlock && (
            <div className="border rounded-md bg-card">
              <h3 className="font-medium p-4 border-b">Assignment's Status</h3>
              {!!assignments?.length ? (
                <div className="overflow-y-auto max-h-[600px]">
                  {assignments.map((assignment: any, index: number) => {
                    const isCurrentUserTrainer = user_id && (assignment.trainer_id === user_id || assignment.backup_trainer_id === user_id);
                    const isCurrentUserAssignee = user_id && assignment.assignee_id === user_id;
                    const canEditSteps = !!isCurrentUserTrainer && !isCurrentUserAssignee;
                    const canViewSteps = isOwner || isCurrentUserTrainer;
                    const trainerAssignment = trainerAssignments.find((a: any) => a.assignment_id === assignment.id);
                    const trainerAssignmentsSteps = trainerAssignment?.steps || [];

                    return (
                      <div key={`assignment-${assignment.id}`} className="p-2 px-2">
                        <div className="space-y-3">
                          <div className="flex justify-between items-center">
                            {((canViewStepModal || canEditStepModal) && trainerAssignmentsSteps?.length > 0) ? (
                              <span className="cursor-pointer text-sm text-muted-foreground border border-2 border-solid border-sky-500 rounded-md px-2" onClick={() => openStepsModal(trainerAssignment, canEditSteps)}>Status</span>
                            ) : (
                              <span className="text-sm text-muted-foreground">Status</span>
                            )}
                            <Badge
                              variant="outline"
                              className={
                                assignment.status === 'COMPLETED'
                                  ? "bg-green-50 text-green-700 border-green-200"
                                  : assignment.status === 'IN_PROGRESS'
                                    ? "bg-blue-50 text-blue-700 border-blue-200"
                                    : "bg-amber-50 text-amber-700 border-amber-200"
                              }
                            >
                              {assignment.status === 'COMPLETED' && <CheckCircle className="h-3 w-3 mr-1" />}
                              {assignment.status === 'IN_PROGRESS' && <PenSquare className="h-3 w-3 mr-1" />}
                              {assignment.status === 'PENDING' && <Clock className="h-3 w-3 mr-1" />}
                              {assignment.status.replace('_', ' ')}
                            </Badge>
                          </div>

                          <div className="flex justify-between items-center">
                            <span className="text-sm text-muted-foreground">Assigned To</span>
                            <div className="flex items-center text-sm">
                              <User className="h-3.5 w-3.5 mr-1 text-muted-foreground" />
                              <span>{assignment.assignee.name}</span>
                            </div>
                          </div>

                          {assignment.trainer_id && (
                            <div className="flex justify-between items-center">
                              <span className="text-sm text-muted-foreground">Trainer</span>
                              <div className="flex items-center text-sm">
                                <UserCheck className="h-3.5 w-3.5 mr-1 text-muted-foreground" />
                                <span>{assignment.trainer.name}</span>
                              </div>
                            </div>
                          )}

                          {assignment.backup_trainer_id && (
                            <div className="flex justify-between items-center">
                              <span className="text-sm text-muted-foreground">Backup Trainer</span>
                              <div className="flex items-center text-sm">
                                <UserCheck className="h-3.5 w-3.5 mr-1 text-muted-foreground" />
                                <span>{assignment.backupTrainer.name}</span>
                              </div>
                            </div>
                          )}

                          <div className="flex justify-between items-center">
                            <span className="text-sm text-muted-foreground">Assigned On</span>
                            <span className="text-sm">{moment.tz(assignment.assigned_at, CommonDestinationTimezone).format('YYYY-MM-DD')}</span>
                          </div>

                          {assignment.completed_at && (
                            <div className="flex justify-between items-center">
                              <span className="text-sm text-muted-foreground">Completed On</span>
                              <span className="text-sm">{moment.tz(assignment.completed_at, CommonDestinationTimezone).format('YYYY-MM-DD')}</span>
                            </div>
                          )}
                        </div>
                        {index < assignments.length - 1 && <Separator className="mt-2" key={`assignment-separator-${assignment.id}`} />}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className="p-4 text-muted-foreground italic">No assignments found</p>
              )}
            </div>
          )}
          {isTaskBlock && currentUserAssignment && assigneeSteps && assigneeSteps.length > 0 && (
            <div key={`steps-${assigneeSteps.length}`}>
              <h2 className="text-xl font-semibold mb-4">Training Progress</h2>
              <div className="space-y-3">
                {assigneeSteps.map((step: KnowledgeApi.KnowledgeStep) => {
                  const completion = (step as any).completion;
                  const completedAt = completion?.completed_at ? moment.tz(completion.completed_at, CommonDestinationTimezone).format('YYYY-MM-DD hh:mm a') : null;
                  const isCompleted = !!completion?.completed_at;
                  return (
                    <div
                      key={`${currentUserAssignment.id}-step-${step.id}`}
                      className="flex items-start p-3 border rounded-md bg-background"
                    >
                      <Checkbox
                        id={`step-${step.id}`}
                        className={cn(
                          "mt-1 mr-3",
                          step.completion_by !== "assignee" && "cursor-not-allowed"
                        )}
                        indicatorClassName={cn(
                          step.completion_by !== "assignee" && "cursor-not-allowed"
                        )}
                        checked={isCompleted}
                        onCheckedChange={(checked) =>
                          handleToggleStep(step, checked as boolean || false, currentAssigneeAssignmentId)
                        }
                        title={step.completion_by !== "assignee" ? "Trainer must complete this step" : ""}
                        disabled={step.completion_by !== "assignee" || !currentAssigneeAssignmentId || isAssigneeFullyCompleted}
                      />
                      <div className="flex-1 space-y-1">
                        <label
                          htmlFor={`step-${step.id}`}
                          className="font-medium cursor-pointer"
                        >
                          {step.title}
                        </label>
                        {step.description && (
                          <p className="text-sm text-muted-foreground">
                            {step.description}
                          </p>
                        )}
                        {completedAt && (
                          <p className="text-xs text-green-600">
                            Completed: {completedAt}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      {isTaskBlock && isAssignDialogOpen && (
        <AssignTaskDialog
          open={isAssignDialogOpen}
          onOpenChange={setIsAssignDialogOpen}
          taskId={taskId || ""}
        />
      )}

      {isTaskBlock && isStepsModalOpen && selectedAssignmentForSteps && (
        <AssignmentStepsModal
          open={isStepsModalOpen}
          onOpenChange={setIsStepsModalOpen}
          assignment={{ ...selectedAssignmentForSteps, id: selectedAssignmentForSteps?.assignment_id || selectedAssignmentForSteps?.id || "" }}
          taskId={taskId!}
          isEditable={isStepsModalEditable}
          taskSteps={selectedAssignmentForSteps?.steps || []} // Pass task steps to modal
        />
      )}
    </section>
  );
}
