import { ChatSourceOptions, ChatTypeOptions, MessageGroupOptions } from "@/lib/api/chats";
import { findByKey } from "@/lib/prelude";

export function formatChatSource({ type: base_type, source: base_source, message_group_env: base_message_group_env }: { source?: string, type?: string, message_group_env?: string }): string[] {
  const source = findByKey("value", base_source)(ChatSourceOptions)?.label || base_source;
  const type = findByKey("value", base_type)(ChatTypeOptions)?.label || base_type;
  const messagingGroup = findByKey("value", base_message_group_env)(MessageGroupOptions)?.label || base_message_group_env;

  if (!source && !type && !messagingGroup) {
    return ["Unknown"];
  }

  return [source, type, messagingGroup];
}

export function getInitials(name: string): string {
  if (!name) return '?';

  const parts = name.split(' ');
  if (parts.length === 1) {
    return parts[0].substring(0, 2).toUpperCase();
  }

  return (parts[0][0] + parts[parts.length - 1][0]).toUpperCase();
}
