import { create } from "zustand";
import { useState, useEffect, useRef, useMemo, Fragment } from "react";
import moment from "moment-timezone";
import {
  Archive as ArchiveXIcon,
  Check as CheckIcon,
  ChevronDown,
  ChevronRight,
  ChevronsUpDown,
  DeleteIcon,
  Filter,
  X
} from "lucide-react";
import * as FormHelper from "@/lib/form";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone, createDateLabel, cn } from "@/lib/utils";
import {
  DeferredPaymentContractCreateSchemaValidation,
  DeferredPaymentInvoiceCreateSchemaValidation,
  useListDeferredContracts,
  useListDeferredInvoices,
  ContractFilter,
  InvoiceFilter,
  DeferredPaymentContract,
  DeferredPaymentInvoice,
  useDeferPaymentSummary,
  DeferPaymentSummary,
  useCreateDeferredPaymentContract,
  useUpdateDeferredPaymentContract,
  useCreateDeferredPaymentInvoice,
  useGenerateReport,
  useUpdateDeferredPaymentInvoice,
  useReleaseInvoice,
  useReleaseContract,
  BaseFilter,
  DeferredPaymentTypeOptions,
  DeferredPaymentType,
  ReleaseDeferPaymentValidation,
  DeferredPaymentCommunicationChannelOptions,
  DeferredPaymentCommunicationChannel,
  useListDeferredReleased,
  DeferredReleasedSummary
} from '@/lib/api/deferred_payments';
import { useListCustomers, useUpdateCustomer, useUpdateCustomerDeferredPaymentLienStatus } from '@/lib/api/customer';
import {
  InputRequiredStatus,
  InputFormType,
  InputType,
  ImportType,
  ExportType,
  InputFilter,
  InputRequiredStatusType,
} from "@/lib/form";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { isValidEmail, findByKey } from "@/lib/prelude";
import { Commodities, CommodityOptions, Uom } from "@/lib/api/commodity";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import "../../../styles/compactTable.css";
import Decimal from "decimal.js";
import { Customer } from "@/lib/api/reports";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs";
import { safeParseDecimal } from "@/lib/excel";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";
import { Combobox } from "@/components/ui/combobox";
import { Input } from "@/components/ui/input";
import { DatePicker } from "@/components/ui/typeable_date_picker";
import { FormGridHelper } from "@/section/reports/helper";
import { uomOptions } from "@/lib/api/generalContract";
import { PrimitiveDialogForm } from "@/lib/form/layout";
import { DataTable, DataTableProps } from "@/components/ui/virtualizedTable";
import { CustomerForm, useBearStore as useCustomerBearStore } from "@/section/customer/ManageCustomers";
import useDebounce from "@/section/otc/useDebounce";
import { findCustomerFrom } from "@/lib/customer";
import { Loader } from "@/components/ui/loader";
import { Checkbox } from "@/components/ui/checkbox";
import { BearFloatInput, BearInput, BearSelectInput } from "@/lib/form/ui";

export const DeferredPaymentContractFormSchema = {
  id: {
    hidden: true,
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Optional
  },

  customer_id: {
    label: "Customer",
    options_reference_id: "customerOptions",
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => {
      return original.Customer?.long_name || "";
    },
  },

  settlement_date: {
    label: "Settlement Date",
    input_type: InputType.Date,
    input_type_validation: InputFormType.Date,
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => {
      return createDateLabel(original.settlement_date);
    },
  },

  contract_no: {
    label: "Contract #",
    input_type: InputType.String,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Required,
  },

  settlement_no: {
    label: "Settlement #",
    input_type: InputType.String,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Required,
  },

  contract_sent: {
    label: "Contract Sent",
    input_type: InputType.Boolean,
    input_type_validation: InputFormType.Boolean,
    required_status: InputRequiredStatus.Required,
    defaultValue: false,
    apiDisplayFormat: ({ original }: any) => {
      return original.contract_sent ? <CheckIcon className="h-4 w-4" /> : <X className="h-4 w-4" />;
    },
  },

  contract_signed: {
    label: "Contract Signed",
    input_type: InputType.Boolean,
    input_type_validation: InputFormType.Boolean,
    required_status: InputRequiredStatus.Required,
    defaultValue: false,
    apiDisplayFormat: ({ original }: any) => {
      return original.contract_signed ? <CheckIcon className="h-4 w-4" /> : <X className="h-4 w-4" />;
    },
  },

  commodity: {
    label: "Commodity",
    options_reference_id: "commodityOptions",
    input_type: InputType.Enum,
    enum: Commodities,
    input_type_validation: InputFormType.Enum(Commodities),
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => {
      return CommodityOptions.find(option => option.value === original.commodity)?.label || original.commodity;
    },
  },

  uom: {
    label: "UOM",
    options_reference_id: "uomOptions",
    input_type: InputType.Enum,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Required,
    dependencies: {
      on: "commodity",
      transform: (commodityValue: any, _formValues: any) =>
        commodityValue === Commodities.Canola ? Uom.Lbs : Uom.Bushel,
    },
  },

  uom_amount: {
    label: "UOM Amount",
    input_type: InputType.Decimal,
    input_type_validation: InputFormType.Decimal,
    required_status: InputRequiredStatus.Required,
  },

  interest: {
    label: "Interest",
    input_type: InputType.Decimal,
    input_type_validation: InputFormType.Decimal,
    required_status: InputRequiredStatus.Optional,
  },

  amount: {
    label: "Amount",
    input_type: InputType.Decimal,
    input_type_validation: InputFormType.Decimal,
    required_status: InputRequiredStatus.Required,
  },

  deferred_to_year: {
    label: "Deferred To Year",
    input_type: InputType.Int,
    input_type_validation: InputFormType.Int,
    required_status: InputRequiredStatus.Required,
    min: "2000",
    max: "2100",
    defaultValue: new Date().getFullYear() + 1,
  },
}

export const DeferredPaymentInvoiceFormSchema = {
  id: {
    hidden: true,
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Optional
  },

  name: {
    label: "Invoice Name",
    input_type: InputType.String,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Required,
  },

  customer_id: {
    label: "Customer",
    options_reference_id: "customerOptions",
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => {
      return original.Customer?.long_name || "";
    },
  },

  commodity: {
    label: "Commodity",
    options_reference_id: "commodityOptions",
    input_type: InputType.Enum,
    enum: Commodities,
    input_type_validation: InputFormType.Enum(Commodities),
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => {
      return CommodityOptions.find(option => option.value === original.commodity)?.label || original.commodity;
    },
  },

  amount: {
    label: "Amount",
    input_type: InputType.Decimal,
    input_type_validation: InputFormType.Decimal,
    required_status: InputRequiredStatus.Required,
  },

  deferred_to_year: {
    label: "Deferred To Year",
    input_type: InputType.Int,
    input_type_validation: InputFormType.Int,
    required_status: InputRequiredStatus.Required,
    min: "2000",
    max: "2100",
    defaultValue: new Date().getFullYear() + 1,
  },
};

// Define a combined type for our table rows
type DeferredPaymentItem = {
  id: string;
  type: 'contract' | 'invoice';
  customer_id: string;
  customer_name?: string;
  commodity: string;
  commodityName?: string;
  amount: string;
  numericAmount: number;
  numericUomAmount: number;
  numericInterest?: number;
  deferred_to_year: number;
  // Contract specific fields
  contract_no?: string;
  settlement_no?: string;
  settlement_date?: Date;
  contract_sent?: boolean;
  contract_signed?: boolean;
  uom?: string;
  uom_amount?: string;
  interest?: string;
  // Invoice specific fields
  invoice_name?: string;
  deferredReleasesLength?: number;
  original_amount?: number;
};

// Simple grouping interface for nested data
interface GroupableData {
  id: string;
  [key: string]: any;
}

// Define types for our grouped structure
type YearGroups = Record<string, Record<string, DeferredPaymentItem[]>>;
type CustomerGroups = Record<string, YearGroups>;

// Helper to group data by customer -> year -> commodity
function groupData(data: DeferredPaymentItem[]): CustomerGroups {
  const result: CustomerGroups = {};

  for (const item of data) {
    const customerName = item.customer_name || 'Unknown';
    const customerId = item.customer_id || 'Unknown';
    const year = item.deferred_to_year.toString();
    const commodity = item.commodityName || item.commodity;

    // Initialize nested structures if they don't exist
    if (!result[customerId]) {
      result[customerId] = {};
    }

    if (!result[customerId][year]) {
      result[customerId][year] = {};
    }

    if (!result[customerId][year][commodity]) {
      result[customerId][year][commodity] = [];
    }

    // Add the item to the appropriate group
    result[customerId][year][commodity].push(item);
  }

  return result;
}

// Create the form states using the FormHelper
const createContractState = FormHelper.generateBaseState({
  schemas: [DeferredPaymentContractFormSchema],
  baseSchema: DeferredPaymentContractFormSchema,
});

const updateContractState = FormHelper.generateBaseState({
  schemas: [DeferredPaymentContractFormSchema],
  baseSchema: DeferredPaymentContractFormSchema,
});

const createInvoiceState = FormHelper.generateBaseState({
  schemas: [DeferredPaymentInvoiceFormSchema],
  baseSchema: DeferredPaymentInvoiceFormSchema,
});

const updateInvoiceState = FormHelper.generateBaseState({
  schemas: [DeferredPaymentInvoiceFormSchema],
  baseSchema: DeferredPaymentInvoiceFormSchema,
});

// For handling expanded state
type ExpandedState = { [key: string]: boolean };

export function DeferredPaymentDashboard() {
  const [accountingStyle, setAccountingStyle] = useState(true);
  const [tabValue, setTabValue] = useState("summary");

  const toggleAccountingStyle = (status: boolean) => {
    setAccountingStyle(status);
  };
  return (
    <>
      <div className="flex justify-between mb-2">
        {tabValue === "summary" ? (
          <h2 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
            Deferred Payments Summary
          </h2>
        ) : (
          <h2 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
            Deferred Payments In-Detail
          </h2>
        )}

        <div className="flex items-center gap-4">
          <span>Display Format:</span>
          <Button
            variant={accountingStyle ? "default" : "outline"}
            onClick={() => setAccountingStyle(true)}
            className="text-xs h-8"
          >
            Accounting
          </Button>
          <Button
            variant={!accountingStyle ? "default" : "outline"}
            onClick={() => setAccountingStyle(false)}
            className="text-xs h-8"
          >
            Standard
          </Button>
        </div>
      </div>
      <Tabs value={tabValue} onValueChange={setTabValue} className="w-full">
        <TabsList>
          <TabsTrigger value="summary">Summary</TabsTrigger>
          <TabsTrigger value="detail">Detail</TabsTrigger>
          <TabsTrigger value="customers">Customer</TabsTrigger>
          <TabsTrigger value="released">Released</TabsTrigger>
        </TabsList>
        <TabsContent value="summary">
          <div className="mt-4">
            <SummarySection accountingStyle={accountingStyle} />
          </div>
        </TabsContent>
        <TabsContent value="detail">
          <div className="mt-4">
            <DetailSection accountingStyle={accountingStyle} />
          </div>
        </TabsContent>
        <TabsContent value="customers">
          <div className="mt-4">
            <CustomerSection />
          </div>
        </TabsContent>
        <TabsContent value="released">
          <div className="mt-4">
            <ReleasedSection accountingStyle={accountingStyle} />
          </div>
        </TabsContent>
      </Tabs>

    </>
  );
}

export function SummarySection({ accountingStyle }: { accountingStyle: boolean }) {
  const summaryRes = useDeferPaymentSummary();

  const summary: DeferPaymentSummary = summaryRes?.data?.data as any;

  const loadingOrErrorUi = GenerateLoadingOrError([summaryRes]);
  if (loadingOrErrorUi) return loadingOrErrorUi;

  // Format currency amounts
  const formatCurrency = (amount: number) => {
    if (!accountingStyle) {
      return `${Math.abs(amount).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`;
    }

    const isNegative = amount < 0;
    const stylizedFormat = Math.abs(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return isNegative ? `(${stylizedFormat})` : stylizedFormat;
  };



  // Calculate totals by payment type
  const paymentTypeTotals = summary.totalsWithPaymentType.reduce((acc, curr) => {
    const amount = typeof curr.amount === 'string' ? parseFloat(curr.amount) : curr.amount;
    const paymentType = formatPaymentTypeLabel(curr.payment_type);
    acc[paymentType] = (acc[paymentType] || 0) + amount;
    return acc;
  }, {} as Record<string, number>);

  // Calculate totals by year
  const yearTotals = summary.totalsWithPaymentType.reduce((acc, curr) => {
    const amount = typeof curr.amount === 'string' ? parseFloat(curr.amount) : curr.amount;
    acc[curr.year] = (acc[curr.year] || 0) + amount;
    return acc;
  }, {} as Record<number, number>);

  const yearPaymentTotals = summary.totalsWithPaymentType.reduce((acc, curr) => {
    const amount = typeof curr.amount === 'string' ? parseFloat(curr.amount) : curr.amount;
    if (!acc[curr.year]) {
      acc[curr.year] = {};
    }

    acc[curr.year][curr.payment_type] = (acc[curr.year][curr.payment_type] || 0) + amount;
    return acc;
  }, {} as Record<number, Record<string, number>>);

  return (
    <section className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      <Card className="col-span-full">
        <CardHeader className="py-2">
          <CardTitle className="text-lg flex justify-between items-center">
            <span>Total Deferred Amount</span>
            <span className="text-xl">
              <span>$</span>
              <span className="tabular-nums">
                {formatCurrency(safeParseDecimal(summary.grandTotal).toNumber())}
              </span>
            </span>
          </CardTitle>
        </CardHeader>
      </Card>

      {/* Payment Type Breakdown */}
      <Card>
        <CardHeader className="pb-2">
          <CardTitle className="text-base">Payment Type Distribution</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            {Object.entries(paymentTypeTotals).map(([type, amount]) => (
              <div key={type} className="flex justify-between items-center">
                <span className="font-medium">{type}</span>
                <span className="tabular-nums flex gap-1">
                  <span>$</span>
                  <span>{formatCurrency(amount)}</span>
                </span>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Year Breakdown */}
      <Card>
        <CardHeader className="pb-2">
          <CardTitle className="text-base">Year Distribution</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            {Object.entries(yearTotals)
              .sort(([yearA], [yearB]) => parseInt(yearA) - parseInt(yearB))
              .map(([year, amount]) => (
                <div key={year} className="flex justify-between items-center">
                  <span className="font-medium">{year}</span>
                  <span className="tabular-nums flex gap-1">
                    <span>$</span>
                    <span>{formatCurrency(amount)}</span>
                  </span>
                </div>
              ))}
          </div>
        </CardContent>
      </Card>

      {/* Year Payment Breakdown */}
      <Card>
        <CardHeader className="pb-2">
          <CardTitle className="text-base">Year Distribution by Payment Type</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {Object.entries(yearPaymentTotals)
              .sort(([yearA], [yearB]) => parseInt(yearA) - parseInt(yearB))
              .map(([year, paymentTypes]) => (
                <div key={year} className="space-y-1">
                  <div className="font-medium text-sm">{year}</div>
                  <div className="pl-4 space-y-1">
                    {Object.entries(paymentTypes).map(([type, amount]) => (
                      <div key={`${year}-${type}`} className="flex justify-between items-center text-sm">
                        <span className="text-gray-600">{formatPaymentTypeLabel(type)}</span>
                        <span className="tabular-nums flex gap-1">
                          <span>$</span>
                          <span>{formatCurrency(amount)}</span>
                        </span>
                      </div>
                    ))}
                    <div className="flex justify-between items-center pt-1 border-t">
                      <span className="font-medium">Total</span>
                      <span className="tabular-nums flex gap-1 font-medium">
                        <span>$</span>
                        <span>{formatCurrency(Object.values(paymentTypes).reduce((sum, val) => sum + val, 0))}</span>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </CardContent>
      </Card>

      {/* Customer Breakdown */}
      <Card className="col-span-full">
        <CardHeader className="pb-2">
          <CardTitle className="text-base">Customer Distribution</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {Object.entries(summary.totalsWithPaymentType
              .reduce((acc, curr) => {
                const amount = typeof curr.amount === 'string' ? parseFloat(curr.amount) : curr.amount;
                acc[curr.customer_name] = (acc[curr.customer_name] || 0) + amount;
                return acc;
              }, {} as Record<string, number>))
              .sort(([nameA], [nameB]) => nameA.localeCompare(nameB))
              .map(([customerName, amount]) => (
                <div key={customerName} className="flex justify-between items-center p-2 bg-muted rounded-md">
                  <span className="font-medium truncate">{customerName}</span>
                  <span className="tabular-nums flex gap-1 ml-2">
                    <span>$</span>
                    <span>{formatCurrency(amount)}</span>
                  </span>
                </div>
              ))}
          </div>
        </CardContent>
      </Card>
    </section >
  )
}

export function ReleasedSection({ accountingStyle }: { accountingStyle: boolean }) {
  const listReleasedRes = useListDeferredReleased();

  const released: DeferredReleasedSummary = listReleasedRes?.data?.data || [];

  const formatCurrency = (amount: number) => {
    if (!accountingStyle) {
      return `${Math.abs(amount).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`;
    }

    const isNegative = amount < 0;
    const stylizedFormat = Math.abs(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    if (isNegative) {
      return `(${stylizedFormat})`
    }

    return `${stylizedFormat}`;
  };

  const pagedReleased = useMemo(() => {
    return released?.map(item => ({
      id: item.id,
        amount: !!item.amount ? `$ ${formatCurrency(safeParseDecimal(item.amount || "0").toNumber())}` : "",
        note: item.note,
        communication_channel: item.communication_channel,
        Invoice: item.Invoice ? {
          ...item.Invoice,
          amount: !!item.Invoice.amount ? `$ ${formatCurrency(safeParseDecimal(item.Invoice.amount || "0").toNumber())}` : "",
        } : undefined,
        Contract: item.Contract ? {
          ...item.Contract,
          amount: !!item.Contract.amount ? `$ ${formatCurrency(safeParseDecimal(item.Contract.amount || "0").toNumber())}` : "",
          name: `${moment.tz(item.Contract.settlement_date, CommonDestinationTimezone).format("MM/DD/YYYY")} - ${item.Contract.contract_no} - ${item.Contract.settlement_no}`
        } : undefined,
    })) || [];
  }, [released]);


  const loadingOrErrorUi = GenerateLoadingOrError([listReleasedRes]);
  if (loadingOrErrorUi) return loadingOrErrorUi;

  const pairedColumns = [{
    key: "customer_name",
    label: "Customer",
    value: (item: any) => item.Contract ? <span className="">{item.Contract?.Customer?.long_name || ""}</span> : <span className="">{item.Invoice?.Customer?.long_name || ""}</span>
  }, {
    key: "amount",
    label: "Released Amount",
  }, {
    key: "note",
    label: "Note",
  }, {
    key: "communication_channel",
    label: "Communication Channel",
  }, {
    key: "linked_name",
    label: "Name",
    value: (item: any) => item.Contract ? <span className="">{item.Contract.name}</span> : <span className="">{item.Invoice.name}</span>
  }, {
    key: "linked_amount",
    label: "Amount",
    value: (item: any) => item.Contract ? <span className="">{item.Contract.amount}</span> : <span className="">{item.Invoice.amount}</span>
  }].map((item: any) => {
    return {
      id: item.key,
      header: item.label,
      cell: ({ row }: any) => item.value ? item?.value(row.original) : row.original[item.key],
      onCellClick: true,
      isCellClick: true,
      enableSorting: true,
      enableHiding: false,
    };
  });

  return (
    <div>
      <DataTable
        className={`grid overflow-y-auto overflow-x-auto`}
        height={'600px'}
        onCellClick={(item) => { }}
        columns={pairedColumns}
        data={pagedReleased}
      />
    </div>
  );
}

export function DetailSection({ accountingStyle }: { accountingStyle: boolean }) {
  const { toast } = useToast();
  const [contractFilters, setContractFilters] = useState<ContractFilter>({});
  const [invoiceFilters, setInvoiceFilters] = useState<InvoiceFilter>({});
  const [baseFilters, setBaseFilters] = useState<BaseFilter>({});
  const [tempBaseFilters, setTempBaseFilters] = useState<BaseFilter>({});
  const [tempContractFilters, setTempContractFilters] = useState<ContractFilter>({});
  const [tempInvoiceFilters, setTempInvoiceFilters] = useState<InvoiceFilter>({});
  const [isBaseFilterDialogOpen, setIsBaseFilterDialogOpen] = useState(false);
  const [isCreateContractOpen, setIsCreateContractOpen] = useState(false);
  const [isCreateInvoiceOpen, setIsCreateInvoiceOpen] = useState(false);
  const [isGenerateReportOpen, setIsGenerateReportOpen] = useState(false);
  const [isUpdatingCustomer, setIsUpdatingCustomer] = useState(false);
  const [selectedContract, setSelectedContract] = useState<DeferredPaymentContract | null>(null);
  const [selectedInvoice, setSelectedInvoice] = useState<DeferredPaymentInvoice | null>(null);
  const [isContractFilterDialogOpen, setIsContractFilterDialogOpen] = useState(false);
  const [isInvoiceFilterDialogOpen, setIsInvoiceFilterDialogOpen] = useState(false);
  const [activeFilterTab, setActiveFilterTab] = useState<"contracts" | "invoices">("contracts");
  const customersRes = useListCustomers();
  const updateCustomerDeferredPaymentLienStatusRes = useUpdateCustomerDeferredPaymentLienStatus();

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const baseFiltersApplied = useRef(false);
  const contractFiltersApplied = useRef(false);
  const invoiceFiltersApplied = useRef(false);

  const fullContractFilters = useMemo(() => {
    return {
      ...contractFilters,
      ...baseFilters,
      has_been_released: false
    };
  }, [contractFilters, baseFilters]);

  const fullInvoiceFilters = useMemo(() => {
    return {
      ...invoiceFilters,
      ...baseFilters,
      has_been_released: false
    };
  }, [invoiceFilters, baseFilters]);

  const listContractRes = useListDeferredContracts(fullContractFilters);
  const listInvoiceRes = useListDeferredInvoices(fullInvoiceFilters);

  // Update temporary filters when dialog opens
  useEffect(() => {
    if (isContractFilterDialogOpen) {
      setTempContractFilters({ ...contractFilters });
    }
  }, [isContractFilterDialogOpen]);

  useEffect(() => {
    if (isInvoiceFilterDialogOpen) {
      setTempInvoiceFilters({ ...invoiceFilters });
    }
  }, [isInvoiceFilterDialogOpen]);

  useEffect(() => {
    if (isBaseFilterDialogOpen) {
      setTempBaseFilters({ ...baseFilters });
    }
  }, [isBaseFilterDialogOpen]);

  const contracts: DeferredPaymentContract[] = listContractRes?.data?.data || [];
  const invoices: DeferredPaymentInvoice[] = listInvoiceRes?.data?.data || [];
  const customers: Customer[] = customersRes?.data?.data || [];

  const customerOptions = useMemo(() => {
    return customers.map((customer: Customer) => ({
      value: customer.id,
      label: customer?.long_name
    })).filter(item => !!item.value);
  }, [customers]);

  const yearOptions = useMemo(() => {
    // Generate options for years from current year to current year + 5
    const currentYear = new Date().getFullYear();
    return [
      { value: (currentYear - 1).toString(), label: (currentYear - 1).toString() },
      ...Array.from({ length: 6 }, (_, i) => currentYear + i).map(year => ({
        value: year.toString(),
        label: year.toString()
      }))
    ];
  }, []);

  const combinedData: DeferredPaymentItem[] = useMemo(() => {
    // Process contracts (positive amounts)
    const contractItems = contracts.map(mapContract);

    // Process invoices (negative amounts)
    const invoiceItems = invoices.map(mapInvoice);

    return [...contractItems, ...invoiceItems];
  }, [contracts, invoices]);

  // Group data by customer -> year -> commodity
  const groupedData = useMemo(() => {
    return groupData(combinedData);
  }, [combinedData]);

  const customerMap = useMemo(() => {
    return (customers || [])?.reduce((acc: Record<string, Customer>, customer) => {
      acc[customer.id] = customer;
      return acc;
    }, {});
  }, [customers]);

  // Calculate total sum
  const totalAmount = useMemo(() => {
    return combinedData.reduce((sum, item) => sum + item.numericAmount, 0);
  }, [combinedData]);

  const loadingOrErrorUi = GenerateLoadingOrError([listInvoiceRes, listContractRes]);
  if (loadingOrErrorUi) return loadingOrErrorUi;

  // Toggle expanded state for a specific group
  const toggleGroup = (groupKey: string) => {
    setExpanded(prev => ({
      ...prev,
      [groupKey]: !prev[groupKey]
    }));
  }

  // Toggle all expanded state
  const toggleAllExpanded = () => {
    if (Object.values(expanded).some(value => value)) {
      // If any are expanded, collapse all
      setExpanded({});
    } else {
      // Otherwise, expand all levels (customers, years, and commodities)
      const expandedState: ExpandedState = {};

      // Expand all customer nodes
      Object.entries(groupedData).forEach(([customerName, yearGroups]) => {
        expandedState[`customer-${customerName}`] = true;

        // Expand all year nodes for this customer
        Object.entries(yearGroups).forEach(([year, commodityGroups]) => {
          expandedState[`year-${customerName}-${year}`] = true;

          // Expand all commodity nodes for this year
          Object.keys(commodityGroups).forEach(commodity => {
            expandedState[`commodity-${customerName}-${year}-${commodity}`] = true;
          });
        });
      });

      setExpanded(expandedState);
    }
  };

  // Handle row click for data rows
  const handleRowClick = (item: DeferredPaymentItem) => {
    if (item.type === 'contract') {
      const contract = contracts.find(c => c.id === item.id);
      if (contract) {
        setSelectedContract(contract);
      }
    } else if (item.type === 'invoice') {
      const invoice = invoices.find(i => i.id === item.id);
      if (invoice) {
        setSelectedInvoice(invoice);
      }
    }
  };

  const handleUpdateCustomerDeferredPaymentLienStatus = async (customer_id: string, lastStatus?: string) => {
    try {
      if (isUpdatingCustomer) return;

      setIsUpdatingCustomer(true);
      const result = await updateCustomerDeferredPaymentLienStatusRes({ customer_id, deferred_payment_lien_status: lastStatus === "active" ? null : "active" });
      if (!result.success) {
        throw result;
      }

      toast({
        title: "Updated",
        description: "",
      });

    } catch (error) {
      console.error("Failed to update customer deferred payment lien status", error);
      toast({
        title: "Failed to lien",
        description: "",
      });
    } finally {
      setIsUpdatingCustomer(false);
    }
  }

  // Calculate summary amounts
  const calculateGroupTotal = (items: DeferredPaymentItem[]) => {
    return items.reduce((total, item) => total.plus(item.numericAmount), new Decimal(0)).toNumber();
  };

  const calculateUomTotal = (items: DeferredPaymentItem[]) => {
    return items.reduce((total, item) => total.plus(item.numericUomAmount), new Decimal(0)).toNumber();
  };

  // Format currency amounts
  const formatCurrency = (amount: number) => {
    if (!accountingStyle) {
      return `${Math.abs(amount).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`;
    }

    const isNegative = amount < 0;
    const stylizedFormat = Math.abs(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    if (isNegative) {
      return `(${stylizedFormat})`
    }

    return `${stylizedFormat}`;
  };

  // Calculate year total
  const calculateYearTotal = (commodityGroups: Record<string, DeferredPaymentItem[]>) => {
    return Object.values(commodityGroups).reduce(
      (sum, items) => sum + calculateGroupTotal(items),
      0
    );
  };

  // Calculate customer total
  const calculateCustomerTotal = (yearGroups: YearGroups) => {
    return Object.values(yearGroups).reduce(
      (customerSum, commodityGroups) => customerSum + calculateYearTotal(commodityGroups),
      0
    );
  };

  // Apply contract filters
  const handleApplyContractFilters = (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    setContractFilters({ ...tempContractFilters });
    setIsContractFilterDialogOpen(false);
    contractFiltersApplied.current = true;
  };

  // Apply invoice filters
  const handleApplyInvoiceFilters = (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    setInvoiceFilters({ ...tempInvoiceFilters });
    setIsInvoiceFilterDialogOpen(false);
    invoiceFiltersApplied.current = true;
  };

  const handleApplyBaseFilters = (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    setBaseFilters({ ...tempBaseFilters });
    setIsBaseFilterDialogOpen(false);
    baseFiltersApplied.current = true;
  };

  // Reset contract filters
  const handleResetContractFilters = () => {
    setTempContractFilters({});
  };

  // Reset invoice filters
  const handleResetInvoiceFilters = () => {
    setTempInvoiceFilters({});
  };

  const handleResetBaseFilters = () => {
    setTempBaseFilters({});
  };

  // Remove a specific contract filter
  const removeContractFilter = (key: keyof ContractFilter) => {
    const newFilters = { ...contractFilters };
    delete newFilters[key];
    setContractFilters(newFilters);
  };

  // Remove a specific invoice filter
  const removeInvoiceFilter = (key: keyof InvoiceFilter) => {
    const newFilters = { ...invoiceFilters };
    delete newFilters[key];
    setInvoiceFilters(newFilters);
  };

  // Remove a specific invoice filter
  const removeBaseFilter = (key: keyof BaseFilter) => {
    const newFilters = { ...baseFilters };
    delete newFilters[key];
    setBaseFilters(newFilters);
  };

  const getCommodityNameByValue = (value: string) => {
    const option = CommodityOptions.find(opt => opt.value === value);
    return option?.label || value;
  };

  // Helper function to get customer name by ID
  const getCustomerNameById = (id: string) => {
    const customer = customers.find(c => c.id === id);
    return customer?.long_name || 'Unknown';
  };


  return (
    <section className="flex min-h-full flex-1 flex-col justify-start py-6">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <span className="flex justify-end gap-2">
          <Button variant="outline" onClick={toggleAllExpanded}>
            {Object.values(expanded).some(value => value) ? 'Collapse All' : 'Expand All'}
          </Button>
          <Dialog open={isBaseFilterDialogOpen} onOpenChange={setIsBaseFilterDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="outline" className="gap-2">
                <Filter className="h-4 w-4" />
                Base Filters
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[500px] md:max-w-[800px]">
              <DialogHeader>
                <DialogTitle>Base Filters</DialogTitle>
                <DialogDescription>
                </DialogDescription>
              </DialogHeader>

              <form className="grid gap-4 py-4" onSubmit={handleApplyBaseFilters}>
                <div className="grid grid-cols-4 items-center gap-4">
                  <label htmlFor="customer" className="text-right col-span-1">
                    Customer:
                  </label>
                  <Combobox
                    isModal
                    className="w-full col-span-3"
                    value={tempBaseFilters.customer_id || ""}
                    onValueChange={(value) => setTempBaseFilters({ ...tempBaseFilters, customer_id: value === "" ? undefined : value })}
                    options={customerOptions}
                    virtualizeList
                    popoverClassName="w-[400px] h-[200px]"
                  />
                </div>

                <div className="grid grid-cols-4 items-center gap-4">
                  <label htmlFor="year" className="text-right">
                    Deferred to Year:
                  </label>
                  <Input
                    id="year"
                    type="number"
                    placeholder="YYYY"
                    className="col-span-3"
                    value={tempBaseFilters.deferred_to_year || ""}
                    onChange={(e) =>
                      setTempBaseFilters({
                        ...tempBaseFilters,
                        deferred_to_year: e.target.value ? parseInt(e.target.value) : undefined
                      })
                    }
                  />
                </div>

                <div className="grid grid-cols-4 items-center gap-4">
                  <label htmlFor="commodity" className="text-right col-span-1">
                    Commodity:
                  </label>
                  <Combobox
                    isModal
                    className="w-full col-span-3"
                    value={tempBaseFilters.commodity || ""}
                    onValueChange={(value) => setTempBaseFilters({ ...tempBaseFilters, commodity: value === "" ? undefined : value?.toLocaleUpperCase() })}
                    options={CommodityOptions}
                  />
                </div>

                <div className="grid grid-cols-4 items-center gap-4">
                  <label htmlFor="customer" className="text-right col-span-1">
                    Payment Type:
                  </label>
                  <Combobox
                    isModal
                    className="w-full col-span-3"
                    value={tempBaseFilters.payment_type || ""}
                    onValueChange={(value: string) => setTempBaseFilters({ ...tempBaseFilters, payment_type: value === "" ? undefined : value as DeferredPaymentType })}
                    options={DeferredPaymentTypeOptions}
                    virtualizeList
                    popoverClassName="w-[400px] h-[200px]"
                  />
                </div>
              </form>

              <DialogFooter>
                <Button variant="outline" onClick={handleResetBaseFilters}>
                  Reset Filters
                </Button>
                <Button onClick={handleApplyBaseFilters}>Apply Filters</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          {/* Contract Filter Dialog Trigger */}
          <Dialog open={isContractFilterDialogOpen} onOpenChange={setIsContractFilterDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="outline" className="gap-2">
                <Filter className="h-4 w-4" />
                Contract Filters
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[500px] md:max-w-[800px]">
              <DialogHeader>
                <DialogTitle>Filter Deferred Payment Contracts</DialogTitle>
                <DialogDescription>
                  Select criteria to filter the contract entries
                </DialogDescription>
              </DialogHeader>

              <form className="grid gap-4 py-4" onSubmit={handleApplyContractFilters}>
                <div className="grid grid-cols-4 items-center gap-4">
                  <label htmlFor="contractNo" className="text-right">
                    Contract #:
                  </label>
                  <Input
                    id="contractNo"
                    placeholder="Contract number"
                    className="col-span-3"
                    value={tempContractFilters.contract_no || ""}
                    onChange={(e) =>
                      setTempContractFilters({ ...tempContractFilters, contract_no: e.target.value || undefined })
                    }
                  />
                </div>

                <div className="grid grid-cols-4 items-center gap-4">
                  <label htmlFor="settlementNo" className="text-right">
                    Settlement #:
                  </label>
                  <Input
                    id="settlementNo"
                    placeholder="Settlement number"
                    className="col-span-3"
                    value={tempContractFilters.settlement_no || ""}
                    onChange={(e) =>
                      setTempContractFilters({ ...tempContractFilters, settlement_no: e.target.value || undefined })
                    }
                  />
                </div>

                <div className="grid grid-cols-4 items-center gap-4">
                  <label htmlFor="contractSent" className="text-right">
                    Contract Sent:
                  </label>
                  <Combobox
                    id="contractSent"
                    className="col-span-3 p-2 border rounded"
                    value={tempContractFilters.contract_sent === undefined ? "" : tempContractFilters.contract_sent ? "true" : "false"}
                    onValueChange={(value) => {
                      setTempContractFilters({
                        ...tempContractFilters,
                        contract_sent: value === "" ? undefined : value === "true"
                      });
                    }}
                    options={[{ value: "", label: "Any" }, { value: "true", label: "Yes" }, { value: "false", label: "No" }]}
                  />
                </div>

                <div className="grid grid-cols-4 items-center gap-4">
                  <label htmlFor="contractSigned" className="text-right">
                    Contract Signed:
                  </label>
                  <Combobox
                    id="contractSigned"
                    className="col-span-3 p-2 border rounded"
                    value={tempContractFilters.contract_signed === undefined ? "" : tempContractFilters.contract_signed ? "true" : "false"}
                    onValueChange={(value) => {
                      setTempContractFilters({
                        ...tempContractFilters,
                        contract_signed: value === "" ? undefined : value === "true"
                      });
                    }}
                    options={[{ value: "", label: "Any" }, { value: "true", label: "Yes" }, { value: "false", label: "No" }]}
                  />
                </div>

                <div className="grid grid-cols-4 items-center gap-4">
                  <label htmlFor="settlementDateRange" className="text-right">
                    Settlement Date Range:
                  </label>
                  <div className="col-span-3 flex gap-2">
                    <DatePicker
                      value={tempContractFilters.settlement_date_range?.start ? moment(tempContractFilters.settlement_date_range.start).toDate() : undefined}
                      onChange={(date) => {
                        setTempContractFilters({
                          ...tempContractFilters,
                          settlement_date_range: {
                            ...tempContractFilters.settlement_date_range || {},
                            start: date ? moment(date).format("YYYY-MM-DD") : undefined
                          } as any
                        });
                      }}
                      placeholder={"Start"}
                      className={cn(
                        "rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 w-full",
                      )}
                    />
                    <DatePicker
                      value={tempContractFilters.settlement_date_range?.end ? new Date(tempContractFilters.settlement_date_range.end) : undefined}
                      onChange={(date) => {
                        setTempContractFilters({
                          ...tempContractFilters,
                          settlement_date_range: {
                            ...tempContractFilters.settlement_date_range || {},
                            end: date ? moment(date).format("YYYY-MM-DD") : undefined
                          } as any
                        });
                      }}
                      placeholder="End Date"
                      className="w-full"
                    />
                  </div>
                </div>
              </form>

              <DialogFooter>
                <Button variant="outline" onClick={handleResetContractFilters}>
                  Reset Filters
                </Button>
                <Button onClick={handleApplyContractFilters}>Apply Filters</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          {/* Invoice Filter Dialog Trigger */}
          <Dialog open={isInvoiceFilterDialogOpen} onOpenChange={setIsInvoiceFilterDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="outline" className="gap-2">
                <Filter className="h-4 w-4" />
                Invoice Filters
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[500px] md:max-w-[800px]">
              <DialogHeader>
                <DialogTitle>Filter Deferred Payment Invoices</DialogTitle>
                <DialogDescription>
                  Select criteria to filter the invoice entries
                </DialogDescription>
              </DialogHeader>

              <form className="grid gap-4 py-4" onSubmit={handleApplyInvoiceFilters}>
                <div className="grid grid-cols-4 items-center gap-4">
                  <label htmlFor="createdBetween" className="text-right">
                    Created Between:
                  </label>
                  <div className="col-span-3 flex gap-2">
                    <DatePicker
                      value={tempInvoiceFilters.created_between?.start ? moment(tempInvoiceFilters.created_between.start).toDate() : undefined}
                      onChange={(date) => {
                        setTempInvoiceFilters({
                          ...tempInvoiceFilters,
                          created_between: {
                            ...tempInvoiceFilters.created_between || {},
                            start: date ? moment(date).format("YYYY-MM-DD") : undefined
                          } as any
                        });
                      }}
                      placeholder="Start Date"
                      className="w-full"
                    />
                    <DatePicker
                      value={tempInvoiceFilters.created_between?.end ? moment(tempInvoiceFilters.created_between.end).toDate() : undefined}
                      onChange={(date) => {
                        setTempInvoiceFilters({
                          ...tempInvoiceFilters,
                          created_between: {
                            ...tempInvoiceFilters.created_between || {},
                            end: date ? moment(date).format("YYYY-MM-DD") : undefined
                          } as any
                        });
                      }}
                      placeholder="End Date"
                      className="w-full"
                    />
                  </div>
                </div>
              </form>

              <DialogFooter>
                <Button variant="outline" onClick={handleResetInvoiceFilters}>
                  Reset Filters
                </Button>
                <Button onClick={handleApplyInvoiceFilters}>Apply Filters</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          <GenerateDownloadReportDialog
            isGenerateReportOpen={isGenerateReportOpen}
            setIsGenerateReportOpen={setIsGenerateReportOpen}
            contract_filters={fullContractFilters}
            invoice_filters={fullInvoiceFilters}
          />
          <div className="flex justify-end gap-2">
            <CreateContractModal
              customers={customers}
              onSuccess={() => { }}
            />
            <CreateInvoiceModal
              customers={customers}
              onSuccess={() => { }}
            />
            <UpdateContractModal
              isOpen={!!selectedContract}
              onDialogChange={(value) => {
                if (!value) setSelectedContract(null);
              }}
              customers={customers}
              contract={selectedContract}
              onSuccess={() => { }}
            />

            <UpdateInvoiceModal
              isOpen={!!selectedInvoice}
              onDialogChange={(value) => {
                if (!value) setSelectedInvoice(null);
              }}
              customers={customers}
              invoice={selectedInvoice}
              onSuccess={() => { }}
            />
          </div>
        </span>
      </div>
      <div className="mb-4">
        {Object.keys(baseFilters).length > 0 && (
          <div>
            <h3 className="text-sm font-medium mb-2">Active Contract Filters</h3>
            <div className="flex flex-wrap gap-2">
              {baseFilters.customer_id && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Customer: {getCustomerNameById(baseFilters.customer_id)}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeBaseFilter('customer_id')} />
                </Badge>
              )}
              {baseFilters.deferred_to_year && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Year: {baseFilters.deferred_to_year}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeBaseFilter('deferred_to_year')} />
                </Badge>
              )}
              {baseFilters.commodity && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Commodity: {baseFilters.commodity}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeBaseFilter('commodity')} />
                </Badge>
              )}
              {baseFilters.payment_type && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Payment Type: {DeferredPaymentTypeOptions.find((item: any) => item.value === baseFilters.payment_type)?.label || baseFilters.payment_type}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeBaseFilter('payment_type')} />
                </Badge>
              )}
            </div>
          </div>
        )}
      </div>
      {/* Display active filters */}
      <div className="mb-4">
        {/* Contract Filters */}
        {Object.keys(contractFilters).length > 0 && (
          <div>
            <h3 className="text-sm font-medium mb-2">Active Contract Filters</h3>
            <div className="flex flex-wrap gap-2">
              {contractFilters.contract_no && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Contract #: {contractFilters.contract_no}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeContractFilter('contract_no')} />
                </Badge>
              )}
              {contractFilters.settlement_no && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Settlement #: {contractFilters.settlement_no}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeContractFilter('settlement_no')} />
                </Badge>
              )}
              {contractFilters.contract_sent !== undefined && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Contract Sent: {contractFilters.contract_sent ? 'Yes' : 'No'}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeContractFilter('contract_sent')} />
                </Badge>
              )}
              {contractFilters.contract_signed !== undefined && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Contract Signed: {contractFilters.contract_signed ? 'Yes' : 'No'}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeContractFilter('contract_signed')} />
                </Badge>
              )}
              {contractFilters.amount && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Amount: {contractFilters.amount}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeContractFilter('amount')} />
                </Badge>
              )}
              {contractFilters.uom_amount && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  UOM Amount: {contractFilters.uom_amount}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeContractFilter('uom_amount')} />
                </Badge>
              )}
              {contractFilters.settlement_date_range?.start && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Settlement Start: {moment.tz(contractFilters.settlement_date_range.start, CommonDestinationTimezone).format("MM/DD/YYYY")}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeContractFilter('settlement_date_range')} />
                </Badge>
              )}
              {contractFilters.settlement_date_range?.end && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Settlement End: {moment.tz(contractFilters.settlement_date_range.end, CommonDestinationTimezone).format("MM/DD/YYYY")}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeContractFilter('settlement_date_range')} />
                </Badge>
              )}
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setContractFilters({})}
                className="h-6 px-2 text-xs"
              >
                Clear Contract Filters
              </Button>
            </div>
          </div>
        )}

        {/* Invoice Filters */}
        {Object.keys(invoiceFilters).length > 0 && (
          <div className="mt-2">
            <h3 className="text-sm font-medium mb-2">Active Invoice Filters</h3>
            <div className="flex flex-wrap gap-2">
              {invoiceFilters.name && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Name: {invoiceFilters.name}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeInvoiceFilter('name')} />
                </Badge>
              )}
              {invoiceFilters.amount && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Amount: {invoiceFilters.amount}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeInvoiceFilter('amount')} />
                </Badge>
              )}
              {invoiceFilters.created_between?.start && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Created At Start: {moment.tz(invoiceFilters.created_between.start, CommonDestinationTimezone).format("MM/DD/YYYY")}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeContractFilter('created_between')} />
                </Badge>
              )}
              {invoiceFilters.created_between?.end && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  Created At End: {moment.tz(invoiceFilters.created_between.end, CommonDestinationTimezone).format("MM/DD/YYYY")}
                  <X className="h-3 w-3 cursor-pointer" onClick={() => removeContractFilter('created_between')} />
                </Badge>
              )}
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setInvoiceFilters({})}
                className="h-6 px-2 text-xs"
              >
                Clear Invoice Filters
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="py-4">
        <div className="rounded-md border">
          <Table className="compact-table">
            <TableHeader>
              <TableRow className="h-8">
                <TableHead className="py-2">Customer / Year / Commodity</TableHead>
                <TableHead className="py-2">Settlement Date</TableHead>
                <TableHead className="py-2">Contract</TableHead>
                <TableHead className="py-2 text-center">Defer Pay Sent</TableHead>
                <TableHead className="py-2  text-center">Defer Pay Signed</TableHead>
                <TableHead className="py-2">Settlement</TableHead>
                <TableHead className="py-2">UOM Amount</TableHead>
                <TableHead className="py-2 text-center">Interest</TableHead>
                <TableHead className="py-2 text-right">Amount</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Object.entries(groupedData).length > 0 ? (
                // Render customer groups
                Object.entries(groupedData).map(([customerId, yearGroups]) => (
                  <Fragment key={`customer-${customerId}`}>
                    {/* Customer Row */}
                    <TableRow className="customer-row h-8">
                      <TableCell colSpan={5} className="py-1">
                        <div className="flex items-center">
                          <button
                            onClick={() => toggleGroup(`customer-${customerId}`)}
                            className="mr-1 hover:bg-gray-200 rounded-full"
                          >
                            {expanded[`customer-${customerId}`] ?
                              <ChevronDown className="h-3 w-3" /> :
                              <ChevronRight className="h-3 w-3" />
                            }
                          </button>
                          {customerMap[customerId]?.long_name || ""}
                          <span className="ml-1 text-gray-500 text-xs">
                            ({Object.keys(yearGroups).length} years)
                          </span>
                          {customerMap[customerId]?.preferred_defer_payment_type && (
                            <span className="ml-1 text-gray-500 text-xs">
                              {DeferredPaymentTypeOptions.find(item => item.value === customerMap[customerId]?.preferred_defer_payment_type)?.label || customerMap[customerId]?.preferred_defer_payment_type || ""}
                            </span>
                          )}
                        </div>
                      </TableCell>

                      <TableCell colSpan={1} className="py-1 text-start flex items-center justify-start">
                        <span className="text-center">
                          Lien:
                        </span>
                        {isUpdatingCustomer ? <Loader className="h-4 w-4 flex items-center justify-center" svgClassName="fill-green-500 h-3 w-3" /> : (
                          <Checkbox
                            className="ml-2"
                            checked={customerMap[customerId]?.deferred_payment_lien_status === "active"}
                            onCheckedChange={() => handleUpdateCustomerDeferredPaymentLienStatus(customerId, customerMap[customerId]?.deferred_payment_lien_status)}
                            disabled={isUpdatingCustomer}
                          />
                        )}

                      </TableCell>
                      <TableCell colSpan={3} className="py-1"></TableCell>
                    </TableRow>

                    {/* Year Groups (only shown if customer is expanded) */}
                    {expanded[`customer-${customerId}`] && Object.entries(yearGroups).map(([year, commodityGroups]) => (
                      <Fragment key={`year-${customerId}-${year}`}>
                        {/* Commodity Groups (only shown if year is expanded) */}
                        {Object.entries(commodityGroups).map(([commodity, items]) => (
                          <Fragment key={`commodity-${customerId}-${year}-${commodity}`}>
                            {/* Commodity Row */}
                            <TableRow className="commodity-row h-6">
                              <TableCell colSpan={9} className="pl-10 py-0.5">
                                <div className="ml-6 flex items-center">
                                  <button
                                    onClick={() => toggleGroup(`commodity-${customerId}-${year}-${commodity}`)}
                                    className="mr-1 hover:bg-gray-200 rounded-full"
                                  >
                                    {expanded[`commodity-${customerId}-${year}-${commodity}`] ?
                                      <ChevronDown className="h-3 w-3" /> :
                                      <ChevronRight className="h-3 w-3" />
                                    }
                                  </button>
                                  {commodity}
                                  <span className="ml-1 text-gray-500 text-xs">
                                    ({items.length} items)
                                  </span>
                                </div>
                              </TableCell>
                            </TableRow>

                            {/* Item Rows (only shown if commodity is expanded) */}
                            {expanded[`commodity-${customerId}-${year}-${commodity}`] &&
                              items.map((item) => (
                                <TableRow
                                  key={`item-${item.id}`}
                                  className="cursor-pointer hover:bg-gray-100 h-6 detail-row"
                                >
                                  <TableCell className="pl-14 py-0.5 text-xs flex cursor-default" onClick={(e) => {
                                    if (e?.preventDefault) { e.preventDefault(); }
                                  }}>
                                    <ReleaseDialog id={item.id} type={item.type} item={item} />
                                    {item.type === 'contract' ? 'Contract' : 'Invoice'}
                                    <span className="text-xs text-gray-500">
                                      {(item.deferredReleasesLength || 0) > 0 && (
                                        <>
                                          <span className="text-xs text-gray-500 ml-2">
                                            {item.deferredReleasesLength} Release{item.deferredReleasesLength === 1 ? "" : "s"}. Original {formatCurrency(item.original_amount || 0)}
                                          </span>
                                        </>
                                      ) }
                                    </span>
                                  </TableCell>
                                  <TableCell className="py-0.5 text-xs" onClick={() => handleRowClick(item)}>

                                    {item.type === 'contract' && item.settlement_date
                                      ? moment(item.settlement_date).format('MM/DD/YYYY')
                                      : ''}
                                  </TableCell>
                                  <TableCell className="py-0.5 text-xs" onClick={() => handleRowClick(item)}>{item.type === 'contract' ? item.contract_no : ""}</TableCell>
                                  <TableCell className="py-0.5 text-xs text-center font-semibold" onClick={() => handleRowClick(item)}>{item.contract_sent ? 'X' : <span />}</TableCell>
                                  <TableCell className="py-0.5 text-xs text-center font-semibold" onClick={() => handleRowClick(item)}>{item.contract_signed ? 'X' : <span />}</TableCell>
                                  <TableCell className="py-0.5 text-xs" onClick={() => handleRowClick(item)}>{item.type === 'contract' ? item.settlement_no : ''}</TableCell>
                                  <TableCell className={cn("py-0.5 text-xs")} onClick={() => handleRowClick(item)}>
                                    {item.type === 'contract' && item.uom_amount
                                      ? `${parseFloat(item.uom_amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${item.uom}`
                                      : ''}
                                  </TableCell>
                                  <TableCell className={cn("py-0.5 text-xs", item.type === "invoice" ? "bg-red-200" : "'")} onClick={() => handleRowClick(item)}>
                                    {item.interest != null && item.numericInterest != null && (
                                      <>
                                        <span>$</span>
                                        {formatCurrency(item.numericInterest)}
                                      </>
                                    )}
                                    {item.type === 'invoice' && (item.invoice_name)}
                                  </TableCell>
                                  <TableCell className={`py-0.5 text-xs text-right ${accountingStyle ? "" : (item.numericAmount >= 0) ? 'text-green-600' : 'text-red-600'} flex justify-between`} onClick={() => handleRowClick(item)}>
                                    <span>$</span>
                                    {formatCurrency(item.numericAmount)}
                                  </TableCell>
                                </TableRow>
                              ))
                            }

                            {/* Commodity Subtotal */}
                            {expanded[`commodity-${customerId}-${year}-${commodity}`] && (
                              <TableRow className="subtotal-row h-6 bg-[#D0CECE]/80 hover:bg-[#D0CECE]">
                                <TableCell colSpan={6} className="py-0.5 text-xs font-medium">
                                </TableCell>
                                <TableCell className="py-0.5 text-xs text-left flex justify-start font-medium">
                                  {calculateUomTotal(items) > 0 && (
                                    <>{formatCurrency(calculateUomTotal(items))} {items?.[0].uom || ""}</>
                                  )}
                                </TableCell>
                                <TableCell className="py-0.5 text-xs font-medium">
                                  <div className="">{commodity} Subtotal:</div>
                                </TableCell>
                                <TableCell className="py-0.5 text-xs text-right flex justify-between font-medium">
                                  <span>$</span>
                                  {formatCurrency(calculateGroupTotal(items))}
                                </TableCell>
                              </TableRow>
                            )}
                          </Fragment>
                        ))}

                        {/* Year Subtotal */}
                        <TableRow className="subtotal-row year-row h-6">
                          <TableCell colSpan={7} className="py-0.5 text-xs font-medium">
                          </TableCell>
                          <TableCell className="py-0.5 text-xs font-medium">
                            <div className="">Year {year} Subtotal:</div>
                          </TableCell>
                          <TableCell className="py-0.5 text-xs text-right flex justify-between font-medium">
                            <span>$</span>
                            {formatCurrency(calculateYearTotal(commodityGroups))}
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))}

                    {/* Customer Subtotal */}
                    <TableRow className="subtotal-row customer-row h-7 !bg-[#871910] text-white">
                      <TableCell colSpan={8} className="py-1 text-md">
                        {customerMap[customerId]?.long_name} Total:
                      </TableCell>
                      <TableCell className="py-1 text-sm text-right flex justify-between font-medium">
                        <span>$</span>
                        {formatCurrency(calculateCustomerTotal(yearGroups))}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              )}

              {/* Grand Total Row */}
              <TableRow className="bg-gray-300 font-bold h-8 subtotal-row">
                <TableCell colSpan={7} className=""></TableCell>
                <TableCell className="py-1">Grand Total</TableCell>
                <TableCell className="py-1 text-right ">
                  {formatCurrency(totalAmount)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </section >
  );
}

export function CreateContractForm({
  onCloseForm,
  onSuccess,
  customers,
}: {
  onCloseForm: () => void;
  onSuccess?: (data: any) => void;
  customers: any[];
}) {
  const createContract = useCreateDeferredPaymentContract();
  const populateWithDefaults: any = createContractState.useInputsState((state: any) => state.populateWith);

  // Set default values
  useEffect(() => {
    populateWithDefaults({
      settlement_date: new Date(),
      deferred_to_year: new Date().getFullYear() + 1,
      contract_sent: false,
      contract_signed: false
    });
  }, []);

  return (
    <FormGridHelper
      state={createContractState}
      activeSchema={DeferredPaymentContractFormSchema}
      id={"CreateDeferredPaymentContract"}
      externalReferences={{
        paymentTypeOptions: DeferredPaymentTypeOptions,
        customerOptions: genIdOptionList(customers, "long_name"),
        commodityOptions: CommodityOptions,
        uomOptions: uomOptions,
      }}
      failMessage="Failed to create contract"
      successMessage="Created"
      onSubmitTo={createContract}
      onCloseForm={onCloseForm}
      onSuccess={onSuccess}
    />
  );
}

export function UpdateContractForm({
  onCloseForm,
  onSuccess,
  customers,
  contract,
}: {
  onCloseForm: () => void;
  onSuccess?: (data: any) => void;
  customers: any[];
  contract: any;
}) {
  const updateContract = useUpdateDeferredPaymentContract();
  const populateWithContractData: any = updateContractState.useInputsState((state: any) => state.populateWith);

  // Populate form with contract data
  useEffect(() => {
    if (contract) {
      populateWithContractData(contract);
    }
  }, [contract]);

  return (
    <FormGridHelper
      state={updateContractState}
      activeSchema={DeferredPaymentContractFormSchema}
      id={"UpdateDeferredPaymentContract"}
      externalReferences={{
        paymentTypeOptions: DeferredPaymentTypeOptions,
        customerOptions: genIdOptionList(customers, "long_name"),
        commodityOptions: CommodityOptions,
        uomOptions: uomOptions,
      }}
      failMessage="Failed to update contract"
      successMessage="Updated"
      onSubmitTo={updateContract}
      onCloseForm={onCloseForm}
      onSuccess={onSuccess}
    />
  );
}

// Invoice form components
export function CreateInvoiceForm({
  onCloseForm,
  onSuccess,
  customers,
}: {
  onCloseForm: () => void;
  onSuccess?: (data: any) => void;
  customers: any[];
}) {
  const createInvoice = useCreateDeferredPaymentInvoice();
  const populateWithDefaults: any = createInvoiceState.useInputsState((state: any) => state.populateWith);

  // Set default values
  useEffect(() => {
    populateWithDefaults({
      deferred_to_year: new Date().getFullYear() + 1,
    });
  }, []);

  return (
    <FormGridHelper
      state={createInvoiceState}
      activeSchema={DeferredPaymentInvoiceFormSchema}
      id={"CreateDeferredPaymentInvoice"}
      externalReferences={{
        paymentTypeOptions: DeferredPaymentTypeOptions,
        customerOptions: genIdOptionList(customers, "long_name"),
        commodityOptions: CommodityOptions,
      }}
      failMessage="Failed to create invoice"
      successMessage="Created"
      onSubmitTo={createInvoice}
      onCloseForm={onCloseForm}
      onSuccess={onSuccess}
    />
  );
}

export function UpdateInvoiceForm({
  onCloseForm,
  onSuccess,
  customers,
  invoice,
}: {
  onCloseForm: () => void;
  onSuccess?: (data: any) => void;
  customers: any[];
  invoice: any;
}) {
  const updateInvoice = useUpdateDeferredPaymentInvoice();
  const populateWithInvoiceData: any = updateInvoiceState.useInputsState((state: any) => state.populateWith);

  // Populate form with invoice data
  useEffect(() => {
    if (invoice) {
      populateWithInvoiceData({
        ...invoice,
        amount: safeParseDecimal(invoice.amount).abs().toString()
      });
    }
  }, [invoice]);

  return (
    <FormGridHelper
      state={updateInvoiceState}
      activeSchema={DeferredPaymentInvoiceFormSchema}
      id={"UpdateDeferredPaymentInvoice"}
      externalReferences={{
        paymentTypeOptions: DeferredPaymentTypeOptions,
        customerOptions: genIdOptionList(customers, "long_name"),
        commodityOptions: CommodityOptions,
      }}
      failMessage="Failed to update invoice"
      successMessage="Updated"
      onSubmitTo={updateInvoice}
      onCloseForm={onCloseForm}
      onSuccess={onSuccess}
    />
  );
}

// Dialog modals for creating and updating
export function CreateContractModal({
  customers,
  onSuccess
}: {
  customers: any[];
  onSuccess?: (data: any) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <PrimitiveDialogForm
      className="max-w-[98%]"
      isOpen={isOpen}
      onDialogChange={setIsOpen}
      buttonLabel={"Create Contract"}
      dialogTitle={"Create Deferred Payment Contract"}
      form={
        <CreateContractForm
          onCloseForm={() => setIsOpen(false)}
          onSuccess={(data) => {
            setIsOpen(false);
            if (onSuccess) onSuccess(data);
          }}
          customers={customers}
        />
      }
    />
  );
}

export function UpdateContractModal({
  isOpen,
  onDialogChange,
  customers,
  contract,
  onSuccess
}: {
  isOpen: boolean;
  onDialogChange: (value: boolean) => void;
  customers: any[];
  contract: any;
  onSuccess?: (data: any) => void;
}) {
  return (
    <PrimitiveDialogForm
      className="max-w-[98%]"
      isOpen={isOpen}
      onDialogChange={onDialogChange}
      dialogTitle={"Update Deferred Payment Contract"}
      form={
        <UpdateContractForm
          onCloseForm={() => onDialogChange(false)}
          onSuccess={(data) => {
            onDialogChange(false);
            if (onSuccess) onSuccess(data);
          }}
          customers={customers}
          contract={contract}
        />
      }
    />
  );
}

export function CreateInvoiceModal({
  customers,
  onSuccess
}: {
  customers: any[];
  onSuccess?: (data: any) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <PrimitiveDialogForm
      className="max-w-[80%]"
      isOpen={isOpen}
      onDialogChange={setIsOpen}
      buttonLabel={"Create Invoice"}
      dialogTitle={"Create Deferred Payment Invoice"}
      form={
        <CreateInvoiceForm
          onCloseForm={() => setIsOpen(false)}
          onSuccess={(data) => {
            setIsOpen(false);
            if (onSuccess) onSuccess(data);
          }}
          customers={customers}
        />
      }
    />
  );
}

export function UpdateInvoiceModal({
  isOpen,
  onDialogChange,
  customers,
  invoice,
  onSuccess
}: {
  isOpen: boolean;
  onDialogChange: (value: boolean) => void;
  customers: any[];
  invoice: any;
  onSuccess?: (data: any) => void;
}) {
  return (
    <PrimitiveDialogForm
      className="max-w-[80%]"
      isOpen={isOpen}
      onDialogChange={onDialogChange}
      dialogTitle={"Update Deferred Payment Invoice"}
      form={
        <UpdateInvoiceForm
          onCloseForm={() => onDialogChange(false)}
          onSuccess={(data) => {
            onDialogChange(false);
            if (onSuccess) onSuccess(data);
          }}
          customers={customers}
          invoice={invoice}
        />
      }
    />
  );
}

function formatPaymentTypeLabel(payment_type?: string) {
  switch (payment_type) {
    case "ach":
      return "ACH";
    case "check":
      return "Check";
    default:
      return "Not Specified"
  }
}

function CustomerSection() {
  const customersRes = useListCustomers();
  const customers: Customer[] = customersRes?.data?.data || [];
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearch = useDebounce(searchQuery, 300);
  const { setCustomer, setCustomerId } = useCustomerBearStore((state) => ({ setCustomerId: state.setCustomerId, setCustomer: state.setCustomer }));

  const loadingOrErrorUi = GenerateLoadingOrError([customersRes]);
  if (loadingOrErrorUi) return loadingOrErrorUi;

  const filteredCustomers = debouncedSearch
    ? customers?.filter((customer: Customer) => {
      const matchedCustomer = findCustomerFrom("", debouncedSearch, [
        customer,
      ]);
      return !!matchedCustomer;
    }) || []
    : customers;

  const pairedColumns = [{
    key: "client_id",
    label: "Agtech ID"
  }, {
    key: "long_name",
    label: "Long Name"
  }, {
    key: "preferred_defer_payment_type",
    label: "Payment Type",
    value: (original: any) => DeferredPaymentTypeOptions.find((item: any) => item.value === original.preferred_defer_payment_type)?.label || "Not Specified"
  }].map((item: any) => {
    return {
      id: item.key,
      header: item.label,
      cell: ({ row }: any) => item.value ? item?.value(row.original) : row.original[item.key],
      onCellClick: true,
      isCellClick: true,
      enableSorting: true,
      enableHiding: false,
    };
  });

  return (
    <div>
      <div className="flex flex-col-reverse gap-4 sm:flex-row sm:justify-between sm:items-center my-4">
        <Input
          placeholder="Search customers..."
          className="max-w-xs"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <DataTable
        className={`grid overflow-y-auto overflow-x-auto`}
        height={'600px'}
        onCellClick={(item) => {
          if (setCustomer) {
            setCustomerId(item.id);
            setCustomer(item as any);
            setIsOpen(true);
          }
        }}
        columns={pairedColumns}
        data={filteredCustomers}
      />
      <CustomerFormModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}

function CustomerFormModal({ isOpen, setIsOpen }: {
  isOpen: boolean;
  setIsOpen: any;
}) {
  const { toast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const { updateCustomerId } = useCustomerBearStore((state) => ({ updateCustomerId: state.updateCustomerId }));
  const clearCustomer = useCustomerBearStore((state) => state.clearCustomer);
  const updateCustomer = useUpdateCustomer();

  const onSubmit = async (formData: any) => {
    if (isSaving) { return false; }
    if (!updateCustomerId) { return false; }
    setIsSaving(true);

    try {
      const data: any = {
        ...formData,
        id: updateCustomerId,
      };
      const result = await updateCustomer(data.id, data);

      if (!result.success) {
        toast({
          title: "Failed to update Customer",
          description: "",
        });
      } else {
        toast({
          title: "Updated",
          description: "",
        });
        setIsOpen(false);
        clearCustomer();
      }

      return result.success;
    } catch (error) {
      return false;
    }
    finally { setIsSaving(false); }
  };

  return (
    <Dialog
      onOpenChange={setIsOpen}
      open={isOpen}
      defaultOpen={false}
    >
      <DialogContent className="sm:max-w-lg md:max-w-2xl lg:max-w-4xl">
        <DialogHeader>
          <DialogTitle>{"Update Customer"}</DialogTitle>
        </DialogHeader>
        <CustomerForm onSubmit={onSubmit} allowUpdatingClientId={false} />
        <DialogFooter className="sm:justify-start"></DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function GenerateDownloadReportDialog({
  isGenerateReportOpen,
  setIsGenerateReportOpen,
  contract_filters,
  invoice_filters
}: {
  isGenerateReportOpen: boolean;
  setIsGenerateReportOpen: (status: boolean) => void;
  contract_filters?: ContractFilter;
  invoice_filters?: InvoiceFilter;
}) {
  const { toast } = useToast();
  const generateReport = useGenerateReport();
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState<string | undefined>(undefined);
  const [reportDestinationType, setReportDestinationType] = useState<"customer" | "internal">("customer");

  const onGenerateReport = async () => {
    if (isLoading) { return; }
    setIsLoading(true);
    try {
      const result = await generateReport({
        contract_filters,
        invoice_filters,
        report_type: "weekly",
        destination_type: reportDestinationType,
      });

      if (!result.success) {
        throw result;
      } else {
        const data = result.data;
        if (!data?.link) {
          throw new Error("Missing link");
        }
        setLink(data?.link)
        toast({
          title: "Generated",
          description: "",
        });
      }
    } catch (error) {
      console.error("Failed to generate report", error)
      toast({
        title: "Failed to Generate Report",
        description: "",
      });
    }
    finally {
      setIsLoading(false)
    }
  };

  return (
    <Dialog open={isGenerateReportOpen} onOpenChange={setIsGenerateReportOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="gap-2">
          Report
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px] md:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Download Report</DialogTitle>
          <DialogDescription>
            Download a report with the current active filters
          </DialogDescription>
        </DialogHeader>
        <div className="grid grid-cols-1 place-content-between gap-2 col-span-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className="grid grid-cols-4 items-center gap-4">

                <label htmlFor="contractSent" className="text-right">
                  Destination Type:
                </label>
                <Combobox
                  disabled={isLoading}
                  id="destinationType"
                  className="col-span-3 p-2 border rounded"
                  value={reportDestinationType}
                  onValueChange={(value) => {
                    setReportDestinationType(value === "" ? "customer" : value as any);
                  }}
                  options={[{ value: "customer", label: "Customer" }, { value: "internal", label: "Internal" }]}
                />
              </div>
              {reportDestinationType === "customer" && !contract_filters?.customer_id && (
                <div className="text-sm text-gray-600">Must filter to a customer, before downloading a customer report</div>
              )}
            </div>

            {!!isLoading && (<Loader className="h4 w-4" />)}
            {!!link && !isLoading && (
              <a
                href={link}
                target="_blank"
                className="underline underline-offset-1 decoration-sky-500"
              >
                Download Report
              </a>
            )}
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={() => setIsGenerateReportOpen(false)}>
            Close
          </Button>
          <Button onClick={onGenerateReport} disabled={isLoading || (reportDestinationType === "customer" && !contract_filters?.customer_id)}>Generate</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

function ReleaseDialog({ id, item, type }: { id: string; type: "contract" | "invoice"; item: any }) {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const releaseInvoice = useReleaseInvoice();
  const releaseContract = useReleaseContract();
  const [releaseData, setReleaseData] = useState<typeof ReleaseDeferPaymentValidation._type>({
    note: "",
    amount: item.type === "contract" ? item.amount : safeParseDecimal(item.amount).times(-1).toString(),
    communication_channel: DeferredPaymentCommunicationChannel.EMAIL,
  });

  useEffect(() => {
    if (isOpen) {
      setReleaseData({
        note: "",
        amount: item.type === "contract" ? item.amount : safeParseDecimal(item.amount).times(-1).toString(),
        communication_channel: DeferredPaymentCommunicationChannel.EMAIL,
      });
    }
  }, [isOpen])


  const onRelease = async (originalData: any) => {
    if (isLoading) { return; }
    setIsLoading(true);

    try {
      const dataResult = ReleaseDeferPaymentValidation.safeParse({
        ...originalData,
        amount: safeParseDecimal(originalData.amount).toString(),
      });
      if (!dataResult.success) {
        toast({
          title: "Invalid release data",
          description: "",
        });
        return;
      }

      const data = dataResult.data;
      const result = type === "contract" ? await releaseContract(id, data) : await releaseInvoice(id, data);

      if (!result.success) {
        throw result;
      } else {
        toast({
          title: "Released",
          description: "",
        });
        setIsOpen(false);
      }
    } catch (error) {
      toast({
        title: "Failed to release",
        description: "",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const errors = {
    note: !releaseData.note?.trim()?.length ? "Note is required" : "",
    amount: (() => {
                const amount = safeParseDecimal(releaseData.amount?.toString() || "0");
                if (amount.isNaN()) return "Amount must be a number";
                if (amount.lte(0)) return "Amount should be >= 0";
                if (item.type === "contract" && amount.gt(item.amount)) return "Amount should be <= " + item.amount;
                if (item.type === "invoice" && amount.times(-1).lt(item.amount)) return "Amount should be >= " + item.amount;
                return "";
              })()
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          size="sm"
          variant="ghost"
          disabled={isLoading}
          className="p-0 m-0 mr-4"
          style={{ width: "12px", height: "12px" }}
          onClick={(e) => {
            if (e.preventDefault) {
              e.preventDefault()
            }
            setIsOpen(true);
          }}
        >
          <DeleteIcon className="h-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px] md:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Release {type === "contract" ? "Contract" : "Invoice"}</DialogTitle>
          <DialogDescription>
            Are you sure you want to release the {type === "contract" ? "contract" : "invoice"}.
          </DialogDescription>
        </DialogHeader>
        <div className="">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col w-full">
              <div className="my-4 p-4 bg-muted/50 rounded-lg border w-full">
                {type === "contract" ? (
                  <div className="w-full space-y-2">
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium">Contract #{item.contract_no}</span>
                      <Badge variant="outline" className="bg-blue-50">
                        Contract
                      </Badge>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-2 text-sm">
                      <div className="text-muted-foreground">Settlement</div>
                      <div className="font-medium">{item.settlement_no}</div>
                      <div className="text-muted-foreground">Customer</div>
                      <div className="font-medium">{item.customer_name}</div>
                      <div className="text-muted-foreground">Settlement Date</div>
                      <div className="font-medium">
                        {moment(item.settlement_date).format('MM/DD/YYYY')}
                      </div>
                      <div className="text-muted-foreground">Amount</div>
                      <div className="font-medium tabular-nums">
                        ${Math.abs(item.numericAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </div>
                      <div className="text-muted-foreground">Quantity</div>
                      <div className="font-medium tabular-nums">
                        {Math.abs(item.numericUomAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.uom}
                      </div>
                      <div className="text-muted-foreground">Status</div>
                      <div className="flex gap-2">
                        {item.contract_sent && (
                          <Badge variant="secondary" className="bg-green-50">Sent</Badge>
                        )}
                        {item.contract_signed && (
                          <Badge variant="secondary" className="bg-green-50">Signed</Badge>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="space-y-2 w-full">
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium">{item.invoice_name}</span>
                      <Badge variant="outline" className="bg-red-50">
                        Invoice
                      </Badge>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-2 text-sm">
                      <div className="text-muted-foreground">Customer</div>
                      <div className="font-medium">{item.customer_name}</div>
                      <div className="text-muted-foreground">Amount</div>
                      <div className="font-medium tabular-nums text-red-600">
                        -${Math.abs(item.numericAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </div>
                      <div className="text-muted-foreground">Commodity</div>
                      <div className="font-medium">{item.commodityName}</div>
                      <div className="text-muted-foreground">Deferred To</div>
                      <div className="font-medium">{item.deferred_to_year}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!!isLoading && (<Loader className="h4 w-4" />)}
          </div>
          <form onSubmit={onRelease} className="flex flex-col sm:grid sm:grid-cols-3">
            <div className="my-4 p-4 bg-muted/50 rounded-lg border w-full col-span-3 flex flex-col sm:grid sm:grid-cols-3 gap-4">
            <BearInput
              key={"DynamicDeferReleaseNote"}
              tabIndex={0}
              required={true}
              name={"Note"}
              label={"Note"}
              error={errors.note}
              value={releaseData.note || ""}
              placeholder={""}
              onChange={(event) =>
                setReleaseData({ ...releaseData, note: event.target.value || "" })
              }
            />
            <BearSelectInput
              key="DynamicDeferReleaseChannel"
              required
              name={"Channel"}
              label={"Channel"}
              value={releaseData.communication_channel || ""}
              onValueChange={(value) => setReleaseData({ ...releaseData, communication_channel: value === "" ? DeferredPaymentCommunicationChannel.EMAIL : value as any })}
              options={DeferredPaymentCommunicationChannelOptions}
            />
            <BearFloatInput
              key="DynamicDeferReleaseAmount"
              required
              name={"Amount"}
              label={"Amount"}
              value={releaseData.amount?.toString() || ""}
              error={errors.amount}
              step={"1"}
              min={"1"}
              max={safeParseDecimal(item.amount).toNumber()}
              onChange={(value) =>
                setReleaseData({ ...releaseData, amount: safeParseDecimal(value) })
              }
              showError={true}
            />
            </div>
            <div className="flex justify-end gap-2 col-span-3 sm:grid-cols-3">
              <span className="text-sm text-muted-foreground col-span-2">
                </span>
              <span className="flex text-sm text-muted-foreground col-span-1 gap-4">
              <Button variant="outline" disabled={isLoading} onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button type="submit" disabled={isLoading || Object.values(errors).filter(Boolean).length > 0} onClick={() => onRelease(releaseData)}>Release</Button>
              </span>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  )
}

function mapContract(contract: DeferredPaymentContract) {
  // Parse amount to a numeric value for calculations
  const numericAmount = safeParseDecimal(contract.amount).toNumber();

  return {
    id: contract.id,
    type: 'contract' as const,
    customer_id: contract.customer_id,
    customer_name: (contract as any)?.Customer?.long_name || 'Unknown',
    commodity: contract.commodity,
    commodityName: CommodityOptions.find(option => option.value === contract.commodity)?.label || contract.commodity,
    amount: contract.amount,
    numericAmount: numericAmount, // Considered positive
    numericUomAmount: safeParseDecimal(contract.uom_amount || "0").toNumber(),
    numericInterest: safeParseDecimal(contract.interest || "0").toNumber(),
    deferred_to_year: contract.deferred_to_year,
    contract_no: contract.contract_no,
    settlement_no: contract.settlement_no,
    settlement_date: contract.settlement_date,
    contract_sent: contract.contract_sent,
    contract_signed: contract.contract_signed,
    uom: contract.uom,
    uom_amount: contract.uom_amount,
    interest: contract.interest,
    deferredReleasesLength: (contract as any)?.DeferredReleases?.length || 0,
    original_amount: (contract as any)?.original_amount || 0,
  };
}


function mapInvoice(invoice: DeferredPaymentInvoice) {
  // Parse amount to a numeric value for calculations and make it negative
  const numericAmount = safeParseDecimal(invoice.amount).toNumber();
  // Ensure invoice amounts are represented as negative
  const normalizedAmount = numericAmount < 0 ? numericAmount : -numericAmount;

  return {
    id: invoice.id,
    type: 'invoice' as const,
    customer_id: invoice.customer_id,
    customer_name: (invoice as any)?.Customer?.long_name || 'Unknown',
    commodity: invoice.commodity,
    commodityName: CommodityOptions.find(option => option.value === invoice.commodity)?.label || invoice.commodity,
    amount: invoice.amount,
    numericAmount: normalizedAmount, // Stored as negative
    numericUomAmount: 0,
    deferred_to_year: invoice.deferred_to_year,
    invoice_name: invoice.name,
    deferredReleasesLength: (invoice as any)?.DeferredReleases?.length || 0,
    original_amount: (invoice as any)?.original_amount || 0,
  };
}
