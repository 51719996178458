import { Button } from "@/components/ui/button";
import { Loader } from "@/components/ui/loader";
import { ChatStream } from "@/lib/api/chats";
import { formatChatSource } from "../utils";
import moment from "moment-timezone";

interface ChatListProps {
  chats: ChatStream[];
  pagination: {
    currentPage: number;
    totalPages: number;
    totalItems: number;
    pageSize: number;
  };
  isLoading: boolean;
  onPageChange: (page: number) => void;
  onSelectChat: (chat: ChatStream) => void;
  selectedChatId: string | null;
}

export function ChatList({
  chats,
  pagination,
  isLoading,
  onPageChange,
  onSelectChat,
  selectedChatId
}: ChatListProps) {
  return (
    <>
      <div className="p-4 border-b bg-muted/50">
        <h2 className="font-semibold">Chat Conversations</h2>
      </div>

      <div className="flex-1 overflow-y-auto ">
        {chats.length === 0 ? (
          <div className="p-4 text-center text-muted-foreground">
            No chats found
          </div>
        ) : (
          <ul className="divide-y">
            {chats.map((chat) => {
              const latestMessage = chat.Messages && chat.Messages.length > 0
                ? chat.Messages[0]
                : null;

              return (
                <li
                  key={chat.id}
                  className={`p-3 cursor-pointer hover:bg-muted/50 transition-colors ${selectedChatId === chat.id ? 'bg-muted' : ''
                    }`}
                  onClick={() => onSelectChat(chat)}
                >
                  <div className="flex justify-between items-start mb-1">
                    <h3 className="font-medium truncate">{chat.name}</h3>
                  </div>
                  <div className="text-sm text-muted-foreground mb-1">
                    {formatChatSource({ source: chat.create_source, type: chat.chat_type, message_group_env: chat.message_service_env }).join(" | ").trim()}
                  </div>
                  {latestMessage && (
                    <>
                      <p className="text-sm text-muted-foreground truncate">
                        <span className="text-xs text-muted-foreground whitespace-nowrap truncate">{latestMessage.message}</span>
                      </p>
                      <p className="text-sm text-muted-foreground truncate sm:hidden">
                        <span className="text-xs text-muted-foreground whitespace-nowrap">
                          Last Message {" "}@{" "}
                          <span className="text-xs text-muted-foreground whitespace-nowrap">
                            {moment.tz(latestMessage.date, "America/Chicago").format("HH:mm a on MM/DD/YYYY ")}
                          </span>
                        </span>
                      </p>
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <div className="p-3 border-t flex items-center justify-between bg-background">
        <Button
          size="sm"
          onClick={() => onPageChange(pagination.currentPage - 1)}
          disabled={pagination.currentPage <= 1 || isLoading}
        >
          {isLoading ? <Loader className="h-4 w-4 animate-spin" /> : <span>Previous</span>}
        </Button>

        <span className="text-sm">
          Page {pagination.currentPage} of {pagination.totalPages}
        </span>

        <Button
          size="sm"
          onClick={() => onPageChange(pagination.currentPage + 1)}
          disabled={pagination.currentPage >= pagination.totalPages || isLoading}
        >
          {isLoading ? <Loader /> : <span>Next</span>}
        </Button>
      </div>
    </>
  );
}
