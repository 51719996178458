import React, { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStytchMember } from "@stytch/react/b2b";
import { useEditor, EditorContent, Editor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import LinkExtension from '@tiptap/extension-link';
import LoomNode, { LOOM_REGEX } from './components/LoomNode';
import FileNode, { FileAttributes } from './components/FileNode'; // Assuming FileNode handles display

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { fetchApiPost } from "@/lib/api/utils";
import { KnowledgeApi, useCreateKnowledgeUploadLink, useGetKnowledgeItem, useGetKnowledgePermissions, useUpdateKnowledgeItem, useGetKnowledgeTags } from "@/lib/api/knowledge";
import { ArrowLeft, Save, Film, Paperclip, PlusCircle, Trash2 } from "lucide-react";
import { EditorProps } from "@tiptap/pm/view";
import { GenerateLoadingOrError } from "@/lib/utils";
import { Combobox } from "@/components/ui/combobox";
import { MultiSelect, SelectItem } from "@/components/ui/selectMulti";
import { UploadPresignedFile } from "@/lib/file";
import { useKnowledgeEditor, KnowledgeEditor } from "./components/KnowledgeEditor";


// Assuming a Step type exists or defining a basic one
interface KnowledgeStep {
    id?: string; // Optional ID if the step already exists
    name: string;
    description: string;
    blocking: boolean;
    completion_by: string; // e.g., 'trainer', 'user' - defaulting to 'trainer'
    // Add other step properties if needed
}

export function KnowledgeTaskEdit() {
    const navigate = useNavigate();
    const { member } = useStytchMember();
    const { taskId } = useParams<{ taskId: string }>(); // Get taskId from URL params
    const user_id = member?.trusted_metadata.db_user_id;

    // Fetching data
    const knowledgeItemRes = useGetKnowledgeItem(taskId);
    const permissionsListRes = useGetKnowledgePermissions();
    const tagsListRes = useGetKnowledgeTags(); // Fetch tags

    // State
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedPermissionId, setSelectedPermissionId] = useState<string | null>(null);
    const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]); // State for selected tags
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [steps, setSteps] = useState<KnowledgeStep[]>([]); // <<< New state for steps
    const updateKnowledgeItem = useUpdateKnowledgeItem(); // Use update hook

    const { editor, fileInputRef, handleFileUpload, error } = useKnowledgeEditor({ mode: "existing", existingData: knowledgeItemRes.data?.data?.content, taskId: taskId });

    // Effect to load data into the form and editor
    useEffect(() => {
        if (knowledgeItemRes.data?.data && editor) {
            const task = knowledgeItemRes.data.data as any; // Using any for now, define a proper type later
            setTitle(task.title || "");
            setDescription(task.description || "");
            setSelectedPermissionId(task.special_permission_id || null);
            // Load existing steps - ensure backend sends `Steps` array
            // Adapt task.Steps based on the actual API response structure
            const originalSteps = (task.OriginalSteps || []).map((step: any) => ({
                ...step,
                name: step.title,
            }))
            setSteps(originalSteps); // <<< Load existing steps

            // Extract existing tag IDs if available
            const currentTagIds = task.Tags?.map((tag: any) => tag.id) || [];
            setSelectedTagIds(currentTagIds);

            // Safely parse and set editor content
            let contentToSet: any = ''; // Allow object or string
            if (task.content) {
                try {
                    // Ensure content is valid JSON before parsing if it's a string
                    const parsedContent = typeof task.content === 'string' ? JSON.parse(task.content) : task.content;
                    // Basic validation if needed: check if parsedContent has type 'doc'
                    if (typeof parsedContent === 'object' && parsedContent !== null && parsedContent.type === 'doc') {
                        contentToSet = parsedContent;
                    } else if (typeof task.content === 'string' && task.content.trim() === '') {
                        // Handle empty string content
                        contentToSet = '';
                    } else if (typeof task.content === 'string') {
                        // If it's just a string, wrap it in a paragraph
                        contentToSet = { type: 'doc', content: [{ type: 'paragraph', content: [{ type: 'text', text: task.content }] }] };
                    } else {
                        console.warn("Content is not a valid Prosemirror document object or recognized string:", task.content);
                        contentToSet = `<p>Error loading content structure.</p>`; // Fallback for unexpected format
                    }

                } catch (error) {
                    console.error("Error parsing task content:", error, "Raw content:", task.content);
                    // Provide more robust fallback for parsing errors
                    contentToSet = { type: 'doc', content: [{ type: 'paragraph', content: [{ type: 'text', text: "Error loading content." }] }] };
                }
            }

            // Check if editor exists before setting content
            if (editor) {
                editor.commands.setContent(contentToSet);
                editor.setEditable(true); // Make editable once content is loaded
            }

        } else if (knowledgeItemRes.error) {
            // Handle error loading task data (e.g., show toast, disable form)
            toast({ title: "Error", description: "Failed to load task details.", variant: "destructive" });
            editor?.setEditable(false);
        }
    }, [knowledgeItemRes.data, knowledgeItemRes.error, editor]); // editor dependency is important

    const permissionsList = permissionsListRes.data?.data || [];
    const availableTags = tagsListRes.data?.data || [];
    const isCommonBlock = knowledgeItemRes.data?.data?.item_type === "common-block";
    const isTaskBlock = knowledgeItemRes.data?.data?.item_type === "task";

    // Format tags for MultiSelect
    const tagOptions: SelectItem[] = availableTags.map((tag: { id: string, name: string }) => ({
        value: tag.id,
        label: tag.name,
    }));

    // <<< Step Handlers >>>
    const handleAddStep = () => {
        setSteps(prevSteps => [
            ...prevSteps,
            {
                name: "",
                description: "",
                blocking: true, // Default
                completion_by: 'trainer' // Default
            }
        ]);
    };

    const handleRemoveStep = (index: number, step: KnowledgeStep) => {
        if (step.id) {
            return;
        } else {
            setSteps(prevSteps => prevSteps.filter((_, i) => i !== index));
        }
    };

    const handleStepChange = (index: number, field: keyof KnowledgeStep, value: string) => {
        setSteps(prevSteps =>
            prevSteps.map((step, i) =>
                i === index ? { ...step, [field]: value } : step
            )
        );
    };
    // <<< End Step Handlers >>>


    // Handle loading/error states for all API calls
    const loadingOrErrorUi = GenerateLoadingOrError([knowledgeItemRes, permissionsListRes, tagsListRes]); // Add tagsListRes

    if (loadingOrErrorUi && !knowledgeItemRes.data) { // Show loading only if task data isn't available yet
        return (
            <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <div className="flex items-center mb-6">
                    {/* Back button and Title */}
                    <Button variant="ghost" size="sm" className="mr-2" onClick={() => navigate(`/tasks/${taskId}`)}><ArrowLeft className="h-4 w-4 mr-1" /> Back</Button>
                    <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">Edit Task</h1>
                </div>
                {loadingOrErrorUi}
            </section>
        );
    }



    const handleSubmit = async (e?: React.FormEvent) => {
        e?.preventDefault();

        if (!taskId) {
            toast({ title: "Error", description: "Task ID is missing.", variant: "destructive" });
            return;
        }
        if (!title.trim()) {
            toast({ title: "Error", description: "Please provide a title.", variant: "destructive" });
            return;
        }
        // Validate step names
        for (const step of steps) {
            if (!step.name.trim()) {
                toast({ title: "Error", description: "All steps must have a name.", variant: "destructive" });
                return;
            }
        }
        if (!editor) {
            toast({ title: "Error", description: "Editor not initialized.", variant: "destructive" });
            return;
        }


        setIsSubmitting(true);

        try {
            const editorContent = editor.isEmpty ? "" : JSON.stringify(editor.getJSON());

            // Prepare steps data for submission
            // You might need to adjust this based on what the API expects
            // e.g., removing temporary IDs if they exist, or formatting differently
            const stepsToSubmit = steps.map(({ id, ...rest }) => ({
                // Include id only if it exists (for updates), otherwise omit it (for creates)
                ...(id && { id }), // Conditionally spread id
                ...rest,
                title: rest.name,
                // Ensure defaults are set if somehow cleared, though UI defaults handle this mostly
                blocking: rest.blocking ?? true,
                completion_by: rest.completion_by as 'assignee' | 'trainer' | 'any',
            }));


            // Call update mutation
            const result = await updateKnowledgeItem({
                id: taskId!, // taskId is checked above
                title,
                description,
                content: editorContent,
                special_permission_id: selectedPermissionId || undefined,
                tags: selectedTagIds, // Include selected tag IDs
                steps: stepsToSubmit, // <<< Include steps in the update payload
            });

            if (result.success) {
                toast({ title: "Task updated", description: "Changes saved successfully." });
                knowledgeItemRes.refetch(); // Refetch data to show updated state
                navigate(`/tasks/${taskId}`);
            } else {
                console.error("Error updating task:", result);
                toast({ title: "Error", description: result.message || "Failed to update task.", variant: "destructive" });
            }
        } catch (error) {
            console.error("Error submitting update:", error);
            toast({ title: "Error", description: "An unexpected error occurred.", variant: "destructive" });
        } finally {
            setIsSubmitting(false);
        }
    };

    // Render UI
    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 lg:px-8">
            <div className="flex items-center mb-6">
                <Button variant="ghost" size="sm" className="mr-2" onClick={() => navigate(`/tasks/${taskId}`)}><ArrowLeft className="h-4 w-4 mr-1" /> Back</Button>
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">Edit Task</h1>
            </div>

            {loadingOrErrorUi && knowledgeItemRes.data && <div className="mb-4">{loadingOrErrorUi}</div>}

            <form onSubmit={handleSubmit} className="space-y-8">
                <div className="grid gap-4">
                    <div className="grid gap-2">
                        <Label htmlFor="title">Title</Label>
                        <Input id="title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Task title" required disabled={isSubmitting || knowledgeItemRes.isLoading} />
                    </div>


                    {isTaskBlock && (
                        <div className="grid gap-2">
                            <Label htmlFor="description">Description</Label>
                            <Textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Brief description" rows={3} disabled={isSubmitting || knowledgeItemRes.isLoading} />
                        </div>
                    )}


                    <div className="flex flex-col sm:flex-row gap-4">
                        {(permissionsListRes.isLoading || permissionsList.length > 0) && (
                            <div className="grid gap-2 w-full sm:w-[300px]">
                                <Label htmlFor="special-permission">Permission</Label>
                                <Combobox
                                    id="special-permission"
                                    value={selectedPermissionId || ""}
                                    onValueChange={(value) => setSelectedPermissionId(value === "" ? null : value)}
                                    options={permissionsList.map((permission: { id: string, name: string }) => ({
                                        label: permission.name,
                                        value: permission.id,
                                    }))}
                                    className="w-full"
                                    popoverClassName="w-[--radix-popover-trigger-width]"
                                    disabled={isSubmitting || knowledgeItemRes.isLoading}
                                />
                            </div>
                        )}
                        {(tagsListRes.isLoading || availableTags.length > 0) && (
                            <div className="grid gap-2 w-full flex-1" key={`select-tags-${tagsListRes.isLoading}`}>
                                <Label>Tags</Label>
                                <MultiSelect
                                    options={tagOptions}
                                    values={selectedTagIds}
                                    setValues={setSelectedTagIds}
                                    className="" // Adjust styling as needed
                                    listClassName="max-h-[400px]"
                                    disabled={isSubmitting || knowledgeItemRes.isLoading || tagsListRes.isLoading}
                                />
                            </div>
                        )}
                    </div>

                    {isTaskBlock && (
                        <div className="grid gap-4 border p-4 rounded-md">
                            <Label className="text-lg font-semibold">Steps</Label>
                            {steps.length === 0 && !isSubmitting && (
                                <p className="text-muted-foreground">No steps defined yet.</p>
                            )}
                            {steps.map((step, index) => (
                                <div key={index} className="grid gap-3 border p-3 rounded bg-muted/40 relative">
                                    <div>
                                        <Button
                                            type="button"
                                            variant="ghost"
                                            size="icon"
                                            className="absolute top-1 right-1 h-6 w-6 text-muted-foreground hover:text-destructive"
                                            onClick={() => handleRemoveStep(index, step)}
                                            disabled={isSubmitting || !!step.id}
                                            aria-label="Remove step"
                                        >
                                            <Trash2 className="h-4 w-4" />
                                        </Button>
                                    </div>
                                    <div className="grid gap-1">
                                        <Label htmlFor={`step-name-${index}`}>Step Name</Label>
                                        <Input
                                            id={`step-name-${index}`}
                                            value={step.name}
                                            onChange={(e) => handleStepChange(index, 'name', e.target.value)}
                                            placeholder={`Step ${index + 1} Name`}
                                            required
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="grid gap-1">
                                        <Label htmlFor={`step-description-${index}`}>Step Description</Label>
                                        <Textarea
                                            id={`step-description-${index}`}
                                            value={step.description}
                                            onChange={(e) => handleStepChange(index, 'description', e.target.value)}
                                            placeholder="Describe this step"
                                            rows={2}
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </div>
                            ))}
                            <Button type="button" variant="outline" onClick={handleAddStep} disabled={isSubmitting} className="mt-2 justify-start w-fit">
                                <PlusCircle className="h-4 w-4 mr-2" /> Add Step
                            </Button>
                        </div>
                    )}

                    <div className="grid gap-2">
                        <Label>Content</Label>
                        <div className="border rounded-md">
                            <KnowledgeEditor mode="existing" editor={editor} handleFileUpload={handleFileUpload} fileInputRef={fileInputRef} taskId={taskId} />
                        </div>
                    </div>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-end">
                    <Button type="button" variant="outline" className="mr-2" onClick={() => navigate(`/tasks/${taskId}`)} disabled={isSubmitting}>Cancel</Button>
                    <Button type="submit" disabled={isSubmitting || !editor?.isEditable || knowledgeItemRes.isLoading || tagsListRes.isLoading}> {/* Disable if tags loading */}
                        {isSubmitting ? "Saving..." : (<><Save className="h-4 w-4 mr-2" /> Save Changes</>)}
                    </Button>
                </div>
            </form>
        </section>
    );
}
