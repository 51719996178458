import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { DbUser } from "../models";
import { BaseDbObject } from "../models/db";
import { Customer } from "./reports";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";

export enum ChatType {
  FORWARD_ALL = 'forward_all',
  SOURCE_FAN_OUT_ONLY = 'source_fan_out_only'
}

export enum ChatCreateSource {
  TWILIO_INBOUND = 'twilio_inbound',
  PORTAL = 'portal'
}

export enum TwilioMessageName {
  HedgeBeaconMessagingSid = "TWILIO_HEDGE_BEACON_MESSAGING_SERVICE_SID",
  DestinationMessagingSid = "TWILIO_DESTINATION_MESSAGING_SID",
}

export const ChatTypeOptions = [{ value: ChatType.FORWARD_ALL, label: "All Send" }, { value: ChatType.SOURCE_FAN_OUT_ONLY, label: "Only Outgoing" }];
export const ChatSourceOptions = [{ value: ChatCreateSource.PORTAL, label: "LHC Origin" }, { value: ChatCreateSource.TWILIO_INBOUND, label: "Twilio Inbound" }];
export const MessageGroupOptions = [{
  value: TwilioMessageName.HedgeBeaconMessagingSid, label: "HedgeBeacon"
}, {
  value: TwilioMessageName.DestinationMessagingSid, label: "Destination Messaging"
}];

export const SendChatMessageValidation = z.object({
  chat_stream_id: z.string().uuid(),
  sender_participant_id: z.string().uuid(),
  message: z.string().min(1)
});

export const SendTestMessageValidation = z.object({
  customer_id: z.string().uuid().optional(),
  message_service_env: z.enum([TwilioMessageName.DestinationMessagingSid, TwilioMessageName.HedgeBeaconMessagingSid]).optional(),
  sms_number: z.string().min(1),
  message: z.string().min(1),
});

export interface ChatStream extends BaseDbObject {
  name: string,
  create_source: string,
  chat_type: string,

  message_service_id?: string,
  message_service_env?: string;

  Participants?: ChatParticipant[],
  Messages?: ChatMessage[],
}

export interface ChatParticipant extends BaseDbObject {
  entered_at: Date,
  exited_at?: Date,

  chat_stream_id: string,

  user_id?: string,
  customer_id?: string,
  sms_number?: string,

  User?: DbUser;
  Customer?: Customer;
  ChatStream?: ChatStream,
  SentMessages?: ChatMessage[],
}

export interface ChatMessage extends BaseDbObject {
  chat_stream_id?: string,
  sender_participant_id?: string,

  date?: Date,

  message?: string,
  user_id?: string;
  inbound_twilio_message_id?: string;

  User?: DbUser;
  ChatStream?: ChatStream,
  SenderParticipant?: ChatParticipant,
}

export interface ChatsParams {
  page: number;
  chatType?: string,
  create_source?: string;
  messageServiceName?: string;
}

interface MessagesParams {
  page: number;
  chat_stream_id: string;
}

function assembleChatQueryParams(params: ChatsParams) {
  const queryParams = new URLSearchParams();
  queryParams.append("page", `${params.page}`)
  queryParams.append("pageSize", `10`);
  if (params.chatType) { queryParams.append("chatType", params.chatType); }
  if (params.create_source) { queryParams.append("create_source", params.create_source); }
  if (params.messageServiceName) { queryParams.append("messageServiceName", MessageGroupOptions.find(item => item.value.toLocaleLowerCase() === params.messageServiceName?.toLocaleLowerCase())?.value || params.messageServiceName); }
  return queryParams;
}

function assembleChatMessagesQueryParams(params: MessagesParams) {
  const queryParams = new URLSearchParams();
  queryParams.append("page", `${params.page}`);
  queryParams.append("pageSize", `5`);
  queryParams.append("chat_stream_id", params.chat_stream_id);
  return queryParams;
}

export function fetchChatsPage(params: ChatsParams) {
  const queryParams = assembleChatQueryParams(params);

  return fetchApiGet<{
    data: ChatStream[]
    pagination: {
      totalItems: number;
      totalPages: number;
      pageSize: number;
      currentPage: number;
    }
  }>(`chats`, queryParams);
}

export function fetchChatMessagesPage(params: MessagesParams) {
  const queryParams = assembleChatMessagesQueryParams(params);

  return fetchApiGet<{
    chatStream: ChatStream;
    participant: ChatParticipant;
    selfParticipants: ChatParticipant[];
    allParticipants: ChatParticipant[];
    data: ChatMessage[];
    pagination: {
      totalItems: number;
      totalPages: number;
      pageSize: number;
      currentPage: number;
    }
  }>(`chats/${params.chat_stream_id}/messages`, queryParams);
}

export function useChatsPaginated({ params, queryFn }: {
  params: ChatsParams;
  queryFn: any;
}) {
  return useQuery({
    queryKey: ["chats", assembleChatQueryParams(params).toString()],
    queryFn,
    retry: 1,
    cacheTime: 0,
    refetchInterval: 10 * 1000,
    keepPreviousData: true,
  });
}

export function useChatMessagesPaginated({ params, queryFn }: {
  params: MessagesParams;
  queryFn: any;
}) {
  return useQuery({
    queryKey: ["chat_messages", params.chat_stream_id, assembleChatMessagesQueryParams(params).toString()],
    queryFn,
    retry: 1,
    cacheTime: 0,
    refetchInterval: 3 * 1000,
    keepPreviousData: true,
  });
}

export function useSendChatMessage() {
  const queryClient = useQueryClient();

  return async (params: typeof SendChatMessageValidation._type) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `chats/${params.chat_stream_id}/messages/send`,
      params,
      queryParams
    );
    queryClient.invalidateQueries({ queryKey: ["chat_messages"] });
    queryClient.invalidateQueries({ queryKey: ["chats"] });

    return result;
  };
}

export function useSendTestMessage() {
  const queryClient = useQueryClient();

  return async (params: typeof SendTestMessageValidation._type) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `chats/test/send`,
      params,
      queryParams
    );

    return result;
  };
}
