import {
  CheckIcon,
  PencilIcon,
  AlertCircleIcon,
  CheckSquare as CheckSquareIcon,
  Plus as PlusIcon,
  Send as SendIcon,
  Trash as TrashIcon,
  EyeIcon,
} from "lucide-react";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import {
  AnalystRecommendation,
  AnalystAlert,
  AnalystRecommendationType,
  AnalysisAction,
  AnalysisCommodityOptions,
  AnalysisSignalOptions,
  AnalystRecommendationCropTypeOptions,
  AlertCommodities,
  ProjectRouteName,
} from "../../lib/models/analystRecommendation";
import {
  useManualCHS,
  useCreateZohoSegment,
  useRefreshZohoMailingList,
  useForceSendTodaysAnalysisComms,
  useUpdateTicketProviderDynamicMappings,
  useRunDataMigrationUpdateCropYears,
  useAtsReplayEvents,
  usePreviewS3FileJob,
} from "../../lib/api/developer";
import { useState } from "react";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { cn } from "@/lib/utils";

type S3FileInfo = {
  bucket: string;
  key: string;
  region: "us-east-1" | "us-east-2" | "us-west-1" | "us-west-2";
};

type S3FileHistoryItem = S3FileInfo & {
  timestamp: number;
  link?: string;
};

export function DeveloperDashboardPage() {
  const { toast } = useToast();

  return (
    <div>
      <div className="container flex flex-col mb-2">
        <h2 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Developer Dashboard
        </h2>

        <ViewDocument />

      </div>
    </div>
  );
}

function ViewDocument() {
  const { toast } = useToast();
  const [s3FileInfo, setS3FileInfo] = useState({
    bucket: "",
    key: "",
    region: "us-east-1" as "us-east-1" | "us-east-2" | "us-west-1" | "us-west-2"
  });
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const previewS3File = usePreviewS3FileJob();
  const [fileHistory, setFileHistory] = useState<S3FileHistoryItem[]>([]);

  const handleS3InfoChange = (field: keyof typeof s3FileInfo, value: string) => {
    setS3FileInfo(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handlePreviewS3File = async () => {
    try {
      const result = await previewS3File(s3FileInfo);
      if (result.success && result.data.link) {
        const link = result.data.link;
        setPreviewUrl(link);
        setFileHistory([
          { ...s3FileInfo, link } as any,
          ...fileHistory,
        ])
        setIsPreviewDialogOpen(true);
        toast({
          title: "File preview ready",
          description: "Opening preview...",
        });
      } else {
        toast({
          title: "Failed to generate preview",
          description: "Please check your S3 file details and try again",
          variant: "destructive",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while trying to preview the file",
        variant: "destructive",
      });
    }
  }

  const handleOpenChange = (open: boolean) => {
    // If closing the dialog, force refresh the color scheme
    if (!open) {
      document.documentElement.style.colorScheme = 'light';
      // Optional: Remove after a short delay to allow for any system preferences
      setTimeout(() => {
        document.documentElement.style.removeProperty('color-scheme');
      }, 100);
    }
  };

  const loadHistorical = (item: any) => {
    setPreviewUrl(item.link);
  };

  return (
    <div>
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>S3 File Preview</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div className="space-y-2">
              <Label htmlFor="s3-bucket">S3 Bucket</Label>
              <Input
                id="s3-bucket"
                placeholder="my-bucket-name"
                value={s3FileInfo.bucket}
                onChange={(e) => handleS3InfoChange('bucket', e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="s3-key">S3 Key</Label>
              <Input
                id="s3-key"
                placeholder="path/to/my/file.pdf"
                value={s3FileInfo.key}
                onChange={(e) => handleS3InfoChange('key', e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="s3-region">AWS Region</Label>
              <Select
                value={s3FileInfo.region}
                onValueChange={(value) => handleS3InfoChange('region', value as any)}
              >
                <SelectTrigger id="s3-region">
                  <SelectValue placeholder="Select region" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="us-east-1">us-east-1</SelectItem>
                  <SelectItem value="us-east-2">us-east-2</SelectItem>
                  <SelectItem value="us-west-1">us-west-1</SelectItem>
                  <SelectItem value="us-west-2">us-west-2</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <Button
            onClick={handlePreviewS3File}
            disabled={!s3FileInfo.bucket || !s3FileInfo.key}
          >
            Preview File
          </Button>
          <Dialog
            open={isPreviewDialogOpen}
            onOpenChange={() => {
              handleOpenChange(!isPreviewDialogOpen);
              setIsPreviewDialogOpen(!isPreviewDialogOpen)
            }}
          >
            <DialogTrigger disabled={!previewUrl}>
              <span className="sr-only">View Document</span>
              <EyeIcon className={cn("ml-4 h-4 w-4")} aria-hidden="true" />
            </DialogTrigger>
            {(isPreviewDialogOpen && previewUrl) && <ViewDocumentModal link={previewUrl} />}
          </Dialog>
        </CardContent>
      </Card>
      <div className="mt-4">
        <ol>
          {fileHistory.map(item => (
            <li key={`${item.bucket}/${item.key}`} onClick={() => loadHistorical(item)} className="cursor-pointer">
              {item.bucket}/{item.key}
            </li>
          ))}
        </ol>
      </div>

    </div>
  );
}

function ViewDocumentModal({ link: signedGetLink }: { link: string }) {

  if (!signedGetLink) {
    return (
      <DialogContent>
        <div className="flex flex-col space-y-2">
          <Skeleton className="h-4 w-[100px]" />
          <Skeleton className="h-4 w-[100px]" />
        </div>
      </DialogContent>
    );
  }

  return (
    <DialogContent className="w-[94vw] h-[96vh] max-w-[98vw] FullDialogContent">
      <iframe
        src={signedGetLink}
        className="w-full h-full"
        style={{
          colorScheme: 'normal',
          isolation: 'isolate',
        }}
      />
    </DialogContent>
  );
}
