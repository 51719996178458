import { useStytchMember } from "@stytch/react/b2b";
import { useEffect, useState } from "react";
import { Eye, Eye as EyeIcon } from "lucide-react";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL, fetchFile } from "@ffmpeg/util";
import { ColumnDef } from "@tanstack/react-table";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import AudioPlayer from 'react-h5-audio-player';
import { fetchTicketJobsPage, useTicketJobsPaginated, useTicketJob } from "../../lib/api/tickets";
import {
  TicketRunSummary,
  TicketsSummary,
  Provider,
} from "../../lib/models/index";
import { TicketJob, TicketJobStatus } from "../../lib/models/tickets";
import { Button } from "../../components/ui/button";
import { Loader } from "../../components/ui/loader";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Badge } from "../../components/ui/badge";
import { Skeleton } from "../../components/ui/skeleton";
import { useToast } from "../../components/ui/use-toast";
import { DataTable } from "../../components/ui/dataTable";
import { CallLog, fetchCallLogsPage, useCallLogsPaginated, useGetAudioUrl } from "@/lib/api/callLog";
import 'react-h5-audio-player/lib/styles.css';
import { useRef } from "react";

export function CallLogsDashboard() {
  const [page, setPage] = useState(1);
  const callLogsRes: any = useCallLogsPaginated({
    page,
    queryFn: () => fetchCallLogsPage(page)
  });

  if ([callLogsRes].some((result) => result.isLoading)) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  if (
    [callLogsRes].some((result) => result.error || !result?.data?.success)
  ) {
    return (
      <div className="flex flex-col space-y-2">
        Failed to fetch call logs. Please refresh and try again
      </div>
    );
  }

  return (
    <DashboardView info={callLogsRes?.data?.data || {}} setPage={setPage} isLoading={callLogsRes.isFetching} />
  );
}

const dashboardColumns: ColumnDef<any>[] = [
  {
    accessorKey: "callLogDate",
    header: "Date",
    cell: ({ row }) => {
      return (
        <span className="">
          {moment
            .tz(row.original.call_log_date, "America/Chicago")
            .format("MM/DD/YYYY HH:mm a")}
        </span>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "from_user",
    header: "From",
    cell: ({ row }) => {
      return (
        <span className="">
          {getFrom(row.original)}
        </span>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "to_user",
    header: "To",
    cell: ({ row }) => {
      return (
        <span className="">
          {getTo(row.original)}
        </span>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "Transcription",
    header: "Transcription",
    cell: ({ row }) => (
      <ViewTranscription callLog={row.original} />
    ),
    enableSorting: false,
    enableHiding: false,
  },
];

export function DashboardView({
  info,
  setPage,
  isLoading,
}: {
  isLoading: boolean;
  setPage: any;
  info: {
    items: CallLog[];
    pagination: {
      currentPage: number;
      totalPages: number;
      totalItems: number;
      pageSize: number;
    },
  };
}) {
  const page = info.pagination.currentPage;
  return (
    <>
      <div className="container flex flex-col mb-2">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Call Logs Dashboard
        </h1>
      </div>
      <div className="flex items-start flex-col h-full">
        <div className="container flex flex-col">
          <DataTable columns={dashboardColumns} data={info.items} />
        </div>
        {/* Pagination controls */}
        <div className="container flex items-center justify-between mt-4">
          <Button
            onClick={() => setPage(page - 1)}
            disabled={page <= 1 || isLoading}
          >
            {isLoading ? <Loader /> : <span>Previous</span>}
          </Button>

          <span>
            Page {page} of {info.pagination.totalPages}
          </span>

          <Button
            onClick={() => setPage(page + 1)}
            disabled={page >= info.pagination.totalPages || isLoading}
          >
            {isLoading ? <Loader /> : <span>Next</span>}
          </Button>
        </div>
      </div>
    </>
  );
}

function ViewTranscription({ callLog }: { callLog: CallLog }) {
  const [isOpen, setIsOpen] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const getAudioUrl = useGetAudioUrl();
  const { toast } = useToast();

  const handleGetAudio = async () => {
    setIsLoadingAudio(true);
    try {
      const response = await getAudioUrl(callLog.id);
      if (response.success && response.data.link) {
        setAudioUrl(response.data.link);
      } else {
        toast({
          title: "Failed to get audio",
          description: "Could not retrieve audio file",
          variant: "destructive",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch audio",
        variant: "destructive",
      });
    }
    setIsLoadingAudio(false);
  };

  return (
    <>
      <Button
        variant="ghost"
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        <Eye className="w-4 h-4" />
      </Button>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Call Transcription</DialogTitle>
          </DialogHeader>

          <div className="space-y-4">
            {/* Call Details */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-sm font-medium">From:</label>
                <p className="text-sm">
                  {getFrom(callLog)}
                </p>
              </div>
              <div>
                <label className="text-sm font-medium">To:</label>
                <p className="text-sm">
                  {getTo(callLog)}
                </p>
              </div>
            </div>

            {/* Audio Player Section */}
            <div className="space-y-2">
              {!audioUrl && (
                <Button
                  onClick={handleGetAudio}
                  disabled={isLoadingAudio}
                >
                  {isLoadingAudio ? <Loader /> : "Play Audio"}
                </Button>
              )}

              {audioUrl && (
                <div className="space-y-2">
                  <AudioConverter presignedUrl={audioUrl} />
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      const link = document.createElement('a');
                      link.href = audioUrl;
                      link.download = `call-recording-${callLog.id}.mp3`; // Set default filename
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    Download Audio
                  </Button>
                </div>
              )}

            </div>

            {/* Transcription Section */}
            <Accordion type="single" collapsible defaultValue="cleaned">
              <AccordionItem value="original">
                <AccordionTrigger>Original Transcription</AccordionTrigger>
                <AccordionContent>
                  <div className="text-sm whitespace-pre-wrap">
                    {callLog.transcribed_meta_data?.reason ? (callLog.transcribed_meta_data?.reason) : (callLog.transcribed_meta_data?.data || "No transcription available")}
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="cleaned">
                <AccordionTrigger>Cleaned Transcription</AccordionTrigger>
                <AccordionContent>
                  <div className="text-sm whitespace-pre-wrap">
                    {callLog.cleaned_meta_data?.data || "No cleaned transcription available"}
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

const AudioConverter = ({ presignedUrl }: { presignedUrl: string }) => {
  const ffmpegRef = useRef(new FFmpeg());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [mp3Url, setMp3Url] = useState<string | null>(null);
  const [playbackRate, setPlaybackRate] = useState(1);
  const playerRef = useRef<null | any>(null);

  useEffect(() => {
    convertToMp3();
  }, [presignedUrl]);

  // Cleanup blob URL on unmount
  useEffect(() => {
    return () => {
      if (mp3Url) {
        URL.revokeObjectURL(mp3Url);
      }
    };
  }, [mp3Url]);

  const handleSpeedChange = (speed: number) => {
    if (playerRef.current) {
      playerRef.current.audio.current.playbackRate = speed;
      setPlaybackRate(speed);
    }
  };


  const convertToMp3 = async () => {
    try {
      setLoading(true);
      setError(null);

      // Load ffmpeg
      if (!ffmpegRef.current.loaded) {
        await ffmpegRef.current.load();
      }

      const ffmpeg = ffmpegRef.current;

      await ffmpeg.writeFile('input.wav', await fetchFile(presignedUrl));

      // Run the conversion
      await ffmpeg.exec(['-i', 'input.wav', 'output.mp3']);

      // Read the result
      const mp3Data = await ffmpeg.readFile('output.mp3');

      // Create a blob URL for the MP3
      const uint8Array = typeof mp3Data === 'string'
        ? new TextEncoder().encode(mp3Data)
        : new Uint8Array(mp3Data as any);

      const blob = new Blob([uint8Array.buffer], { type: 'audio/mp3' });
      const url = URL.createObjectURL(blob);

      setMp3Url(url);
      setLoading(false);

      await new Promise(res => setTimeout(res, 100));
      try {
        await ffmpeg.deleteFile('input.wav');
      } catch (error) {
        console.warn("Failed to delete input")
      }

      try {
        await ffmpeg.deleteFile('output.mp3');
      } catch (error) {
        console.warn("Failed to delete output")
      }
    } catch (err) {
      console.error('Conversion error:', err);
      setError('Failed to convert audio file');
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Converting audio file...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-2">
      <AudioPlayer
        ref={playerRef}
        src={mp3Url!}
        preload={"metadata"}
        autoPlay={false}
        showJumpControls={true}
        customControlsSection={['MAIN_CONTROLS', 'VOLUME_CONTROLS'] as any[]}
        onError={(e) => {
          console.error('Convert Audio Player Error:', e);
        }}
        onPlayError={(e) => {
          console.error('Convert Audio Play Error:', e);
        }}
        onChangeCurrentTimeError={(e) => {
          console.error('Convert Audio Change Time Error:', e);
        }}
      />
      <div className="flex justify-center gap-2">
        <Button
          variant={playbackRate === 1 ? "default" : "outline"}
          size="sm"
          onClick={() => handleSpeedChange(1)}
        >
          1x
        </Button>
        <Button
          variant={playbackRate === 1.25 ? "default" : "outline"}
          size="sm"
          onClick={() => handleSpeedChange(1.25)}
        >
          1.5x
        </Button>
        <Button
          variant={playbackRate === 1.5 ? "default" : "outline"}
          size="sm"
          onClick={() => handleSpeedChange(1.5)}
        >
          2x
        </Button>
      </div>
    </div>
  );
};

function getTo(item: CallLog) {
  return item.to_user_name || item.outgoing_number || item.to_user || "Unknown";
}

function getFrom(item: CallLog) {
  return item.from_user_name || item.from_number || item.from_user || "Unknown";
}
