import React from 'react';
import { NodeViewWrapper } from '@tiptap/react';

const LoomEmbedComponent = (props: any) => {
  const { node } = props;
  const videoId = node.attrs.videoId;

  if (!videoId) {
     // You could render a placeholder or error message here
    return (
        <NodeViewWrapper className="loom-embed-nodeview">
             <div className="p-2 border border-dashed border-muted-foreground text-muted-foreground">
                Invalid Loom Link
             </div>
        </NodeViewWrapper>
    );
  }

  const embedUrl = `https://www.loom.com/embed/${videoId}`;

  return (
    // NodeViewWrapper is essential for Tiptap to manage the node view
    <NodeViewWrapper className="loom-embed-nodeview my-2 relative" data-drag-handle>
      {/* The data-drag-handle attribute allows dragging the block */}
      <div style={{ position: 'relative', paddingBottom: '62.5%', height: 0 }}>
        <iframe
          src={embedUrl}
          // Tiptap handles selection, so disable pointer events on the iframe itself
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }}
          frameBorder="0"
          allowFullScreen
          title={`Loom Video Embed ${videoId}`}
        ></iframe>
      </div>
       {/* Optional: Add controls or overlays here if needed */}
    </NodeViewWrapper>
  );
};

export default LoomEmbedComponent;
