import { z } from "zod";
import Decimal from "decimal.js";
import moment from "moment-timezone";
import { CommonDestinationTimezone, fetchApiDelete, fetchApiGet, fetchApiPost, fetchApiPut } from "./utils";
import { isValidDecimal, safeParseDecimal } from "../excel";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BasicDbObjectValidation, zodTransformDecimal, zodTransformChicagoDate, DateRangeSchemaValidation } from "./utils";

export type DeferredReleasedSummary = {
  id: string;
  amount: Decimal;
  note: string;
  communication_channel: DeferredPaymentCommunicationChannel;
  Invoice: {
    id: string;
    name: string;
    amount: Decimal;
    Customer: {
      id: string;
      long_name: string;
    }
  },
  Contract: {
    id: string;
    contract_no: string;
    settlement_no: string;
    settlement_date: Date;
    amount: Decimal;
    Customer: {
      id: string;
      long_name: string;
    }
  },
}[];

export interface DeferPaymentSummary {
  grandTotal: number | string,
  customerTotalWithPaymentTotal: Record<string, Record<string, Record<string, Record<string, number | string>>>>,
  totalsWithPaymentType: { customer_name: string; customer_id: string; year: number; commodity: string; amount: number | string; payment_type: string }[]
}

export enum DeferredPaymentType {
  ACH = "ach",
  CHECK = "check",
}

export enum DeferredPaymentCommunicationChannel {
  EMAIL = "email",
  PHONE = "phone",
}

export const DeferredPaymentCommunicationChannelOptions = [{
  value: DeferredPaymentCommunicationChannel.EMAIL, label: "Email"
}, {
  value: DeferredPaymentCommunicationChannel.PHONE, label: "Phone"
}];

export const DeferredPaymentTypeOptions = [{
  value: DeferredPaymentType.ACH, label: "ACH"
}, {
  value: DeferredPaymentType.CHECK, label: "Check"
}];

export const ReleaseDeferPaymentValidation = z.object({
  note: z.string(),
  amount: z.string().transform(zodTransformDecimal),
  communication_channel: z.enum([DeferredPaymentCommunicationChannel.EMAIL, DeferredPaymentCommunicationChannel.PHONE]),
});

// Schema for contract filters
export const ContractFilterSchemaValidation = z.object({
  customer_id: z.string().uuid().optional(),
  deferred_to_year: z.coerce.number().int().positive().min(2000).optional(),
  commodity: z.string().optional(),
  contract_no: z.string().optional(),
  settlement_no: z.string().optional(),
  contract_sent: z.coerce.boolean().optional(),
  contract_signed: z.coerce.boolean().optional(),
  has_been_released: z.coerce.boolean().optional(),
  uom_amount: z.string().optional(),
  amount: z.string().optional(),
  settlement_date_range: DateRangeSchemaValidation.optional(),
  created_between: DateRangeSchemaValidation.optional(),
  payment_type: z.enum([DeferredPaymentType.ACH, DeferredPaymentType.CHECK]).optional(),
});

// Type for contract filters based on the schema
export type ContractFilter = z.infer<typeof ContractFilterSchemaValidation>;

export interface BaseFilter {
  customer_id?: string;
  commodity?: string;
  deferred_to_year?: number;
  payment_type?: DeferredPaymentType;
}

// Schema for invoice filters
export const InvoiceFilterSchemaValidation = z.object({
  name: z.string().optional(),
  customer_id: z.string().uuid().optional(),
  deferred_to_year: z.coerce.number().int().positive().optional(),
  commodity: z.string().optional(),
  amount: z.string().optional(),
  created_between: DateRangeSchemaValidation.optional(),
  payment_type: z.enum([DeferredPaymentType.ACH, DeferredPaymentType.CHECK]).optional(),
  has_been_released: z.coerce.boolean().optional(),
});

// Type for invoice filters based on the schema
export type InvoiceFilter = z.infer<typeof InvoiceFilterSchemaValidation>;


export const DeferredPaymentContractCreateSchemaValidation = z.object({
  customer_id: z.string().uuid({
    message: "A valid customer ID is required"
  }),
  settlement_date: z.coerce.date(),
  contract_no: z.string().min(1, {
    message: "Contract number is required"
  }),
  settlement_no: z.string().min(1, {
    message: "Settlement number is required"
  }),
  contract_sent: z.boolean(),
  contract_signed: z.boolean(),
  commodity: z.string().min(1, {
    message: "Commodity is required"
  }),
  uom: z.string().min(1, {
    message: "Unit of measure is required"
  }),
  uom_amount: z.string(),
  amount: z.string(),
  interest: z.string().optional(),
  deferred_to_year: z.number().int().min(2000),
});

export type DeferredPaymentContractCreate = z.infer<typeof DeferredPaymentContractCreateSchemaValidation>;

export const DeferredPaymentInvoiceCreateSchemaValidation = z.object({
  name: z.string({ message: "Missing name" }).min(1),
  customer_id: z.string().uuid({
    message: "A valid customer ID is required"
  }),
  commodity: z.string().min(1, {
    message: "Commodity is required"
  }),
  amount: z.string(),
  deferred_to_year: z.number().int().min(2000),
});

export const DeferredPaymentContractValidation = DeferredPaymentContractCreateSchemaValidation.merge(BasicDbObjectValidation);
export const DeferredPaymentInvoiceValidation = DeferredPaymentInvoiceCreateSchemaValidation.merge(BasicDbObjectValidation);

export type DeferredPaymentContract = z.infer<typeof DeferredPaymentContractValidation>;
export type DeferredPaymentInvoice = z.infer<typeof DeferredPaymentInvoiceValidation>;

function assembleContractFilters(params: ContractFilter) {
  const queryParams = new URLSearchParams();

  if (params.amount) { queryParams.append("amount", `${params.amount}`); }
  if (params.commodity) { queryParams.append("commodity", `${params.commodity}`); }
  if (params.contract_no) { queryParams.append("contract_no", `${params.contract_no}`); }
  if (params.contract_sent != undefined) { queryParams.append("contract_sent", `${params.contract_sent}`); }
  if (params.contract_signed != undefined) { queryParams.append("contract_signed", `${params.contract_signed}`); }
  if (params.customer_id) { queryParams.append("customer_id", `${params.customer_id}`); }
  if (params.deferred_to_year) { queryParams.append("deferred_to_year", `${params.deferred_to_year}`); }
  if (params.uom_amount) { queryParams.append("uom_amount", `${params.uom_amount}`); }
  if (params.settlement_no) { queryParams.append("settlement_no", `${params.settlement_no}`); }
  if (params.created_between?.start || params.created_between?.end) { queryParams.append("created_between", JSON.stringify(params.created_between)); }
  if (params.settlement_date_range?.start || params.settlement_date_range?.end) { queryParams.append("settlement_date_range", JSON.stringify(params.settlement_date_range)); }
  if (params.payment_type) { queryParams.append("payment_type", params.payment_type); }
  if (params.has_been_released != undefined) { queryParams.append("has_been_released", `${params.has_been_released}`); }

  return queryParams;
}

function assembleInvoiceFilters(params: InvoiceFilter) {
  const queryParams = new URLSearchParams();

  if (params.name) { queryParams.append("name", `${params.name}`); }
  if (params.deferred_to_year) { queryParams.append("deferred_to_year", `${params.deferred_to_year}`); }
  if (params.customer_id) { queryParams.append("customer_id", `${params.customer_id}`); }
  if (params.commodity) { queryParams.append("commodity", `${params.commodity}`); }
  if (params.amount) { queryParams.append("amount", `${params.amount}`); }
  if (params.created_between?.start || params.created_between?.end) { queryParams.append("created_between", JSON.stringify(params.created_between)); }
  if (params.payment_type) { queryParams.append("payment_type", params.payment_type); }
  if (params.has_been_released != undefined) { queryParams.append("has_been_released", `${params.has_been_released}`); }

  return queryParams;
}


export function useListDeferredContracts(filters: ContractFilter) {
  const queryParams = assembleContractFilters(filters);

  return useQuery({
    queryKey: ["deferred", "contracts", queryParams.toString()],
    queryFn: () =>
      fetchApiGet<DeferredPaymentContract[]>("defer_payment/contracts", queryParams),
    retry: 2,
  });
}

export function useListDeferredInvoices(filters: InvoiceFilter) {
  const queryParams = assembleInvoiceFilters(filters);

  return useQuery({
    queryKey: ["deferred", "invoice", queryParams.toString()],
    queryFn: () =>
      fetchApiGet<DeferredPaymentInvoice[]>("defer_payment/invoices", queryParams),
    retry: 2,
  });
}

export function useListDeferredReleased() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["deferred", "released"],
    queryFn: () =>
      fetchApiGet<DeferredReleasedSummary>("defer_payment/released", queryParams),
    retry: 2,
  });
}

export function useDeferPaymentSummary() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["deferred", "summary", queryParams.toString()],
    queryFn: () =>
      fetchApiGet<DeferPaymentSummary>("defer_payment/summary", queryParams),
    retry: 2,
  });
}

export function useCreateDeferredPaymentContract() {
  const queryClient = useQueryClient();
  return async (contract: any) => {
    try {
      const response = await fetchApiPost<any>("defer_payment/contract", contract);
      if (response.success) {
        queryClient.invalidateQueries({ queryKey: ["deferred"] });
      }

      return response;
    } catch (error: any) {
      return {
        success: false,
        error: error?.response?.data?.message || "Failed to create contract",
      };
    }
  };
}

// Update an existing deferred payment contract
export function useUpdateDeferredPaymentContract() {
  const queryClient = useQueryClient();
  return async (contract: any) => {
    try {
      const response = await fetchApiPut<any>(`defer_payment/contract/${contract.id}`, contract);
      if (response.success) {
        queryClient.invalidateQueries({ queryKey: ["deferred"] });
      }

      return response;
    } catch (error: any) {
      return {
        success: false,
        error: error?.response?.data?.message || "Failed to update contract",
      };
    }
  };
}

// Create a new deferred payment invoice
export function useCreateDeferredPaymentInvoice() {
  const queryClient = useQueryClient();
  return async (invoice: any) => {
    try {
      const amount = safeParseDecimal(invoice.amount);
      if (amount.gte(0)) {
        invoice.amount = amount.times(-1).toString();
      }

      const response = await fetchApiPost<any>("defer_payment/invoice", invoice);
      if (response.success) {
        queryClient.invalidateQueries({ queryKey: ["deferred"] });
      }

      return response;
    } catch (error: any) {
      return {
        success: false,
        error: error?.response?.data?.message || "Failed to create invoice",
      };
    }
  };
}

// Update an existing deferred payment invoice
export function useUpdateDeferredPaymentInvoice() {
  const queryClient = useQueryClient();

  return async (invoice: any) => {
    try {
      const amount = safeParseDecimal(invoice.amount);
      if (amount.gte(0)) {
        invoice.amount = amount.times(-1).toString();
      }

      const response = await fetchApiPut<any>(`defer_payment/invoice/${invoice.id}`, invoice);
      if (response.success) {
        queryClient.invalidateQueries({ queryKey: ["deferred"] });
      }

      return response;
    } catch (error: any) {
      return {
        success: false,
        error: error?.response?.data?.message || "Failed to update invoice",
      };
    }
  };
}

interface GenerateReportFilters {
  contract_filters?: ContractFilter;
  invoice_filters?: InvoiceFilter;
  report_type: "weekly" | "yearly",
  destination_type: "customer" | "internal",
}


// Update an existing deferred payment invoice
export function useGenerateReport() {
  const queryClient = useQueryClient();

  return async (filters: GenerateReportFilters) => {
    try {
      const response = await fetchApiPost<{ link: string; }>(`defer_payment/report`, filters);

      return response;
    } catch (error: any) {
      return {
        success: false,
        data: {} as any,
        error: error?.response?.data?.message || "Failed to generate report",
      };
    }
  };
}

export function useReleaseInvoice() {
  const queryClient = useQueryClient();

  return async (id: string, data: z.infer<typeof ReleaseDeferPaymentValidation>) => {
    try {
      const response = await fetchApiPost<any>(`defer_payment/invoice/${id}/release`, data);
      if (response.success) {
        queryClient.invalidateQueries({ queryKey: ["deferred"] });
      }
      return response;
    } catch (error: any) {
      return {
        success: false,
        data: {} as any,
        error: error?.response?.data?.message || "Failed to release invoice",
      };
    }
  };
}

export function useReleaseContract() {
  const queryClient = useQueryClient();

  return async (id: string, data: z.infer<typeof ReleaseDeferPaymentValidation>) => {
    try {
      const response = await fetchApiPost<any>(`defer_payment/contract/${id}/release`, data);
      if (response.success) {
        queryClient.invalidateQueries({ queryKey: ["deferred"] });
      }
      return response;
    } catch (error: any) {
      return {
        success: false,
        data: {} as any,
        error: error?.response?.data?.message || "Failed to release contract",
      };
    }
  };
}
