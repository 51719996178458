import { create } from "zustand";
import { useState, useEffect } from "react";
import { useStytchMember, useStytchMemberSession } from "@stytch/react/b2b";
import moment from "moment-timezone";
import {
  GenerateLoadingOrError,
  genFlatOptionList,
  genIdOptionList,
  CommonDestinationTimezone,
  createDateLabel,
} from "../../lib/utils";
import {
  Archive as ArchiveXIcon,
  ListFilter as ListFilterIcon,
  ArrowDownWideNarrow as SortIcon,
} from "lucide-react";
import {
  CommodityOptions,
  Commodities,
  GeneralContractSettingType,
  GeneralContractSettingTypeOptions,
  GeneralContractSettingContractTypeOptions,
} from "../../lib/api/commodity";
import {
  InputRequiredStatus,
  InputFormType,
  InputType,
  ImportType,
  ExportType,
  InputFilter,
  InputRequiredStatusType,
} from "../../lib/form";
import {
  Uom,
  Currency,
  CostCategory,
  CalculateOn,
  AddDedAdjType,
  RateType,
  ContractType,
  PurchaseSaleType,
  useListGeneralContracts,
  useListLocations,
  useListMarketZones,
  GeneralContract,
  useCreateGeneralContract,
  useUpdateGeneralContract,
  Location,
  MarketZone,
  purchaseSaleOptions,
  uomOptions,
  currencyOptions,
  generateContractTypesWith,
  calculateOnOptions,
  rateTypeOptions,
  costCategoryOptions,
  addDedADjOptions,
  deliveryTermsOptions,
  DeliveryTerms,
  useDownloadOpenRecentContractData,
  useDownloadOpenImportContractData,
  useArchiveGeneralContract,
  ContractFilterValidation,
  ContractSortValidation,
  ContractSortOptions,
  ContractFilterOptions,
  ContractSortFiltersOptions,
  ContractSourceType,
  mzVendorOptions,
  useUpdateGeneralContractSetting,
  useCreateGeneralContractSetting,
  useListGeneralContractSettings,
  useArchiveGeneralContractSetting,
} from "../../lib/api/generalContract";
import { useListTraders } from "../../lib/api/trader";
import { reduceArrayTo, formulateOptionMonths } from "../../lib/api/common";
import { useListCustomers } from "../../lib/api/customer";
import { Trader, Customer } from "../../lib/api/reports";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
  PrimitiveGridLayout,
  PrimitiveManagedTable,
  PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { FormGridHelper } from "../reports/helper";
import {
  BaseGeneralSettingFormSchema,
  ContractAddDedAdjBaseForm,
  ContractMzAdjBaseForm,
  FilterFormSchema,
  SortFormSchema,
  SortingOptions,
} from "../../lib/schemas/generalContract";
import { getUserStytchName } from "../../lib/models/auth";

export const BaseFormSchema = {
  archive_contract_button: {
    label: "Archive",
    action_reference_id: "onArchive",
    action: "button",
    derived: true,
    skipForm: true,
    apiDisplayFormat: ({ original }: any) => {
      return <ArchiveXIcon className="h-4 w-4" />;
    },
  },
  ...BaseGeneralSettingFormSchema,
};

const useSortFilterState = create((set) => ({
  sorting: {
    created_at: "desc",
  } as ContractSortOptions,
  filters: {} as ContractFilterOptions,
  setSortState: (newState: ContractSortOptions) =>
    set((state: any) => ({ sorting: newState })),
  setFilterState: (newState: ContractFilterOptions) =>
    set((state: any) => ({ filters: newState })),
  clearAll: () => set((state: any) => ({ filters: {}, sorting: {} })),
}));

const createState = FormHelper.generateBaseState({
  schemas: [BaseFormSchema],
  baseSchema: BaseFormSchema,
  overrideValues: {
    setting_type: GeneralContractSettingType.ContractComment,
    active_start_date: new Date(),
    use_all_commodities: false,
  }
});

const updateState = FormHelper.generateBaseState({
  schemas: [BaseFormSchema],
  baseSchema: BaseFormSchema,
});

function CreateDynamicForm({ onCloseForm }: { onCloseForm: any }) {
  const createApiContractSetting = useCreateGeneralContractSetting();

  return (
    <FormGridHelper
      state={createState}
      activeSchema={BaseFormSchema}
      id={"CreateGeneralContractSetting"}
      externalReferences={{
        commodityOptions: CommodityOptions,
        settingTypeOptions: GeneralContractSettingTypeOptions,
        baseContractTypeOptions: GeneralContractSettingContractTypeOptions,
      }}
      failMessage="Failed to create setting"
      successMessage="Created"
      onSubmitTo={createApiContractSetting}
      onCloseForm={onCloseForm}
      preventSubmitClose={false}
    />
  );
}

function UpdateDynamicForm({ onCloseForm }: { onCloseForm: any }) {
  const updateApiContractSetting = useUpdateGeneralContractSetting();
  return (
    <FormGridHelper
      state={updateState}
      id={"UpdateGeneralContractSetting"}
      activeSchema={BaseFormSchema}
      externalReferences={{
        commodityOptions: CommodityOptions,
        settingTypeOptions: GeneralContractSettingTypeOptions,
        baseContractTypeOptions: GeneralContractSettingContractTypeOptions,
      }}
      failMessage="Failed to update setting"
      successMessage="Updated"
      onSubmitTo={updateApiContractSetting}
      onCloseForm={onCloseForm}
      preventSubmitClose={false}
    />
  );
}

function CreateFormModal({ }: {}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PrimitiveDialogForm
      className="max-w-[98%] h-[98%]"
      isOpen={isOpen}
      onDialogChange={setIsOpen}
      buttonLabel={"Create"}
      dialogTitle={"Create Setting"}
      form={<CreateDynamicForm onCloseForm={setIsOpen} />}
    />
  );
}

function UpdateFormModal({
  isOpen,
  onDialogChange,
}: {
  isOpen: boolean;
  onDialogChange: any;
}) {
  return (
    <PrimitiveDialogForm
      className="max-w-[98%] h-[98%]"
      isOpen={isOpen}
      onDialogChange={onDialogChange}
      dialogTitle={"Update Setting"}
      form={<UpdateDynamicForm onCloseForm={onDialogChange} />}
    />
  );
}

export function GeneralContractSettingsDashboard() {
  const { toast } = useToast();
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const settingsRes = useListGeneralContractSettings();
  const archiveGeneralContractSetting = useArchiveGeneralContractSetting();
  const loadingOrErrorUi = GenerateLoadingOrError([settingsRes]);
  const updatePopulateWith: any = updateState.useInputsState(
    (state: any) => state.populateWith,
  );
  const updateClearForm: any = updateState.useInputsState(
    (state: any) => state.clearForm,
  );

  if (loadingOrErrorUi) {
    return (
      <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          General Contract Settings
        </h1>
        {loadingOrErrorUi}
      </section>
    );
  }

  const onArchive = async ({ id }: { id: string }) => {
    if (isArchiving) return;
    setIsArchiving(true);

    const result = await archiveGeneralContractSetting({ id });
    setIsArchiving(false);

    if (!result.success) {
      toast({
        title: "Failed to archive setting",
        description: "",
      });
    } else {
      toast({
        title: "Archived",
        description: "",
      });
    }
  };

  const settings = settingsRes.data?.data || [];

  return (
    <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
      <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
        General Contracts Settings
      </h1>
      <div className="flex justify-end gap-4 mb-4">
        <CreateFormModal />
        <UpdateFormModal
          isOpen={isUpdateOpen}
          onDialogChange={(value: any) => {
            if (!value) {
              setIsUpdateOpen(false);
              updateClearForm();
            }
          }}
        />
      </div>
      <PrimitiveManagedTable
        tableHeight="600px"
        externalReferences={{
          onArchive: onArchive,
          onArchiveLoading: isArchiving,
        }}
        schema={BaseFormSchema}
        values={settings}
        onRowClick={(item) => {
          setIsUpdateOpen(true);
          updatePopulateWith(item);
        }}
      />
    </section>
  );
}
