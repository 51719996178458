import { useEffect, useRef, useState } from "react";
import { Filter } from "lucide-react";
import parsePhoneNumber, { AsYouType as PhoneNumberFormatterAsYouType } from 'libphonenumber-js';
import { fetchChatsPage, useChatsPaginated, ChatStream, ChatsParams, ChatTypeOptions, ChatSourceOptions, MessageGroupOptions, useSendTestMessage, TwilioMessageName, SendTestMessageValidation } from "@/lib/api/chats";
import { Skeleton } from "@/components/ui/skeleton";
import { ChatList } from "./ChatList";
import { ChatDetail } from "./ChatDetail";
import { cn, GenerateLoadingOrError } from "@/lib/utils";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Combobox } from "@/components/ui/combobox";
import { useToast } from "@/components/ui/use-toast";
import { Loader } from "@/components/ui/loader";
import { BearInput, BearSelectInput } from "@/lib/form/ui";

export function ChatDashboard() {
  const [page, setPage] = useState(1);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState<ChatStream | null>(null);
  const [temporaryChatParams, setTemporaryChatParams] = useState<Omit<ChatsParams, "page">>({});
  const [chatParams, setChatParams] = useState<Omit<ChatsParams, "page">>({});
  const filtersApplied = useRef(false);
  const chatsRes: any = useChatsPaginated({
    params: { ...chatParams, page },
    queryFn: () => fetchChatsPage({ ...chatParams, page })
  });

  useEffect(() => {
    if (filterDialogOpen) {
      setTemporaryChatParams({ ...chatParams });
    }
  }, [filterDialogOpen]);

  const loadingOrErrorUi = GenerateLoadingOrError([chatsRes]);

  if (loadingOrErrorUi) {
    return (
      <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Chat Streams
        </h1>
        {loadingOrErrorUi}
      </section>
    );
  }

  const handleApplyFilters = (e: any) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setChatParams({
      ...temporaryChatParams
    });
    setPage(1);
    setFilterDialogOpen(false);
    filtersApplied.current = true;
  };

  const handleResetFilters = () => {
    setTemporaryChatParams({});
  };

  const chatData = chatsRes?.data?.data || {};
  const chatItems = chatData.data || [];
  const pagination = chatData.pagination || {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    pageSize: 10
  };

  return (
    <div className="flex flex-col h-screen max-h-[calc(100vh-4rem)]">
      <div className=" mb-2 ">
        <span className="flex flex-row justify-between items-center">
          <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
            Chats
          </h1>
          <span>
            <TestMessageModal />
            <Dialog open={filterDialogOpen} onOpenChange={setFilterDialogOpen}>
              <DialogTrigger asChild>
                <Button variant="outline" className="gap-2">
                  <Filter className="h-4 w-4" />
                  Filter
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[500px] md:max-w-[800px]">
                <DialogHeader>
                  <DialogTitle>Filter Chats</DialogTitle>
                </DialogHeader>

                <form className="grid gap-4 py-4" onSubmit={handleApplyFilters}>
                  <div className="grid grid-cols-[minmax(50px,100px),minmax(100px,300px)] items-center gap-4">
                    <label htmlFor="filter_chat_type_options" className="text-right">
                      Chat Type:
                    </label>
                    <Combobox
                      isModal
                      className={cn(
                        "w-full",
                      )}
                      value={temporaryChatParams.chatType || ""}
                      onValueChange={(value) => setTemporaryChatParams({ ...temporaryChatParams, chatType: value === "" ? undefined : value })}
                      options={ChatTypeOptions}
                    />
                  </div>
                  <div className="grid grid-cols-[minmax(50px,100px),minmax(100px,300px)]  items-center gap-4">
                    <label htmlFor="filter_chat_source_options" className="text-right">
                      Source:
                    </label>
                    <Combobox
                      isModal
                      className={cn(
                        "w-full",
                      )}
                      value={temporaryChatParams.create_source || ""}
                      onValueChange={(value) => setTemporaryChatParams({ ...temporaryChatParams, create_source: value === "" ? undefined : value })}
                      options={ChatSourceOptions}
                    />
                  </div>
                  <div className="grid grid-cols-[minmax(50px,100px),minmax(100px,300px)] items-center gap-4">
                    <label htmlFor="filter_chat_message_group" className="text-right">
                      Feature:
                    </label>
                    <Combobox
                      isModal
                      className={cn(
                        "w-full",
                      )}
                      value={temporaryChatParams.messageServiceName || ""}
                      onValueChange={(value) => setTemporaryChatParams({ ...temporaryChatParams, messageServiceName: value === "" ? undefined : value })}
                      options={MessageGroupOptions}
                    />
                  </div>
                </form>

                <DialogFooter>
                  <Button variant="outline" onClick={handleResetFilters}>
                    Reset Filters
                  </Button>
                  <Button onClick={handleApplyFilters}>Apply Filters</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </span>
        </span>
      </div>
      <div className="flex flex-col flex-grow h-[calc(100%-3rem)] md:grid md:grid-cols-[minmax(300px,400px),1fr] gap-4">
        <div className={cn("w-full h-full border rounded-lg overflow-hidden flex flex-col", !selectedChat ? "" : "hidden sm:block")}>
          <ChatList
            chats={chatItems}
            pagination={pagination}
            isLoading={chatsRes.isFetching}
            onPageChange={setPage}
            onSelectChat={setSelectedChat}
            selectedChatId={selectedChat?.id || null}
          />
        </div>
        <div className={cn("w-full h-full border rounded-lg overflow-hidden flex flex-col", !selectedChat ? "hidden sm:block" : "")}>
          {selectedChat ? (
            <ChatDetail
              chat={selectedChat}
              onBackClick={() => setSelectedChat(null)}
            />
          ) : (
            <div className="flex items-center justify-center h-full text-muted-foreground">
              Select a chat to view the conversation
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function TestMessageModal() {
  const { toast } = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const sendTestMessage = useSendTestMessage();
  const [formData, setFormData] = useState({
    sms_number: "",
    message_service_env: TwilioMessageName.DestinationMessagingSid,
    message: ""
  });

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onStatusChange = (status: boolean) => {
    setIsModalOpen(status);
    if (!status) { onClose(); }
  };

  const handleInputChange = (field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSendTestMessage = async () => {
    if (isSending) return;
    setIsSending(true);
    try {
      if (formData.sms_number && !parsePhoneNumber(formData.sms_number, "US")?.isValid()) {
        toast({
          title: "Invalid Number",
          description: "",
        });
        return;
      }

      const parsedDataValidation = SendTestMessageValidation.safeParse({
        ...formData,
        sms_number: parsePhoneNumber(formData.sms_number, "US")?.format("E.164") || formData.sms_number
      });

      if (!parsedDataValidation.success) {
        toast({
          title: "Missing info",
          description: "SMS number and message are required",
        });
        return;
      }
      const result = await sendTestMessage(parsedDataValidation.data!);
      if (result.success) {
        toast({
          title: "Sent",
          description: "",
        });
      } else {
        toast({
          title: "Failed to send toast message",
          description: "",
        });
      }
    } catch (error) {
      console.error("Failed to send test message", error);
      toast({
        title: "Failed to send toast message",
        description: "",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={onStatusChange}>
      <DialogTrigger asChild>
        <Button variant="outline" className="gap-2 mr-2">
          <Filter className="h-4 w-4" />
          Test Message
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px] md:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Send Test Message</DialogTitle>
        </DialogHeader>

        <form className="grid gap-4 py-4" onSubmit={handleSendTestMessage}>
          <BearSelectInput
            disabled={isSending}
            key={"message_service_env"}
            required
            name={"message_service_env"}
            label="Feature"
            value={formData.message_service_env}
            onValueChange={(value) => handleInputChange("message_service_env", value?.toLocaleUpperCase())}
            options={MessageGroupOptions}
          />
          <div className="grid grid-cols-1  items-center gap-4">
            <BearInput
              disabled={isSending}
              key={"chat_test_message_sms_number"}
              required
              name={"sms_number"}
              label={"To Number"}
              value={(() => {
                const phone_number = formData.sms_number;
                if (!phone_number?.trim()) return phone_number || "";
                return new PhoneNumberFormatterAsYouType('US').input(phone_number);
              })()}
              placeholder={""}
              onChange={(event) => handleInputChange("sms_number", event.target.value)}
            />
          </div>
          <div className="grid grid-cols-1  items-center gap-4">
            <BearInput
              disabled={isSending}
              key={"chat_test_message"}
              required
              name={"message"}
              label={"Message"}
              value={formData.message}
              placeholder={""}
              onChange={(event) => handleInputChange("message", event.target.value)}
            />
          </div>
        </form>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
          <Button onClick={handleSendTestMessage} disabled={isSending}>
            {isSending ? <Loader className="w-4 h-4" /> : <span>Send Test</span>}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
