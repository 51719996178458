import { useQuery, useQueryClient, QueryKey } from "@tanstack/react-query";
import Decimal from "decimal.js";
import { BaseDbObject } from "../models/db";
import { Customer } from "./reports";
import { z } from "zod";
import moment, { Moment } from "moment-timezone";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import { PossibleReturnType } from "../responseResults";
import { genFlatOptionList, CommonDestinationTimezone } from "../utils";
import { formatDateWithoutTimezone } from "./date";
import { DbUser } from "../models/index";

const queryKey = "inventoryMap";

export function useCreateInventoryMapUpload() {
  const queryClient = useQueryClient();

  return async (params: any) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<{ link: string; job_id: string }>(
      `/inventoryMap/harvestIq/upload/presign`,
      params,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: [queryKey] });

    return result;
  };
}

export function useGenerateInventoryMapDownloadLink() {
  const queryClient = useQueryClient();

  return async (params: { job_id: string; }) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<{ link: string }>(
      `/inventoryMap/harvestIq/upload/download/${params.job_id}`,
      params,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: [queryKey] });

    return result;
  };
}
