import LinkExtension from '@tiptap/extension-link';
import { useEditor, EditorContent, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Film, Paperclip } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from 'react';
import LoomNode, { LOOM_REGEX } from "./LoomNode";
import FileNode, { FileAttributes } from "./FileNode";
import { Loader } from "@/components/ui/loader";
import { useToast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { UploadPresignedFile } from '@/lib/file';
import { useCreateKnowledgeUploadLink } from '@/lib/api/knowledge';
import { EditorProps } from '@tiptap/pm/view';
import TextAlign from '@tiptap/extension-text-align';
import { cn } from '@/lib/utils';

const TiptapToolbar = ({ editor, onUploadClick }: { editor: Editor | null, onUploadClick: () => void }) => {
    const { toast } = useToast();
    if (!editor) {
        return null;
    }

    const addLoomVideo = () => {
        const url = window.prompt('Enter Loom Video URL (share or embed link):');

        if (url && LOOM_REGEX.test(url)) {
            editor.chain().focus().setLoomVideo({ src: url }).run();
        } else if (url) {
            toast({
                title: "Invalid URL",
                description: "Please enter a valid Loom share or embed URL.",
                variant: "destructive",
            });
        }
    };

    return (
        <div className="border border-input bg-transparent rounded-md p-1 mb-2 flex flex-wrap gap-1">
            {/* Toolbar buttons (Bold, Italic, Strike, H1, H2, List, Loom, Attach, Highlight, Align) */}
            <Button type="button" variant={editor.isActive('bold') ? 'default' : 'ghost'} size="sm" onClick={() => editor.chain().focus().toggleBold().run()} disabled={!editor.can().chain().focus().toggleBold().run()}>Bold</Button>
            <Button type="button" variant={editor.isActive('italic') ? 'default' : 'ghost'} size="sm" onClick={() => editor.chain().focus().toggleItalic().run()} disabled={!editor.can().chain().focus().toggleItalic().run()}>Italic</Button>
            <Button type="button" variant={editor.isActive('strike') ? 'default' : 'ghost'} size="sm" onClick={() => editor.chain().focus().toggleStrike().run()} disabled={!editor.can().chain().focus().toggleStrike().run()}>Strike</Button>
            <Button type="button" variant={editor.isActive('heading', { level: 1 }) ? 'default' : 'ghost'} size="sm" onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}>H1</Button>
            <Button type="button" variant={editor.isActive('heading', { level: 2 }) ? 'default' : 'ghost'} size="sm" onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}>H2</Button>
            <Button type="button" variant={editor.isActive('bulletList') ? 'default' : 'ghost'} size="sm" onClick={() => editor.chain().focus().toggleBulletList().run()}>List</Button>
            <Button type="button" variant='ghost' size="sm" onClick={addLoomVideo} title="Embed Loom Video"><Film className="h-4 w-4" /></Button>
            <Button type="button" variant='ghost' size="sm" onClick={onUploadClick} title="Attach Files"><Paperclip className="h-4 w-4" /></Button>
            <Button type="button" variant='ghost' size="sm" onClick={() => editor.chain().focus().toggleHighlight().run()} className={editor.isActive('highlight') ? 'is-active' : ''}>Highlight</Button>
            <Button type="button" variant='ghost' size="sm" onClick={() => editor.chain().focus().setTextAlign('left').run()} className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}>Left</Button>
            <Button type="button" variant='ghost' size="sm" onClick={() => editor.chain().focus().setTextAlign('center').run()} className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}>Center</Button>
            <Button type="button" variant='ghost' size="sm" onClick={() => editor.chain().focus().setTextAlign('right').run()} className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}>Right</Button>
            <Button type="button" variant='ghost' size="sm" onClick={() => editor.chain().focus().setTextAlign('justify').run()} className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}>Justify</Button>
        </div>
    );
};

export function useKnowledgeEditor({ mode, existingData, taskId }: { mode: 'new' | 'existing' | 'view'; existingData?: string; taskId?: string }) {
    const { toast } = useToast();
    const [error, setError] = useState<string>("")
    const createKnowledgeUploadLink = useCreateKnowledgeUploadLink();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const editor = useEditor({
        extensions: [
            StarterKit as any,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
            Highlight,
            FileNode.configure({
                showEditButton: mode === 'existing' || mode === "new" || false,
                showDownloadButton: mode === "view" || false,
            }),
            LinkExtension.configure({
                openOnClick: mode === "view" ? true : false,
                autolink: true,
            }),
            LoomNode,
        ],
        content: '',
        editable: mode === 'view' ? false : true,
        editorProps: {
            attributes: {
                class: cn('prose dark:prose-invert focus:outline-none min-h-[200px] w-full', ["new", "existing"].includes(mode) ? "p-2" : ""),
            },
            ...((mode === "existing" || mode === "new") ? ({
                handleDrop: (view, event, slice, moved) => {
                    if (!moved && event.dataTransfer?.files && event.dataTransfer.files.length > 0 && editor) {
                        handleFileUpload(event.dataTransfer.files, editor);
                        event.preventDefault(); // Prevent default drop behavior
                        return true; // Indicate we handled the drop
                    }
                    return false; // Let tiptap handle other drops
                },
                handlePaste: (view, event, slice) => {
                    return false; // Let tiptap handle other pastes
                }
            } as EditorProps) : ({})),
        },
    });

    // File Upload Logic (remains mostly the same)
    const onUploadFile = useCallback(async (file: File): Promise<{ success: boolean; fileId?: string; fileName?: string; error?: string }> => {
        if (!taskId) return { success: false, error: "Task ID is missing" };

        console.log(`Uploading file: ${file.name} (${file.size} bytes) for task ${taskId}`);
        toast({ title: "Uploading...", description: file.name });

        const result = await createKnowledgeUploadLink({
            mimetype: file.type,
            file_name: file.name,
            file_size: file.size,
            knowledge_item_id: taskId
        });

        if (!result.success || !result.data?.uploadLink || !result.data?.fileAttachmentId) {
            const errorMsg = result.message || "Failed to create upload link";
            console.error("Upload link creation failed:", errorMsg, result);
            toast({ title: "Upload Failed", description: errorMsg, variant: "destructive" });
            return { success: false, error: "Failed to get upload details" };
        }

        const { uploadLink, fileAttachmentId } = result.data;

        const uploadResult = await UploadPresignedFile(uploadLink, file);
        if (!uploadResult.success) {
            console.error("Upload failed:", uploadResult);
            toast({ title: "Upload Failed", description: "Failed to upload file to storage", variant: "destructive" });
            return { success: false, error: "Failed to upload file" };
        }

        toast({ title: "Upload Complete", description: file.name });
        return { success: true, fileId: fileAttachmentId, fileName: file.name };
    }, [taskId, createKnowledgeUploadLink]);

    const handleFileUpload = useCallback(async (files: FileList | null, editorInstance: Editor) => {
        if (!files || files.length === 0 || !editorInstance) return;

        for (const file of Array.from(files)) {
            const uploadResult = await onUploadFile(file);
            if (uploadResult.success && uploadResult.fileId && uploadResult.fileName) {
                const fileAttrs: FileAttributes = {
                    fileId: uploadResult.fileId,
                    fileName: uploadResult.fileName,
                    fileType: file.type,
                    alias: null
                };

                editorInstance.chain().focus().insertContent({
                    type: 'fileEmbed', // Use the correct node name
                    attrs: fileAttrs,
                }).run();
            } else {
                console.error("Upload failed for file:", file.name, uploadResult.error);
            }
        }
        // Clear the file input after processing
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }, [onUploadFile]); // Depend on onUploadFile


    useEffect(() => {
        if (editor && (mode === 'existing' || mode === "view") && existingData) {
            try {
                const content = JSON.parse(existingData);
                editor.commands.setContent(content);

                setError("");
            } catch (parseError) {
                console.error("Error parsing task content:", parseError);
                setError("Failed to load task content.");
                editor.commands.clearContent();
            }
        }
    }, [editor, existingData, mode]);

    // console.warn("hi ", existingData, mode, error)


    return { editor, error, handleFileUpload, fileInputRef };
}

export function KnowledgeEditor({
    mode, existingData, taskId, editor, handleFileUpload, fileInputRef, error
}: {
    error?: string;
    mode: 'new' | 'existing' | 'view';
    existingData?: string;
    taskId?: string;
    editor: Editor | null;
    handleFileUpload: (files: FileList | null, editorInstance: Editor) => Promise<void>;
    fileInputRef: React.RefObject<HTMLInputElement>
}) {
    const handleUploadButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (editor) {
            handleFileUpload(event.target.files, editor);
        }
    };

    if (error) {
        return (<p className="text-destructive italic">{error}</p>);
    }

    if ((mode === "existing") && !taskId) {
        return (<Loader className="w-4 h-4" />);
    }

    // console.warn("has editor", !!editor, mode, existingData, taskId)
    if (!editor) {
        return (<Loader className="w-4 h-4" />);
    }

    if (mode === "view" && editor.isEmpty) {
        return (<p className="text-muted-foreground italic">No content provided</p>);
    }

    return (
        <div className="w-full flex flex-col">
            {mode === "new" || mode === "existing" && (
                <input
                    type="file"
                    multiple
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    className="hidden"
                    accept="*" // Or specify accepted file types
                />
            )}
            {mode === "new" || mode === "existing" && (
                <TiptapToolbar editor={editor} onUploadClick={handleUploadButtonClick} />
            )}
            <EditorContent editor={editor} className="w-full min-h-[200px]" />
        </div>
    );
}
