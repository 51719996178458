import { z } from "zod";
import moment from "moment-timezone";
import { isValidDecimal, safeParseDecimal } from "../excel";
import {
  PossibleReturnType,
  ThrottledError,
  ServerError,
  SuccessResult,
} from "../responseResults";
import Decimal from "decimal.js";

export const CommonDestinationTimezone = "America/Chicago";
const apiUrl = process.env.REACT_APP_API_URL;

export const BasicDbObjectValidation = z.object({
  id: z.string().uuid(),
  created_at: z.coerce.date(),
  updated_at: z.coerce.date(),
});

export const DateRangeSchemaValidation = z.object({
  start: z.string().or(z.date()).optional().transform(zodTransformChicagoDate),
  end: z.string().or(z.date()).optional().transform(zodTransformChicagoDate)
});

export interface RequestOptions {
  accept?: string;
  contentType?: string;
}

function generateUrlFrom(pathname: string, queryParams?: URLSearchParams) {
  const url = new URL(`${apiUrl}/${pathname}`);

  if (queryParams) {
    for (const [key, value] of queryParams.entries()) {
      url.searchParams.append(key, value);
    }
  }
  return url;
}

async function handleFetchResponse<T>(
  fetchUrl: string,
  fetchBody: RequestInit,
): Promise<PossibleReturnType<T>> {
  try {
    return await fetch(fetchUrl, fetchBody)
      .then((res) => {
        if (res.status === 429)
          throw new ThrottledError("Please wait a minute and try again.");
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        if (typeof res?.success !== "boolean") {
          console.error("Encountered unknown api response type: ", res);
          throw new Error("Invalid response body");
        }
        return res;
      });
  } catch (error: any) {
    return new ServerError("", error);
  }
}

async function handleFetchResponseRaw<T>(
  fetchUrl: string,
  fetchBody: RequestInit,
  contentType?: string,
): Promise<PossibleReturnType<T>> {
  try {
    return await fetch(fetchUrl, fetchBody)
      .then((res) => {
        if (res.status === 429)
          throw new ThrottledError("Please wait a minute and try again.");
        return res;
      })
      .then((res) => res.text())
      .then(res => new SuccessResult("", res));
  } catch (error: any) {
    return new ServerError("", error);
  }
}

export async function fetchApiDelete<T>(
  pathname: string,
  queryParams?: URLSearchParams,
): Promise<PossibleReturnType<T>> {
  const url = generateUrlFrom(pathname, queryParams);
  return handleFetchResponse(url.toString(), {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
}

export async function fetchApiPut<T>(
  pathname: string,
  body: any,
  queryParams?: URLSearchParams,
): Promise<PossibleReturnType<T>> {
  const url = generateUrlFrom(pathname, queryParams);
  return handleFetchResponse(url.toString(), {
    method: "PUT",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export async function fetchApiPost<T>(
  pathname: string,
  body: any,
  queryParams?: URLSearchParams,
  options?: RequestOptions,
): Promise<PossibleReturnType<T>> {
  const url = generateUrlFrom(pathname, queryParams);

  return handleFetchResponse(url.toString(), {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: options?.accept || "application/json",
      "Content-Type": options?.contentType || "application/json",
    },
    body: JSON.stringify(body),
  });
}

export async function fetchApiPostFormData<T>(
  pathname: string,
  form: FormData,
): Promise<PossibleReturnType<T>> {
  const url = generateUrlFrom(pathname, new URLSearchParams());

  return handleFetchResponse(url.toString(), {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": `multipart/form-data`, // ; boundary=${(form as any)._boundary}
    },
    body: form,
  });
}

export async function fetchApiGet<T>(
  pathname: string,
  queryParams?: URLSearchParams,
): Promise<PossibleReturnType<T>> {
  const url = generateUrlFrom(pathname, queryParams);

  return handleFetchResponse(url.toString(), {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export async function fetchApiGetRaw<T>(
  pathname: string,
  contentType?: string,
  queryParams?: URLSearchParams,
): Promise<PossibleReturnType<T>> {
  const url = generateUrlFrom(pathname, queryParams);

  return handleFetchResponseRaw(url.toString(), {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: contentType || "application/json",
      "Content-Type": contentType || "application/json",
    },
  });
}


export function zodTransformDecimal(val: string | undefined): Decimal | undefined {
  if (isValidDecimal(val)) {
    return safeParseDecimal(val!);
  }
  return undefined;
}

export function zodTransformChicagoDate(item: string | Date | undefined | null): Date | undefined | null {
  if (typeof item === "string" && !item) { return undefined; }
  if (!item) { return item as any; }
  const moment_date = moment.tz(item, CommonDestinationTimezone);
  if (!moment_date.isValid()) return undefined;
  return moment_date.toDate();
}
