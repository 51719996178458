import React, { useEffect, useState } from 'react';
import { useGetKnowledgeTags, KnowledgeApi, useCreateTag, useUpdateTag, AddTagValidation, useGetKnowledgePermissions } from '@/lib/api/knowledge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { GenerateLoadingOrError } from '@/lib/utils';
import { PlusCircle, Edit } from 'lucide-react';
import { Loader } from '@/components/ui/loader';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { useToast } from '@/components/ui/use-toast';
import { Combobox } from '@/components/ui/combobox';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Badge } from '@/components/ui/badge';

const CreateTagModal = ({ isOpen, onClose, permissionOptions }: { isOpen: boolean, onClose: () => void, permissionOptions: { value: string, label: string }[] }) => {
    const { toast } = useToast();
    const createTag = useCreateTag();
    const [data, setData] = useState<typeof AddTagValidation._type>({} as any);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setData({} as any);
        }
    }, [isOpen]);

    if (!isOpen) return null;


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        if (e) {
            e.preventDefault();
        }
        if (isLoading) return;

        try {
            setIsLoading(true);
            const result = await createTag({ name: data.name, special_permission_id: data.special_permission_id });
            if (result.success) {
                toast({
                    title: "Success",
                    description: "Tag Created",
                });
                onClose();
            } else {
                throw result;
            }

        } catch (error: any) {
            toast({
                title: "Failed to create tag",
                description: error?.message || "",
                variant: "destructive"
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create Tag</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col items-start justify-center gap-4">
                        <div className="grid gap-2">
                            <Label className="flex-shrink-0" htmlFor="tag-name">Tag Name</Label>
                            <Input
                                id="tag-name"
                                required
                                type="text"
                                placeholder="Tag Name"
                                value={data.name || ""}
                                onChange={(e) => setData({ ...data, name: e.target.value || "" })}
                            />
                        </div>
                        {permissionOptions.length > 0 && (
                            <div className="grid gap-2">
                                <Label className="flex-shrink-0" htmlFor="permission-select">Permission</Label>
                                <Combobox
                                    id="permission-select"
                                    options={permissionOptions}
                                    value={data.special_permission_id || ""}
                                    onValueChange={(value) => setData({ ...data, special_permission_id: value })}
                                />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row items-center justify-between gap-2 mt-4">
                        <Button variant="outline" onClick={onClose}>Close</Button>
                        <Button type="submit" disabled={isLoading}>Save</Button>
                    </div>

                </form>

            </DialogContent>
        </Dialog>
    );
};

const EditTagModal = ({ isOpen, onClose, tag, permissionOptions }: { isOpen: boolean, onClose: () => void, tag: KnowledgeApi.KnowledgeTaskTag | null, permissionOptions: { value: string, label: string }[] }) => {
    const { toast } = useToast();
    const updateTag = useUpdateTag();
    const [data, setData] = useState<typeof AddTagValidation._type>({} as any);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setData(tag as any);
        }
    }, [isOpen]);

    if (!isOpen || !tag) return null;


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        if (e) {
            e.preventDefault();
        }
        if (isLoading) return;

        try {
            setIsLoading(true);
            const result = await updateTag({ id: tag.id, name: data.name, special_permission_id: data.special_permission_id });
            if (result.success) {
                toast({
                    title: "Success",
                    description: "Tag Updated",
                });
                onClose();
            } else {
                throw result;
            }

        } catch (error: any) {
            toast({
                title: "Failed to update tag",
                description: error?.message || "",
                variant: "destructive"
            });
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Edit Tag: {tag.name}</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col items-start justify-center gap-4">
                        <div className="grid gap-2">
                            <Label className="flex-shrink-0" htmlFor="tag-name">Tag Name</Label>
                            <Input
                                id="tag-name"
                                required
                                type="text"
                                placeholder="Tag Name"
                                value={data.name || ""}
                                onChange={(e) => setData({ ...data, name: e.target.value || "" })}
                            />
                        </div>
                        {permissionOptions.length > 0 && (
                            <div className="grid gap-2">
                                <Label className="flex-shrink-0" htmlFor="permission-select">Permission</Label>
                                <Combobox
                                    id="permission-select"
                                    options={permissionOptions}
                                    value={data.special_permission_id || ""}
                                    onValueChange={(value) => setData({ ...data, special_permission_id: value })}
                                />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row items-center justify-between gap-2 mt-4">
                        <Button variant="outline" onClick={onClose}>Close</Button>
                        <Button type="submit" disabled={isLoading}>Save</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};


export function TagsManagementView() {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedTag, setSelectedTag] = useState<KnowledgeApi.KnowledgeTaskTag | null>(null);
    const permissionOptionsRes = useGetKnowledgePermissions();

    const tagsRes = useGetKnowledgeTags();
    // Uncomment and use these when modals are implemented
    // const updateTagMutation = useUpdateTag();

    const loadingOrErrorUi = GenerateLoadingOrError([tagsRes, permissionOptionsRes]);

    const handleCreateClick = () => {
        setIsCreateModalOpen(true);
    };

    const handleEditClick = (tag: KnowledgeApi.KnowledgeTaskTag) => {
        setSelectedTag(tag);
        setIsEditModalOpen(true);
    };

    const handleCloseModals = () => {
        setIsCreateModalOpen(false);
        setIsEditModalOpen(false);
        setSelectedTag(null);
    };

    const tags = tagsRes.data?.data || [];
    const permissions = permissionOptionsRes.data?.data || [];
    const permissionOptions = permissions?.map((permission: { id: string, name: string }) => ({
        value: permission.id,
        label: permission.name
    })) || [];

    return (
        <div className="space-y-4">
            <div className="flex flex-row items-center justify-between mb-4">
                <h2 className="scroll-m-20 text-xl font-semibold tracking-tight lg:text-2xl">
                    Manage Tags
                </h2>
                <Button onClick={handleCreateClick} size="sm">
                    <PlusCircle className="h-4 w-4 mr-2" />
                    Create Tag
                </Button>
            </div>

            {loadingOrErrorUi ? (
                <div className="flex justify-center items-center h-40">
                    {loadingOrErrorUi}
                </div>
            ) : tags.length > 0 ? (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
                    {tags.map((tag: KnowledgeApi.KnowledgeTaskTag) => (
                        <div
                            key={tag.id}
                            className="flex flex-row items-center justify-between p-4 border rounded-lg hover:shadow-md transition-shadow duration-200 bg-card text-card-foreground"
                        >
                            <span className="font-medium break-words text-sm flex-grow">
                                {tag.name}
                            </span>
                            {!!tag.SpecialPermission && (
                                <Badge variant="outline" className="text-xs text-muted-foreground">
                                    {tag.SpecialPermission.name || ""}
                                </Badge>
                            )}
                            <div className="">
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    className="h-7 w-7"
                                    onClick={() => handleEditClick(tag)}
                                >
                                    <Edit className="h-4 w-4" />
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="flex justify-center items-center h-40 border rounded-lg bg-muted/40">
                    <p className="text-sm text-muted-foreground text-center">No tags created yet.</p>
                </div>
            )}

            {/* Render Modals (currently placeholders) */}
            <CreateTagModal
                isOpen={isCreateModalOpen}
                onClose={handleCloseModals}
                permissionOptions={permissionOptions}
            />
            <EditTagModal
                isOpen={isEditModalOpen}
                onClose={handleCloseModals}
                tag={selectedTag}
                permissionOptions={permissionOptions}
            />
        </div>
    );
} 