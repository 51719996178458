import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment-timezone";
import { z } from "zod";
import { DbUser } from "../models";
import { BaseDbObject } from "../models/db";
import { Customer } from "./reports";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";


export const AddPriceDataValidation = z.object({
date: z.string().refine((date) => {
    if (!date || !/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    return false;
    }

    const parsedDate = moment(date);
    return parsedDate.isValid();
}, {
    message: "Invalid date",
}),

commodity: z.string().min(4),
high: z.number().min(0),
low: z.number().min(0),
open: z.number().min(0),
close: z.number().min(0),

override: z.boolean().optional().default(false),
});


export function useAddPriceData() {
  const queryClient = useQueryClient();

  return async (params: typeof AddPriceDataValidation._type) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `price-data/futures/contracts/commodity`,
      params,
      queryParams
    );

    return result;
  };
}
