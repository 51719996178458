import { ArrowLeft, ArrowLeftSquare, ChevronLeft } from "lucide-react";
import { create } from "zustand";
import { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import { fetchChatMessagesPage, useChatMessagesPaginated, ChatParticipant, ChatMessage, useSendChatMessage } from "@/lib/api/chats";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Avatar } from "@/components/ui/avatar";
import { Input } from "@/components/ui/input";
import { Loader } from "@/components/ui/loader";
import { ChatStream } from "@/lib/api/chats";
import { CommonDestinationTimezone } from "@/lib/api/document";
import { formatChatSource } from "../utils";
import { useToast } from "@/components/ui/use-toast";

interface ChatStore {
  messages_by_stream: Record<string, ChatMessage[]>,
  draft_messages_by_stream: Record<string, string>,
  self_participant: ChatParticipant | undefined,
  known_messages: Set<string>,

  addMessages: ({ data, chatStream }: { data: ChatMessage[], chatStream: { id: string } }) => void;
  setDraftMessage: (stream_id: string, messages: string) => void;
  setSelfParticipant: (participant: ChatParticipant | undefined) => void;
}

const useMessagesSet = create<ChatStore>((set) => ({
  messages_by_stream: {},
  draft_messages_by_stream: {},
  self_participant: undefined,
  known_messages: new Set(),

  addMessages: (info: { data: ChatMessage[], chatStream: { id: string } }) => {
    return set((state: any) => {
      const chatId = info.chatStream.id;
      if (!chatId) return state;

      const latest_chat_messages = state.messages_by_stream[chatId] || [];
      for (const message of info.data) {
        if (state.known_messages.has(message.id)) {
          continue;
        }

        latest_chat_messages.push(message);
        state.known_messages.add(message.id);
      }

      latest_chat_messages.sort((a: ChatMessage, b: ChatMessage) => {
        // Sort by date first (ascending)
        const aDate = moment.tz(a.date, CommonDestinationTimezone);
        const bDate = moment.tz(b.date, CommonDestinationTimezone);
        const dateComparison = aDate.valueOf() - bDate.valueOf();
        // If dates are equal, sort by id as secondary criteria
        return dateComparison !== 0 ? dateComparison : a.id.localeCompare(b.id);
      });


      state.messages_by_stream[chatId] = latest_chat_messages;
      return state;
    })
  },
  setDraftMessage: (stream_id: string, message: string) =>
    set((state: any) => ({
      draft_messages_by_stream: {
        ...state.draft_messages_by_stream,
        [stream_id]: message
      }
    })),
  setSelfParticipant: (participant: ChatParticipant | undefined) =>
    set((state: any) => ({ self_participant: participant })),
}));

export function ChatDetail({
  chat,
  onBackClick
}: {
  chat: ChatStream,
  onBackClick: () => void
}) {
  const [page, setPage] = useState(1);
  const [isSending, setIsSending] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  // Fetch messages for the selected chat
  const messagesRes: any = useChatMessagesPaginated({
    params: {
      page,
      chat_stream_id: chat.id,
    },
    queryFn: () => fetchChatMessagesPage({
      page,
      chat_stream_id: chat.id,
    })
  });
  const chat_messages = useMessagesSet((state) => state.messages_by_stream[chat.id] || []);
  const selfParticipant = useMessagesSet((state) => state.self_participant);
  const draftMessage = useMessagesSet((state) => state.draft_messages_by_stream[chat.id] || "");
  const setDraftMessage = useMessagesSet((state) => state.setDraftMessage);
  const setSelfParticipant = useMessagesSet((state) => state.setSelfParticipant);
  const addMessages = useMessagesSet((state) => state.addMessages || {});
  const sendMessage = useSendChatMessage();
  const { toast } = useToast();

  useEffect(() => {
    setPage(1);
  }, [chat.id, setPage]);

  // Scroll to bottom when messages change
  useEffect(() => {
    if (messagesRes?.data?.success && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messagesRes.data]);

  // Set self participant when data is loaded
  useEffect(() => {
    if (messagesRes?.data?.success) {
      const selfParticipants = messagesRes.data.data.selfParticipants || [];
      if (selfParticipants.length > 0) {
        setSelfParticipant(selfParticipants[0]);
      }
      if (messagesRes?.data?.data) {
        addMessages(messagesRes.data.data)
      }
    }
  }, [chat.id, messagesRes.data]);

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSending) { return; }
    setIsSending(true);
    try {
      if (!draftMessage.trim() || !selfParticipant) return;
      const res = await sendMessage({
        chat_stream_id: chat.id,
        sender_participant_id: selfParticipant.id,
        message: draftMessage,
      });

      if (res.success) {
        setDraftMessage(chat.id, "");
        toast({
          title: "Sent",
          description: "Reloading messages.",
        });
      } else {
        console.error("Failed to send message local", res.message, res.data);
        toast({
          title: "Failed to send",
          description: res.message,
        });
      }
    } catch (error) {
      console.error("Caught Failed to send message", error);
      toast({
        title: "Failed to send",
        description: "",
      });
    } finally {
      setIsSending(false);
    }
  };

  if (messagesRes.isLoading && !messagesRes.data) {
    return (
      <div className="flex flex-col h-full p-4">
        <div className="flex items-center mb-4">
          <Button
            variant="ghost"
            size="icon"
            onClick={onBackClick}
            className="md:hidden mr-2"
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <h2 className="text-lg font-medium">{chat.name}</h2>
        </div>
        <div className="flex-grow flex items-center justify-center">
          <Loader />
        </div>
      </div>
    );
  }

  if (messagesRes.error || !messagesRes?.data?.success) {
    return (
      <div className="flex flex-col h-full p-4">
        <div className="flex items-center mb-4">
          <Button
            variant="ghost"
            size="icon"
            onClick={onBackClick}
            className="md:hidden mr-2"
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <h2 className="text-lg font-medium">{chat.name}</h2>
        </div>
        <div className="flex-grow flex items-center justify-center text-red-500">
          Failed to load messages. Please try again.
        </div>
      </div>
    );
  }

  const pagination = messagesRes?.data?.data?.pagination;
  const allParticipants = messagesRes?.data?.data?.allParticipants || [];

  // Helper to find participant by ID
  const findParticipant = (participantId: string) => {
    return allParticipants.find((p: ChatParticipant) => p.id === participantId);
  };

  // Helper to check if a message is from the current user
  const isFromSelf = (message: ChatMessage) => {
    return message.sender_participant_id === selfParticipant?.id;
  };

  // Helper to get participant name
  const getParticipantName = (participant: ChatParticipant | undefined) => {
    if (!participant) return "Unknown";

    if (participant.User) {
      return participant.User.name || participant.User.email || "User";
    }

    if (participant.Customer) {
      return participant.Customer.long_name || "Customer";
    }

    return participant.sms_number || "Unknown";
  };

  // Helper to get participant type
  const getParticipantType = (participant: ChatParticipant | undefined) => {
    if (!participant) return "unknown";
    if (participant.User) return "user";
    return "customer";
  };

  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="flex items-center p-3 border-b">
        <div>
          <span className="flex flex-row items-center">
            <Button
              variant="ghost"
              onClick={onBackClick}
              className="p-2 m-0 mr-1 sm:hidden"
            >
              <ArrowLeft className="h-6 w-6" />
            </Button>
            <h2 className="text-lg font-medium">{chat.name}</h2>
          </span>
          <p className="text-xs text-muted-foreground">
            {formatChatSource({ source: chat.create_source, type: chat.chat_type, message_group_env: chat.message_service_env }).join(" | ").trim()}
          </p>
        </div>
      </div>

      {/* Messages */}
      <div className="flex-grow overflow-y-auto p-4">
        {/* Load more button */}
        {pagination.currentPage < pagination.totalPages && (
          <div className="flex justify-center mb-4">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setPage(page + 1)}
              disabled={messagesRes.isFetching}
            >
              {messagesRes.isFetching ? <Loader className="h-4 w-4 animate-spin" /> : "Load earlier messages"}
            </Button>
          </div>
        )}

        {/* Message list */}
        <div className="space-y-4">
          {chat_messages.map((message: ChatMessage) => {
            const sender = findParticipant(message.sender_participant_id || '');
            const isSelf = isFromSelf(message);
            const participantType = getParticipantType(sender);

            return (
              <div
                key={message.id}
                className={`flex ${isSelf ? 'justify-end' : 'justify-start'}`}
              >
                <div className={`flex max-w-[80%] ${isSelf ? 'flex-row-reverse' : 'flex-row'}`}>
                  {!isSelf && (
                    <Avatar className="mr-2 h-8 w-8 flex-shrink-0">
                      <div className={`h-full w-full rounded-full
                        ${participantType === 'user' ? 'bg-blue-500' : 'bg-green-500'}`}
                      />
                    </Avatar>
                  )}

                  <div className="flex flex-col">
                    <Card
                      className={`p-3 min-w-[150px]  ${isSelf
                        ? 'bg-blue-500 text-white'
                        : participantType === 'user'
                          ? 'bg-blue-100'
                          : 'bg-green-100'
                        }`}
                    >
                      {!isSelf ? (
                        <p className="text-xs font-medium mb-1">
                          {getParticipantName(sender)}
                        </p>
                      ) : (
                        <p className="text-xs font-medium mb-1">
                          LHC
                        </p>
                      )}
                      <p className="text-sm">{message.message}</p>
                    </Card>
                    <span className={`text-xs text-muted-foreground mt-1 ${isSelf ? 'text-right' : 'text-left'}`}>
                      {moment.tz(message.date, CommonDestinationTimezone).format('h:mm A on MMMM Do YYYY ')}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>

        {chat_messages.length === 0 && !messagesRes.isFetching && (
          <div className="flex items-center justify-center h-full text-muted-foreground">
            No messages yet
          </div>
        )}
      </div>

      {/* Message input */}
      <div className="p-3 border-t">
        {!!selfParticipant && (
          <form onSubmit={handleSendMessage} className="flex">
            <Input
              value={draftMessage}
              onChange={(e) => setDraftMessage(chat.id, e.target.value || "")}
              placeholder="Type a message..."
              className="mr-2"
              disabled={!selfParticipant}
            />
            <Button
              type="submit"
              disabled={!draftMessage.trim() || !selfParticipant || isSending}
            >
              Send
            </Button>
          </form>
        )}
        {!selfParticipant && (
          <p className="text-xs text-red-500 mt-1">
            You don't have permission to send messages in this chat
          </p>
        )}
      </div>
    </div>
  );
}
