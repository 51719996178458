import {
  ColumnDef,
  Row,
  SortDirection,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { HTMLAttributes, forwardRef, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { TableCell, TableHead, TableRow } from "./table";
import { cn } from "../../lib/utils";

// Original Table is wrapped with a <div> (see https://ui.shadcn.com/docs/components/table#radix-:r24:-content-manual),
// but here we don't want it, so let's use a new component with only <table> tag
const TableComponent = forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <table
    ref={ref}
    className={cn("w-full caption-bottom text-sm", className)}
    {...props}
  />
));
TableComponent.displayName = "TableComponent";

export interface KeyRow<TData> extends Row<TData> {
  key?: string;
}

interface TableRowProps<TData> {
  onCellClick?: (row: any, cell_id?: string) => void;
  onRowClick?: (row: any) => void;
  rows: KeyRow<TData>[];
  formId?: string
}

const TableRowComponent = <TData,>(data: TableRowProps<TData>) =>
  function getTableRow(props: HTMLAttributes<HTMLTableRowElement>) {
    // @ts-expect-error data-index is a valid attribute
    const index = props["data-index"];
    const row = data.rows[index];

    if (!row) return null;

    return (
      <TableRow
        key={`${data.formId}:Row:${row.key || row.id}`}
        className={cn(data.onRowClick ? "cursor-pointer" : "")}
        data-state={row.getIsSelected() && "selected"}
        onClick={!!data.onRowClick ? () => (data as any).onRowClick(row?.original) : undefined}
        {...props}
      >
        {row.getVisibleCells().map((cell) => {
          const isCellClick = (cell.column.columnDef as any).onCellClick;
          return (
            <TableCell
              key={cell.id}
              onClick={
                isCellClick && !!data.onCellClick
                  ? () =>
                    data.onCellClick?.(
                      row?.original,
                      cell.column.columnDef.id,
                    )
                  : undefined
              }
              className={cn(
                isCellClick && data.onCellClick ? "cursor-pointer" : "",
              )}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

function SortingIndicator({ isSorted }: { isSorted: SortDirection | false }) {
  if (!isSorted) return null;
  return (
    <div>
      {
        {
          asc: "↑",
          desc: "↓",
        }[isSorted]
      }
    </div>
  );
}

export interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  height: string;
  onCellClick?: (row: any, cell_id?: string) => void;
  onRowClick?: (row: any) => void;
  className?: string;
  formId?: string;
  emptyResultsMessage?: string;
}

const renderEmptyPlaceholder = (columns: any, emptyResultsMessage?: string) => (props: any) => {
  return (
    <tbody>
      <tr>
        <td
          colSpan={columns.length}
          className="h-24 text-center"
          style={{ textAlign: "center" }}
        >
          {emptyResultsMessage || "No Results."}
        </td>
      </tr>
    </tbody>
  );
};

export function DataTable<TData, TValue>({
  onRowClick,
  onCellClick,
  columns,
  data,
  height,
  className,
  formId,
  emptyResultsMessage,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  return (
    <div className={cn("rounded-md border", className)}>
      <TableVirtuoso
        style={{ height }}
        totalCount={rows.length}
        components={{
          Table: TableComponent,
          TableRow: TableRowComponent({ formId, rows, onRowClick, onCellClick }),
          EmptyPlaceholder: renderEmptyPlaceholder(columns, emptyResultsMessage),
        }}
        fixedHeaderContent={() =>
          table.getHeaderGroups().map((headerGroup) => (
            // Change header background color to non-transparent
            <TableRow className="bg-card hover:bg-muted" key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={`${formId}:head:${header.id}`}
                    colSpan={header.colSpan}
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className="flex items-center"
                        {...{
                          style: header.column.getCanSort()
                            ? {
                              cursor: "pointer",
                              userSelect: "none",
                            }
                            : {},
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        <SortingIndicator
                          isSorted={header.column.getIsSorted()}
                        />
                      </div>
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))
        }
      />
    </div>
  );
}
