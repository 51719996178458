import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import {
  TicketRun,
  TicketsSummary,
  TicketRunSummary,
  Empty,
} from "../models/index";
import { TicketJob, TicketProviderSummary } from "../models/tickets";
import { ProviderType } from "../models/provider";

export interface CallLog {
  id: string;
  created_at: string;
  updated_at: string;

  call_log_date: Date;
  outgoing_number?: string;
  from_number?: string;

  from_user?: string;
  to_user?: string;

  from_user_name?: string;
  to_user_name?: string;

  original_call_log_key: string;

  transcribed_meta_data?: { data: string; reason?: string, empty?: boolean }
  cleaned_meta_data?: { data: string; }
  summary_meta_data?: { data: any; }
}

export function fetchCallLogsPage(page: number) {
  const queryParams = new URLSearchParams();
  queryParams.append("page", `${page}`)

  return fetchApiGet<{
    data: TicketJob[]
    total: number;
    totalPages: number;
    pageSize: number;
    currentPage: number;
  }>(`call_log`, queryParams);
}

export function useCallLogsPaginated({ page, queryFn }: {
  page: number,
  queryFn: any,
}) {
  return useQuery({
    queryKey: ["call_logs", `${page}`],
    queryFn,
    retry: 1,
    cacheTime: 0,
    keepPreviousData: true,
  });
}

export function useCallLogs() {
  const queryParams = new URLSearchParams();
  return useQuery({
    queryKey: ["call_logs"],
    queryFn: () => {
      return fetchApiGet<TicketJob[]>(`call_log`, queryParams);
    },
    retry: 1,
    cacheTime: 0,
  });
}

export function useGetAudioUrl() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (id: string) => {
    const result = await fetchApiPost<{}>(`call_log/${id}/get_call`, { id }, queryParams);

    return result;
  };
}
