import Decimal from "decimal.js";
import { useState } from "react";
import { Trash as TrashIcon } from "lucide-react";
import {
  TransformFormToApiSchema,
  generateErrorMessageFor,
  InputValue,
  InputRequiredStatus,
  FormSchema,
  InputSchema,
  InputType,
  ValidateSubmissionAgainstSchema,
} from "./index";
import {
  BearDatePicker,
  BearSelectInput,
  BearFloatInput,
  BearIntInput,
  BearInput,
  BearCheckbox,
  BearFile,
  BearTextArea,
} from "./ui";
import { findMatchOption } from "../api/common";
import { Button } from "../../components/ui/button";
import { Loader } from "../../components/ui/loader";
import {
  DataTable,
  DataTableProps,
} from "../../components/ui/virtualizedTable";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from "../../components/ui/dialog";
import { cn } from "../../lib/utils";
import { Label } from "../../components/ui/label";

export function PrimitiveGridLayout() { }

export function RenderFullPrimitiveGridLayout({
  isSubmittingDisabled,
  hideRelations,
  hideSkipButton,
  className,
  id,
  onCloseForm,
  formRelationships,
  updateFormRelationship,
  handleSubmitResponse,
  onSubmitTo,
  submissionState,
  updateFormValue,
  externalReferences,
  formValues,
  activeSchema,
  totalFields,
}: {
  hideRelations?: boolean;
  isSubmittingDisabled?: boolean;
  className?: string;
  hideSkipButton?: boolean;
  id: string;
  activeSchema: FormSchema;
  totalFields: Record<string, InputSchema>;
  formRelationships: Record<string, boolean>;
  formValues: Record<string, InputValue>;
  externalReferences: Record<string, any>;
  updateFormValue: ({
    label,
    link,
    key,
    value,
    error,
    externalReferences,
  }: {
    label?: any;
    link?: string;
    key: string;
    value?: any;
    error?: any;
    externalReferences: any;
  }) => void;
  updateFormRelationship: ({ baseKey, linkedKey, value }: any) => void;
  onCloseForm?: any;
  submissionState: {
    is_valid: boolean;
    is_submitting: boolean;
    has_submitted_form: boolean;
    updateSubmissionState: ({
      is_submitting,
      is_valid,
      has_submitted_form,
    }: {
      is_submitting?: boolean;
      is_valid?: boolean;
      has_submitted_form?: boolean;
    }) => void;
  };
  onSubmitTo: (item: any) => Promise<any>;
  handleSubmitResponse: (res: any) => void;
}) {
  const onSubmit = async ({
    formValues,
    activeSchema,
    submissionState,
  }: any) => {
    if (!submissionState.is_valid) {
      console.error("Invalid form state", formValues);
      return;
    }

    if (submissionState.is_submitting) {
      console.error("Already submitting form state");
      return;
    }

    submissionState.updateSubmissionState({
      is_submitting: true,
      has_submitted_form: true,
    });

    const parseFromValidation = ValidateSubmissionAgainstSchema({
      formRelationships,
      formValues,
      activeSchema,
    });

    if (!parseFromValidation.isValid) {
      const errors = parseFromValidation.errors;
      submissionState.updateSubmissionState({ is_submitting: false });
      return;
    }

    const transformPayload = TransformFormToApiSchema({
      formRelationships,
      formValues,
      activeSchema,
    });

    const res = await onSubmitTo(transformPayload);
    if (res?.success === true) {
      submissionState.updateSubmissionState({
        is_submitting: false,
        has_submitted_form: false,
      });
    } else {
      submissionState.updateSubmissionState({ is_submitting: false });
    }

    handleSubmitResponse(res);
  };

  const entriesData = Object.entries(activeSchema).filter(
    ([key, inputSchema]: any[], index: number) => {
      if (inputSchema.hidden || inputSchema.skipForm) return false;
      return true;
    },
  );

  const linkRows = Object.entries(activeSchema).filter(
    ([key, inputSchema]: any[], index: number) => {
      return inputSchema.isLink;
    },
  );

  return (
    <form
      id={`${id}:DynamicFormInput`}
      onSubmit={(e) => {
        if (e?.preventDefault) e.preventDefault();
        return onSubmit({ formValues, activeSchema, submissionState });
      }}
      className={cn("flex flex-col gap-4", className)}
    >
      <div
        className={cn(
          "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 items-start gap-4 content-center",
          entriesData?.length < 4 ? `sm:grid-cols-4 md:grid-cols-4` : "",
        )}
      >
        {RenderSchemaFields({
          formRelationships,
          indexOffset: 0,
          id,
          submissionState,
          entriesData,
          linkKey: undefined,
          link: undefined,
          updateFormValue,
          formValues,
          externalReferences,
        })}
      </div>
      {!hideRelations && linkRows.length > 0 && (
        <div
          className={cn(
            "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 items-start gap-4 content-center",
            linkRows?.length < 4 ? `md:grid-cols-${linkRows?.length}` : "",
          )}
        >
          {linkRows.map(([key, inputSchema], index: number) => {
            const is_required =
              inputSchema.required_status === InputRequiredStatus.Custom
                ? inputSchema.required_status_check(formValues).isRequired
                : inputSchema.required_status === InputRequiredStatus.Required;

            const error = submissionState.has_submitted_form
              ? generateErrorMessageFor({ formValues, key, inputSchema })
              : undefined;

            index = index + 1 + entriesData.length;
            const hasValue = formRelationships?.[key] || false;
            if (!hasValue) {
              return (
                <>
                  <BearCheckbox
                    disabled={inputSchema.disabled || false}
                    key={`${id}:DynamicFormInput:Create:${key}`}
                    tabIndex={index}
                    required={is_required}
                    name={key}
                    label={inputSchema.label}
                    error={error}
                    value={hasValue}
                    onChange={(value) =>
                      updateFormRelationship({
                        baseKey: key,
                        linkedKey: inputSchema.linkedKey,
                        value,
                      })
                    }
                  />
                </>
              );
            }

            return (
              <span
                className="grid items-center gap-1.5"
                key={`${id}:DynamicForm:${key}:Delete`}
              >
                <Label
                  htmlFor={key}
                  className="flex flex-row justify-between text-base items-center"
                >
                  <span className="font-semibold">{inputSchema.label}</span>
                </Label>
                <Button
                  variant="ghost"
                  className="h-8 w-8 p-0"
                  onClick={() =>
                    updateFormRelationship({
                      baseKey: key,
                      linkedKey: inputSchema.linkedKey,
                      value: false,
                    })
                  }
                  disabled={false}
                >
                  <span className="sr-only">Delete {inputSchema.label}</span>
                  <TrashIcon className="h-4 w-4" />
                </Button>
              </span>
            );
          })}
        </div>
      )}

      {!hideRelations &&
        linkRows.map(([key, inputSchema], index: number) => {
          const linkEntryData = Object.entries(inputSchema.linked!);
          if (formRelationships[key] !== true) {
            return null;
          }

          index =
            index +
            linkRows.length +
            entriesData.length +
            Object.keys(linkRows[Math.max(index - 1, 0)]).length * index;
          return (
            <div
              className="flex flex-col"
              key={`${id}:linkRowOneToOne:${key}:${inputSchema.linkedKey}`}
            >
              <div className="text-xl font-bold tracking-tight ">
                {inputSchema.label}
              </div>
              <div
                className={cn(
                  "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 items-start gap-4 content-center",
                  linkEntryData?.length < 4
                    ? `md:grid-cols-${linkEntryData?.length}`
                    : "",
                )}
              >
                {RenderSchemaFields({
                  formRelationships,
                  indexOffset: index,
                  id,
                  submissionState,
                  entriesData: linkEntryData,
                  linkedKey: inputSchema.linkedKey,
                  link: inputSchema.linkedKey,
                  updateFormValue,
                  formValues,
                  externalReferences,
                })}
              </div>
            </div>
          );
        })}
      <div className="flex flex-row justify-end mt-4">
        {!hideSkipButton && (
          <Button
            type="button"
            variant="secondary"
            className="col-start-1"
            onClick={() => (onCloseForm ? onCloseForm() : undefined)}
          >
            Close
          </Button>
        )}

        <Button
          type="submit"
          size="sm"
          className="ml-4 px-3 col-start-4"
          disabled={submissionState.is_submitting || isSubmittingDisabled}
        >
          {submissionState.is_submitting ? <Loader /> : <span>Submit</span>}
        </Button>
      </div>
    </form>
  );
}

// Ability to unselect select inputs or delete foreign keys or unfurled rows
// Select inputs that options come from backend
// Select input option labels
// Fields (Select inputs) with fields dependent on other fields
//    To filter down what you can select (if not selected provide default or superset?)
// Select inputs that represent foreign keys

// Fields that open to new forms? MZ ADJ? (or unfurl in the current form?)

// importing / exporting types correctly

// Add Visibility, so these can appear or not appear depending on values
// Add support for 1-1 sub forms
function GenTableCell({ row, inputSchema, externalReferences, key }: any) {
  if (inputSchema.action === "button" && !!inputSchema["action_reference_id"]) {
    const referenceId = inputSchema["action_reference_id"];
    const onAction = externalReferences[`${referenceId}`];
    const isLoading = externalReferences[`${referenceId}Loading`] || false;
    return (
      <Button
        variant="ghost"
        className="h-8 w-8 p-0 cursor-pointer"
        onClick={() => onAction(row.original)}
        disabled={isLoading}
      >
        <span className="sr-only">{key}</span>
        {isLoading ? (
          <Loader />
        ) : (
          inputSchema.apiDisplayFormat({
            original: row.original,
            item: row.original[key],
            externalReferences,
          })
        )}
      </Button>
    );
  }

  return inputSchema.apiDisplayFormat
    ? inputSchema.apiDisplayFormat({
      original: row.original,
      item: row.original[key],
      externalReferences,
    })
    : row.original[key];
}

export function PrimitiveManagedTable<T>({
  tableHeight,
  externalReferences,
  schema,
  values,
  onRowClick,
}: {
  externalReferences?: any;
  onRowClick?: (value: T) => void;
  schema: FormSchema;
  values: T[];
  tableHeight?: string;
}) {
  const schemaEntries = Object.entries(schema).filter(
    ([key, inputSchema]) => !inputSchema.hidden,
  );
  const pairedColumns = schemaEntries.map(([key, inputSchema]) => {
    return {
      id: key,
      header: inputSchema.label,
      cell: ({ row }: any) =>
        GenTableCell({ row, inputSchema, externalReferences, key }),
      onCellClick: inputSchema.action === "button" ? false : true,
      isCellClick: inputSchema.action === "button" ? false : true,
      enableSorting: true,
      enableHiding: false,
    };
  });

  const useSimpleTable = false;

  if (useSimpleTable) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            {schemaEntries.map(([key, inputSchema]) => (
              <TableHead key={`ManagedTableHeader:${key}`}>
                {inputSchema.label}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {values.map((item: any) => (
            <TableRow
              key={`ManagedTableRow:${item.id}`}
              onClick={() => {
                if (onRowClick) {
                  onRowClick(item);
                }
              }}
              className="cursor-pointer"
            >
              {schemaEntries.map(([key, inputSchema]) => (
                <TableCell key={`ManagedTableRow:${item.id}_${key}`}>
                  {inputSchema.apiDisplayFormat
                    ? inputSchema.apiDisplayFormat({
                      original: item,
                      item: item[key],
                      externalReferences,
                    })
                    : item[key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  const destTableHeight = tableHeight || "500px";
  return (
    <DataTable
      className={`grid overflow-y-auto overflow-x-auto`}
      height={destTableHeight}
      onCellClick={(item) => {
        if (onRowClick) {
          onRowClick(item);
        }
      }}
      columns={pairedColumns}
      data={values}
    />
  );
}

export function PrimitiveDialogForm({
  footer,
  isOpen,
  hasTrigger,
  buttonIcon,
  buttonLabel,
  onDialogChange,
  dialogTitle,
  form,
  className,
}: {
  className?: string;
  buttonLabel?: string;
  buttonIcon?: any;
  onDialogChange?: any;
  dialogTitle: string;
  form: any;
  hasTrigger?: boolean;
  isOpen?: boolean;
  footer?: any;
}) {
  return (
    <Dialog
      onOpenChange={(value) => {
        if (onDialogChange) {
          onDialogChange(value);
        }
      }}
      open={isOpen || false}
      defaultOpen={false}
    >
      {(!!(buttonLabel || buttonIcon) || hasTrigger) && (
        <DialogTrigger asChild>
          <Button
            type="button"
            variant={buttonIcon ? "ghost" : undefined}
            className=""
            disabled={isOpen || false}
            onClick={() => {
              if (onDialogChange) {
                onDialogChange(true);
              }
            }}
          >
            {buttonIcon ? buttonIcon : buttonLabel}
          </Button>
        </DialogTrigger>
      )}
      <DialogContent
        className={cn(
          "max-w-full sm:max-w-lg lg:max-w-3xl xl:max-w-7xl flex flex-col flex-start",
          className,
        )}
      >
        <DialogHeader>
          <DialogTitle>{dialogTitle}</DialogTitle>
        </DialogHeader>
        {form}
        {footer ? footer : null}
      </DialogContent>
    </Dialog>
  );
}

function RenderSchemaFields({
  formRelationships,
  indexOffset,
  id,
  submissionState,
  entriesData,
  linkedKey,
  link,
  updateFormValue,
  formValues: baseFormValues,
  externalReferences,
}: any) {
  const formValues = link ? baseFormValues?.[linkedKey] : baseFormValues;

  return entriesData
    .filter(([key, inputSchema]: any) => {
      if (inputSchema.hidden || inputSchema.skipForm) return false;
      return true;
    })
    .map(([key, inputSchema]: any[], index: number) => {
      const is_required =
        inputSchema.required_status === InputRequiredStatus.Custom
          ? inputSchema.required_status_check(formValues).isRequired
          : inputSchema.required_status === InputRequiredStatus.Required;

      const error = submissionState.has_submitted_form
        ? generateErrorMessageFor({
          formRelationships,
          formValues,
          key,
          inputSchema,
        })
        : undefined;
      index = index + 1 + indexOffset;

      const elementKey = `${id}:DynamicFormInput:${linkedKey}:${key}`;
      const value = !!inputSchema.input_formatter
        ? inputSchema.input_formatter(formValues, key)
        : formValues?.[key]?.value;
      switch (inputSchema.input_type) {
        case InputType.File:
          return (
            <BearFile
              disabled={inputSchema.disabled || false}
              key={elementKey}
              accept={inputSchema.fileExtensions.join(",")}
              tabIndex={indexOffset || index}
              required={is_required}
              name={key || ""}
              label={inputSchema.label}
              error={error}
              multiple={inputSchema.allowMany || false}
              onChange={(event) => {
                return updateFormValue({
                  link,
                  key,
                  value: inputSchema.allowMany ? event.target.files : event.target.files[0],
                  externalReferences,
                });
              }}
            />
          );
        case InputType.String:
          return (
            <BearInput
              disabled={inputSchema.disabled || false}
              key={elementKey}
              tabIndex={indexOffset || index}
              required={is_required}
              name={key || ""}
              label={inputSchema.label || ""}
              error={error}
              value={value || ""}
              placeholder={inputSchema.placeholder}
              onChange={(event) =>
                updateFormValue({
                  link,
                  key,
                  value: event.target.value,
                  externalReferences,
                })
              }
            />
          );
        case InputType.TextArea:
          return (
            <BearTextArea
              rows={inputSchema.rows || 3}
              disabled={inputSchema.disabled || false}
              key={elementKey}
              tabIndex={indexOffset || index}
              required={is_required}
              name={key || ""}
              label={inputSchema.label || ""}
              error={error}
              value={value || ""}
              placeholder={inputSchema.placeholder}
              onChange={(event) =>
                updateFormValue({
                  link,
                  key,
                  value: event.target.value,
                  externalReferences,
                })
              }
            />
          );
        case InputType.Literal:
          return (
            <BearInput
              key={elementKey}
              tabIndex={indexOffset || index}
              required={is_required}
              name={key || ""}
              label={inputSchema.label || ""}
              value={value}
              error={error}
              disabled
              onChange={(event) => { }}
            />
          );
        case InputType.Decimal:
        // TODO support import/exporting fields as a number instead of Decimal
        case InputType.Number:
        case InputType.Float:
          return (
            <BearFloatInput
              disabled={inputSchema.disabled || false}
              key={elementKey}
              tabIndex={indexOffset || index}
              required={is_required}
              name={key || ""}
              label={inputSchema.label || ""}
              value={value}
              error={error}
              step={inputSchema.step}
              onChange={(value) =>
                updateFormValue({ link, key, value, externalReferences })
              }
            />
          );
        case InputType.Int:
          return (
            <BearIntInput
              disabled={inputSchema.disabled || false}
              key={elementKey}
              tabIndex={indexOffset || index}
              required={is_required}
              name={key || ""}
              label={inputSchema.label || ""}
              value={value}
              error={error}
              step={inputSchema.step}
              min={inputSchema.min}
              max={inputSchema.max}
              onChange={(value) =>
                updateFormValue({ link, key, value, externalReferences })
              }
            />
          );
        case InputType.Boolean:
          return (
            <div className="flex flex-col space-y-2" key={elementKey}>
              <BearCheckbox
                key={elementKey}
                disabled={inputSchema.disabled || false}
                tabIndex={indexOffset || index}
                required={false}
                name={key}
                label={inputSchema.label}
                error={error}
                value={value || false}
                onChange={(value) =>
                  updateFormValue({ link, key, value, externalReferences })
                }
              />
              {inputSchema.description && (
                <p className="text-sm text-muted-foreground">
                  {inputSchema.description}
                </p>
              )}
            </div>
          );
        case InputType.Date:
        case InputType.DateTime:
          const minDate =
            typeof inputSchema.minDate === "function"
              ? inputSchema.minDate()
              : inputSchema.minDate;
          const maxDate =
            typeof inputSchema.maxDate === "function"
              ? inputSchema.maxDate()
              : inputSchema.maxDate;
          return (
            <BearDatePicker
              disabled={inputSchema.disabled || false}
              key={elementKey}
              tabIndex={indexOffset || index}
              required={is_required}
              name={key}
              label={inputSchema.label}
              error={error}
              value={value}
              minDate={minDate}
              maxDate={maxDate}
              onValueChange={(value) =>
                updateFormValue({ link, key, value, externalReferences })
              }
            />
          );
        case InputType.Enum:
        case InputType.Uuid:
        case InputType.SelectInput:
          const options =
            externalReferences[inputSchema.options_reference_id] || [];
          return (
            <BearSelectInput
              disabled={inputSchema.disabled || false}
              key={elementKey}
              tabIndex={indexOffset || index}
              required={is_required}
              name={key}
              label={inputSchema.label}
              value={value}
              error={error}
              onValueChange={(value) => {
                return updateFormValue({
                  key,
                  link,
                  label: options?.find((option: any) => option.value === value)
                    ?.label,
                  value: findMatchOption(options, value)?.value,
                  error: undefined,
                  externalReferences,
                })
              }
              }
              options={
                !inputSchema.input_filter
                  ? options
                  : inputSchema.input_filter(options, formValues, key)
              }
            />
          );
      }
    });
}
