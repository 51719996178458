import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useStytchMember } from "@stytch/react/b2b";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { fetchApiPost } from "@/lib/api/utils";
import { KnowledgeApi, useCreateKnowledgeItem, useCreateKnowledgeUploadLink, useGetKnowledgePermissions, useUpdateKnowledgeItem } from "@/lib/api/knowledge";
import { ArrowLeft, Save } from "lucide-react";
import { GenerateLoadingOrError } from "@/lib/utils";
import { Combobox } from "@/components/ui/combobox";
import { Checkbox } from "@/components/ui/checkbox";

export function KnowledgeTaskCreate() {
    const navigate = useNavigate();
    const { member } = useStytchMember();
    const user_id: string = member?.trusted_metadata?.db_user_id as any;
    const permissionsListRes = useGetKnowledgePermissions();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedPermissionId, setSelectedPermissionId] = useState<string | null>(null);
    const [title, setTitle] = useState("");
    const [itemType, setItemType] = useState<"task" | "common-block">("task");
    const [description, setDescription] = useState("");
    const createKnowledgeItem = useCreateKnowledgeItem();

    const permissionsList = permissionsListRes.data?.data || [];

    const loadingOrErrorUi = GenerateLoadingOrError([permissionsListRes]);

    if (loadingOrErrorUi) {
        return (
            <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <div className="flex items-center mb-6">
                    <Button
                        variant="ghost"
                        size="sm"
                        className="mr-2"
                        onClick={() => navigate("/tasks")}
                    >
                        <ArrowLeft className="h-4 w-4 mr-1" />
                        Back
                    </Button>
                    <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                        Create Task
                    </h1>
                </div>
                {loadingOrErrorUi}
            </section>
        );
    }

    const handleSubmit = async (e: React.FormEvent) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }

        if (!user_id) {
             toast({ title: "Error", description: "User not identified.", variant: "destructive" });
             return;
        }

        if (!title.trim()) {
            toast({
                title: "Error",
                description: "Please provide a title for the task.",
                variant: "destructive",
            });
            return;
        }

        setIsSubmitting(true);

        try {
            const result = await createKnowledgeItem({
                title,
                description: itemType === "task" ? description : "",
                content: "",
                owner_id: user_id,
                item_type: itemType,
                special_permission_id: selectedPermissionId || undefined
            });

            if (!result.success || !result?.data?.id) {
                 const errorMsg = result.message || "Failed to create task.";
                 console.error("Task creation failed:", errorMsg, result);
                toast({
                    title: "Failed",
                    description: errorMsg,
                    variant: "destructive",
                });
                setIsSubmitting(false);
                return;
            }

            const newTaskId = result.data.id;
            navigate(`/tasks/${newTaskId}/edit`);
            
        } catch (error) {
            console.error("Error creating task:", error);
            toast({
                title: "Error",
                description: "An unexpected error occurred. Please try again.",
                variant: "destructive",
            });
             setIsSubmitting(false);
        }
    };

    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 lg:px-8">
            <div className="flex items-center mb-6">
                <Button
                    variant="ghost"
                    size="sm"
                    className="mr-2"
                    onClick={() => navigate("/tasks")}
                >
                    <ArrowLeft className="h-4 w-4 mr-1" />
                    Back
                </Button>
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Create Task
                </h1>
            </div>

            <form onSubmit={handleSubmit} className="space-y-8">
                <div className="grid gap-4">
                    <div className="grid gap-2">
                        <Label htmlFor="item-type">Is Common Block</Label>
                        <Checkbox
                            id="item-type"
                            checked={itemType === "common-block"}
                            onCheckedChange={(checked) => setItemType(checked ? "common-block" : "task")}
                        />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="title">Title</Label>
                        <Input
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Task title"
                            required
                            disabled={isSubmitting}
                        />
                    </div>

                    {itemType === "task" && (
                        <div className="grid gap-2">
                            <Label htmlFor="description">Description</Label>
                            <Textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Brief description of this task"
                            rows={3}
                            disabled={isSubmitting}
                        />
                    </div>
                    )}

                    {(permissionsListRes.isLoading || permissionsList.length > 0) && (
                        <div className="grid gap-2">
                            <Label htmlFor="special-permission">Special Permission</Label>
                            <Combobox
                                id="special-permission"
                                value={selectedPermissionId || ""}
                                onValueChange={(value) => setSelectedPermissionId(value === "" ? null : value)}
                                options={permissionsList.map((permission: { id: string, name: string }) => ({
                                    label: permission.name,
                                    value: permission.id,
                                }))}
                                className="w-[300px]"
                                popoverClassName="w-[300px]"
                                disabled={isSubmitting}
                            />
                        </div>
                    )}
                </div>

                <div className="flex justify-end">
                    <Button
                        type="button"
                        variant="outline"
                        className="mr-2"
                        onClick={() => navigate("/tasks")}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </Button>
                    <Button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Creating..." : (
                            <>
                                <Save className="h-4 w-4 mr-2" />
                                Continue
                            </>
                        )}
                    </Button>
                </div>
            </form>
        </section>
    );
} 