import { KnowledgeApi } from "@/lib/api/knowledge";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Eye, PenSquare, CheckCircle, AlertCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface KnowledgeTaskCardProps {
  task: KnowledgeApi.KnowledgeItem;
  showActions?: boolean;
  isAssigned?: boolean;
  assignmentCount?: number;
}

export function KnowledgeTaskCard({ 
  task, 
  showActions = true,
  isAssigned = false,
  assignmentCount = 0
}: KnowledgeTaskCardProps) {
  const navigate = useNavigate();
  
  const handleViewTask = () => {
    navigate(`/tasks/${task.id}`);
  };

  const getStatusBadge = () => {
    if (!isAssigned) {
      return (
        <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">
          {assignmentCount} assigned
        </Badge>
      );
    }
    
    const status = task.assignments?.[0]?.status;
    
    switch (status) {
      case "COMPLETED" as KnowledgeApi.KnowledgeAssignmentStatus:
        return (
          <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">
            <CheckCircle className="h-3 w-3 mr-1" />
            Completed
          </Badge>
        );
      case "IN_PROGRESS" as KnowledgeApi.KnowledgeAssignmentStatus:
        return (
          <Badge variant="outline" className="bg-blue-50 text-blue-700 border-blue-200">
            <PenSquare className="h-3 w-3 mr-1" />
            In Progress
          </Badge>
        );
      case "PENDING":
      default:
        return (
          <span className="text-muted-foreground italic">
          </span>
        );
    }
  };

  return (
    <Card className="overflow-hidden transition-all hover:shadow-md">
      <CardHeader className="pb-2">
        <div className="flex justify-between items-start">
          <CardTitle className="text-md font-semibold line-clamp-1">{task.title}</CardTitle>
          {getStatusBadge()}
        </div>
        <CardDescription className="line-clamp-2 mt-1">
          {task.description}
        </CardDescription>
      </CardHeader>
      <CardContent className="text-sm text-muted-foreground pb-3">
        {task.steps && (
          <div className="mt-1">
            <span className="font-medium">Steps:</span> {task.steps.length}
          </div>
        )}
      </CardContent>
      {showActions && (
        <CardFooter className="pt-0 pb-3 gap-2 justify-between">
          <span className="text-sm text-muted-foreground">
            Owned by {task.Owner?.name || ""}
          </span>
          <Button 
            variant="outline" 
            size="sm" 
            className="h-8"
            onClick={handleViewTask}
          >
            <Eye className="h-3.5 w-3.5 mr-1" />
            View
          </Button>
        </CardFooter>
      )}
    </Card>
  );
} 