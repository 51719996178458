import {
  Uom,
  Currency,
  CostCategory,
  CalculateOn,
  AddDedAdjType,
  RateType,
  ContractType,
  PurchaseSaleType,
  GeneralContract,
  Location,
  MarketZone,
  purchaseSaleOptions,
  uomOptions,
  currencyOptions,
  generateContractTypesWith,
  calculateOnOptions,
  rateTypeOptions,
  costCategoryOptions,
  addDedADjOptions,
  deliveryTermsOptions,
  DeliveryTerms,
  ContractSourceType,
  MzVendor,
} from "../../lib/api/generalContract";
import {
  InputRequiredStatus,
  InputFormType,
  InputType,
  ImportType,
  ExportType,
  InputFilter,
  InputRequiredStatusType,
} from "../../lib/form";
import {
  CommodityOptions,
  Commodities,
  GeneralContractSettingTypeOptions,
  GeneralContractSettingContractTypeOptions,
  GeneralContractSettingBasicContractType,
  GeneralContractSettingType,
} from "../../lib/api/commodity";
import { reduceArrayTo, formulateOptionMonths } from "../../lib/api/common";
import {
  GenerateLoadingOrError,
  genFlatOptionList,
  genIdOptionList,
  CommonDestinationTimezone,
  createDateLabel,
} from "../../lib/utils";
import moment from "moment-timezone";

export const ContractMzAdjBaseForm = {
  id: {
    hidden: true,
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Optional,
  },

  cost_category: {
    label: "Cost Category",
    options_reference_id: "costCategoryOptions",
    input_type: InputType.Enum,
    enum: CostCategory,
    input_type_validation: InputFormType.Enum(CostCategory),
    required_status: InputRequiredStatus.Required,
  },

  accrue: {
    label: "Accrue",
    input_type: InputType.Boolean,
    input_type_validation: InputFormType.Boolean,
    required_status: InputRequiredStatus.Required,
    defaultValue: true,
  },
  budget: {
    label: "Budget",
    input_type: InputType.Boolean,
    input_type_validation: InputFormType.Boolean,
    required_status: InputRequiredStatus.Required,
  },

  rate_type: {
    label: "Rate Type",
    options_reference_id: "rateTypeOptions",
    input_type: InputType.Enum,
    enum: RateType,
    input_type_validation: InputFormType.Enum(RateType),
    required_status: InputRequiredStatus.Optional,
  },
  vehicle: {
    label: "Vehicle",
    input_type: InputType.String,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Optional,
  },
  amount: {
    label: "Amount",
    input_type: InputType.Decimal,
    input_type_validation: InputFormType.Decimal,
    required_status: InputRequiredStatus.Optional,
  },
  uom: {
    label: "Uom",
    options_reference_id: "uomOptions",
    input_type: InputType.Enum,
    enum: Uom,
    input_type_validation: InputFormType.Enum(Uom),
    required_status: InputRequiredStatus.Optional,
  },
  currency: {
    label: "Currency",
    options_reference_id: "currencyOptions",
    input_type: InputType.Enum,
    enum: Currency,
    input_type_validation: InputFormType.Enum(Currency),
    required_status: InputRequiredStatus.Optional,
  },
  vendor: {
    label: "Vendor",
    options_reference_id: "mzVendorOptions",
    input_type: InputType.Enum,
    enum: MzVendor,
    input_type_validation: InputFormType.Enum(MzVendor),
    required_status: InputRequiredStatus.Optional,
  },

  mz_adj: {
    label: "MZ ADJ",
    input_type: InputType.Decimal,
    input_type_validation: InputFormType.Decimal,
    required_status: InputRequiredStatus.Required,
  },

  calculate_on: {
    label: "Calculate On",
    options_reference_id: "calculateOnOptions",
    input_type: InputType.Enum,
    enum: CalculateOn,
    input_type_validation: InputFormType.Enum(CalculateOn),
    required_status: InputRequiredStatus.Required,
  },

  include_for_iv_costing: {
    label: "Include for I/V Costing",
    input_type: InputType.Boolean,
    input_type_validation: InputFormType.Boolean,
    required_status: InputRequiredStatus.Required,
    defaultValue: true,
  },

  standard: {
    hidden: true,
    label: "Standard",
    input_type: InputType.Decimal,
    required_status: InputRequiredStatus.Required,
    input_type_validation: InputFormType.Decimal,
    defaultValue: "0",
  },
  fx_rate: {
    hidden: true,
    label: "FX Rate",
    input_type: InputType.Decimal,
    required_status: InputRequiredStatus.Required,
    input_type_validation: InputFormType.Decimal,
    defaultValue: "1",
  },
};

export const ContractAddDedAdjBaseForm = {
  id: {
    hidden: true,
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Optional,
  },

  adjustment: {
    label: "Adjustment",
    options_reference_id: "addDedADjOptions",
    input_type: InputType.Enum,
    enum: AddDedAdjType,
    input_type_validation: InputFormType.Enum(AddDedAdjType),
    required_status: InputRequiredStatus.Required,
  },

  rate_type: {
    label: "Rate Type",
    options_reference_id: "rateTypeOptions",
    input_type: InputType.Enum,
    enum: RateType,
    input_type_validation: InputFormType.Enum(RateType),
    required_status: InputRequiredStatus.Required,
  },
  amount: {
    label: "Amount",
    input_type: InputType.Decimal,
    input_type_validation: InputFormType.Decimal,
    required_status: InputRequiredStatus.Required,
  },
  note: {
    label: "Note",
    input_type: InputType.String,
    input_type_validation: InputFormType.Decimal,
    required_status: InputRequiredStatus.Optional,
  },

  include_for_iv_costing: {
    label: "Include for I/V Costing",
    input_type: InputType.Boolean,
    input_type_validation: InputFormType.Boolean,
    required_status: InputRequiredStatus.Required,
  },
};

const SourceTypeOptions = [
  { value: ContractSourceType.SpeechInput, label: "Spoken" },
  { value: ContractSourceType.GeneralInput, label: "Form" },
];

export const BaseGeneralFormSchema = {
  id: {
    hidden: true,
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Optional,
  },
  source_type: {
    hidden: true,
    input_type: InputType.String,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Optional,
  },

  contract_date: {
    label: "Date",
    input_type: InputType.Date,
    input_type_validation: InputFormType.Date,
    required_status: InputRequiredStatus.Optional,
    apiDisplayFormat: ({ original }: any) => {
      return createDateLabel(original.contract_date);
    },
  },
  location_id: {
    label: "Location",
    options_reference_id: "locationOptions",
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => {
      return original.Location.long_name;
    },
  },
  purchase_sale_type: {
    label: "P/S",
    options_reference_id: "purchaseSaleOptions",
    input_type: InputType.Enum,
    enum: PurchaseSaleType,
    input_type_validation: InputFormType.Enum(PurchaseSaleType),
    required_status: InputRequiredStatus.Required,
  },

  // TODO determine whether we want many schemas or one schema
  // Which does custom function validation
  // Many schemas has a bunch of repeat, but is easier to declare what values to drop
  contract_type: {
    label: "Type",
    options_reference_id: "contractTypeOptions",
    input_type: InputType.Enum,
    required_status: InputRequiredStatus.Required,
    input_type_validation: InputFormType.Custom(
      ({ location_id, contract_type }: any) => {
        if (!contract_type?.value) {
          return { isValid: false, errorMessage: "Missing" };
        }
        if (!location_id?.value) {
          return { isValid: false, errorMessage: "Missing Location" };
        }
        const types = generateContractTypesWith({
          locationLabel: location_id.label,
        });

        const isValid = types.includes(contract_type?.value);
        return {
          isValid,
          errorMessage: isValid ? "" : "Invalid Type for Location",
        };
      },
    ),
    input_filter: InputFilter.Enum(({ location_id }: any) => {
      if (!location_id?.value) {
        return undefined;
      }
      return generateContractTypesWith({ locationLabel: location_id.label });
    }),
  },
  customer_id: {
    label: "Customer",
    options_reference_id: "customerOptions",
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => {
      return original.Customer.long_name;
    },
  },

  contract_no: {
    label: "Contract #",
    input_type: InputType.String,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Optional,
  },

  commodity: {
    label: "Commodity",
    options_reference_id: "commodityOptions",
    input_type: InputType.Enum,
    enum: Commodities,
    input_type_validation: InputFormType.Enum(Commodities),
    required_status: InputRequiredStatus.Required,
  },
  crop_year: {
    label: "Crop Year",
    input_type: InputType.Number,
    input_type_validation: InputFormType.Number,
    required_status: InputRequiredStatus.Required,
  },
  option_month: {
    // TODO on submit make sure to prefix with commodity
    // on receive from backend make sure to strip commodity
    label: "Option Month",
    options_reference_id: "optionMonthOptions",
    input_type: InputType.Enum,
    input_type_validation: InputFormType.Enum(
      reduceArrayTo(formulateOptionMonths(), "value"),
    ),
    required_status: InputRequiredStatus.Required,
  },

  reference: {
    label: "Reference",
    input_type: InputType.String,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Optional,
  },
  quantity: {
    label: "Quantity",
    step: "5000",
    input_type: InputType.Decimal,
    input_type_validation: InputFormType.Decimal,
    required_status: InputRequiredStatus.Required,
  },

  uom: {
    label: "Uom",
    options_reference_id: "uomOptions",
    input_type: InputType.Enum,
    enum: Uom,
    required_status: InputRequiredStatus.Required,
    input_type_validation: InputFormType.Custom(({ commodity, uom }: any) => {
      if (!commodity?.value) {
        return { isValid: false, errorMessage: "Missing Commodity" };
      }
      const types =
        commodity.value === Commodities.Canola ? [Uom.Lbs] : [Uom.Bushel];
      const isValid = types.includes(uom?.value);
      return {
        isValid,
        errorMessage: isValid ? "" : "Invalid UOM for Commodity",
      };
    }),
    input_filter: InputFilter.Enum(({ commodity }: any) => undefined),
    dependencies: {
      on: "commodity",
      transform: (commodityValue: any, _formValues: any) =>
        commodityValue === Commodities.Canola ? Uom.Lbs : Uom.Bushel,
    },
  },
  price_uom: {
    label: "Price Uom",
    options_reference_id: "uomOptions",
    input_type: InputType.Enum,
    enum: Uom,
    required_status: InputRequiredStatus.Optional,
    input_type_validation: InputFormType.Custom(
      ({ commodity, price_uom }: any) => {
        if (!commodity?.value) {
          return { isValid: false, errorMessage: "Missing Commodity" };
        }
        const types =
          commodity.value === Commodities.Canola ? [Uom.Lbs] : [Uom.Bushel];
        const isValid = types.includes(price_uom?.value);
        return {
          isValid,
          errorMessage: isValid ? "" : "Invalid Price UOM for Commodity",
        };
      },
    ),
    input_filter: InputFilter.Enum(({ commodity }: any) => undefined),
    dependencies: {
      on: "commodity",
      transform: (commodityValue: any, _formValues: any) =>
        commodityValue === Commodities.Canola ? Uom.Lbs : Uom.Bushel,
    },
  },
  currency: {
    label: "Currency",
    options_reference_id: "currencyOptions",
    input_type: InputType.Enum,
    enum: Currency,
    input_type_validation: InputFormType.Enum(Currency),
    required_status: InputRequiredStatus.Optional,
  },

  basis: {
    label: "Basis",
    step: "0.01",
    input_type: InputType.Decimal,
    input_type_validation: InputFormType.Decimal,
    required_status: InputRequiredStatus.Custom,
    required_status_check: InputRequiredStatusType.Custom(
      ({ contract_type }: any) => {
        if (!contract_type?.value) {
          return { isRequired: false };
        }
        const isRequired =
          contract_type.value === "Flat-CAN" ||
          contract_type.value.startsWith("Basis") ||
          contract_type.value.startsWith("Flat");
        return { isRequired };
      },
    ),
  },
  futures: {
    label: "Futures",
    step: "0.1",
    input_type: InputType.Decimal,
    input_type_validation: InputFormType.Decimal,
    required_status: InputRequiredStatus.Custom,
    required_status_check: InputRequiredStatusType.Custom(
      ({ contract_type }: any) => {
        if (!contract_type?.value) {
          return { isRequired: false };
        }
        const isRequired =
          contract_type.value === "Flat-CAN" ||
          contract_type.value.startsWith("HTA") ||
          contract_type.value.startsWith("Flat");
        return { isRequired };
      },
    ),
  },

  delivery_terms: {
    label: "Delivery Terms",
    options_reference_id: "deliveryTermsOptions",
    input_type: InputType.Enum,
    enum: DeliveryTerms,
    input_type_validation: InputFormType.Enum(DeliveryTerms),
    required_status: InputRequiredStatus.Required,
  },
  comments: {
    label: "Comments",
    input_type: InputType.String,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Optional,
  },

  market_zone_id: {
    label: "Market Zone",
    options_reference_id: "marketZonesOptions",
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => {
      return original.MarketZone?.market_zone || "";
    },
    dependencies: {
      on: "contract_type",
      transform: (
        contractType: any,
        _formValues: any,
        externalReferences: any,
      ) => {
        const existingValue = _formValues.market_zone_id?.value;
        const foundMarketZoneWrapper =
          externalReferences?.marketZonesOptions?.find((item: any) =>
            /hta/gim.test(item.label || ""),
          );
        return (
          (contractType?.toLocaleLowerCase()?.includes("hta")
            ? foundMarketZoneWrapper?.value || existingValue
            : existingValue) || ""
        );
      },
    },
  },
  trader_id: {
    label: "Trader",
    options_reference_id: "traderOptions",
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => {
      return original.Trader?.name || "";
    },
  },

  shipment_start_date: {
    label: "Shipment Start Date",
    input_type: InputType.Date,
    input_type_validation: InputFormType.Date,
    required_status: InputRequiredStatus.Required,
    minDate: () => moment().subtract(90, "days").startOf("day").toDate(),
    apiDisplayFormat: ({ original }: any) => {
      return createDateLabel(original.shipment_start_date);
    },
  },
  shipment_end_date: {
    label: "Shipment End Date",
    input_type: InputType.Date,
    input_type_validation: InputFormType.Date,
    required_status: InputRequiredStatus.Required,
    minDate: () => moment().subtract(90, "days").startOf("day").toDate(),
    apiDisplayFormat: ({ original }: any) => {
      return createDateLabel(original.shipment_end_date);
    },
  },

  mz_adj_display: {
    label: "MZ ADJ",
    derived: true,
    skipForm: true,
    apiDisplayFormat: ({ original }: any) => {
      return original.MzAdj?.mz_adj || "0";
    },
  },
  add_ded_display: {
    label: "MZ ADJ",
    derived: true,
    skipForm: true,
    apiDisplayFormat: ({ original }: any) => {
      return original.AddDedAdj?.amount || "0";
    },
  },

  source_type_display: {
    label: "Source",
    derived: true,
    skipForm: true,
    apiDisplayFormat: ({ original }: any) => {
      return SourceTypeOptions.find(
        (item) => item.value === original.source_type,
      )?.label;
    },
  },

  mz_adj_id: {
    label: "MZ ADJ",
    hidden: true,
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Optional,
    isLink: true,
    linkedKey: "MzAdj",
    linked: ContractMzAdjBaseForm,
  },
  add_ded_adj_id: {
    label: "Add/Ded ADJ",
    hidden: true,
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Optional,
    isLink: true,
    linkedKey: "AddDedAdj",
    linked: ContractAddDedAdjBaseForm,
  },
};

export const SortingOptions = [
  { value: "asc", label: "Ascending" },
  { value: "desc", label: "Descending" },
];

export const SortFormSchema = {
  created_at: {
    label: "Created Date",
    options_reference_id: "SortingOptions",
    input_type: InputType.Enum,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Optional,
  },
  contract_date: {
    label: "Contract Date",
    options_reference_id: "SortingOptions",
    input_type: InputType.Enum,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Optional,
  },
  contract_no: {
    label: "Contract #",
    options_reference_id: "SortingOptions",
    input_type: InputType.Enum,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Optional,
  },
  source_type: {
    label: "Source",
    options_reference_id: "SortingOptions",
    input_type: InputType.Enum,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Optional,
  },
};

export const FilterFormSchema = {
  contract_date: {
    label: "Contract Date",
    input_type: InputType.Date,
    input_type_validation: InputFormType.DateString,
    required_status: InputRequiredStatus.Optional,
  },
  contract_no: {
    label: "Contract #",
    input_type: InputType.String,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Optional,
  },

  option_month: {
    // TODO on submit make sure to prefix with commodity
    // on receive from backend make sure to strip commodity
    label: "Option Month",
    options_reference_id: "optionMonthOptions",
    input_type: InputType.Enum,
    input_type_validation: InputFormType.Enum(
      reduceArrayTo(formulateOptionMonths(), "value"),
    ),
    required_status: InputRequiredStatus.Optional,
  },
  commodity: {
    label: "Commodity",
    options_reference_id: "commodityOptions",
    input_type: InputType.Enum,
    enum: Commodities,
    input_type_validation: InputFormType.Enum(Commodities),
    required_status: InputRequiredStatus.Optional,
  },
  contract_type: {
    label: "Type",
    options_reference_id: "contractTypeOptions",
    input_type: InputType.Enum,
    required_status: InputRequiredStatus.Optional,
    input_type_validation: InputFormType.Custom(
      ({ location_id, contract_type }: any) => {
        const types = generateContractTypesWith({ locationLabel: undefined });

        const isValid = types.includes(contract_type?.value);
        return {
          isValid,
          errorMessage: isValid ? "" : "Invalid Type for Location",
        };
      },
    ),
    input_filter: InputFilter.Enum(({ location_id }: any) => {
      if (!location_id?.value) {
        return undefined;
      }
      return generateContractTypesWith({ locationLabel: location_id.label });
    }),
  },
  customer_id: {
    label: "Customer",
    options_reference_id: "customerOptions",
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Optional,
    apiDisplayFormat: ({ original }: any) => {
      return original.Customer.long_name;
    },
  },
  location_id: {
    label: "Location",
    options_reference_id: "locationOptions",
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Optional,
    apiDisplayFormat: ({ original }: any) => {
      return original.Location.long_name;
    },
  },
  shipment_start_date: {
    label: "Shipment Start Date",
    input_type: InputType.Date,
    input_type_validation: InputFormType.DateString,
    required_status: InputRequiredStatus.Optional,
  },
  shipment_end_date: {
    label: "Shipment End Date",
    input_type: InputType.Date,
    input_type_validation: InputFormType.DateString,
    required_status: InputRequiredStatus.Optional,
  },
  trader_id: {
    label: "Trader",
    options_reference_id: "traderOptions",
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Optional,
    apiDisplayFormat: ({ original }: any) => {
      return original.Trader?.name || "";
    },
  },
};

export const BaseGeneralSettingFormSchema = {
  id: {
    hidden: true,
    input_type: InputType.Uuid,
    input_type_validation: InputFormType.Uuid,
    required_status: InputRequiredStatus.Optional,
  },

  use_all_commodities: {
    hidden: true,
    input_type: InputType.Boolean,
    input_type_validation: InputFormType.Boolean,
    required_status: InputRequiredStatus.Required,
  },

  setting_type: {
    disabled: true,
    label: "Settings Type",
    options_reference_id: "settingTypeOptions",
    input_type: InputType.Enum,
    input_type_validation: InputFormType.Enum(GeneralContractSettingType),
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => {
      return (
        GeneralContractSettingTypeOptions.find(
          (item) => item.value === original.setting_type,
        )?.label || original.setting_type
      );
    },
  },

  active_start_date: {
    label: "Start Date",
    input_type: InputType.Date,
    input_type_validation: InputFormType.DateString,
    required_status: InputRequiredStatus.Required,
    minDate: () => moment().startOf("day").toDate(),
    apiDisplayFormat: ({ original }: any) => {
      if (!original.active_start_date) return "";
      return moment
        .tz(original.active_start_date, CommonDestinationTimezone)
        .format("MMM Do, YYYY");
    },
  },

  active_end_date: {
    hidden: true,
    label: "End Date",
    input_type: InputType.Date,
    input_type_validation: InputFormType.DateString,
    required_status: InputRequiredStatus.Optional,
    apiDisplayFormat: ({ original }: any) => {
      if (!original.active_end_date) return "";
      return moment
        .tz(original.active_end_date, CommonDestinationTimezone)
        .format("MMM Do, YYYY");
    },
  },

  basic_contract_type: {
    // TODO on submit make sure to prefix with commodity
    // on receive from backend make sure to strip commodity
    label: "Contract Type",
    options_reference_id: "baseContractTypeOptions",
    input_type: InputType.Enum,
    input_type_validation: InputFormType.Enum(
      GeneralContractSettingBasicContractType,
    ),
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => {
      return (
        GeneralContractSettingContractTypeOptions.find(
          (item) => item.value === original.basic_contract_type,
        )?.label || original.basic_contract_type
      );
    },
  },

  commodity: {
    label: "Commodity",
    options_reference_id: "commodityOptions",
    input_type: InputType.Enum,
    enum: Commodities,
    input_type_validation: InputFormType.Enum(Commodities),
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => {
      return (
        CommodityOptions.find((item) => item.value === original.commodity)
          ?.label || original.commodity
      );
    },
  },

  comment: {
    label: "Comment",
    rows: 8,
    input_type: InputType.TextArea,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Required,
  },
};
