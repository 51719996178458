import { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment-timezone";
import { Filter } from "lucide-react";
import parsePhoneNumber, { AsYouType as PhoneNumberFormatterAsYouType } from 'libphonenumber-js';
import { fetchChatsPage, useChatsPaginated, ChatStream, ChatsParams, ChatTypeOptions, ChatSourceOptions, MessageGroupOptions, useSendTestMessage, TwilioMessageName, SendTestMessageValidation } from "@/lib/api/chats";
import { Skeleton } from "@/components/ui/skeleton";
import { cn, CommonDestinationTimezone, GenerateLoadingOrError } from "@/lib/utils";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Combobox } from "@/components/ui/combobox";
import { useToast } from "@/components/ui/use-toast";
import { Loader } from "@/components/ui/loader";
import { BearInput, BearSelectInput } from "@/lib/form/ui";
import { AddPriceDataValidation, useAddPriceData } from "@/lib/api/priceData";
import { useStytchMember } from "@stytch/react/b2b";
import { PossiblePackage } from "@/lib/models/auth";
import { doesUserHavePackages } from "@/lib/models/auth";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";

function generateCommodityOptions() {
    const base_cont_commodities = [
        { label: "Corn", value: "corn" },
        { label: "Canola", value: "canola" },
        { label: "Msp Wheat", value: "msp_wheat" },
        { label: "Soybeans", value: "soybeans" },
    ];
    const base_nc_commodities = [
        { label: "Corn New Crop", value: "corn_new_crop" },
        { label: "Canola New Crop", value: "canola_new_crop" },
        { label: "Msp Wheat New Crop", value: "msp_wheat_new_crop" },
        { label: "SoyBeans New Crop", value: "beans_new_crop" },
    ];

    const current_year = moment.tz(CommonDestinationTimezone).format("YYYY");
    const next_year = moment.tz(CommonDestinationTimezone).add(1, "year").format("YYYY");

    return [
        ...base_cont_commodities.map(commodity => ({
            label: commodity.label,
            value: commodity.value,
        })),
        ...base_nc_commodities.map(commodity => ({
            label: `${commodity.label} ${current_year}`,
            value: `${commodity.value}_${current_year}`,
        })),
        ...base_nc_commodities.map(commodity => ({
            label: `${commodity.label} ${next_year}`,
            value: `${commodity.value}_${next_year}`,
        })),
    ];
}

export function ManualPriceData() {
    const { toast } = useToast();
    const { member } = useStytchMember();
    const addPriceData = useAddPriceData();
    const [data, setData] = useState<typeof AddPriceDataValidation._type>({} as any);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isDeveloper = member && doesUserHavePackages({ member, requiredPackages: [PossiblePackage.Developer] });

    const handleApplyFilters = async (e: React.FormEvent<HTMLFormElement>) => {
        if (e) { e.preventDefault(); }
        if (isSubmitting) return;
        setIsSubmitting(true);

        try {
            const parseResult = AddPriceDataValidation.safeParse(data);
            if (!parseResult.success) {
                toast({
                    title: "Invalid data",
                    description: parseResult.error.message,
                });
                return;
            }

            const result = await addPriceData(parseResult.data);
            if (!result.success) {
                toast({
                    title: "Failed to add price data",
                    description: result?.message || "",
                });
                return;
            }

            toast({
                title: "Price data added",
                description: "Price data added successfully",
            });
            setData({} as any);

        } catch (error: any) {
            console.error("Failed to add price data", error);
            toast({
                title: "Failed to add price data",
                description: typeof error?.message === "string" ? error.message : "Unknown error",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const commodityOptions = useMemo(() => generateCommodityOptions(), []);

    return (
        <div className="flex flex-col h-screen max-h-[calc(100vh-4rem)]">
            <div className=" mb-2 ">
                <span className="flex flex-row justify-between items-center">
                    <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                        Manual Price Data
                    </h1>
                </span>
            </div>
            <form onSubmit={handleApplyFilters}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
                    <div className="space-y-4">
                        <div className="space-y-2">
                            <Label htmlFor="date" className="flex justify-between"><span>Date</span><span className="text-xs text-gray-500">(YYYY-MM-DD)</span></Label>
                            <Input
                                required
                                id="date"
                                value={data.date || ""}
                                pattern="\d{4}-\d{2}-\d{2}"
                                onChange={(e) => setData({ ...data, date: e.target.value })}
                            />
                        </div>

                        <div className="space-y-2 flex flex-col">
                            <Label htmlFor="commodity">Commodity</Label>
                            <Combobox
                                id="commodity"
                                className="w-[300px]"
                                popoverClassName="w-[300px]"
                                value={data.commodity}
                                onValueChange={(value) => setData({ ...data, commodity: value })}
                                options={commodityOptions}
                            />
                        </div>
                    </div>

                    <div className="space-y-4">
                        <div className="space-y-2">
                            <Label htmlFor="open">Open</Label>
                            <Input
                                required
                                id="open"
                                type="number"
                                step="0.01"
                                min="0"
                                value={data.open || ""}
                                onChange={(e) => setData({ ...data, open: parseFloat(e.target.value) })}
                            />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="high">High</Label>
                            <Input
                                required
                                id="high"
                                type="number"
                                step="0.01"
                                min="0"
                                value={data.high || ""}
                                onChange={(e) => setData({ ...data, high: parseFloat(e.target.value) })}
                            />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="low">Low</Label>
                            <Input
                                required
                                id="low"
                                type="number"
                                step="0.01"
                                min="0"
                                value={data.low || ""}
                                onChange={(e) => setData({ ...data, low: parseFloat(e.target.value) })}
                            />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="close">Close</Label>
                            <Input
                                required
                                id="close"
                                type="number"
                                step="0.01"
                                min="0"
                                value={data.close || ""}
                                onChange={(e) => setData({ ...data, close: parseFloat(e.target.value) })}
                            />
                        </div>

                        {isDeveloper && (
                            <div className="flex items-center space-x-2 pt-2">
                                <Label htmlFor="override">Override existing data</Label>
                                <Checkbox
                                    id="override"
                                    checked={data.override || false}
                                    onCheckedChange={(checked) =>
                                        setData({ ...data, override: checked === true })
                                    }
                                />
                            </div>
                        )}

                        <span className="flex justify-between">
                            <Button
                                onClick={() => setData({} as any)}
                                disabled={isSubmitting}
                            >
                                clear
                            </Button>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Submitting..." : "Submit"}
                            </Button>
                        </span>
                    </div>
                </div>
            </form>
        </div>
    );
}
