import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z as zod } from "zod";
import { fetchApiGet, fetchApiPost, fetchApiPut } from "./utils";
import { DeferredPaymentType } from "./deferred_payments";
import { Customer } from "./reports";

export const UpdateCustomerDeferredPaymentLienStatusValidation = zod.object({
  customer_id: zod.string().uuid(),
  deferred_payment_lien_status: zod.enum(['active']).optional().nullable(),
});

export const SourcePhoneNumberValidation = zod.object({
  source_type: zod.enum(["destination", "mobile_user"]),
  label: zod.string(),
  phone_number: zod.string().min(1),
});

export const SourceEmailValidation = zod.object({
  source_type: zod.enum(["payment", "payment_ach", "payment_check", "pdf_signature"]),
  label: zod.string(),
  email: zod.string().email(),
});

export const CreateCustomerValidation = zod.object({
  id: zod.string().optional(),

  client_id: zod.string(),
  short_name: zod.string().optional().nullable(),
  long_name: zod.string(),

  is_commercial: zod.boolean(),

  email: zod.string().email().optional().nullable(),
  address_line_one: zod.string().optional().nullable(),
  address_line_two: zod.string().optional().nullable(),
  city: zod.string().optional().nullable(),
  state: zod.string().optional().nullable(),
  zipcode: zod.string().optional().nullable(),
  country: zod.string().optional().nullable(),

  phone_number: zod.string().optional().nullable(),
  source_phone_numbers: zod.array(SourcePhoneNumberValidation).optional(),
  source_emails: zod.array(SourceEmailValidation).optional(),

  preferred_defer_payment_type: zod.enum([DeferredPaymentType.ACH, DeferredPaymentType.CHECK]).optional().nullable(),
  deferred_payment_lien_status: zod.enum(['active']).optional().nullable(),
});

export type BaseCustomer = typeof CreateCustomerValidation._type;

export const FileUploadValidation = zod.object({
  filename: zod.string(),
  mimetype: zod.string(),
});

export const BulkUploadValidation = zod.object({
  zip_file: FileUploadValidation
});

export function useListCustomers() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["baseCustomers"],
    queryFn: () => fetchApiGet<Customer[]>("customer", queryParams),
    retry: 1,
  });
}

export function useCreateCustomer() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (data: BaseCustomer) => {
    const result = await fetchApiPost<BaseCustomer>(
      "customer",
      data,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["baseCustomers"] });
    return result;
  };
}

export function useUpdateCustomer() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (id: string, data: BaseCustomer) => {
    if (data.preferred_defer_payment_type === "" as any) { data.preferred_defer_payment_type = null; }

    const result = await fetchApiPut<BaseCustomer>(
      `customer/${id}`,
      data,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["baseCustomers"] });
    return result;
  };
}

export function useUploadBulkPaymentRemittanceJob() {
  const queryParams = new URLSearchParams();

  return async (data: typeof BulkUploadValidation._type) => {
    const result = await fetchApiPost<{ job_id: string; document_id: string; link: string }>(
      `customer/payment/pdf/bulk`,
      data,
      queryParams,
    );

    return result;
  };
}

export function useUpdateCustomerDeferredPaymentLienStatus() {
  const queryParams = new URLSearchParams();
  const queryClient = useQueryClient();

  return async (data: typeof UpdateCustomerDeferredPaymentLienStatusValidation._type) => {
    const result = await fetchApiPut<{ job_id: string; document_id: string; link: string }>(
      `customer/${data.customer_id}/deferred_payment_lien_status`,
      data,
      queryParams,
    );

    if (result.success) {
      queryClient.invalidateQueries({ queryKey: ["baseCustomers"] });
    }

    return result;
  };
}
