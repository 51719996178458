import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { Loader } from "@/components/ui/loader";
import { toast } from "@/components/ui/use-toast";
import { KnowledgeApi, useUpdateAssignmentStepCompletion } from '@/lib/api/knowledge'; // Assuming types are here
import { cn, CommonDestinationTimezone } from '@/lib/utils';
import { ServerError } from '@/lib/responseResults';
import { useStytchMember } from '@stytch/react/b2b';

interface AssignmentStepStatus {
  step_id: string;
  completed_at: string | null;
}

interface AssignmentStepsModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  assignment: any;
  taskId: string;
  isEditable: boolean;
  taskSteps: KnowledgeApi.KnowledgeStep[];
}

export function AssignmentStepsModal({
  open,
  onOpenChange,
  assignment,
  taskId,
  isEditable,
  taskSteps: assignmentSteps,
}: AssignmentStepsModalProps) {
  const { member } = useStytchMember();
  const [stepStatuses, setStepStatuses] = useState<Record<string, AssignmentStepStatus>>({});
  const [isLoading, setIsLoading] = useState(false); // For internal loading state
  const [isSaving, setIsSaving] = useState(false);
  const updateAssignmentStepCompletion = useUpdateAssignmentStepCompletion();

  const user_id = member?.trusted_metadata?.db_user_id;
  const taskSteps = useMemo(() => {
    return assignmentSteps.sort((a, b) => {
      const orderDiff = (a.order || 0) - (b.order || 0);
      return orderDiff !== 0 ? orderDiff :
        (a.created_at && b.created_at) ?
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime() : 0;
    });
  }, [assignmentSteps]);

  useEffect(() => {
    if (open && assignment?.id) {
      setIsLoading(true);

      const statusesMap = taskSteps.reduce((acc, step) => {
        const completion = (step as any).completion;
        acc[step.id] = {
          step_id: step.id,
          completed_at: completion?.completed_at,
        };
        return acc;
      }, {} as Record<string, AssignmentStepStatus>);

      setStepStatuses(statusesMap);
      setIsLoading(false);
    } else if (!open) {
      // Reset state when modal closes
      setStepStatuses({});
      setIsLoading(false);
    }
  }, [open, assignment?.id, taskSteps]);

  const handleToggleStep = async (stepId: string, isCompleted: boolean) => {
    if (!isEditable) {
      toast({ title: "Read Only", description: "You do not have permission to edit these steps.", variant: "default" });
      return;
    }

    try {
      const stepDefinition = taskSteps.find(s => s.id === stepId);
      if (!stepDefinition) {
        console.error("Step definition not found for ID:", stepId);
        return;
      }
      if (isSaving) {
        return;
      }

      setIsSaving(true);
      const isTrainer = assignment.trainer_id === user_id || assignment?.trainer?.id === user_id;
      const isAssignee = assignment.assignee_id === user_id || assignment?.assignee?.id === user_id;

      const isAllowed = (stepDefinition.completion_by === 'trainer' && isTrainer) || (stepDefinition.completion_by === 'assignee' && isAssignee);

      if (!isAllowed) {
        switch (stepDefinition.completion_by) {
          case 'trainer':
            toast({ title: "Action Not Allowed", description: "This step must be completed by the trainer.", variant: "destructive" });
            break;
          case 'assignee':
            toast({ title: "Action Not Allowed", description: "This step must be completed by the assignee.", variant: "destructive" });
            break;
          default:
            toast({ title: "Action Not Allowed", description: "", variant: "destructive" });
        }
        return;
      }

      const result = await updateAssignmentStepCompletion({
        knowledge_item_id: taskId,
        assignment_id: assignment.id,
        step_id: stepId,
        status: isCompleted
      });

      if (!result.success) {
        throw new ServerError(isCompleted ? "Failed to complete step" : "Failed to uncomplete step", {});
      }

      if (isCompleted) {
      setStepStatuses(prevStatuses => ({
        ...prevStatuses,
        [stepId]: {
          step_id: stepId,
          completed_by_id: user_id,
          acknowledged_by_id: user_id,
          completed_at: moment().toISOString(),
        } 
      }));
      } else {
        setStepStatuses(prevStatuses => ({
          ...prevStatuses,
          [stepId]: {
            step_id: stepId,
            completed_by_id: null,
            acknowledged_by_id: null,
            completed_at: null,
          }
        }));
      }

      toast({ title: "Updated", description: `Step marked as ${isCompleted ? 'complete' : 'incomplete'}.` });
    } catch (error) {
      console.error("Error updating step status:", error);
      toast({ title: "Update Failed", description: "Could not update step status.", variant: "destructive" });
    } finally {
      setIsSaving(false);
    }
  };


  const isFullyCompleted = taskSteps.every(step => stepStatuses[step.id]?.completed_at) || assignment.status === 'COMPLETED';

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Training Progress</DialogTitle>
          <DialogDescription>
            {isFullyCompleted && <span className="ml-2">All steps completed</span>}
            {!isFullyCompleted && <span>Viewing progress for <span className="font-semibold">{assignment?.assignee?.name || 'Assignee'}</span>.</span>}
            {(isEditable && !isFullyCompleted) && <Badge variant="outline" className="ml-2">Editable</Badge>}
          </DialogDescription>
        </DialogHeader>

        <Separator className="my-4" />

        {isLoading ? (
          <div className="flex justify-center items-center h-40">
            <Loader />
          </div>
        ) : (
          <div className="space-y-4 max-h-[60vh] overflow-y-auto pr-2">
            {taskSteps.length > 0 ? taskSteps.map((step) => {
              const status = stepStatuses[step.id];
              const isCompleted = !!status?.completed_at;
              const completedAt = status?.completed_at ? moment.tz(status.completed_at, CommonDestinationTimezone).format('MM/DD/YYYY hh:mm a') : null;

              const isDisabled = !isEditable || step.completion_by === 'assignee';

              return (
                <div
                  key={`${assignment.id}-modal-step-${step.id}`}
                  className="flex items-start p-3 border rounded-md bg-background/50"
                >
                  <Checkbox
                    id={`modal-step-${step.id}`}
                    className={cn(
                      "mt-1 mr-3",
                      isDisabled && "cursor-not-allowed"
                    )}
                    indicatorClassName={cn(
                      isDisabled && "cursor-not-allowed"
                    )}
                    checked={isCompleted}
                    onCheckedChange={(checked) =>
                      handleToggleStep(step.id, checked as boolean)
                    }
                    disabled={isDisabled || isSaving || isFullyCompleted}
                    aria-label={`Mark step "${step.title}" as ${isCompleted ? 'incomplete' : 'complete'}`}
                    title={isDisabled ? (step.completion_by === 'assignee' ? "Assignee must complete this step" : "Read-only") : ""}
                  />
                  <div className="flex-1 space-y-1">
                    <label
                      htmlFor={`modal-step-${step.id}`}
                      className={cn("font-medium", !isDisabled && "cursor-pointer")}
                    >
                      {step.title}
                    </label>
                    {step.description && (
                      <p className="text-sm text-muted-foreground">
                        {step.description}
                      </p>
                    )}
                    {completedAt && (
                      <p className="text-xs text-green-600">
                        Completed: {completedAt}
                      </p>
                    )}
                  </div>
                </div>
              );
            }) : (
              <p className="text-muted-foreground italic text-center py-4">No steps defined for this task.</p>
            )}
          </div>
        )}

        <Separator className="my-4" />

        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)} disabled={isSaving}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
