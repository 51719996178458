import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { DbUser } from "../models";
import { BaseDbObject } from "../models/db";
import { Customer } from "./reports";
import {
    fetchApiDelete,
    fetchApiGet,
    fetchApiPost,
    fetchApiPut,
} from "./utils";

export const CreateCommonBlockValidation = z.object({
    content: z.string().min(1),
    special_permission_id: z.string().uuid().optional(),
});

export const UpdateCommonBlockValidation = z.object({
    id: z.string().uuid(),
    content: z.string().min(1),
    special_permission_id: z.string().uuid().optional(),
});

export const UpdateKnowledgeItemAssignmentStepValidation = z.object({
    knowledge_item_id: z.string().uuid(),
    assignment_id: z.string().uuid(),
    step_id: z.string().uuid(),

    status: z.boolean()
});

export const AddTagValidation = z.object({
    name: z.string().min(1),
    special_permission_id: z.string().uuid().optional(),
});

export const UpdateTagValidation = z.object({
    id: z.string().uuid(),
    name: z.string().min(1),
    special_permission_id: z.string().uuid().optional(),
});

export const CreateKnowledgeItemValidation = z.object({
    title: z.string().min(1),
    description: z.string().default(""),
    content: z.string().optional(),
    special_permission_id: z.string().uuid().optional(),
    owner_id: z.string().uuid(),
    item_type: z.enum(['task', 'common-block']),
});

export const UpdateKnowledgeItemValidation = z.object({
    id: z.string().uuid(),
    title: z.string().min(1),
    description: z.string().min(1),
    content: z.string().min(1),
    special_permission_id: z.string().uuid().optional(),
    tags: z.array(z.string().uuid()).optional(),
    steps: z.array(z.object({
        id: z.string().uuid().optional(),
        name: z.string().min(1),
        description: z.string().default(""),
        blocking: z.boolean().optional().default(true),
        completion_by: z.enum(['assignee', 'trainer', 'any']).optional().default('trainer'),
    })).optional(),
});

export const UploadFileValidation = z.object({
    mimetype: z.string(),
    file_name: z.string(),
    file_size: z.number(),
    knowledge_item_id: z.string().uuid(),
});

export const AddAssignmentValidation = z.object({
    knowledge_item_id: z.string().uuid(),
    assignee_id: z.string().uuid(),
    trainer_id: z.string().uuid().optional(),
    backup_trainer_id: z.string().uuid().optional(),
    status: z.enum(['IN_PROGRESS']),
});

export const UpdateAssignmentValidation = z.object({
    knowledge_item_id: z.string().uuid(),
    assignment_id: z.string().uuid(),
    status: z.enum(['COMPLETED', 'IN_PROGRESS']),
});

export interface KnowledgeItem extends BaseDbObject {
    title: string;
    description: string;
}

interface KnowledgeItemsParams {
    page: number;
    filter?: 'all' | 'owned' | 'assigned' | 'training' | 'common-blocks';
    activeTagId?: string;
    emptyTags?: boolean;
}

function assembleKnowledgeItemsQueryParams(params: KnowledgeItemsParams) {
    const queryParams = new URLSearchParams();
    queryParams.append("page", `${params.page}`);
    queryParams.append("pageSize", `21`);
    if (params.filter) {
        queryParams.append("filter", params.filter);
    }
    if (params.activeTagId) {
        queryParams.append("tag_id", params.activeTagId);
    }
    if (params.emptyTags) {
        queryParams.append("empty_tags", `${params.emptyTags}`);
    }
    return queryParams;
}

export function fetchKnowledgeItemsPage(params: KnowledgeItemsParams) {
    const queryParams = assembleKnowledgeItemsQueryParams(params);

    return fetchApiGet<{
        data: KnowledgeItem[];
        pagination: {
            totalItems: number;
            totalPages: number;
            pageSize: number;
            currentPage: number;
        }
    }>(`knowledge/items`, queryParams);
}

export function useKnowledgeItemsPaginated({ params, queryFn, enabled, keyOverride }: {
    params: KnowledgeItemsParams;
    queryFn: any;
    enabled?: boolean;
    keyOverride?: string;
}) {
    const middleKey = keyOverride ?? "tasks";
    return useQuery({
        queryKey: ["knowledge", middleKey, assembleKnowledgeItemsQueryParams(params).toString()],
        queryFn: () => queryFn(params),
        retry: 1,
        keepPreviousData: true,
        enabled: enabled ?? true,
    });
}

export function useGetKnowledgeItem(taskId?: string) {
    return useQuery({
        queryKey: ["knowledge", "task", taskId],
        queryFn: () => fetchApiGet<KnowledgeApi.KnowledgeItem>(`knowledge/items/${taskId}`),
        enabled: !!taskId,
        retry: 1,
    });
}

export function useGetAssignableUsers() {
    return useQuery({
        queryKey: ["knowledge", "assignableUsers"],
        queryFn: () => fetchApiGet<{ id: string, name: string }[]>(`knowledge/assignable-users`),
    });
}

export function useGetKnowledgePermissions() {
    return useQuery({
        queryKey: ["knowledge", "permissions"],
        queryFn: () => fetchApiGet<{ id: string, name: string; description: string }[]>(`knowledge/permissions`),
    });
}

export function useCreateKnowledgeItem() {
    const queryClient = useQueryClient();

    return async (params: typeof CreateKnowledgeItemValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPost<any>(
            `knowledge/items`,
            params,
            queryParams
        );
        if (result.success) {
            queryClient.invalidateQueries({ queryKey: ["knowledge", "tasks"] });
            queryClient.invalidateQueries({ queryKey: ["knowledge", "all-tasks"] });
        }

        return result;
    };
}

export function useUpdateKnowledgeItem() {
    const queryClient = useQueryClient();

    return async (params: typeof UpdateKnowledgeItemValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPut<any>(
            `knowledge/items/${params.id}`,
            params,
            queryParams
        );

        if (result.success) {
            queryClient.invalidateQueries({ queryKey: ["knowledge", "tasks"] });
            queryClient.invalidateQueries({ queryKey: ["knowledge", "all-tasks"] });
        }

        return result;
    };
}

export function useAddAssignment() {
    const queryClient = useQueryClient();

    return async (params: typeof AddAssignmentValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPost<any>(
            `knowledge/items/${params.knowledge_item_id}/assignments`,
            params,
            queryParams
        );

        if (result.success) {
            queryClient.invalidateQueries({ queryKey: ["knowledge", "tasks"] });
            queryClient.invalidateQueries({ queryKey: ["knowledge", "all-tasks"] });
            queryClient.invalidateQueries({ queryKey: ["knowledge", "task", params.knowledge_item_id] });
        }

        return result;
    };
}

export function useUpdateAssignment() {
    const queryClient = useQueryClient();

    return async (params: typeof UpdateAssignmentValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPut<any>(
            `knowledge/items/${params.knowledge_item_id}/assignments/${params.assignment_id}`,
            params,
            queryParams
        );

        if (result.success) {
            queryClient.invalidateQueries({ queryKey: ["knowledge", "tasks"] });
            queryClient.invalidateQueries({ queryKey: ["knowledge", "all-tasks"] });
            queryClient.invalidateQueries({ queryKey: ["knowledge", "task", params.knowledge_item_id] });
        }

        return result;
    };
}

export function useCreateKnowledgeUploadLink() {
    const queryClient = useQueryClient();

    return async (params: typeof UploadFileValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPost<any>(
            `knowledge/file/upload/link`,
            params,
            queryParams
        );

        if (result.success) {
            queryClient.invalidateQueries({ queryKey: ["knowledge", "task", params.knowledge_item_id] });
        }

        return result;
    };
}

export function useGenerateGetPresignedUrl() {
    const queryClient = useQueryClient();

    return async (attachmentId: string) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPost<any>(
            `knowledge/file/${attachmentId}`,
            {},
            queryParams
        );

        return result;
    };
}

export function useCreateTag() {
    const queryClient = useQueryClient();

    return async (params: typeof AddTagValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPost<any>(
            `knowledge/tags`,
            params,
            queryParams
        );

        if (result.success) {
            queryClient.invalidateQueries({ queryKey: ["knowledge", "tags"] });
        }

        return result;
    };
}

export function useUpdateTag() {
    const queryClient = useQueryClient();

    return async (params: typeof UpdateTagValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPut<any>(
            `knowledge/tags/${params.id}`,
            params,
            queryParams
        );

        if (result.success) {
            queryClient.invalidateQueries({ queryKey: ["knowledge"] });
        }

        return result;
    };
}

export function useUpdateAssignmentStepCompletion() {
    const queryClient = useQueryClient();

    return async (params: typeof UpdateKnowledgeItemAssignmentStepValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPut<any>(
            `knowledge/items/${params.knowledge_item_id}/assignments/${params.assignment_id}/steps/${params.step_id}`,
            params,
            queryParams
        );

        if (result.success) {
            queryClient.invalidateQueries({ queryKey: ["knowledge", "task", params.knowledge_item_id] });
        }

        return result;
    }
}

export function useCreateCommonBlock() {
    const queryClient = useQueryClient();

    return async (params: typeof CreateCommonBlockValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPost<any>(
            `knowledge/common-blocks`,
            params,
            queryParams
        );

        if (result.success) {
            queryClient.invalidateQueries({ queryKey: ["knowledge", "common-blocks"] });
        }

        return result;
    }
}

export function useUpdateCommonBlock() {
    const queryClient = useQueryClient();

    return async (params: typeof UpdateCommonBlockValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPut<any>(
            `knowledge/common-blocks/${params.id}`,
            params,
            queryParams
        );

        if (result.success) {
            queryClient.invalidateQueries({ queryKey: ["knowledge", "common-blocks"] });
            queryClient.invalidateQueries({ queryKey: ["knowledge", "common-block", params.id] });
        }

        return result;
    }
}

export function useGetCommonBlocks() {
    return useQuery({
        queryKey: ["knowledge", "common-blocks"],
        queryFn: () => fetchApiGet<KnowledgeApi.KnowledgeCommonBlock[]>(`knowledge/common-blocks`),
    });
}

export function useGetCommonBlock(id: string) {
    return useQuery({
        queryKey: ["knowledge", "common-block", id],
        queryFn: () => fetchApiGet<KnowledgeApi.KnowledgeCommonBlock>(`knowledge/common-blocks/${id}`),
        enabled: !!id,
    });
}

export function useGetKnowledgeTags() {
    return useQuery({
        queryKey: ["knowledge", "tags"],
        queryFn: () => fetchApiGet<{ id: string, name: string }[]>(`knowledge/tags`),
    });
}

export namespace KnowledgeApi {
    export type StepCompletionBy = 'assignee' | 'trainer' | 'any';
    export type KnowledgeAttachmentType = 'FILE' | 'LINK' | 'IMAGE' | 'VIDEO' | 'AUDIO' | 'PDF';
    export type KnowledgeAssignmentStatus = 'PENDING' | 'IN_PROGRESS' | 'COMPLETED';

    export interface KnowledgeTaskTag extends BaseDbObject {
        name: string;
        special_permission_id?: string;

        SpecialPermission?: SpecialPermission;
      }
      
      export interface KnowledgeCommonBlock extends BaseDbObject {
        content: string;
        special_permission_id?: string;
      
        SpecialPermission?: SpecialPermission;
      }
      
      export interface KnowledgeTagLink extends BaseDbObject {
        tag_id: string;
        item_id?: string;
        common_block_id?: string;
      
        KnowledgeTaskTag?: KnowledgeTaskTag;
        KnowledgeItem?: KnowledgeItem;
        KnowledgeCommonBlock?: KnowledgeCommonBlock;
      }

    export interface KnowledgeItem extends BaseDbObject {
        item_type: 'task' | 'common-block';
        title: string;
        description: string;
        content: string;

        owner_id: string;
        special_permission_id?: string;

        steps?: KnowledgeStep[];
        attachments?: KnowledgeAttachment[];

        assignments?: KnowledgeAssignment[];
        assignmentsCount?: number;

        Owner?: DbUser;
        SpecialPermission?: SpecialPermission;
    }

    export interface KnowledgeStep extends BaseDbObject {
        knowledge_item_id: string;

        title: string;
        description: string;
        order: number | null;
        is_blocker: boolean;
        completion_by: StepCompletionBy;
    }

    export interface KnowledgeAttachment extends BaseDbObject {
        name: string;
        type: KnowledgeAttachmentType;
        url: string;
        position?: number;
    }

    export interface KnowledgeAssignment extends BaseDbObject {
        knowledge_item_id: string;

        assignee_id: string;
        trainer_id?: string;
        backup_trainer_id?: string;

        assigned_at: Date;
        completed_at?: Date;

        status: KnowledgeAssignmentStatus;


        KnowledgeItem?: KnowledgeItem;
        Assignee?: DbUser;
        Trainer?: DbUser;
        BackupTrainer?: DbUser;

        StepCompletions?: StepCompletion[];
    }

    export interface StepCompletion extends BaseDbObject {
        step_id: string;

        assignment_id: string;

        completed_by_id: string;
        acknowledged_by_id?: string;
        last_action_id?: string;

        completed_at: Date;
    }

    export interface SpecialPermission extends BaseDbObject {
        name: string;
        description: string;
    }

    export interface UserSpecialPermission extends BaseDbObject {
        user_id: string;
        permission_id: string;
    }
}