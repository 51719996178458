import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { BaseDbObject } from "../models/db";
import { fetchApiGet, fetchApiPost } from "./utils";

export const CreateJobValidation = z.object({
  filename: z.string(),
  mimeType: z.string(),
});

export function useGetTraderCustomersInfo() {
  return useQuery({
    queryKey: ["TraderCustomerInfo"],
    queryFn: () => fetchApiGet<TraderCustomerBulk>("reports/traderCustomer"),
    retry: 1,
    cacheTime: 0,
  });
}

export interface TraderCustomerBulk {
  traders: Trader[];
  customers: Customer[];
  traderCustomers: TraderCustomer[];
}

export interface Customer extends BaseDbObject {
  client_id: string;
  short_name: string;
  long_name: string;
  meta_data: any;

  is_commercial: boolean;

  email?: string;

  address_line_one?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;

  preferred_defer_payment_type?: string;
  deferred_payment_lien_status?: string;

  TraderCustomers?: TraderCustomer[];
}

export interface Trader extends BaseDbObject {
  initials: string;
  name: string;

  meta_data: any;

  TraderCustomers?: TraderCustomer[];
}

export interface TraderCustomer extends BaseDbObject {
  customer_id: string;
  trader_id: string;

  Trader?: Trader;
  Customer?: Customer;
}


export function useGenerateContractQuantityAlertJob() {
  return async (params: typeof CreateJobValidation._type) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<{ link: string }>(
      `contract/quantity/job`,
      params,
      queryParams,
    );

    return result;
  };
}
