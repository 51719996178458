import { z } from "zod";
import Decimal from "decimal.js";
import moment from "moment-timezone";
import { CommonDestinationTimezone, fetchApiGet } from "./utils";
import { isValidDecimal, safeParseDecimal } from "../excel";
import { useQuery } from "@tanstack/react-query";
import { BasicDbObjectValidation, zodTransformDecimal, zodTransformChicagoDate, DateRangeSchemaValidation } from "./utils";
import { BaseDbObject } from "../models/db";

export const CostBasisQueryParamsValidation = z.object({
  page: z.coerce.number().min(1).default(1),
  pageSize: z.coerce.number().min(1).max(50).optional(),
  providerId: z.string().uuid().optional(),
  locationId: z.string().uuid().optional(),
  hash: z.string().optional(),
  search: z.string().trim().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
});

interface CostBasisSummary {
  entries: CostBasis[];
  hash: String;
  Provider: { id: string; name: string; }
  Location: { id: string; name: string; }
}

interface SummariesParams {
  showSingleData: boolean;
  page: number
  pageSize?: number;
  providerId?: string;
  locationId?: string;
}

interface ProviderParams {
  page: number
}

interface LocationParams {
  page: number
}

interface CostBasisParams {
  page: number
}

interface CostBasisProvider extends BaseDbObject {
  name: string;
}

interface CostBasisLocation extends BaseDbObject {
  name: string;

  provider_id: string;
  url: string;
  last_synced_date?: Date;

  Provider?: Partial<CostBasisProvider>;
}

interface CostBasis extends BaseDbObject {
  provider_id: string;
  location_id: string;
  commodity: string;
  basis: string;
  futures_price: string;
  futures_month: string;
  delivery_month: string;
  futures_month_date: Date;

  Location?: Partial<CostBasisLocation>;
  Provider?: Partial<CostBasisProvider>;
}

function assembleCostBasisSummariesParams(params: SummariesParams) {
  const queryParams = new URLSearchParams();

  queryParams.append("showSingleData", `${params.showSingleData}`);
  queryParams.append("page", `${params.page}`)
  queryParams.append("pageSize", `${params.pageSize || 100}`);
  if (params.providerId) { queryParams.append("providerId", params.providerId || ""); }
  if (params.locationId) { queryParams.append("locationId", params.locationId || ""); }
  return queryParams;
}

function assembleCostBasisProviderParams(params: ProviderParams) {
  const queryParams = new URLSearchParams();
  queryParams.append("page", `${params.page}`)
  queryParams.append("pageSize", `40`);
  return queryParams;
}

function assembleCostBasisLocationParams(params: LocationParams) {
  const queryParams = new URLSearchParams();
  queryParams.append("page", `${params.page}`)
  queryParams.append("pageSize", `40`);
  return queryParams;
}

function assembleCostBasisParams(params: typeof CostBasisQueryParamsValidation._type) {
  const queryParams = new URLSearchParams();
  queryParams.append("page", `${params.page}`);
  queryParams.append("pageSize", params.pageSize ? `${params.pageSize}` : `40`);
  if (params.providerId) { queryParams.append("providerId", params.providerId || ""); }
  if (params.locationId) { queryParams.append("locationId", params.locationId || ""); }
  if (params.hash) { queryParams.append("hash", params.hash || ""); }
  if (params.search) { queryParams.append("search", params.search || ""); }
  if (params.startDate) { queryParams.append("startDate", params.startDate || ""); }
  if (params.endDate) { queryParams.append("endDate", params.endDate || ""); }
  return queryParams;
}

export function fetchSummariesPage(params: SummariesParams) {
  const queryParams = assembleCostBasisSummariesParams(params);

  return fetchApiGet<{
    data: CostBasisSummary[]
    pagination: {
      totalItems: number;
      totalPages: number;
      pageSize: number;
      currentPage: number;
    }
  }>(`cost_basis/group/summary`, queryParams);
}

export function fetchLocationsPage(params: LocationParams) {
  const queryParams = assembleCostBasisLocationParams(params);

  return fetchApiGet<{
    data: CostBasisLocation[]
    pagination: {
      totalItems: number;
      totalPages: number;
      pageSize: number;
      currentPage: number;
    }
  }>(`cost_basis/location`, queryParams);
}

export function fetchProvidersPage(params: ProviderParams) {
  const queryParams = assembleCostBasisProviderParams(params);

  return fetchApiGet<{
    data: CostBasisProvider[]
    pagination: {
      totalItems: number;
      totalPages: number;
      pageSize: number;
      currentPage: number;
    }
  }>(`cost_basis/provider`, queryParams);
}

export function fetchCostBasisPage(params: CostBasisParams) {
  const queryParams = assembleCostBasisParams(params);

  return fetchApiGet<{
    data: CostBasis[]
    pagination: {
      totalItems: number;
      totalPages: number;
      pageSize: number;
      currentPage: number;
    }
  }>(`cost_basis/basis`, queryParams);
}

export function useSummariesPaginated({ params, queryFn }: {
  params: SummariesParams;
  queryFn: any;
}) {
  return useQuery({
    queryKey: ["cost_basis", "summaries", assembleCostBasisSummariesParams(params).toString()],
    queryFn: () => queryFn(params),
    retry: 1,
    cacheTime: 0,
    keepPreviousData: true,
  });
}

export function useLocationsPaginated({ params, queryFn }: {
  params: LocationParams;
  queryFn: any;
}) {
  return useQuery({
    queryKey: ["cost_basis", "locations", assembleCostBasisLocationParams(params).toString()],
    queryFn: () => queryFn(params),
    retry: 1,
    cacheTime: 0,
    keepPreviousData: true,
  });
}

export function useProvidersPaginated({ params, queryFn }: {
  params: ProviderParams;
  queryFn: any;
}) {
  return useQuery({
    queryKey: ["cost_basis", "providers", assembleCostBasisProviderParams(params).toString()],
    queryFn: () => queryFn(params),
    retry: 1,
    cacheTime: 0,
    keepPreviousData: true,
  });
}

export function useCostBasisPaginated({ params, queryFn }: {
  params: typeof CostBasisQueryParamsValidation._type;
  queryFn: any;
}) {
  return useQuery({
    enabled: !!params?.providerId,
    queryKey: ["cost_basis", "basis", assembleCostBasisParams(params).toString()],
    queryFn: () => queryFn(params),
    retry: 1,
    cacheTime: 0,
    keepPreviousData: true,
  });
}

export function useCostBasisFitlers() {
  const queryParams = new URLSearchParams();
  return useQuery({
    queryKey: ["cost_basis", "filters"],
    queryFn: () => {
      return fetchApiGet<{
        locations: { id: string; name: string; provider_id: string; is_enabled: true; }[]
        providers: { id: string; name: string; is_enabled: true; }[]
      }[]>(`cost_basis/filters`, queryParams);
    },
    retry: 1,
    cacheTime: 0,
  });
}
