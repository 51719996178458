import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import FileEmbedComponent from './FileEmbedComponent'; // We'll create this next

export interface FileOptions {
  HTMLAttributes: Record<string, any>,
  showEditButton?: boolean,
  showDownloadButton?: boolean,
  // We'll pass the download handler via node view props later
}

// Define the attributes our node will store
export type FileAttributes = {
  fileId: string | null;
  fileName: string | null;
  alias?: string | null; // Optional display name
  fileType?: string | null; // Optional: e.g., 'application/pdf'
};

const FileNode = Node.create<FileOptions>({
  name: 'fileEmbed', // Choose a unique name
  group: 'block', // Display as a block-level element
  atom: true, // Treat as a single, indivisible unit
  draggable: true, // Allow dragging within the editor

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'file-embed-container', // Class for the wrapper element
      },
    }
  },

  addAttributes(): Record<keyof FileAttributes, any> {
    return {
      fileId: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-file-id'),
        renderHTML: (attributes: FileAttributes) => ({ 'data-file-id': attributes.fileId }),
      },
      fileName: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-file-name'),
        renderHTML: (attributes: FileAttributes) => ({ 'data-file-name': attributes.fileName }),
      },
      alias: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-alias'),
        renderHTML: (attributes: FileAttributes) => attributes.alias ? ({ 'data-alias': attributes.alias }) : {},
      },
      fileType: {
         default: null,
         parseHTML: (element: HTMLElement) => element.getAttribute('data-file-type'),
         renderHTML: (attributes: FileAttributes) => attributes.fileType ? ({ 'data-file-type': attributes.fileType }) : {},
      }
    }
  },

  parseHTML() {
    return [
      {
        // Match the structure we define in renderHTML
        tag: `div[data-file-id][data-file-name]`,
      },
    ]
  },

  renderHTML({ HTMLAttributes, node, disabled }: any) {
    // This generates the HTML that gets saved (serialized)
    // The React component handles the live editor view
    const attrs = node.attrs as FileAttributes;
    const displayAttrs = {
        ...HTMLAttributes,
        'data-file-id': attrs.fileId,
        'data-file-name': attrs.fileName,
        ...(attrs.alias && {'data-alias': attrs.alias}),
        ...(attrs.fileType && {'data-file-type': attrs.fileType}),
    };

    // Simple representation for saved HTML
    return ['div', mergeAttributes(this.options.HTMLAttributes, displayAttrs),
        `[File: ${attrs.alias || attrs.fileName || 'Unknown'}]` // Basic text fallback
    ]
  },

  // Use ReactNodeViewRenderer to render with our component
  addNodeView() {
    // Pass necessary props down to the React component
    return ReactNodeViewRenderer(FileEmbedComponent, {
        // props that will be available in the FileEmbedComponent props.node.props
        // e.g., we can pass handlers here if needed, but it's often cleaner
        // to manage handlers in the main component and pass them via context or props there.
    });
  },

  addCommands() {
    return {
      setFile: (attributes: FileAttributes) => ({ commands }) => {
        if (!attributes.fileId || !attributes.fileName) {
            console.error("File ID and File Name are required to set a file node.");
            return false;
        }
        return commands.insertContent({
          type: this.name,
          attrs: attributes,
        })
      },
      // Command to update the alias later, if needed
      updateFileAlias: (alias: string | null) => ({ commands }) => {
         return commands.updateAttributes(this.name, { alias: alias });
      }
    }
  },

  // Input rules are less common for file uploads, primarily handled by drop/paste/button
});

export default FileNode;

// Declare command types for editor type safety
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fileEmbed: {
      /**
       * Insert a file embed node
       */
      setFile: (attributes: FileAttributes) => ReturnType,
      /**
       * Update the alias of the currently selected file node
       */
      updateFileAlias: (alias: string | null) => ReturnType,
    }
  }
}
