import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import Decimal from "decimal.js";
import moment from "moment-timezone";
import { CommonDestinationTimezone } from "../api/trading";
import { Customer, Trader, TraderCustomerBulk } from "../api/reports";
import { parseFromFile } from "../file";
import { UnsettledApRow, CogsAndSalesRow } from "./models";
import { ValidationError } from "../responseResults";
import { findCustomerFrom, findTraderForCustomer } from "../customer";
import { PotentialCommodityMappings, Commodities, CommodityOptions as BaseCommodityOptions } from "../api/commodity";
import { CellFormat } from "../excel";

const CommodityOptions = [
  ...BaseCommodityOptions,
  { value: Commodities.Soybeans, label: "Soybean" },
  { value: Commodities.MinneapolisWheat, label: "Minneapolis Wheat" },
  { value: Commodities.MinneapolisWheat, label: "Wheat" },
];

interface GroupDescription {
  account_description: string;
  description: string;
  debit: Decimal;
  credit: Decimal;
  uom: Decimal;
  quantity: number;
}

type GroupAcc = Record<string, GroupDescription>;

// Unsettled AR
// Sales

export async function processOldCogsReport({ reportFile }: { reportFile: File }) {
  const cogsWorkbook = await parseFromFile(reportFile);
  return processOldCogsReportHelper(cogsWorkbook);
}

export async function processFromFiles({ customers, reportFiles, unsettledFiles }: { reportFiles?: File[]; unsettledFiles?: File[]; customers: Customer[] }) {
  const cogsWorkbooks = await Promise.all(
    (reportFiles || []).map(parseFromFile)
  );

  const unsettledWorkbooks = await Promise.all(
    (unsettledFiles || []).map(parseFromFile)
  );

  return processCogsAndUnsettledReport({ cogsWorkbooks, unsettledWorkbooks, customers });
}

function processCogsAndUnsettledReport({ cogsWorkbooks, unsettledWorkbooks, customers }: { cogsWorkbooks: XLSX.WorkBook[], unsettledWorkbooks: XLSX.WorkBook[]; customers: Customer[] }) {
  try {
    const cogsOutputWorkbook = cogsWorkbooks?.length > 0 ? loadCogsSeparatedData({ cogsWorkbooks, customers}) : undefined;

    const unsettledOutputWorkbook = unsettledWorkbooks?.length > 0 ? loadUnsettledApData({ unsettledWorkbooks, customers }) : undefined;

    return { cogsOutputWorkbook, unsettledOutputWorkbook, error: undefined };
  } catch(error) {
    return { cogsOutputWorkbook: undefined, unsettledOutputWorkbook: undefined, error }
  }
}

function aggregateSheetData<T>(workbooks: XLSX.WorkBook[]) {
  return workbooks.reduce((acc: T[], workbook: XLSX.WorkBook) => {
    let sheetName = workbook.SheetNames[0]!;
    if (!sheetName) throw new ValidationError("Failed to find sheet", {});

    let sheet = workbook.Sheets[sheetName];

    let sheetData: T[] = XLSX.utils.sheet_to_json(sheet);

    acc.push(...sheetData.map((row: T) => {
      return Object.entries(row as any).reduce((acc: T, [key, value]) => {
        (acc as any)[key.trim()] = value;
        return acc;
      }, {} as T) as any;
    }) as any[]);
    return acc;
  }, []);
}

function loadUnsettledApData({ unsettledWorkbooks, customers }: { unsettledWorkbooks: XLSX.WorkBook[]; customers: Customer[] }) {
  let sheetData: UnsettledApRow[] = aggregateSheetData<UnsettledApRow>(unsettledWorkbooks);
  sheetData.sort((a, b) => {
    if (a["Customer/Vendor Name"] === b["Customer/Vendor Name"]) return 0;
    if (a["Customer/Vendor Name"] < b["Customer/Vendor Name"]) return -1;
    return 1;
  });

  let rowCustomerMap = sheetData.reduce((acc: Record<string, Record<string, Record<string, UnsettledApRow[]>>>, row: UnsettledApRow) => {
    const customerName = row["Customer/Vendor Name"];
    const commodity: string | undefined = Object.values(Commodities).find(commodity => {
      return commodity?.toLocaleLowerCase()?.trim() === row["Commodity Id"]?.toLocaleLowerCase()?.trim();
    });

    const customer = findCustomerFrom(
        "Master",
        customerName,
        customers.filter(customer => {
          return getCustomerCountryFromCurrency({
            currency: row["Currency"],
            customerCountry: customer.meta_data?.country || customer?.country
          }).isValid;
        }),
      );

    if (!customer) {
      return acc;
    }

    if (!commodity) {
      return acc;
    }

    const countryMatch = getCustomerCountryFromCurrency({ currency: row["Currency"], customerCountry: customer.meta_data?.country || customer?.country })?.match || "";
    const stateMatch = customer.meta_data?.state || customer?.state || customer?.client_id || "Unknown";

    if (!acc[stateMatch]) acc[stateMatch] = {};
    if (!acc[stateMatch][commodity]) acc[stateMatch][commodity] = {};
    if (!acc[stateMatch][commodity][customer.id]) acc[stateMatch][commodity][customer.id] = [];

    acc[stateMatch][commodity][customer.id].push(row);
    return acc;
  }, {} as Record<string, Record<string, Record<string, UnsettledApRow[]>>>);

  const outputExcel = new ExcelJS.Workbook();

  for (const stateMatch of Object.keys(rowCustomerMap)) {
    const commoditySheet = outputExcel.addWorksheet(stateMatch.replaceAll(/:|\/|\*|\?\[|\]/img, ""));

    commoditySheet.getColumn('C').numFmt = CellFormat.ACCOUNTING;

    commoditySheet.getColumn('A').width = 8;
    commoditySheet.getColumn('B').width = 8;
    commoditySheet.getColumn('C').width = 15;

    for (const commodity of Object.keys(rowCustomerMap[stateMatch])) {
      let commodityNet = new Decimal(0);

      for (const customerId of Object.keys(rowCustomerMap[stateMatch][commodity])) {
        commodityNet = rowCustomerMap[stateMatch][commodity][customerId].reduce((acc, row) => {
          if (row["Net"]) {
            return acc.plus(new Decimal(row["Net"]));
          }
          return acc;
        }, commodityNet);
      }

      commoditySheet.addRow([stateMatch, commodity, commodityNet.toNumber() || 0]);
    }
  }

  return outputExcel;
}

function loadCogsData(workbook: XLSX.WorkBook) {
   let sheetName = workbook.SheetNames[0]!;
  if (!sheetName) throw new ValidationError("Failed to find sheet", {});

  let sheet = workbook.Sheets[sheetName];

  let sheetData: CogsAndSalesRow[] = XLSX.utils.sheet_to_json(sheet);
  sheetData = sheetData.map((row: CogsAndSalesRow) => {
    return Object.entries(row).reduce((acc: CogsAndSalesRow, [key, value]) => {
      (acc as any)[key.trim()] = value;
      return acc;
    }, {} as CogsAndSalesRow);
  });

  sheetData.sort((a, b) => {
    if (a.Description === b.Description) return 0;
    if (a.Description < b.Description) return -1;
    return 1;
  });

  const groups = sheetData.reduce((acc: GroupAcc, row: CogsAndSalesRow) => {
    const description = row.Description;

    if (!acc[description]) {
      acc[description] = {
        account_description: row["Account Description"]?.trim(),
        description,
        debit: new Decimal(0),
        credit: new Decimal(0),
        quantity: 0,
        uom: new Decimal(0),
      };
    }

    acc[description].debit = acc[description].debit.plus(
      new Decimal(row["Debit Amount"]! || 0),
    );
    acc[description].credit = acc[description].credit.plus(
      new Decimal(row["Credit Amount"]! || 0),
    );
    acc[description].quantity += row.Quantity! || 0;

    return acc;
  }, {} as GroupAcc);

  const rows = Object.entries(groups).map(([key, value]) => {
    if (value.account_description?.toLocaleLowerCase().includes("cogs")) {
      value.uom = value.debit.sub(value.credit);
    } else {
      value.uom = value.credit.sub(value.debit);
    }
    return value;
  });

  const grandTotal = rows.reduce(
    (acc, row) => {
      acc.quantity += row.quantity;
      acc.uom = acc.uom.plus(row.uom);
      acc.debit = acc.debit.plus(row.debit);
      acc.credit = acc.credit.plus(row.credit);

      return acc;
    },
    {
      account_description: "",
      description: "Grand Total",
      quantity: 0,
      uom: new Decimal(0),
      debit: new Decimal(0),
      credit: new Decimal(0),
    },
  );

  return {
    grandTotal,
    rows,
    groups,
  }
}

function processOldCogsReportHelper(workbook: XLSX.WorkBook) {
  let sheetName = workbook.SheetNames[0]!;
  if (!sheetName) throw new ValidationError("Failed to find sheet", {});

  let sheet = workbook.Sheets[sheetName];

  let sheetData: CogsAndSalesRow[] = XLSX.utils.sheet_to_json(sheet);
  sheetData = sheetData.map((row: CogsAndSalesRow) => {
    return Object.entries(row).reduce((acc: CogsAndSalesRow, [key, value]) => {
      (acc as any)[key.trim()] = value;
      return acc;
    }, {} as CogsAndSalesRow);
  });

  sheetData.sort((a, b) => {
    if (a.Description === b.Description) return 0;
    if (a.Description < b.Description) return -1;
    return 1;
  });

  const groups = sheetData.reduce((acc: GroupAcc, row: CogsAndSalesRow) => {
    const description = row.Description;

    if (!acc[description]) {
      acc[description] = {
        account_description: row["Account Description"]?.trim(),
        description,
        debit: new Decimal(0),
        credit: new Decimal(0),
        quantity: 0,
        uom: new Decimal(0),
      };
    }

    acc[description].debit = acc[description].debit.plus(
      new Decimal(row["Debit Amount"]! || 0),
    );
    acc[description].credit = acc[description].credit.plus(
      new Decimal(row["Credit Amount"]! || 0),
    );
    acc[description].quantity += row.Quantity! || 0;

    return acc;
  }, {} as GroupAcc);

  const rows = Object.entries(groups).map(([key, value]) => {
    if (value.account_description?.toLocaleLowerCase().includes("cogs")) {
      value.uom = value.debit.sub(value.credit);
    } else {
      value.uom = value.credit.sub(value.debit);
    }
    return value;
  });

  const grandTotal = rows.reduce(
    (acc, row) => {
      acc.quantity += row.quantity;
      acc.uom = acc.uom.plus(row.uom);
      acc.debit = acc.debit.plus(row.debit);
      acc.credit = acc.credit.plus(row.credit);

      return acc;
    },
    {
      account_description: "",
      description: "Grand Total",
      quantity: 0,
      uom: new Decimal(0),
      debit: new Decimal(0),
      credit: new Decimal(0),
    },
  );

  const wb = XLSX.utils.book_new();
  const csvSheet = XLSX.utils.aoa_to_sheet(
    [
      {
        account_description: "Account Description",
        description: "Description",
        quantity: "Quantity",
        uom: "Uom",
        credit: "Credit Amount",
        debit: "Debit Amount",
      },
      ...rows,
      {
        account_description: "",
        description: "",
        quantity: "",
        uom: "",
        credit: "",
        debit: "",
      },
      grandTotal,
    ].map((value) => [
      value.description,
      value.quantity,
      outputMaybeDecimal(value.uom),
      outputMaybeDecimal(value.debit),
      outputMaybeDecimal(value.credit),
    ]),
  );
  XLSX.utils.book_append_sheet(wb, csvSheet, "Sheet1");

  return { cogsOutputWorkbook: undefined, unsettledOutputWorkbook: undefined, error: undefined};
}

function outputMaybeDecimal(item: string | Decimal | undefined | number) {
  if (typeof item === "number") return item;
  if (!item) return "";
  if (typeof item === "string") return item;

  return item.toNumber();
}

function getCustomerCountryFromCurrency({ currency: originalCurrency, customerCountry: originalCountry }: { currency: string, customerCountry: string }): { match: "US" | "CANADA" | 'Unknown Currency' | 'Unknown Country'| undefined; isValid: boolean} {
  const currency = originalCurrency?.replace("$", "")?.trim()?.toLocaleUpperCase();
  const customerCountry = originalCountry?.trim()?.toLocaleLowerCase();

  if (!currency) return { match: 'Unknown Currency', isValid: true };
  if (!customerCountry) return { match: 'Unknown Country', isValid: true };

  if (currency === 'US' && convertCustomerCountryToUnified(customerCountry) === 'US') {
    return { match: 'US', isValid: true };
  }
  if (['CAD', "CAN"].includes(currency) && convertCustomerCountryToUnified(customerCountry) === 'CANADA') {
    return { match: 'CANADA', isValid: true };
  }

  return { match: undefined, isValid: false };
}

function convertCustomerCountryToUnified(country?: string): 'US' | 'CANADA' | undefined {
  const us_options = ["united states", 'usa', 'united state of america', 'us of a']
  const canada_options = ["canada", "cad"]

  if (!country) return undefined;

  if (country && us_options.includes(country?.trim()?.toLocaleLowerCase())) {
    return "US";
  }

  if (country && canada_options.includes(country?.trim()?.toLocaleLowerCase())) {
    return "CANADA";
  }

  return undefined
}

function loadCogsSeparatedData({ cogsWorkbooks, customers }: { cogsWorkbooks: XLSX.WorkBook[]; customers: Customer[] }) {
  let sheetData: CogsAndSalesRow[] = aggregateSheetData<CogsAndSalesRow>(cogsWorkbooks);
  sheetData.sort((a, b) => {
    if (a.Description === b.Description) return 0;
    if (a.Description < b.Description) return -1;
    return 1;
  });

  const groupMatches = sheetData.reduce((acc: any, row: CogsAndSalesRow) => {
    const description = row.Description;
      const customer = findCustomerFrom(
        "CogsCustomerData",
        row.Description,
        customers,
      );

      if (!customer && ["unsettled", "adjustment", "fx"].some(ignore => row.Description?.toLocaleLowerCase().includes(ignore))) {
        return acc;
      }

    const stateMatch = customer?.meta_data?.state || customer?.state || row.Description;
    const commodityMatch = findCogsCommodity(row["Account Description"]?.trim()) || 'Unknown Commodity';

    if (!acc[stateMatch]) { acc[stateMatch] = {}; }
    if (!acc[stateMatch][commodityMatch]) { acc[stateMatch][commodityMatch] = {
        quantity: 0,
        debit: new Decimal(0),
        credit: new Decimal(0),
        uom: new Decimal(0),
    }; }

    const debit = new Decimal(row["Debit Amount"]! || 0);
    const credit = new Decimal(row["Credit Amount"]! || 0);
    acc[stateMatch][commodityMatch].debit = acc[stateMatch][commodityMatch].debit.plus(debit);
    acc[stateMatch][commodityMatch].credit = acc[stateMatch][commodityMatch].credit.plus(credit);
    acc[stateMatch][commodityMatch].quantity += row.Quantity! || 0;

    if (row["Account Description"]?.toLocaleLowerCase().includes("cogs")) {
      acc[stateMatch][commodityMatch].uom = acc[stateMatch][commodityMatch].uom.plus(debit.sub(credit));
    } else {
      acc[stateMatch][commodityMatch].uom = acc[stateMatch][commodityMatch].uom.plus(credit.sub(debit));
    }

    return acc;
  }, {} as GroupAcc);

  const workbook = new ExcelJS.Workbook();

  for (const stateMatch of Object.keys(groupMatches)) {
    const sheet = workbook.addWorksheet(stateMatch.replaceAll(/:|\/|\*|\?\[|\]/img, ""));
    sheet.getColumn('C').numFmt = CellFormat.ACCOUNTING;

    sheet.getColumn('A').width = 8;
    sheet.getColumn('B').width = 8;
    sheet.getColumn('C').width = 15;

    for (const commodityMatch of Object.keys(groupMatches[stateMatch])) {
      sheet.addRow([stateMatch, commodityMatch, groupMatches[stateMatch][commodityMatch]?.uom?.toNumber() || 0]);
    }
  }


  return workbook;
}

function findCogsCommodity(accountDescription?: string) {
  if (!accountDescription) return undefined;
  const description = accountDescription.replace(/COGS/i, "")?.trim();

  const potentialResult = Object.entries(PotentialCommodityMappings).find(([key, value]) => {
    return description?.toLocaleLowerCase() === key?.toLocaleLowerCase();
  });

  if (potentialResult) return potentialResult[1];

  const commodityFromLabel = CommodityOptions.find(({ label, value }) => {
    return description?.toLocaleLowerCase().includes(label?.toLocaleLowerCase());
  });

  if (commodityFromLabel) return commodityFromLabel?.value


  return undefined;
}
