import { Node, mergeAttributes, nodeInputRule } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import LoomEmbedComponent from './LoomEmbedComponent'; // We'll create this React component next

export interface LoomOptions {
  HTMLAttributes: Record<string, any>,
}

// Regex to capture Loom video ID from share or embed URLs
// Example: https://www.loom.com/share/d5f3c4a6b1c54d29a4f7b6a8c1b4e9a5
// Example: https://www.loom.com/embed/d5f3c4a6b1c54d29a4f7b6a8c1b4e9a5
export const LOOM_REGEX = /https?:\/\/(?:www\.)?loom\.com\/(?:share|embed)\/([a-f0-9]+)/

const LoomNode = Node.create<LoomOptions>({
  name: 'loom',
  group: 'block',
  atom: true, // Makes the node behave as a single, indivisible unit

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'loom-embed-container', // Optional: class for styling wrapper
      },
    }
  },

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: element => element.querySelector('iframe')?.getAttribute('src'),
        renderHTML: attributes => {
          if (!attributes.src) {
            return {}
          }
          // Ensure it's the embed URL format
          const match = attributes.src.match(LOOM_REGEX);
          const videoId = match ? match[1] : null;
          if (!videoId) return {};

          return {
             'data-loom-src': `https://www.loom.com/embed/${videoId}` // Store original src if needed, iframe src set by component
          }
        },
      },
      videoId: {
          default: null,
           parseHTML: element => {
              const src = element.querySelector('iframe')?.getAttribute('src');
              if (src) {
                  const match = src.match(LOOM_REGEX);
                  return match ? match[1] : null;
              }
              return null;
           },
           // videoId is derived from src, not directly rendered in outer element
      }
    }
  },

  parseHTML() {
    return [
      {
        // Match existing iframes with loom embed source
        tag: `div[data-loom-embed] iframe[src*="loom.com/embed/"]`,
      },
       {
        // Or match a wrapper div we might create
        tag: `div[data-loom-src]`,
      }
    ]
  },

  renderHTML({ HTMLAttributes, node }) {
     // This HTML is mainly for serialization (saving).
     // The actual editor rendering is handled by the React component.
     const videoId = node.attrs.videoId;
     if (!videoId) return ['div', { class: 'loom-embed-placeholder' }]; // Handle error state

     // Create a wrapper div that the React component will replace in the editor view
     return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {'data-loom-src': `https://www.loom.com/embed/${videoId}`}), 0]
  },

  // Use ReactNodeViewRenderer to render the node with a React component
  addNodeView() {
    return ReactNodeViewRenderer(LoomEmbedComponent)
  },

  addCommands() {
    return {
      setLoomVideo: (options: { src: string }) => ({ commands }) => {
        const match = options.src.match(LOOM_REGEX);
        const videoId = match ? match[1] : null;

        if (!videoId) {
          console.warn("Invalid Loom URL provided:", options.src);
          // Optionally show a toast or error message to the user here
          return false;
        }

        const embedSrc = `https://www.loom.com/embed/${videoId}`;

        return commands.insertContent({
          type: this.name,
          attrs: { src: embedSrc, videoId: videoId }, // Store both original (embed) src and extracted ID
        })
      },
    }
  },

   // Add input rule to automatically convert pasted URLs
  addInputRules() {
    return [
      nodeInputRule({
        find: LOOM_REGEX,
        type: this.type,
        getAttributes: match => {
          const videoId = match[1];
          const embedSrc = `https://www.loom.com/embed/${videoId}`;
          return { src: embedSrc, videoId: videoId }
        },
      }),
    ]
  },
})

export default LoomNode;

// Declare command types for editor type safety (optional but good practice)
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    loom: {
      /**
       * Insert a Loom video embed
       */
      setLoomVideo: (options: { src: string }) => ReturnType,
    }
  }
}
