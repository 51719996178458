import { useStytchMember } from "@stytch/react/b2b";
import { useState } from "react";
import { doesUserHavePackages, PossiblePackage } from "lhc_permissions";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Textarea } from "../../components/ui/textarea";
import { Label } from "../../components/ui/label";
import { Loader } from "../../components/ui/loader";
import { Input } from "../../components/ui/input";
import { ContractQuantitySection } from "../reports/ContractQuantityAlert";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { SelectInput } from "../../components/ui/SelectInput";
import { Skeleton } from "../../components/ui/skeleton";
import { useToast } from "../../components/ui/use-toast";
import { ContractQuantityAlertRow, ExceedRecordStatus, useContractQuantityAlertsInReview, useContractQuantityAlertSummary, useUpdateQuantityAlertStatus } from "@/lib/api/alerts";
import { cn, GenerateLoadingOrError } from "@/lib/utils";
import { InputFormType, InputRequiredStatus, InputType } from "@/lib/form";
import { PrimitiveManagedTable } from "@/lib/form/layout";
import { safeParseDecimal } from "@/lib/excel";

export const ContractQuantityListSchema = {
  id: { hidden: true, input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
  customer_name: { label: "Customer", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional, apiDisplayFormat: ({ original }: any) => { return original.customer.long_name; } },
  contract_no: { label: "Contract No", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.row.contract_no; } },
  commodity: { label: "Commodity", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.row.commodity; } },
  market_zone: { label: "Market Zone", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.row.market_zone; } },
  release: { label: "Release", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.row.release; } },
  quantity: { label: "Quantity", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return `${original.quantity || 0}`; } },
  remaining_balance: { label: "Remaining Balance", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return `${original.remaining_balance || 0}`; } },
  threshold: { label: "Threshold", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return `${original.threshold}% `; } }
};

export function ContractQuantityDashboard() {
  const [tabValue, setTabValue] = useState("review");
  return (
    <>
      <Tabs value={tabValue} onValueChange={setTabValue} className="w-full">
        <TabsList>
          <TabsTrigger value="upload">Upload</TabsTrigger>
          <TabsTrigger value="review">Review</TabsTrigger>
        </TabsList>
        <TabsContent value="review">
          <div className="mt-4">
            <InReviewSection />
          </div>
        </TabsContent>
        <TabsContent value="upload">
          <div className="mt-4">
            <ContractQuantitySection />
          </div>
        </TabsContent>
      </Tabs>
    </>
  );
}

export function InReviewSection({ }: {}) {
  return (
    <>
      <h2 className="scroll-m-20 text-xl font-extrabold tracking-tight lg:text-2xl my-4">Alerts In Review</h2>
      <SummarySection />
      <ListAndUpdateModal />
    </>
  );
}

function ListAndUpdateModal() {
  const listRes = useContractQuantityAlertsInReview();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [actionItem, setActionItem] = useState<any>(undefined);

  const loadingOrErrorUi = GenerateLoadingOrError([listRes]);


  if (loadingOrErrorUi) {
    return (
      loadingOrErrorUi
    );
  }


  const in_review_list = listRes.data!.data || [];

  return (
    <>
      <ActionDialog
        actionItem={actionItem}
        isOpen={isDialogOpen}
        onDialogChange={setIsDialogOpen}
      />
      <PrimitiveManagedTable
        schema={ContractQuantityListSchema}
        values={in_review_list}
        onRowClick={(value) => {
          setActionItem(value);
          setIsDialogOpen(true);
        }}
      />
    </>
  );
}

function SummarySection() {
  const summaryRes = useContractQuantityAlertSummary();
  const loadingOrErrorUi = GenerateLoadingOrError([summaryRes]);

  if (loadingOrErrorUi) {
    return (
      loadingOrErrorUi
    );
  }

  const summaryCounts = summaryRes.data?.data || [];
  const findCountFor = (status: ExceedRecordStatus) => (summaryCounts.find((wrapper: any) => wrapper.status === status)?.count || 0);
  return (
    <div className="flex flex-col flex-wrap gap-4 mb-4 justify-center items-start">
      <h3 className="flex font-bold text-lg sm:text-md">Todays Summary</h3>
      <span className="flex flex-wrap gap-4 justify-start items-center">
        <div className="flex items-center gap-2 px-4 py-2 bg-gray-100 rounded-md">
          <span className="font-medium">Sent:</span>
          <span>{findCountFor(ExceedRecordStatus.NormalSend)}</span>
        </div>
        <div className="flex items-center gap-2 px-4 py-2 bg-gray-100 rounded-md">
          <span className="font-medium">Forced Sent:</span>
          <span>{findCountFor(ExceedRecordStatus.ForceSend)}</span>
        </div>
        <div className="flex items-center gap-2 px-4 py-2 bg-gray-100 rounded-md">
          <span className="font-medium">Ignored:</span>
          <span>{findCountFor(ExceedRecordStatus.Ignored)}</span>
        </div>
        <div className="flex items-center gap-2 px-4 py-2 bg-gray-100 rounded-md">
          <span className="font-medium">In Review:</span>
          <span>{findCountFor(ExceedRecordStatus.InReview)}</span>
        </div>
      </span>
    </div>
  );
}

function ActionDialog({
  isOpen,
  onDialogChange,
  className,
  actionItem,
}: {
  actionItem: ContractQuantityAlertRow;
  isOpen: boolean;
  className?: string;
  onDialogChange: (value: boolean) => void;
}) {
  return (
    <Dialog
      onOpenChange={(value: boolean) => {
        if (onDialogChange) {
          onDialogChange(value);
        }
      }}
      open={isOpen || false}
      defaultOpen={false}
    >
      <DialogContent className={cn("max-w-full sm:max-w-lg lg:max-w-3xl xl:max-w-7xl flex flex-col flex-start", className)}>
        <DialogHeader>
          <DialogTitle>
            Send or Ignore Alert
          </DialogTitle>
        </DialogHeader>
        <ActionForm actionItem={actionItem} onDialogChange={onDialogChange} />
      </DialogContent>
    </Dialog>
  );
}

function ActionForm({
  actionItem,
  onDialogChange,
}: {
  actionItem: ContractQuantityAlertRow;
  onDialogChange: (value: boolean) => void;
}) {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const updateStatus = useUpdateQuantityAlertStatus();

  const onMarkAsIgnored = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const result = await updateStatus({
        id: actionItem.id,
        status: ExceedRecordStatus.Ignored
      });

      if (!result.success) {
        throw result;
      } else {
        toast({
          title: "Marked as Ignored",
          description: "",
        });
        onDialogChange(false);
      }

    } catch (error) {
      console.error("Failed to ignore contract quantity alert", error, actionItem)
      toast({
        title: "Failed to ignore",
        description: "Please try again",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onForceSend = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const result = await updateStatus({
        id: actionItem.id,
        status: ExceedRecordStatus.ForceSend
      });

      if (!result.success) {
        throw result;
      } else {
        toast({
          title: "Sending Now",
          description: "",
        });
        onDialogChange(false);
      }

    } catch (error) {
      console.error("Failed to force send contract quantity alert", error, actionItem)
      toast({
        title: "Failed to Send",
        description: "Please try again",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <RowSummary item={actionItem} />
      <div className="flex flex-row justify-end items-center ">
        <Button className="" disabled={isLoading} onClick={onMarkAsIgnored}>
          Ignore
        </Button>
        <span className="mx-4 font-bold">or</span>
        <Button className="" disabled={isLoading} onClick={onForceSend}>
          Force Send
        </Button>
      </div>
    </div>
  );
}

function RowSummary({ item }: { item: ContractQuantityAlertRow }) {
  return (
    <div className="bg-gray-50 rounded-lg p-4 mb-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <span className="text-sm text-gray-500">Customer</span>
          <div className="font-medium">{item.customer.long_name}</div>
        </div>
        <div>
          <span className="text-sm text-gray-500">Contract No</span>
          <div className="font-medium">{item.row.contract_no}</div>
        </div>
        <div>
          <span className="text-sm text-gray-500">Commodity</span>
          <div className="font-medium">{item.row.commodity}</div>
        </div>
        <div>
          <span className="text-sm text-gray-500">Market Zone</span>
          <div className="font-medium">{item.row.market_zone}</div>
        </div>
        <div>
          <span className="text-sm text-gray-500">Release</span>
          <div className="font-medium">{item.row.release}</div>
        </div>
        <div>
          <span className="text-sm text-gray-500">Threshold</span>
          <div className="font-medium">{safeParseDecimal(item.threshold).toFixed()}%</div>
        </div>
      </div>
    </div>
  );
}
