import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { SelectOptionItem } from "../../lib/models/ui";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { ScrollArea } from "./scroll-area";

export function Combobox<T extends string>({
  id,
  placeholder,
  className,
  options,
  value,
  onValueChange,
  disabled,
  isModal,
  tabIndex,
  popoverClassName,
  virtualizeList
}: {
  placeholder?: string;
  id?: string;
  disabled?: boolean;
  options: SelectOptionItem<T>[];
  value: string;
  onValueChange: (value: string) => void;
  className?: string;
  isModal?: boolean;
  tabIndex?: number;
  popoverClassName?: string
  virtualizeList?: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const parentRef = React.useRef<HTMLDivElement>(null);

  const filteredOptions = React.useMemo(() => {
    return options.filter((option) => {
      if (!search?.trim()) return true;
      return (
        option.label
          ?.toLocaleLowerCase()
          ?.trim()
          ?.includes(search?.toLocaleLowerCase()?.trim()) || false
      );
    });
  }, [options, search])

  const virtualizer = useVirtualizer({
    count: filteredOptions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35, // Approximate height of each item
    overscan: 10,
  });

  // Force recalculate when dropdown opens
  React.useEffect(() => {
    if (open && virtualizer) {
      // Small delay to ensure the DOM is ready
      const timer = setTimeout(() => {
        virtualizer.measure();
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [open, virtualizer]);

  return (
    <Popover open={open} onOpenChange={setOpen} modal={isModal}>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          tabIndex={tabIndex}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-[200px] justify-between", className)}
        >
          {value
            ? options.find((item) =>
              [
                item.value?.toLocaleLowerCase(),
                item.label?.toLocaleLowerCase(),
              ].includes(value?.toLocaleLowerCase()),
            )?.label
            : placeholder || ""}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn("w-[200px] max-h-52 p-0 overflow-y-scroll", popoverClassName)}>
        <Command shouldFilter={false}>
          <CommandInput
            placeholder=""
            value={search}
            onValueChange={setSearch}
          />
          {filteredOptions.length === 0 ? (
            <CommandEmpty>No results found.</CommandEmpty>
          ) : (
            virtualizeList ? (<>
              <div
                ref={parentRef}
                className="overflow-auto overflow-y-scroll"
                style={{ height: "200px" }}
              >
                <div
                  style={{
                    height: `${virtualizer.getTotalSize()}px`,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  {virtualizer.getVirtualItems().map((virtualRow) => {
                    const option = filteredOptions[virtualRow.index];
                    return (
                      <CommandItem
                        key={option.value}
                        value={option.value}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: `${virtualRow.size}px`,
                          transform: `translateY(${virtualRow.start}px)`,
                        }}
                        onSelect={(currentValue) => {
                          onValueChange(currentValue === value ? "" : currentValue);
                          setOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            value === option.value ? "opacity-100" : "opacity-0",
                          )}
                        />
                        {option.label}
                      </CommandItem>
                    );
                  })}
                </div>
              </div>
            </>) : (
              <CommandGroup>
                {options
                  .filter((option) => {
                    if (!search?.trim()) return true;
                    return (
                      option.label
                        ?.toLocaleLowerCase()
                        ?.trim()
                        ?.includes(search?.toLocaleLowerCase()?.trim()) || false
                    );
                  })
                  .map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onSelect={(currentValue) => {
                        onValueChange(currentValue === value ? "" : currentValue);
                        setOpen(false);
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          value === option.value ? "opacity-100" : "opacity-0",
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
              </CommandGroup>
            )

          )}
        </Command>

      </PopoverContent>
    </Popover>
  );
}
