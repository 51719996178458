import { useState, useEffect } from "react";
import { useStytchMember } from "@stytch/react/b2b";
import { useNavigate } from "react-router-dom";
import { useGetCreateJobStatus, useCreatePreliminaryUpload } from "../../lib/api/dorman";
import { FormGridHelper } from "../reports/helper";
import {
  InputRequiredStatus,
  InputFormType,
  InputType,
  ImportType,
  ExportType,
  InputFilter,
  InputRequiredStatusType,
} from "../../lib/form";
import * as FormHelper from "../../lib/form";
import { downloadFromS3, UploadPresignedFile } from "../../lib/file";
import * as FormLayout from "../../lib/form/layout";
import {
  PrimitiveGridLayout,
  PrimitiveManagedTable,
  PrimitiveDialogForm,
} from "../../lib/form/layout";
import { SuccessResult, ServerError, ValidationError } from "../../lib/responseResults";
import { useToast } from "../../components/ui/use-toast";
import { Loader } from "../../components/ui/loader";
import { doesUserHavePackages, PossiblePackage } from "lhc_permissions";
import { useGenerateInventoryMapDownloadLink, useCreateInventoryMapUpload } from "../../lib/api/inventoryMap";

export const CreateJobFormSchema = {
  inventory_map_file: { label: "Inventory Map Excel", fileExtensions: [".xlsx", ".xls"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Required },
};

export function InventoryMapSection() {
  const { member } = useStytchMember();
  const isAvailable = doesUserHavePackages({
    requiredPackages: [PossiblePackage.InventoryMap],
    member,
  });
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const jobStatusRes = useGetCreateJobStatus(isAvailable ? jobId : undefined);

  if (!isAvailable) {
    return null;
  }

  const hasFetchedJob = !!jobStatusRes?.data;
  const jobStatus = jobStatusRes?.data?.data?.job_status;


  return (
    <div className="container">
      <div className="flex flex-col">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl mb-4">
          Inventory Map Upload
        </h1>
        <InventoryMapCreateJobForm setJobId={setJobId} />
        <div className="mt-8 flex flex-col">
          {!!hasFetchedJob && !!jobStatus && (
            <DownloadLinkJobStatus job_id={jobId!} jobStatus={jobStatus} linkKey="link" setJobId={setJobId} />
          )}
        </div>
      </div>
    </div>
  );
}

function DownloadLinkJobStatus({ job_id, jobStatus, linkKey, setJobId }: { job_id: string; jobStatus: string; linkKey: string; setJobId: any }) {
  const [downloadLink, setDownloadLink] = useState<string | undefined>(undefined);
  const generateDownloadLink = useGenerateInventoryMapDownloadLink();
  const { toast } = useToast();
  const className = "text-lg font-bold tracking-tight mb-4";

  useEffect(() => {
    if (downloadLink) {
      toast({
        title: "Downloading",
        description: ""
      })
      downloadFromS3(downloadLink!, "new_inventory_map_files.zip")
      setDownloadLink("");
    }
  }, [downloadLink]);

  useEffect(() => {
    if (job_id && jobStatus && jobStatus === "completed") {
      generateDownloadLink({ job_id }).then((res: any) => {
        if (!res.success || !res.data.link) {
          toast({
            title: "Failed to download file",
            description: "Please refresh or try again",
          });
        } else {
          setDownloadLink(res.data.link);
        }
      });
    }
  }, [jobStatus, job_id])

  if (typeof downloadLink === "string") {
    return <div />;
  }

  if (!jobStatus) { return (<div className={className}><Loader /></div>) }
  if (jobStatus === "queued") { return (<div className={className}>Processing</div>) }
  if (jobStatus === "doesnt_exist") { return (<div className={className}>Failed to find files</div>) }
  if (jobStatus === "failed") { return (<div className={className}>Failed to process</div>) }
  if (jobStatus === "halt") { return (<div className={className}>Failed to process</div>) }
  if (jobStatus !== "completed") { return (<div className={className}>Unknown</div>); }

  return (<div className={className}>Downloading</div>);
}


const createJobState = FormHelper.generateBaseState({
  schemas: [CreateJobFormSchema],
  baseSchema: CreateJobFormSchema,
});

function InventoryMapCreateJobForm({ setJobId }: { setJobId: any }) {
  const inputState: any = createJobState.useInputsState((state: any) => state);
  const submissionState: any = createJobState.useSubmissionState((state: any) => state);
  const onCreateJob = useCreateInventoryMapUpload();
  const { toast } = useToast();

  return FormLayout.RenderFullPrimitiveGridLayout({
    hideSkipButton: true,
    id: "CreateInventoryMapUploadJob",
    activeSchema: CreateJobFormSchema,
    totalFields: inputState.totalFields,
    formValues: inputState.formValues,
    updateFormValue: inputState.updateFormValue,
    formRelationships: inputState.formRelationships,
    updateFormRelationship: inputState.updateFormRelationship,
    submissionState,
    externalReferences: {},
    onSubmitTo: async (item: any) => {
      if (item.inventory_map_file && ![".xlsx", ".xls"].some((extension: string) => item.inventory_map_file?.name?.toLocaleLowerCase()?.endsWith(extension))) {
        toast({
          title: "Invalid Inventory Map File",
          description: "Must be an Excel file",
        });
        return new ValidationError("", {});
      }

      const createBody = {
        filename: item.inventory_map_file?.name || "",
        mimeType: item.inventory_map_file?.type || "",
      };

      const createResult = await onCreateJob(createBody);

      if (!createResult.success) {
        return createResult;
      }

      const resultData = createResult.data;
      setJobId(resultData.job_id);

      await UploadPresignedFile(resultData.link, item.inventory_map_file);

      return createResult;
    },
    handleSubmitResponse: (result) => {
      if (result.statusCode === 400) {
        toast({
          title: "Failed to upload files",
          description: "",
        });
        return;
      } else if (!result.success) {
        toast({
          title: "Failed to upload files",
          description: "",
        });
      } else {
        toast({
          title: "Queued - Please wait.",
          description: "",
        });
      }
    }
  });
}
