import { create } from "zustand";
import { z } from "zod";
import moment from "moment-timezone";
import { useState } from "react";
import parsePhoneNumber, {
  AsYouType as PhoneNumberFormatterAsYouType,
} from "libphonenumber-js";
import {
  BaseCustomer,
  CreateCustomerValidation,
  useCreateCustomer,
  useListCustomers,
  useUpdateCustomer,
} from "../../lib/api/customer";
import { DeferredPaymentTypeOptions } from "@/lib/api/deferred_payments";
import { downloadCsvTextAsFile } from "../../lib/browser/download";
import { Skeleton } from "../../components/ui/skeleton";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { useToast } from "../../components/ui/use-toast";
import { Checkbox } from "../../components/ui/checkbox";
import {
  PrimitiveGridLayout,
  PrimitiveManagedTable,
  PrimitiveDialogForm,
} from "../../lib/form/layout";
import { isValidEmail } from "../../lib/prelude";
import { findCustomerFrom } from "../../lib/customer";
import { Customer } from "@/lib/api/reports";
import { BaseFormSchema } from "@/lib/schemas/customer";
import { Combobox } from "../../components/ui/combobox";
import useDebounce from "../otc/useDebounce";

interface SourcePhoneNumber {
  source_type: "destination" | "mobile_user";
  label: string;
  phone_number: string;
}

interface SourceEmail {
  source_type: "payment" | "pdf_signature" | "payment_ach" | "payment_check";
  label: string;
  email: string;
}

interface UpdateCustomerInfo {
  client_id?: string;
  short_name?: string;
  long_name?: string;

  is_commercial?: boolean;

  address_line_one?: string;
  address_line_two?: string;
  city?: string;
  zipcode?: string
  state?: string;
  country?: string;
  email?: string;
  phone_number?: string;

  preferred_defer_payment_type?: string;

  source_phone_numbers?: SourcePhoneNumber[];
  source_emails?: SourceEmail[];
}

interface BearState {
  exception_id?: string;
  exception_type?: string;
  updateCustomerId?: string;
  updateCustomer: UpdateCustomerInfo;

  setCustomerId: (id: string) => void;
  setCustomer: (customer: BaseCustomer) => void;
  updateCustomerField: (key: string, value: any) => void;
  clearCustomer: () => void;
}

export const useBearStore = create<BearState>((set) => ({
  updateCustomerId: undefined,
  updateCustomer: {
    is_commercial: false,
    exception_id: undefined,
    exception_type: undefined,
  },

  setCustomerId: (customerId: string) =>
    set((state) => ({ updateCustomerId: customerId })),
  clearCustomer: () =>
    set((state) => ({
      updateCustomer: { is_commercial: false },
      updateCustomerId: undefined,
    })),
  setCustomer: (customer: BaseCustomer) =>
    set((state) => ({
      updateCustomerId: customer.id,
      updateCustomer: customer as any,
    })),
  updateCustomerField: (key: string, value: string) =>
    set((state) => ({
      ...state,
      updateCustomer: {
        ...state.updateCustomer,
        [key]: value,
      },
    })),
}));

export const ManageCustomersPage = () => {
  const customersResult = useListCustomers();
  const [isModalOpen, setModalOpenStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearch = useDebounce(searchQuery, 300);
  const clearCustomer = useBearStore((state) => state.clearCustomer);
  const setCustomer = useBearStore((state) => state.setCustomer);

  if ([customersResult].some((result) => result.isLoading)) {
    return (
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  const onUpdateCreateModalStatus = (status: boolean) => {
    setModalOpenStatus(status);

    if (!status) {
      clearCustomer();
    }
  };

  const onSetupUpdateCustomer = (customer: BaseCustomer) => {
    setCustomer(customer);
    setModalOpenStatus(true);
  };

  const customerList = customersResult?.data?.data || [];
  const filteredCustomers = debouncedSearch
    ? customerList?.filter((customer: Customer) => {
      const matchedCustomer = findCustomerFrom("", debouncedSearch, [
        customer,
      ]);
      return !!matchedCustomer;
    }) || []
    : customerList;

  return (
    <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
      <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
        Manage Customers
      </h1>
      <div className="flex flex-col-reverse gap-4 sm:flex-row sm:justify-between sm:items-center my-4">
        <Input
          placeholder="Search customers..."
          className="max-w-xs"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="flex flex-col sm:flex-row gap-2">
          <DownloadCustomerButton customerList={customerList} />
          <CustomerModal
            isModalOpen={isModalOpen}
            onUpdateCreateModalStatus={onUpdateCreateModalStatus}
          />
        </div>
      </div>
      <PrimitiveManagedTable
        schema={BaseFormSchema}
        values={filteredCustomers}
        onRowClick={(item: any) => {
          onSetupUpdateCustomer(item);
        }}
      />
    </section>
  );
};

async function writeCsv(customerList: BaseCustomer[], filename: string) {
  const headers = [
    "Id",
    "Short Name",
    "Long Name",
    "State",
    "Country",
    "Email",
    "Is Commercial",
  ];

  return {
    success: true,
    data: [
      headers.join(","),
      ...customerList.map((customer) => {
        return [
          customer.client_id,
          customer.short_name,
          customer.long_name,
          customer.state,
          customer.country,
          customer.email,
          `${customer.is_commercial}`,
        ]
          .map((item) => item || "")
          .map((item) => (item.includes(",") ? `"${item}"` : item))
          .join(",");
      }),
    ].join("\n"),
  };
}

function DownloadCustomerButton({
  customerList,
}: {
  customerList: BaseCustomer[];
}) {
  const { toast } = useToast();
  const onDownload = async (filename: string) => {
    const result = await writeCsv(customerList, filename);

    if (!result.success) {
      toast({
        title: "Failed to download customer list",
        description: "",
      });
    } else {
      toast({
        title: "Downloaded",
        description: "",
      });
      downloadCsvTextAsFile(filename, result.data);
    }
  };

  const fetchDate = moment().format("MM_DD_YY");

  return (
    <Button
      type="button"
      variant="outline"
      className="mr-4"
      disabled={false}
      onClick={() => onDownload(`customer_list_${fetchDate}.csv`)}
    >
      Download Customer List
    </Button>
  );
}

export function CustomerModal({
  isModalOpen,
  onUpdateCreateModalStatus,
}: {
  isModalOpen: boolean;
  onUpdateCreateModalStatus: (item: boolean) => void;
}) {
  const { toast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const updateCustomerId = useBearStore((state) => state.updateCustomerId);
  const createCustomer = useCreateCustomer();
  const updateCustomer = useUpdateCustomer();

  const isUpdateForm = !!updateCustomerId;

  const onSubmit = async (formData: UpdateCustomerInfo) => {
    if (isSaving) return false;
    if (formData.email && !isValidEmail(formData.email)) {
      toast({
        title: "Invalid email",
        description: "",
      });
      return false;
    }

    const parsedResult = CreateCustomerValidation.safeParse(formData);
    if (!parsedResult.success) {
      toast({
        title: "Invalid customer data",
        description: "",
      });
      return false;
    }

    setIsSaving(true);

    const saveResult = await (!!isUpdateForm
      ? updateCustomer(updateCustomerId, parsedResult.data)
      : createCustomer(parsedResult.data));

    setIsSaving(false);

    if (!saveResult.success) {
      toast({
        title: isUpdateForm
          ? "Failed to update customer"
          : "Failed to create customer",
        description: "",
      });
    } else {
      onUpdateCreateModalStatus(false);
      toast({
        title: isUpdateForm ? "Updated" : "Created",
        description: "",
      });
    }

    return true;
  };

  return (
    <Dialog
      onOpenChange={onUpdateCreateModalStatus}
      open={isModalOpen}
      defaultOpen={false}
    >
      <DialogTrigger asChild>
        <Button
          type="button"
          className=""
          disabled={isModalOpen}
          onClick={() => onUpdateCreateModalStatus(true)}
        >
          Create Customer
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-lg md:max-w-2xl lg:max-w-5xl">
        <DialogHeader>
          <DialogTitle>
            {isUpdateForm ? "Update Customer" : "New Customer"}
          </DialogTitle>
        </DialogHeader>
        <CustomerForm onSubmit={onSubmit} />
        <DialogFooter className="sm:justify-start"></DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function CustomerForm({
  allowUpdatingClientId = false,
  onSubmit,
}: {
  allowUpdatingClientId?: boolean;
  onSubmit: (formData: UpdateCustomerInfo) => Promise<boolean>;
}) {
  const [sourceEmailAddType, setSourceEmailAddType] = useState("payment_ach");
  const [sourcePhoneNumberAddType, setSourcePhoneNumberAddType] =
    useState("destination");

  const updateCustomerId = useBearStore((state) => state.updateCustomerId);
  const customer = useBearStore((state) => state.updateCustomer);
  const updateCustomerField = useBearStore(
    (state) => state.updateCustomerField,
  );

  const isUpdateForm = !!updateCustomerId;

  return (
    <form
      onSubmit={(e) => {
        if (e?.preventDefault) e.preventDefault();
        return onSubmit(customer);
      }}
    >
      <div className="flex flex-col gap-4 md:grid md:grid-cols-2 lg:grid-cols-3">
        <div className="grid grid-cols-1 items-start gap-1 lg:col-span-1">
          <div className="grid grid-cols-2 gap-2">
            <div className="grid items-center gap-1.5">
              <Label htmlFor="customerClientId">Id</Label>
              <Input
                required
                disabled={isUpdateForm && !allowUpdatingClientId}
                id="customerClientId"
                value={customer.client_id}
                onChange={(event) =>
                  updateCustomerField("client_id", event.target.value)
                }
              />
            </div>

            <div className="grid items-center gap-1.5">
              <Label htmlFor="short_name">Short Name</Label>
              <Input
                required
                id="short_name"
                value={customer.short_name}
                onChange={(event) =>
                  updateCustomerField("short_name", event.target.value)
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-2">
            <div className="grid items-center gap-1.5">
              <Label htmlFor="long_name">Long Name</Label>
              <Input
                required
                id="long_name"
                value={customer.long_name}
                onChange={(event) =>
                  updateCustomerField("long_name", event.target.value)
                }
              />
            </div>

            <div className="grid items-center gap-1.5">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={customer.email}
                onChange={(event) =>
                  updateCustomerField("email", event.target.value)
                }
              />
            </div>
            <div className="grid items-center gap-1.5">
              <Label htmlFor="phone_number">Phone Number</Label>
              <Input
                id="phone_number"
                type="tel"
                value={
                  new PhoneNumberFormatterAsYouType("US").input(
                    customer.phone_number || "",
                  ) || ""
                }
                onChange={(event) =>
                  updateCustomerField("phone_number", event.target.value)
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="grid items-center gap-1.5">
              <Label htmlFor="is_commercial">Is Commercial</Label>
              <Checkbox
                id="is_commercial"
                checked={customer.is_commercial}
                onCheckedChange={(value) =>
                  updateCustomerField("is_commercial", value as any)
                }
              />
            </div>
            <div className="grid items-center gap-1.5" >
              <Label htmlFor="preferred_defer_payment_type">Payment Type</Label>
              <Combobox
                className=" w-[132px]"
                id="preferred_defer_payment_type"
                value={customer.preferred_defer_payment_type || ""}
                onValueChange={(value) =>
                  updateCustomerField("preferred_defer_payment_type", value as any)
                }
                options={DeferredPaymentTypeOptions}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 items-start lg:col-span-2">
          <div className="flex flex-col gap-2 lg:grid lg:grid-cols-4 lg:col-span-4">
            <div className="flex flex-col lg:col-span-4 gap-2">
              <div className="grid items-center gap-1.5">
                <Label htmlFor="address_line_one">Address Line One</Label>
                <Input
                  id="address_line_one"
                  value={customer.address_line_one}
                  onChange={(event) =>
                    updateCustomerField("address_line_one", event.target.value)
                  }
                />
              </div>
              <div className="grid items-center gap-1.5">
                <Label htmlFor="address_line_two">Address Line Two</Label>
                <Input
                  id="address_line_two"
                  value={customer.address_line_two}
                  onChange={(event) =>
                    updateCustomerField(
                      "address_line_two",
                      event.target.value,
                    )
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-4 gap-2 lg:col-span-4">
              <div className="grid items-center gap-1.5">
                <Label htmlFor="city">City</Label>
                <Input
                  id="city"
                  value={customer.city}
                  onChange={(event) =>
                    updateCustomerField("city", event.target.value)
                  }
                />
              </div>
              <div className="grid items-center gap-1.5">
                <Label htmlFor="zipcode">Zipcode</Label>
                <Input
                  id="zipcode"
                  value={customer.zipcode}
                  onChange={(event) =>
                    updateCustomerField("zipcode", event.target.value)
                  }
                />
              </div>
              <div className="grid items-center gap-1.5">
                <Label htmlFor="state">State</Label>
                <Input
                  id="state"
                  value={customer.state}
                  onChange={(event) =>
                    updateCustomerField(
                      "state",
                      event.target.value?.toLocaleUpperCase(),
                    )
                  }
                />
              </div>
         <div className="grid items-center gap-1.5">
              <Label htmlFor="country">Country</Label>
              <Input
                id="country"
                value={customer.country}
                onChange={(event) =>
                  updateCustomerField("country", event.target.value)
                }
              />
            </div>
            </div>
   

          </div>

          <div className="grid grid-cols-1 items-start gap-1.5 w-full">
            <div className="flex flex-row gap-2 justify-between items-center">
              <Label>Other Numbers</Label>
              <span>
                <Combobox
                  options={["destination", "mobile_user"].map((value) => ({
                    label: generatePhoneNumberSourceTypeLabel(value),
                    value,
                  }))}
                  value={sourcePhoneNumberAddType}
                  onValueChange={(value: string) => {
                    setSourcePhoneNumberAddType(value);
                  }}
                />
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  className="ml-2"
                  onClick={() => {
                    const newPhoneNumber = {
                      source_type: sourcePhoneNumberAddType,
                      label: "",
                      phone_number: "",
                    };
                    updateCustomerField("source_phone_numbers", [
                      newPhoneNumber,
                      ...(customer.source_phone_numbers || []),
                    ]);
                  }}
                >
                  +
                </Button>
              </span>
            </div>

            {(customer.source_phone_numbers || []).map((phone, index) => (
              <div
                key={index}
                className="flex flex-row gap-2 mt-2 items-center sm:grid sm:grid-cols-[minmax(auto,100px)_1fr_1fr_auto] sm:gap-2"
              >
                <span className="text-sm text-gray-500">
                  {generatePhoneNumberSourceTypeLabel(phone.source_type)}
                </span>
                <Input
                  placeholder="Phone Label"
                  value={phone.label}
                  onChange={(event) => {
                    const updated = [...(customer.source_phone_numbers || [])];
                    updated[index] = {
                      ...phone,
                      label: event.target.value || "",
                    };
                    updateCustomerField("source_phone_numbers", updated);
                  }}
                />
                <Input
                  type="tel"
                  placeholder="Phone Number"
                  value={
                    new PhoneNumberFormatterAsYouType("US").input(
                      phone.phone_number || "",
                    ) || ""
                  }
                  onChange={(event) => {
                    const updated = [...(customer.source_phone_numbers || [])];
                    updated[index] = {
                      ...phone,
                      phone_number: event.target.value || "",
                    };
                    updateCustomerField("source_phone_numbers", updated);
                  }}
                />
                <Button
                  type="button"
                  variant="destructive"
                  size="sm"
                  onClick={() => {
                    const updated = customer.source_phone_numbers?.filter(
                      (_, i) => i !== index,
                    );
                    updateCustomerField("source_phone_numbers", updated);
                  }}
                >
                  X
                </Button>
              </div>
            ))}
          </div>

          <div className="grid grid-cols-1 items-start gap-1.5 mt-4 w-full">
            <div className="flex flex-row gap-2 justify-between items-center">
              <Label>Other Emails</Label>
              <span>
                <Combobox
                  options={["payment_ach", "payment_check", "pdf_signature"].map((value) => ({
                    label: generateEmailSourceTypeLabel(value),
                    value,
                  }))}
                  value={sourceEmailAddType}
                  onValueChange={(value: string) => {
                    setSourceEmailAddType(value);
                  }}
                />
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  className="ml-2"
                  onClick={() => {
                    const newEmail = {
                      source_type: sourceEmailAddType,
                      label: "",
                      email: "",
                    };
                    updateCustomerField("source_emails", [
                      newEmail,
                      ...(customer.source_emails || []),
                    ]);
                  }}
                >
                  +
                </Button>
              </span>
            </div>

            {(customer.source_emails || []).map((email, index) => (
              <div
                key={index}
                className="flex flex-row gap-2 mt-2 items-center  sm:grid sm:grid-cols-[minmax(auto,100px)_1fr_1fr_auto] sm:gap-2"
              >
                <span className="text-sm text-gray-500">
                  {generateEmailSourceTypeLabel(email.source_type)}
                </span>
                <Input
                  placeholder="Email Label"
                  value={email.label}
                  onChange={(event) => {
                    const updated = [...(customer.source_emails || [])];
                    updated[index] = {
                      ...email,
                      label: event.target.value || "",
                    };
                    updateCustomerField("source_emails", updated);
                  }}
                />
                <Input
                  type="email"
                  placeholder="Email"
                  value={email.email}
                  onChange={(event) => {
                    const updated = [...(customer.source_emails || [])];
                    updated[index] = {
                      ...email,
                      email: event.target.value || "",
                    };
                    updateCustomerField("source_emails", updated);
                  }}
                />
                <Button
                  type="button"
                  variant="destructive"
                  size="sm"
                  onClick={() => {
                    const updated = customer.source_emails?.filter(
                      (_, i) => i !== index,
                    );
                    updateCustomerField("source_emails", updated);
                  }}
                >
                  X
                </Button>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-row justify-between gap-2 md:col-span-2 lg:col-span-3">
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              Exit
            </Button>
          </DialogClose>

          <Button type="submit" size="sm" className="px-3">
            {isUpdateForm ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </form>
  );
}

function generateEmailSourceTypeLabel(source_type: string) {
  switch (source_type) {
    case "payment":
      return "Payment";
    case "payment_ach":
      return "Payment ACH";
    case "payment_check":
      return "Payment Check";
    case "pdf_signature":
      return "PDF Signature";
    default:
      return "Unknown";
  }
}

function generatePhoneNumberSourceTypeLabel(source_type: string) {
  switch (source_type) {
    case "destination":
      return "Destination";
    case "mobile_user":
      return "Mobile";
    default:
      return "Unknown";
  }
}

export default ManageCustomersPage;
