import { useState, useEffect, useMemo, Fragment, useRef } from "react";
import { ChevronDown, ChevronRight, Filter, LinkIcon, X } from "lucide-react";
import moment from "moment-timezone";
import {
  GenerateLoadingOrError,
  CommonDestinationTimezone,
  cn
} from "@/lib/utils";
import {
  useSummariesPaginated,
  fetchSummariesPage,
  CostBasisQueryParamsValidation,
  useCostBasisFitlers,
  useCostBasisPaginated,
  fetchCostBasisPage
} from "@/lib/api/cost_basis";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import "@/styles/compactTable.css";
import { ColumnDef } from "@tanstack/react-table";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Combobox } from "@/components/ui/combobox";
import { DatePicker } from "@/components/ui/typeable_date_picker";
import { Badge } from "@/components/ui/badge";
import { Loader } from "@/components/ui/loader";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";

// Type for expanded state tracking
type ExpandedState = { [key: string]: boolean };

// Define the filter state interface
interface FilterState {
  providerId?: string;
  locationId?: string;
  search?: string;
  startDate?: string;
  endDate?: string;
}

export function CostBasisDashboard() {
  const [showSingleData, setShowSingleData] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [detailPage, setDetailPage] = useState(1);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [tabValue, setTabValue] = useState("changes");
  const [filters, setFilters] = useState<FilterState>({});
  const [tempFilters, setTempFilters] = useState<FilterState>({});
  const [selectedEntry, setSelectedEntry] = useState<{ provider_id: string; location_id: string; hash: string } | undefined>(undefined);
  const filtersApplied = useRef(false);
  const filtersRes = useCostBasisFitlers();
  const summariesRes = useSummariesPaginated({
    params: { showSingleData, providerId: filters.providerId, locationId: filters.locationId, page },
    queryFn: fetchSummariesPage
  });
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const detailedEntriesRes = useCostBasisPaginated({
    params: {
      pageSize: 5,
      page: detailPage,
      providerId: selectedEntry?.provider_id,
      locationId: selectedEntry?.location_id,
      hash: selectedEntry?.hash
    },
    queryFn: fetchCostBasisPage
  });

  useEffect(() => {
    if (filterDialogOpen) {
      setTempFilters({ ...filters });
    }
  }, [filterDialogOpen]);

  // Group summaries by provider
  const groupedByProvider = useMemo(() => {
    const summaries = summariesRes?.data?.data?.data || [];
    const grouped: { [providerId: string]: { provider: any, locations: any[] } } = {};

    summaries.forEach((summary: any) => {
      const providerId = summary.Provider.id;

      if (!grouped[providerId]) {
        grouped[providerId] = {
          provider: summary.Provider,
          locations: []
        };
      }

      grouped[providerId].locations.push({
        location: summary.Location,
        entries: summary.entries,
        hash: summary.hash
      });
    });

    return Object.values(grouped);
  }, [summariesRes?.data?.data?.data, showSingleData]);

  // Extract data from responses
  const summaries = summariesRes?.data?.data?.data || [];
  const pagination = summariesRes?.data?.data?.pagination;

  // Toggle expanded state for a specific group
  const toggleGroup = (groupKey: string) => {
    setExpanded(prev => ({
      ...prev,
      [groupKey]: !prev[groupKey]
    }));
  };

  // Toggle all expanded state
  const toggleAllExpanded = () => {
    if (Object.values(expanded).some(value => value)) {
      // If any are expanded, collapse all
      setExpanded({});
    } else {
      // Otherwise, expand all provider and location nodes
      const expandedState: ExpandedState = {};

      summaries.forEach((summary: any) => {
        const providerKey = `provider-${summary.Provider.id}`;
        const locationKey = `location-${summary.Provider.id}-${summary.Location.id}`;

        expandedState[providerKey] = true;
        expandedState[locationKey] = true;
      });

      setExpanded(expandedState);
    }
  };


  const handleApplyFilters = (e: any) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setFilters({
      ...tempFilters
    });
    setPage(1);
    setFilterDialogOpen(false);
    filtersApplied.current = true;
  };

  const handleResetFilters = () => {
    setTempFilters({});
  };

  const removeFilter = (key: keyof FilterState) => {
    const newFilters = { ...filters };
    delete newFilters[key];
    setFilters(newFilters);
    setPage(1);
  };

  const loadingOrErrorUi = GenerateLoadingOrError([filtersRes, summariesRes]);
  if (loadingOrErrorUi) return loadingOrErrorUi;

  const filterOptions = (filtersRes?.data as any)?.data || { providers: [], locations: [] };

  const getProviderNameById = (id: string) => {
    const provider = filterOptions.providers?.find((p: any) => p.id === id);
    return provider?.name || 'Unknown';
  };

  const getLocationNameById = (id: string) => {
    const location = filterOptions.locations?.find((l: any) => l.id === id);
    return location?.name || 'Unknown';
  };

  const formatDate = (date: Date) => {
    return moment.tz(date, CommonDestinationTimezone).format('MM/DD/YYYY');
  };

  // Find current provider and location names for the detail modal
  const currentProviderName = selectedEntry ? getProviderNameById(selectedEntry.provider_id) : '';
  const currentLocationName = selectedEntry ? getLocationNameById(selectedEntry.location_id) : '';

  const detailedEntries = detailedEntriesRes?.data?.data?.data || [];
  const detailPagination = detailedEntriesRes?.data?.data?.pagination;

  // Get commodity info if available
  const currentCommodityInfo = detailedEntries.length > 0 ? {
    commodity: detailedEntries[0].commodity,
    futures_month: detailedEntries[0].futures_month,
    delivery_month: detailedEntries[0].delivery_month
  } : null;

  return (
    <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
      <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
        Cost Basis Dashboard
      </h1>
      <Tabs value={tabValue} onValueChange={setTabValue} className="w-full mt-2">
        <TabsList>
          <TabsTrigger value="changes">Changes</TabsTrigger>
          <TabsTrigger value="locations">Locations</TabsTrigger>
        </TabsList>
        <TabsContent value="changes">
          <div className="flex flex-col mb-4">
            <div className="flex justify-end items-center">
              <span className="flex flex-row justify-end">
                <span className="mr-2">
                  <Label
                    htmlFor={"changes_only_checkbox"}
                    className="flex flex-row justify-between text-base items-center"
                  >
                    <span className="font-semibold">Show Changes Only</span>
                  </Label>
                  <Checkbox
                    id={"changes_only_checkbox"}
                    checked={!showSingleData}
                    onCheckedChange={(status) => {
                      if (typeof status === 'boolean') {
                        setShowSingleData(!status);
                      } else {
                        setShowSingleData(false);
                      }
                    }}
                    className={cn(
                      "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 h-6 w-6",
                    )}
                  />
                </span>
                <Button variant="outline" onClick={toggleAllExpanded} className="mr-4">
                  {Object.values(expanded).some(value => value) ? 'Collapse All' : 'Expand'}
                </Button>
                <Dialog open={filterDialogOpen} onOpenChange={setFilterDialogOpen}>
                  <DialogTrigger asChild>
                    <Button variant="outline" className="gap-2">
                      <Filter className="h-4 w-4" />
                      Filter
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[500px] md:max-w-[800px]">
                    <DialogHeader>
                      <DialogTitle>Filter Cost Basis Data</DialogTitle>
                      <DialogDescription>
                        Select criteria to filter the cost basis entries
                      </DialogDescription>
                    </DialogHeader>

                    <form className="grid gap-4 py-4" onSubmit={handleApplyFilters}>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <label htmlFor="provider" className="text-right col-span-1">
                          Provider:
                        </label>
                        <Combobox
                          isModal
                          className={cn(
                            "w-full col-span-3",
                          )}
                          value={tempFilters.providerId || ""}
                          onValueChange={(value) => setTempFilters({ ...tempFilters, providerId: value === "" ? undefined : value })}
                          options={filterOptions.providers.map((provider: any) => ({ value: provider.id, label: provider.name }))}
                        />
                      </div>

                      <div className="grid grid-cols-4 items-center gap-4">
                        <label htmlFor="location" className="text-right col-span-1">
                          Location:
                        </label>
                        <Combobox
                          isModal
                          className={cn(
                            "w-full col-span-3",
                          )}
                          value={tempFilters.locationId || ""}
                          onValueChange={(value) => setTempFilters({ ...tempFilters, locationId: value === "" ? undefined : value })}
                          options={filterOptions.locations?.filter((location: any) => {
                            if (!tempFilters.providerId) { return true; }
                            return location.provider_id === tempFilters.providerId;
                          }).map((item: any) => ({ value: item.id, label: item.name }))}
                        />
                      </div>
                    </form>

                    <DialogFooter>
                      <Button variant="outline" onClick={handleResetFilters}>
                        Reset Filters
                      </Button>
                      <Button onClick={handleApplyFilters}>Apply Filters</Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </span>
            </div>

            {Object.keys(filters).length > 0 && (
              <div className="flex flex-wrap gap-2 mt-4">
                {filters.providerId && (
                  <Badge variant="secondary" className="flex items-center gap-1">
                    Provider: {getProviderNameById(filters.providerId)}
                    <X
                      className="h-3 w-3 cursor-pointer"
                      onClick={() => removeFilter('providerId')}
                    />
                  </Badge>
                )}
                {filters.locationId && (
                  <Badge variant="secondary" className="flex items-center gap-1">
                    Location: {getLocationNameById(filters.locationId)}
                    <X
                      className="h-3 w-3 cursor-pointer"
                      onClick={() => removeFilter('locationId')}
                    />
                  </Badge>
                )}
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    setFilters({});
                    setPage(1);
                  }}
                  className="h-6 px-2 text-xs"
                >
                  Clear All
                </Button>
              </div>
            )}
          </div>


          <div className="flex flex-col">
            <div className="rounded-md border">
              <Table className="compact-table">
                <TableHeader>
                  <TableRow className="h-8">
                    <TableHead className="py-2">Provider / Location</TableHead>
                    <TableHead className="py-2">Commodity</TableHead>
                    <TableHead className="py-2">Basis</TableHead>
                    <TableHead className="py-2">Futures Price</TableHead>
                    <TableHead className="py-2">Futures Month</TableHead>
                    <TableHead className="py-2">Delivery Month</TableHead>
                    <TableHead className="py-2">Date</TableHead>
                    <TableHead className="py-2">Synced on</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {groupedByProvider.length > 0 ? (
                    groupedByProvider.map((providerGroup) => (
                      <Fragment key={`provider-${providerGroup.provider.id}`}>
                        {/* Provider Row */}
                        <TableRow className="provider-row h-8">
                          <TableCell colSpan={8} className="py-1">
                            <div className="flex items-center">
                              <button
                                onClick={() => toggleGroup(`provider-${providerGroup.provider.id}`)}
                                className="mr-1 hover:bg-gray-200 rounded-full"
                              >
                                {expanded[`provider-${providerGroup.provider.id}`] ?
                                  <ChevronDown className="h-3 w-3" /> :
                                  <ChevronRight className="h-3 w-3" />
                                }
                              </button>
                              <span className="font-medium">{providerGroup.provider.name}</span>
                            </div>
                          </TableCell>
                        </TableRow>

                        {/* Show locations only if provider is expanded */}
                        {expanded[`provider-${providerGroup.provider.id}`] && (
                          providerGroup.locations.map((locationData) => (
                            <Fragment key={`location-${providerGroup.provider.id}-${locationData.location.id}-${locationData.hash}`}>
                              <TableRow className="location-row h-7">
                                <TableCell colSpan={8} className="pl-6 py-0.5">
                                  <div className="ml-4 flex items-center">
                                    <button
                                      onClick={() => toggleGroup(`location-${providerGroup.provider.id}-${locationData.location.id}-${locationData.hash}`)}
                                      className="mr-1 hover:bg-gray-200 rounded-full"
                                    >
                                      {expanded[`location-${providerGroup.provider.id}-${locationData.location.id}-${locationData.hash}`] ?
                                        <ChevronDown className="h-3 w-3" /> :
                                        <ChevronRight className="h-3 w-3" />
                                      }
                                    </button>
                                    <span>{locationData.location.name} | {locationData.entries[0].commodity} | {locationData.entries[0].delivery_month} | {locationData.entries[0].futures_price}</span>
                                  </div>
                                </TableCell>
                              </TableRow>

                              {/* Entries - only shown if location is expanded */}
                              {expanded[`location-${providerGroup.provider.id}-${locationData.location.id}-${locationData.hash}`] &&
                                locationData.entries.map((entry: any, index: number) => (
                                  <TableRow
                                    key={entry.id}
                                    className={`cursor-pointer h-6 detail-row ${index === 0 ? 'bg-gray-50' : ''}`}
                                    onClick={() => setSelectedEntry({
                                      provider_id: providerGroup.provider.id,
                                      location_id: locationData.location.id,
                                      hash: locationData.hash
                                    })}
                                  >
                                    <TableCell className="pl-14 py-0.5 text-xs">
                                      {index === 0 ? "Latest" : "Previous"}
                                    </TableCell>
                                    <TableCell className="py-0.5 text-xs">{entry.commodity}</TableCell>
                                    <TableCell className="py-0.5 text-xs">{entry.basis}</TableCell>
                                    <TableCell className="py-0.5 text-xs">{entry.futures_price}</TableCell>
                                    <TableCell className="py-0.5 text-xs">{entry.futures_month}</TableCell>
                                    <TableCell className="py-0.5 text-xs">{entry.delivery_month}</TableCell>
                                    <TableCell className="py-0.5 text-xs">
                                      {formatDate(entry.futures_month_date)}
                                    </TableCell>
                                    <TableCell className="py-0.5 text-xs">
                                      {entry.created_at ? moment.tz(entry.created_at, CommonDestinationTimezone).format('MM/DD/YYYY') : ""}
                                    </TableCell>
                                  </TableRow>
                                ))
                              }
                            </Fragment>
                          ))
                        )}
                      </Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} className="h-24 text-center">
                        {Object.keys(filters).length > 0 ? "No results found with the current filters. Try adjusting your criteria." : "No cost basis data found."}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>



            <div className="flex items-center justify-between mt-4">
              <Button
                onClick={() => setPage(page - 1)}
                disabled={page <= 1 || summariesRes.isFetching}
              >
                {summariesRes.isFetching ? <Loader /> : <span>Previous</span>}
              </Button>

              <span className="flex flex-row gap-2">
                <span>
                  Page {pagination?.currentPage || 1} of {pagination?.totalPages || 1}
                </span>

                <span>-</span>
                <span>
                  {pageSize} entries per page
                </span>
              </span>

              <Button
                onClick={() => setPage(page + 1)}
                disabled={!pagination || page >= pagination.totalPages || summariesRes.isFetching}
              >
                {summariesRes.isFetching ? <Loader /> : <span>Next</span>}
              </Button>
            </div>

            {/* Detail entries modal */}
            <Dialog
              open={!!selectedEntry}
              onOpenChange={(open) => {
                if (!open) setSelectedEntry(undefined);
              }}
            >
              <DialogContent className="sm:max-w-[700px] md:max-w-[900px] max-h-[80vh] overflow-y-auto">
                <DialogHeader>
                  <DialogTitle>
                    Cost Basis History
                  </DialogTitle>
                  <DialogDescription>
                    {currentProviderName && (
                      <div className="flex flex-col gap-1 mt-2 sm:grid sm:grid-cols-3">
                        <div><span className="font-semibold">Provider:</span> {currentProviderName}</div>
                        <div><span className="font-semibold">Location:</span> {currentLocationName}</div>
                        {currentCommodityInfo && (
                          <>
                            <div><span className="font-semibold">Commodity:</span> {currentCommodityInfo.commodity}</div>
                            <div><span className="font-semibold">Futures Month:</span> {currentCommodityInfo.futures_month}</div>
                            <div><span className="font-semibold">Delivery Month:</span> {currentCommodityInfo.delivery_month}</div>
                          </>
                        )}
                      </div>
                    )}
                  </DialogDescription>
                </DialogHeader>

                <div className="py-4">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Basis</TableHead>
                        <TableHead>Futures Price</TableHead>
                        <TableHead>Date</TableHead>
                        <TableHead>Synced on</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {detailedEntriesRes.isLoading ? (
                        <TableRow>
                          <TableCell colSpan={4} className="text-center py-4">
                            <Loader className="mx-auto" />
                          </TableCell>
                        </TableRow>
                      ) : detailedEntries.length > 0 ? (
                        detailedEntries.map((entry: any) => (
                          <TableRow key={entry.id}>
                            <TableCell>{entry.basis}</TableCell>
                            <TableCell>{entry.futures_price}</TableCell>
                            <TableCell>{formatDate(entry.futures_month_date)}</TableCell>
                            <TableCell>
                              {entry.created_at ? moment.tz(entry.created_at, CommonDestinationTimezone).format('MM/DD/YYYY HH:mm') : ""}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4} className="h-24 text-center">
                            No detailed history available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  {/* Modal pagination */}
                  {detailPagination && detailPagination.totalPages > 1 && (
                    <div className="flex items-center justify-between mt-4">
                      <Button
                        onClick={() => setDetailPage(detailPage - 1)}
                        disabled={detailPage <= 1 || detailedEntriesRes.isFetching}
                        size="sm"
                      >
                        {detailedEntriesRes.isFetching ? <Loader className="h-4 w-4" /> : <span>Previous</span>}
                      </Button>

                      <span className="text-sm">
                        Page {detailPagination?.currentPage || 1} of {detailPagination?.totalPages || 1}
                      </span>

                      <Button
                        onClick={() => setDetailPage(detailPage + 1)}
                        disabled={!detailPagination || detailPage >= detailPagination.totalPages || detailedEntriesRes.isFetching}
                        size="sm"
                      >
                        {detailedEntriesRes.isFetching ? <Loader className="h-4 w-4" /> : <span>Next</span>}
                      </Button>
                    </div>
                  )}
                </div>

                <DialogFooter>
                  <Button onClick={() => setSelectedEntry(undefined)}>Close</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>

          </div>
        </TabsContent>
        <TabsContent value="locations">
          <LocationsTab />
        </TabsContent>
      </Tabs>

    </section >
  );
}

function LocationsTab() {
  const filtersRes = useCostBasisFitlers();
  const [expanded, setExpanded] = useState<ExpandedState>({});

  // Group locations by provider
  const groupedByProvider = useMemo(() => {
    const locations = filtersRes?.data?.data?.locations || [];
    const providers = filtersRes?.data?.data?.providers || [];

    // Create a lookup for providers
    const providerLookup = providers.reduce((acc: any, provider: any) => {
      acc[provider.id] = provider;
      return acc;
    }, {} as Record<string, any>);

    // Group locations by provider
    const grouped: { [providerId: string]: { provider: any, locations: any[] } } = {};

    locations.forEach((location: any) => {
      const providerId = location.provider_id;

      if (!grouped[providerId]) {
        grouped[providerId] = {
          provider: providerLookup[providerId] || { id: providerId, name: 'Unknown Provider' },
          locations: []
        };
      }

      grouped[providerId].locations.push(location);
    });

    // Sort locations in each provider group alphabetically
    Object.values(grouped).forEach(group => {
      group.locations.sort((a, b) => a.name.localeCompare(b.name));
    });

    // Convert to array and sort by provider name
    return Object.values(grouped).sort((a, b) => a.provider.name.localeCompare(b.provider.name));
  }, [filtersRes?.data?.data]);

  // Toggle expanded state for a specific group
  const toggleGroup = (groupKey: string) => {
    setExpanded(prev => ({
      ...prev,
      [groupKey]: !prev[groupKey]
    }));
  };

  // Toggle all expanded state
  const toggleAllExpanded = () => {
    if (Object.values(expanded).some(value => value)) {
      // If any are expanded, collapse all
      setExpanded({});
    } else {
      // Otherwise, expand all provider nodes
      const expandedState: ExpandedState = {};

      groupedByProvider.forEach((group) => {
        const providerKey = `provider-${group.provider.id}`;
        expandedState[providerKey] = true;
      });

      setExpanded(expandedState);
    }
  };

  const loadingOrErrorUi = GenerateLoadingOrError([filtersRes]);
  if (loadingOrErrorUi) return loadingOrErrorUi;

  return (
    <div className="container flex flex-col">
      <div className="flex justify-between items-center mb-4">
        <Button variant="outline" onClick={toggleAllExpanded}>
          {Object.values(expanded).some(value => value) ? 'Collapse All' : 'Expand All'}
        </Button>
      </div>

      <div className="rounded-md border">
        <Table className="compact-table">
          <TableHeader>
            <TableRow className="h-8">
              <TableHead className="py-2">Provider / Location</TableHead>
              <TableHead className="py-2">Provider Enabled</TableHead>
              <TableHead className="py-2">Location Enabled</TableHead>
              <TableHead className="py-2">Last Synced</TableHead>
              <TableHead className="py-2">Link</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {groupedByProvider.length > 0 ? (
              groupedByProvider.map((providerGroup) => (
                <Fragment key={`provider-${providerGroup.provider.id}`}>
                  {/* Provider Row */}
                  <TableRow className="provider-row h-8">
                    <TableCell className="py-1">
                      <div className="flex items-center">
                        <button
                          onClick={() => toggleGroup(`provider-${providerGroup.provider.id}`)}
                          className="mr-1 hover:bg-gray-200 rounded-full"
                        >
                          {expanded[`provider-${providerGroup.provider.id}`] ?
                            <ChevronDown className="h-3 w-3" /> :
                            <ChevronRight className="h-3 w-3" />
                          }
                        </button>
                        <span className="font-medium">{providerGroup.provider.name}</span>
                      </div>
                    </TableCell>
                    <TableCell className="py-1">
                      <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${providerGroup.provider.is_enabled ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                        {providerGroup.provider.is_enabled ? 'Enabled' : 'Disabled'}
                      </span>
                    </TableCell>
                    <TableCell className="py-1"></TableCell>
                    <TableCell className="py-1"></TableCell>
                  </TableRow>

                  {/* Show locations only if provider is expanded */}
                  {expanded[`provider-${providerGroup.provider.id}`] && (
                    providerGroup.locations.map((location) => (
                      <TableRow key={`location-${location.id}`} className="location-row h-7">
                        <TableCell className="pl-8 py-0.5">
                          <div className="ml-4 flex items-center">
                            <span>{location.name}</span>
                          </div>
                        </TableCell>
                        <TableCell className="py-0.5"></TableCell>
                        <TableCell className="py-0.5">
                          <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${(location.is_enabled && providerGroup.provider.is_enabled) ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                            {(location.is_enabled && providerGroup.provider.is_enabled) ? 'Enabled' : 'Disabled'}
                          </span>
                        </TableCell>
                        <TableCell className="py-0.5 text-xs">
                          {location.last_synced_date ?
                            moment.tz(location.last_synced_date, CommonDestinationTimezone).format('MM/DD/YYYY HH:mm') :
                            <span className="text-gray-400 italic">Never</span>
                          }
                        </TableCell>
                        <TableCell className="py-0.5 text-xs">
                          {location.url ?
                            <a target="_blank" href={location.url}><LinkIcon /></a> :
                            <span className="text-gray-400 italic">Missing</span>
                          }
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} className="h-24 text-center">
                  No locations found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

// {filters.search && (
//   <Badge variant="secondary" className="flex items-center gap-1">
//     Search: {filters.search.split(",").map((searchItem: string) => <span className="font-semibold text-orange-500">{searchItem}</span>).reduce((acc: any[], item: any, index: number) => {
//       if (index === 0) {
//         acc.push(item);
//         return acc;
//       }
//       acc.push(...[(<span className="text-gray-500 ">{" "}or{" "}</span>), item]);
//       return acc;
//     }, [] as any[])}
//     <X
//       className="h-3 w-3 cursor-pointer"
//       onClick={() => removeFilter('search')}
//     />
//   </Badge>
// )}
// {filters.startDate && (
//   <Badge variant="secondary" className="flex items-center gap-1">
//     From: {moment(new Date(filters.startDate)).format("MM/DD/YYYY")}
//     <X
//       className="h-3 w-3 cursor-pointer"
//       onClick={() => removeFilter('startDate')}
//     />
//   </Badge>
// )}
// {filters.endDate && (
//   <Badge variant="secondary" className="flex items-center gap-1">
//     To: {moment(new Date(filters.endDate)).format("MM/DD/YYYY")}
//     <X
//       className="h-3 w-3 cursor-pointer"
//       onClick={() => removeFilter('endDate')}
//     />
//   </Badge>
// )}
//

// <div className="grid grid-cols-4 items-center gap-4">
//   <label htmlFor="startDate" className="text-right">
//     Start Date:
//   </label>
//   <div className="col-span-3">
//     <DatePicker
//       value={tempFilters.startDate ? moment(tempFilters.startDate).toDate() : undefined}
//       maxDate={moment().toDate()}
//       onChange={(date) => {
//         setTempFilters({
//           ...tempFilters,
//           startDate: date ? moment(date).format("YYYY-MM-DD") : undefined
//         })
//       }}
//       placeholder={"MM/DD/YYYY"}
//       className={cn(
//         "rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 w-full",
//       )}
//     />
//   </div>
// </div>

// <div className="grid grid-cols-4 items-center gap-4">
//   <label htmlFor="endDate" className="text-right">
//     End Date:
//   </label>
//   <div className="col-span-3">
//     <DatePicker
//       value={tempFilters.endDate ? moment(tempFilters.endDate).toDate() : undefined}
//       maxDate={moment().toDate()}
//       onChange={(date) => {
//         setTempFilters({
//           ...tempFilters,
//           endDate: date ? moment(date).format("YYYY-MM-DD") : undefined
//         })
//       }}
//       placeholder={"MM/DD/YYYY"}
//       className={cn(
//         "rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 w-full",
//       )}
//     />
//   </div>
// </div>
