import ExcelJS from "exceljs";
import * as XLSX from "xlsx";
import Decimal from "decimal.js";
import moment from "moment-timezone";
import { CommonDestinationTimezone } from "../api/trading";
import { downloadBinaryData } from "../browser/download";
import { lightGreenFill, boldCell, setColumnInfo, workbookToBuffer, safeParseDecimal, parseFromBrowserFile, CellFormat } from "../excel";
import { parseFromFile as parseXlsxFromFile } from "../file";
import { SuccessResult, ServerError } from "../responseResults";
import {
  parseFromString,
  UnsettledArCanRow,
  UnsettledArUsRow,
  TrialArRow,
} from "./utils";
import { optionLetterToMonthNumber, monthNumberToOptionLetter } from "../api/otc";
import { Commodities, CommodityOptions } from "../api/commodity";
import { Customer } from "../api/reports";
import { searchFuzzyCustomers } from "../customer";
import { getPath } from "../prelude";
import {
  addImage, applyBorderToRange,
  setFontColor, setFontSize, setCellFill, setCellBorders, setCellAlignments,
  getCells, getCell, makePaperLikeSpreadsheet,
} from "../excel";
import * as Excel from "../excel/index"
import * as ExcelComposer from '../excel/composer';

export enum ReportVariation {
  MonthEnd = "month_end",
  Standard = "standard"
}

interface UnsettledReleaseResult {
  errors: {
    missingCustomer: Record<string, any>,
    missingCurrency: Record<string, any>,

    unknownCustomer: Record<string, any>,
    unknownCurrency: Record<string, any>,
  },
  found: {
    currency: Record<string, Record<string, any>>,
    rows: any[]
  }
}

export async function processFromFiles({
  files,
}: {
  files: {
    file_trial_balance: File[];
    file_unsettled_ar_us: File;
    file_unsettled_ar_can: File;
  }
},
  externalReferences: { customers: Customer[]; },
  options: { manualFxRate?: number; aggNonCommLimit: number; aggCommLimit: number; variation: ReportVariation; },
) {
  const parsedFiles: Record<string, any[]> = {};
  for (const pair of Object.entries(files)) {
    const [key, value] = pair;

    if (Array.isArray(value)) {
      parsedFiles[key] = await Promise.all(value.map(async (file) => ({
        filename: file.name,
        value: await getSheetDataFor(file)
      })));
    } else {
      parsedFiles[key] = await getSheetDataFor(value);
    }
  }

  return await processCSVs(parsedFiles as any, externalReferences, options);
}

async function processCSVs(files: {
  file_trial_balance: { filename: string; value: TrialArRow[] }[];
  file_unsettled_ar_us: UnsettledArCanRow[];
  file_unsettled_ar_can: UnsettledArUsRow[];
},
  externalReferences: { customers: Customer[]; },
  options: { manualFxRate?: number; aggNonCommLimit: number; aggCommLimit: number; variation: ReportVariation; },
) {
  const startDate = new Date();

  const unsettledUsAggregation = aggregateUnsettledAr(files.file_unsettled_ar_us, startDate, externalReferences);

  const unsettledCanAggregation = aggregateUnsettledAr(files.file_unsettled_ar_can, startDate, externalReferences);

  const results: { filename: string; result: Awaited<ReturnType<typeof aggregateAndAssembleMasterReport>> }[] = [];
  const trial_aggregations: ReturnType<typeof aggregateTrial>[] = [];

  for (const file of files.file_trial_balance) {
    const trialAggregation = aggregateTrial(file.value, startDate, externalReferences)
    trial_aggregations.push(trialAggregation);

    results.push({
      filename: file.filename,
      result: new SuccessResult("", {
        errors: {
          unsettledUs: unsettledUsAggregation.errors,
          unsettledCan: unsettledCanAggregation.errors,
          trial: trialAggregation.errors,
        }
      })
    })
  }

  const date = moment().format("YYYY_MM_DDTHH:mm");
  const new_filename = `ar_report_${date}_${options.variation}.xlsx`;

  const groupedTrialAggregation = trial_aggregations.reduce((acc: UnsettledReleaseResult, item: UnsettledReleaseResult) => {
    // Merge errors
    Object.keys(item.errors).forEach((errorType) => {
      acc.errors[errorType as keyof typeof acc.errors] = {
        ...(acc.errors[errorType as keyof typeof acc.errors] || {}),
        ...(item.errors[errorType as keyof typeof item.errors] || {})
      };
    });

    // Merge currency data
    Object.entries(item.found.currency).forEach(([currency, customers]) => {
      if (!acc.found.currency[currency]) {
        acc.found.currency[currency] = {};
      }

      Object.entries(customers).forEach(([customerName, data]) => {
        if (!acc.found.currency[currency][customerName]) {
          acc.found.currency[currency][customerName] = {
            amount: new Decimal(0),
            isCommercial: data.isCommercial
          };
        }

        // Add amounts together
        acc.found.currency[currency][customerName].amount =
          acc.found.currency[currency][customerName].amount.plus(data.amount);
      });
    });

    // Merge rows
    acc.found.rows.push(...item.found.rows);

    return acc;
  }, {
    errors: {
      missingCustomer: {},
      missingCurrency: {},

      unknownCustomer: {},
      unknownCurrency: {},
    },
    found: {
      currency: {},
      rows: []
    }
  })

  await aggregateAndAssembleMasterReport({
    new_filename,
    unsettledUsAggregation,
    unsettledCanAggregation,
    trialAggregation: groupedTrialAggregation,
    externalReferences,
    options,
  })

  return results;
}

function aggregateUnsettledAr(
  rows: (UnsettledArCanRow | UnsettledArUsRow)[],
  startDate: Date,
  externalReferences: { customers: Customer[]; }
) {
  return rows.reduce((acc: UnsettledReleaseResult, row: (UnsettledArCanRow | UnsettledArUsRow)) => {
    const customerDescription = row["Customer/Vendor Id"]?.trim();
    const currency = row["Currency"]?.trim();
    const net = row["Net"];

    if (!customerDescription) {
      acc.errors.missingCustomer[`ContractNo: ${row["Contract No"]}. TicketNo: ${row["Ticket No"]}`] = true;
      return acc;
    }


    if (!currency) {
      acc.errors.missingCurrency[`Customer: ${customerDescription}. ContractNo: ${row["Contract No"]}. TicketNo: ${row["Ticket No"]}}`] = true;
      return acc;
    }

    const foundCustomer = searchFuzzyCustomers(customerDescription, externalReferences.customers);
    if (!foundCustomer) {
      acc.errors.unknownCustomer[customerDescription] = true;
      return acc;
    }
    if (!["US$", "CAN$"].includes(currency)) {
      acc.errors.unknownCurrency[currency] = true;
      return acc;
    }

    const convertedCurrency: string = currency?.toLocaleUpperCase() as any;

    if (!acc.found.currency[convertedCurrency]) {
      acc.found.currency[convertedCurrency] = {};
    }

    if (!acc.found.currency[convertedCurrency][foundCustomer.long_name]) {
      acc.found.currency[convertedCurrency][foundCustomer.long_name] = { amount: new Decimal(0), isCommercial: foundCustomer.is_commercial || false };
    }

    acc.found.currency[convertedCurrency][foundCustomer.long_name].amount = acc.found.currency[convertedCurrency][foundCustomer.long_name].amount.plus(safeParseDecimal(net));

    return acc;
  }, {
    errors: {
      missingCustomer: {},
      missingCurrency: {},

      unknownCustomer: {},
      unknownCurrency: {},
    },
    found: {
      currency: {},
      rows: []
    }
  });
}

function aggregateTrial(
  rows: TrialArRow[],
  startDate: Date,
  externalReferences: { customers: Customer[]; }
) {
  return rows.reduce((acc: UnsettledReleaseResult, row: TrialArRow) => {
    const customerDescription = row["Customer Id"]?.trim();
    const currency = row["Currency"]?.trim();
    const net = row["Total AR"];

    if (!customerDescription) {
      acc.errors.missingCustomer[`Invoice: ${row["Invoice Number"]}. TransactionDate: ${row["Transaction Date"]}`] = true;
      return acc;
    }

    if (!currency) {
      acc.errors.missingCurrency[`Customer: ${customerDescription}. Invoice: ${row["Invoice Number"]}`] = true;
      return acc;
    }

    const foundCustomer = searchFuzzyCustomers(customerDescription, externalReferences.customers);
    if (!foundCustomer) {
      acc.errors.unknownCustomer[customerDescription] = true;
      return acc;
    }
    if (!["US$", "CAN$"].includes(currency)) {
      acc.errors.unknownCurrency[currency] = true;
      return acc;
    }

    const convertedCurrency: string = currency?.toLocaleUpperCase() as any;

    if (!acc.found.currency[convertedCurrency]) {
      acc.found.currency[convertedCurrency] = {};
    }

    if (!acc.found.currency[convertedCurrency][foundCustomer.long_name]) {
      acc.found.currency[convertedCurrency][foundCustomer.long_name] = { amount: new Decimal(0), isCommercial: foundCustomer.is_commercial || false };
    }

    acc.found.currency[convertedCurrency][foundCustomer.long_name].amount = acc.found.currency[convertedCurrency][foundCustomer.long_name].amount.plus(safeParseDecimal(net));

    return acc;
  }, {
    errors: {
      missingCustomer: {},
      missingCurrency: {},

      unknownCustomer: {},
      unknownCurrency: {},
    },
    found: {
      currency: {},
      rows: []
    }
  });
}

async function aggregateAndAssembleMasterReport({
  new_filename,
  unsettledUsAggregation,
  unsettledCanAggregation,
  trialAggregation,
  externalReferences,
  options,
}: {
  new_filename: string;
  unsettledUsAggregation: any,
  unsettledCanAggregation: any,
  trialAggregation: any,
  externalReferences: { customers: Customer[]; }
  options: { manualFxRate?: number; aggNonCommLimit: number; aggCommLimit: number; variation: ReportVariation; };
}) {
  const composer = new ExcelComposer.ExcelComposer();
  const isMonthEndVariant = options.variation === ReportVariation.MonthEnd;
  const fxRate = options.manualFxRate || 1;

  const commExceedLimit = 1000000;
  const nonCommExceedLimit = 75000;

  const sheets = {
    Summary: composer.createSheet("Summary").currentSheet!,
    Commercial: composer.createSheet(!isMonthEndVariant ? "Commercial" : "Commercial(Insurance-Bonded)").currentSheet!,
    NonCommercial: composer.createSheet("Non Commercial").currentSheet!,
    Loads: !isMonthEndVariant ? composer.createSheet("Loads").currentSheet! : undefined,
  }

  composer.setSheet(sheets.NonCommercial);
  const nonComResult = aggregateMasterSheet(
    composer,
    sheets.NonCommercial,
    {
      unsettledUsAggregation: unsettledUsAggregation,
      unsettledCanAggregation: unsettledCanAggregation,
      trialAggregation,
      isCommercial: false,
    },
    (data: any) => data.isCommercial === false,
    externalReferences,
    { fxRate, exceededAmount: nonCommExceedLimit },
  );

  const nonComSummaryBox = composer.addSection({
    headers: ["Non-Commercial", ""],
    data: [
      [{ value: "" }, { value: "" }],
      [{ value: "US$" }, { formula: `=E${nonComResult.usSection.settledSection.footerRowNumber} + J${nonComResult.usSection.unsettledArSection.footerRowNumber}` }],
      [{ value: "CAN$" }, { formula: `=E${nonComResult.canSection.settledSection.footerRowNumber} + J${nonComResult.canSection.unsettledArSection.footerRowNumber}` }],
    ],
    footerCalculations: [
      { type: "value", value: `Total`, bold: true, fill: Excel.Fills.lightGrey },
      { type: "sum", column: 1, bold: true, fill: Excel.Fills.lightGrey },
    ],
    styling: {
      section: { border: { type: "around" } },
      header: { bold: true }
    }
  } as ExcelComposer.SectionConfig, { startRow: nonComResult.canSection.settledSection.footerRowNumber! + 2, startColumn: Excel.excelColumnToNumber("B") });


  composer.setSheet(sheets.Commercial);
  const commResult = aggregateMasterSheet(
    composer,
    sheets.Commercial,
    {
      unsettledUsAggregation: unsettledUsAggregation,
      unsettledCanAggregation: unsettledCanAggregation,
      trialAggregation,
      isCommercial: true,
    },
    (data: any) => data.isCommercial === true,
    externalReferences,
    { fxRate, exceededAmount: commExceedLimit },
  );

  const basicComSummaryBox = composer.addSection({
    headers: ["", ""],
    data: [
      [{ value: "Commercial" }, { formula: `=E${commResult.usSection.settledSection.footerRowNumber} + J${commResult.usSection.unsettledArSection.footerRowNumber} + E${commResult.canSection.settledSection.footerRowNumber} + J${commResult.canSection.unsettledArSection.footerRowNumber}` }],
      [{ value: "Non Commercial" }, { formula: `='Non Commercial'!C${nonComSummaryBox.footerRowNumber}` }],
    ],
    footerCalculations: [
      { type: "value", value: `Total`, bold: true, fill: Excel.Fills.lightGrey },
      { type: "sum", column: 1, bold: true, fill: Excel.Fills.lightGrey },
    ],
    styling: {
      section: { border: { type: "around" } },
    }
  } as ExcelComposer.SectionConfig, { startRow: commResult.canSection.unsettledArSection.footerRowNumber! + 2, startColumn: Excel.excelColumnToNumber("I") });

  composer.addSection({
    headers: ["", "Total", "Balance Sheet", ""],
    data: [
      [{ value: "Total Unsettled AR US$" }, { formula: `=J${commResult.usSection.unsettledArSection.footerRowNumber} + 'Non Commercial'!J${nonComResult.usSection.unsettledArSection.footerRowNumber}` }, { value: 0, fill: Excel.Fills.lightGreen }, { formula: `N$ROW - M$ROW` }],
      [{ value: "Total Unsettled CAN$" }, { formula: `=H${commResult.canSection.unsettledArSection.footerRowNumber} + 'Non Commercial'!H${nonComResult.canSection.unsettledArSection.footerRowNumber}` }, { value: 0, fill: Excel.Fills.lightGreen }, { formula: `N$ROW - M$ROW` }],
    ],
    footerCalculations: [
      { type: "value", value: ``, },
      { type: "sum", column: 1, bold: true },
      { type: "sum", column: 2, bold: true },
      { type: "sum", column: 3, bold: true },
    ],
    styling: {
      header: { bold: true },
      columns: {
        L: { width: 30 },
        M: { width: 24, format: Excel.CellFormat.ACCOUNTING },
        N: { width: 24, format: Excel.CellFormat.ACCOUNTING },
        O: { width: 24, format: Excel.CellFormat.ACCOUNTING },
      },
    }
  } as ExcelComposer.SectionConfig, { startRow: 2, startColumn: Excel.excelColumnToNumber("L") });

  composer.addSection({
    headers: [],
    data: [
      [{ value: "Total AR US$" }, { formula: `=C${commResult.usSection.settledSection.footerRowNumber} + 'Non Commercial'!C${nonComResult.usSection.settledSection.footerRowNumber}` }, { value: 0, fill: Excel.Fills.lightGreen }, { formula: `N$ROW - M$ROW` }],
      [{ value: "CAN$" }, { formula: `=C${commResult.canSection.settledSection.footerRowNumber} + 'Non Commercial'!C${nonComResult.canSection.settledSection.footerRowNumber}` }, { value: 0, fill: Excel.Fills.lightGreen }, { formula: `N$ROW - M$ROW` }],
    ],
    footerCalculations: [
      { type: "value", value: ``, },
      { type: "sum", column: 1, bold: true },
      { type: "sum", column: 2, bold: true },
      { type: "sum", column: 3, bold: true },
    ],
    styling: {
    }
  } as ExcelComposer.SectionConfig, { startRow: 8, startColumn: Excel.excelColumnToNumber("L") });

  composer.addSection({
    headers: [],
    data: [
      [{ value: "Total FX-Accounts Receivable" }, { formula: `=I${commResult.canSection.unsettledArSection.footerRowNumber} + D${commResult.canSection.settledSection.footerRowNumber} + 'Non Commercial'!I${nonComResult.canSection.unsettledArSection.footerRowNumber} + 'Non Commercial'!D${nonComResult.canSection.settledSection.footerRowNumber}` }, { value: 0, fill: Excel.Fills.lightGreen }, { formula: `N$ROW - M$ROW` }],
    ],
    footerCalculations: [],
    styling: {
    }
  } as ExcelComposer.SectionConfig, { startRow: 13, startColumn: Excel.excelColumnToNumber("L") });

  composer.addSection({
    headers: [],
    data: [
      [{ value: "" }, { value: "" }, { formula: `= N9 + N4` }, { value: "" }],
      [{ value: "" }, { value: "" }, { formula: `= N15 * N18` }, { value: "" }],
    ],
    footerCalculations: [],
    styling: {}
  } as ExcelComposer.SectionConfig, { startRow: 15, startColumn: Excel.excelColumnToNumber("L") });

  composer.addSection({
    headers: [],
    data: [
      [{ value: 1, format: Excel.CellFormat.NUMBER }, { value: fxRate, format: Excel.CellFormat.NUMBER }, { formula: `= L18 - M18`, format: Excel.CellFormat.NUMBER }, { value: "" }],
    ],
    footerCalculations: [],
    styling: {}
  } as ExcelComposer.SectionConfig, { startRow: 18, startColumn: Excel.excelColumnToNumber("L") });

  Excel.applyAroundBorders(sheets.Commercial, `L3`, `O20`);

  generateSummaryPageContent({
    sheets,
    composer,
    commResult,
    nonComResult,
    nonComSummaryBox,
  });

  downloadBinaryData(
    await composer.getBuffer(),
    new_filename,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  );

  return new SuccessResult("", {
    errors: {
      unsettledUs: unsettledUsAggregation.errors,
      unsettledCan: unsettledCanAggregation.errors,
      trial: trialAggregation.errors,
    }
  });
}

function aggregateMasterSheet(
  composer: ExcelComposer.ExcelComposer,
  sheet: any,
  {
    unsettledUsAggregation,
    unsettledCanAggregation,
    trialAggregation,
    isCommercial,
  }: {
    unsettledUsAggregation: any;
    unsettledCanAggregation: any;
    trialAggregation: any;
    isCommercial: boolean;
  },
  shouldUseRow: (item: any) => boolean,
  externalReferences: { customers: Customer[]; },
  options: { fxRate: number, exceededAmount: number },
) {
  const fxRate = options.fxRate;
  const exceededAmount = options.exceededAmount;

  composer.addSection({
    headers: ["Accounts Receivable Aging Report"],
    data: [],
    footerCalculations: [],
    styling: {
      columns: {
        B: { width: 30 },
      },
      header: { bold: true }
    }
  } as ExcelComposer.SectionConfig, { startRow: 1, startColumn: Excel.excelColumnToNumber("B") });

  const usSection = computeSection({
    composer,
    sheet,
    aggData: unsettledUsAggregation,
    trialAggregation,
    fxRate,
    shouldUseRow,
    convertCurrency: "",
    currency: "US$",
    exceededAmount,
    startRow: {
      settledStart: 2,
      unsettledStart: 2,
    },
  });

  const canSection = computeSection({
    composer,
    sheet,
    aggData: unsettledCanAggregation,
    trialAggregation,
    fxRate,
    shouldUseRow,
    convertCurrency: "US$",
    currency: "CAN$",
    exceededAmount,
    startRow: {
      settledStart: usSection.settledSection.footerRowNumber! + 1,
      unsettledStart: usSection.unsettledArSection.footerRowNumber! + 1,
    },
  });

  return {
    usSection,
    canSection,
  }
}

function computeSection({
  composer,
  currency,
  convertCurrency,
  aggData,
  trialAggregation,
  sheet,
  fxRate,
  shouldUseRow,
  exceededAmount,
  startRow,
}: {
  composer: ExcelComposer.ExcelComposer,
  currency: string;
  convertCurrency: string;
  aggData: any;
  trialAggregation: any;
  sheet: any;
  fxRate: number;
  shouldUseRow: (item: any) => boolean;
  exceededAmount: number;
  startRow: {
    settledStart: number;
    unsettledStart: number;
  };
}) {
  // TODO still have to bold if exceeds after relaunching sheet

  const currencyData = aggData.found.currency[currency] || {};
  const trialData = trialAggregation.found.currency[currency] || {};

  const unsettledKeys = Object.keys(currencyData);
  const newTrialKeys = Object.keys(trialData);

  const settledSection = composer.addSection({
    headers: !convertCurrency ? ["", "AR", "FX Trade Rec", "Total AR"] : ["", "", "", ""],
    data: newTrialKeys.filter((customerName: string) => !!shouldUseRow(trialData[customerName])).map((customerName: string) => {
      const value = trialData[customerName];
      const amount = safeParseDecimal(value.amount as any);

      return [{ value: customerName }, { value: amount.toNumber() }, { formula: !!convertCurrency ? `=-C$ROW * 'Commercial(Insurance-Bonded)'!$N$18` : `=0` }, { formula: `C$ROW + D$ROW` }]
    }),
    padData: {
      ifEmpty: true,
      data: (new Array(4).fill("", 0, 4)).map((_item) => ([{ value: "" }, { value: 0 }, { formula: !!convertCurrency ? `=-C$ROW * 'Commercial(Insurance-Bonded)'!$N$18` : `=0` }, { formula: `C$ROW + D$ROW` }]))
    },
    footerCalculations: [
      { type: "value", value: `Total ${currency}`, bold: true, fill: Excel.Fills.lightGrey },
      { type: "sum", column: 1, bold: true, fill: Excel.Fills.lightGrey },
      { type: 'sum', column: 2, bold: true, fill: Excel.Fills.lightGrey },
      { type: 'sum', column: 3, bold: true, fill: Excel.Fills.lightGrey },
    ],
    styling: {
      columns: {
        B: { width: 30 },
        C: { width: 14, format: Excel.CellFormat.ACCOUNTING },
        D: { width: 14, format: Excel.CellFormat.ACCOUNTING },
        E: { width: 25, format: Excel.CellFormat.ACCOUNTING },
      },
      ...(!convertCurrency ? ({ header: { bold: true, border: { type: "bottom" } } }) : ({})),
      conditionalFormatting: [{ column: "E", rules: [{ amountCeiling: `${exceededAmount}`, bold: true }] }]
    }
  } as ExcelComposer.SectionConfig, { startRow: startRow.settledStart, startColumn: Excel.excelColumnToNumber("B") });

  const unsettledArSection = composer.addSection({
    headers: !convertCurrency ? ["", "Unsettled AR", "Unsettled AR FX", "Total Unsettled AR"] : ["", "", "", ""],
    data: unsettledKeys.filter((customerName: string) => !!shouldUseRow(currencyData[customerName])).map((customerName: string) => {
      const value = currencyData[customerName];
      const amount = safeParseDecimal(value.amount as any);

      return [{ value: customerName }, { value: amount.toNumber() }, { formula: !!convertCurrency ? `=-H$ROW * 'Commercial(Insurance-Bonded)'!$N$18` : `=0` }, { formula: `H$ROW + I$ROW` }]
    }),
    padData: {
      ifEmpty: true,
      data: (new Array(4).fill("", 0, 4)).map((_item) => ([{ value: "" }, { value: 0 }, { formula: !!convertCurrency ? `=-H$ROW * 'Commercial(Insurance-Bonded)'!$N$18` : `=0` }, { formula: `H$ROW + I$ROW` }]))
    },
    footerCalculations: [
      { type: "value", value: `Total ${currency}`, bold: true, fill: Excel.Fills.lightGrey },
      { type: "sum", column: 1, bold: true, fill: Excel.Fills.lightGrey },
      { type: 'sum', column: 2, bold: true, fill: Excel.Fills.lightGrey },
      { type: 'sum', column: 3, bold: true, fill: Excel.Fills.lightGrey },
    ],
    styling: {
      columns: {
        G: { width: 30 },
        H: { width: 14, format: Excel.CellFormat.ACCOUNTING },
        I: { width: 14, format: Excel.CellFormat.ACCOUNTING },
        J: { width: 25, format: Excel.CellFormat.ACCOUNTING },
      },
      ...(!convertCurrency ? ({ header: { bold: true, border: { type: "bottom" } } }) : ({})),
      conditionalFormatting: [{ column: "J", rules: [{ amountCeiling: `${exceededAmount}`, bold: true }] }]
    }
  } as ExcelComposer.SectionConfig, { startRow: startRow.unsettledStart, startColumn: Excel.excelColumnToNumber("G") });

  return {
    unsettledArSection,
    settledSection
  };
}

function findDifferences(array1: string[], array2: string[]) {
  const set1 = new Set(array1);
  return array2.filter(item => !set1.has(item));
}

function generateSummaryPageContent({
  sheets,
  composer,
  commResult,
  nonComResult,
  nonComSummaryBox,
}: any) {
  makePaperLikeSpreadsheet(sheets.Summary);

  composer.setSheet(sheets.Summary);
  // Side Panel
  const sidePanelOneSection = composer.addSection({
    headers: ["Balance Sheet AR Accounts", ""],
    data: [
      [{ value: "" }, { value: "" }],
      [{ value: " Marketing Fees Accruals " }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: " Origination Fees Accruals " }, { value: 0, fill: Excel.Fills.lightGreen }],
    ],
    footerCalculations: [
      { type: "value", value: "Total" },
      { type: "sum", column: 1, bold: true },
    ],
    styling: {
    }
  } as ExcelComposer.SectionConfig, { startRow: 3, startColumn: Excel.excelColumnToNumber("F") });

  const sidePanelTwoSection = composer.addSection({
    headers: [],
    data: [
      [{ value: " Investment in LHC International " }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: " Due from LHL " }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: " Due from LHH " }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: " Note Rec-Mybrandforce " }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: " Andrew Kabes " }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: " Dave Spickler " }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: " Investment in Red Trail Energy " }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: " Patronage " }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: "" }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: "" }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: "" }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: "" }, { value: 0, fill: Excel.Fills.lightGreen }],
      [{ value: "" }, { value: 0, fill: Excel.Fills.lightGreen }],
    ],
    footerCalculations: [
      { type: "value", value: "Total" },
      { type: "sum", column: 1, bold: true },
    ],
    styling: {
      columns: {
        E: { width: 30 },
        F: { width: 34 },
        G: { width: 35, format: Excel.CellFormat.ACCOUNTING },
      }
    }
  } as ExcelComposer.SectionConfig, { startRow: 9, startColumn: Excel.excelColumnToNumber("F") });

  composer.addSection({
    headers: ["Aggregate Bonded/Insured & Non-Commerical", ""],
    data: [
      [{ value: "Lighthouse Commodities", bold: true }, { value: "" }],
    ],
    footerCalculations: [],
    styling: {
      columns: {
        B: { width: 32 },
        C: { width: 25, format: Excel.CellFormat.ACCOUNTING },
      },
      header: { bold: true, fontColor: "FFFFFFFF", fill: Excel.Fills.primaryRed }
    }
  } as ExcelComposer.SectionConfig, { startRow: 2, startColumn: Excel.excelColumnToNumber("B") });

  composer.addSection({
    headers: ["Type", "Total AR"],
    data: [
      [{ value: "" }, { value: "" }],
      [{ value: "Aggregate US$  Bonded/Insured" }, { formula: `=G22 + 'Commercial(Insurance-Bonded)'!E${commResult.usSection.settledSection.footerRowNumber} + 'Commercial(Insurance-Bonded)'!J${commResult.usSection.unsettledArSection.footerRowNumber}` }],
      [{ value: "Aggregate CAN$ Bonded/Insured" }, { formula: `='Commercial(Insurance-Bonded)'!E${commResult.canSection.settledSection.footerRowNumber} + 'Commercial(Insurance-Bonded)'!J${commResult.canSection.unsettledArSection.footerRowNumber}` }],
      [{ value: "Aggregate Non-Commerical" }, { formula: `='Non Commercial'!C${nonComSummaryBox.footerRowNumber}` }],
    ],
    footerCalculations: [
      { type: "value", value: "", fill: Excel.Fills.lightGrey },
      { type: "sum", column: 1, bold: true, fill: Excel.Fills.lightGrey },
    ],
    styling: {
      header: { bold: true, fontColor: "FFFFFFFF", fill: Excel.Fills.darkGrey },
      section: { border: { type: "box" } },
    }
  } as ExcelComposer.SectionConfig, { startRow: 5, startColumn: Excel.excelColumnToNumber("B") });

  composer.addSection({
    headers: ["Accounts Receivable", ""],
    data: [
      [{ value: "Lighthouse Commodities", bold: true }, { value: "" }],
    ],
    footerCalculations: [],
    styling: {
      header: { bold: true, fontColor: "FFFFFFFF", fill: Excel.Fills.primaryRed }
    }
  } as ExcelComposer.SectionConfig, { startRow: 14, startColumn: Excel.excelColumnToNumber("B") });

  composer.addSection({
    headers: ["Type", "Total AR"],
    data: [
      [{ value: "" }, { value: "" }],
      [{ value: "US$ Total" }, { formula: `=G22 + 'Commercial(Insurance-Bonded)'!E${commResult.usSection.settledSection.footerRowNumber} + 'Commercial(Insurance-Bonded)'!J${commResult.usSection.unsettledArSection.footerRowNumber} + 'Non Commercial'!E${nonComResult.usSection.settledSection.footerRowNumber} + 'Non Commercial'!J${nonComResult.usSection.unsettledArSection.footerRowNumber}` }],
      [{ value: "CAN$ Total" }, { formula: `'Commercial(Insurance-Bonded)'!E${commResult.canSection.settledSection.footerRowNumber} + 'Commercial(Insurance-Bonded)'!J${commResult.canSection.unsettledArSection.footerRowNumber} + 'Non Commercial'!E${nonComResult.canSection.settledSection.footerRowNumber} + 'Non Commercial'!J${nonComResult.canSection.unsettledArSection.footerRowNumber}` }],
      [{ value: "Marketing & Origination Fees" }, { formula: `=G7` }],
    ],
    footerCalculations: [
      { type: "value", value: "", fill: Excel.Fills.lightGrey },
      { type: "sum", column: 1, bold: true, fill: Excel.Fills.lightGrey },
    ],
    styling: {
      header: { bold: true, fontColor: "FFFFFFFF", fill: Excel.Fills.darkGrey },
      section: { border: { type: "box" } },
    }
  } as ExcelComposer.SectionConfig, { startRow: 17, startColumn: Excel.excelColumnToNumber("B") });

  composer.addSection({
    headers: [],
    data: [
      [{ value: " AR-Bonded Warehouses <30 Days>  " }, { value: "" }, { formula: "=C7 + C21" }],
      [{ value: "AR -Other <60 days>" }, { value: "" }, { formula: "=C8 + C9" }],
    ],
    footerCalculations: [
      { type: "value", value: "" },
      { type: "value", value: "Total" },
      { type: "sum", column: 2, bold: true },
    ],
    styling: {
    }
  } as ExcelComposer.SectionConfig, { startRow: 26, startColumn: Excel.excelColumnToNumber("E") });

  // TODO add these to the composer
  sheets.Summary.mergeCells("B2:C2");
  sheets.Summary.mergeCells("B3:C3");
  sheets.Summary.mergeCells("B14:C14");
  sheets.Summary.mergeCells("B15:C15");

  setFontSize(sheets.Summary, ["B2", "B14"], 16);
  setFontSize(sheets.Summary, ["B3", "B15"], 14);

  setCellAlignments(sheets.Summary, ["B2", "B3", "B5", "C5", "B17", "C17", "B14", "B15"]);
  sheets.Summary.getRow(2).height = 43;
  sheets.Summary.getRow(14).height = 43;
}

async function getSheetDataFor(file: File) {
  if (file.name.endsWith(".csv")) {
    return parseFromString((await parseFromBrowserFile(file)).data);
  } else {
    const workbook = await parseXlsxFromFile(file);
    let sheetName = workbook.SheetNames[0]!;
    let sheet = workbook.Sheets[sheetName];
    let sheetData = XLSX.utils.sheet_to_json(sheet);

    sheetData = sheetData.map((row: any) => {
      return Object.entries(row).reduce((acc: any, [key, value]) => {
        (acc as any)[key.trim()] = value;
        return acc;
      }, {} as any);
    });

    return sheetData;
  }
}
