import React, { useState } from 'react';
import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import { FileAttributes } from './FileNode'; // Import the type
import { FileText, Download, Edit } from 'lucide-react'; // Icons
import { Button } from '@/components/ui/button';
import { useGenerateGetPresignedUrl } from '@/lib/api/knowledge';
import { useToast } from '@/components/ui/use-toast';
import { downloadFromS3 } from '@/lib/file';


const FileEmbedComponent = (props: NodeViewProps) => {
  const { toast } = useToast();
  const [isDownloading, setIsDownloading] = useState(false);
  const generateGetPresignedUrl = useGenerateGetPresignedUrl();
  const { node, editor, updateAttributes, extension } = props;
  const { fileId, fileName, alias, fileType } = node.attrs as FileAttributes;

  const showEditButton = (extension.options as any)?.showEditButton || false;
  const showDownloadButton = (extension.options as any)?.showDownloadButton || false;

  // Determine display name
  const displayName = alias || fileName || 'Unnamed File';

  // Prop to control download behavior (passed from editor setup)
  // Defaulting to true for this example
  const enableDownload = (editor.options.editorProps as any).enableFileDownload !== false;

  const handleDownloadClick = async (e: React.MouseEvent) => {
    if (e) {
    e.stopPropagation(); // Prevent editor focus issues
    }

    if (!fileId || !fileName) return;
    if (isDownloading) return;
    setIsDownloading(true);
    console.log(`Attempting to download file: ID=${fileId}, Name=${fileName}`);

    try {
      const result = await generateGetPresignedUrl(fileId);
      if (!result.success || result.data?.presignedUrl) {
        toast({ title: "Download Failed", description: result.message || "Failed to get presigned URL.", variant: "destructive" });
        return;
      }

      const link = result.data.link;
      downloadFromS3(link, fileName);
    } catch (error) {
      toast({ title: "Download Failed", description: String(error), variant: "destructive" });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleEditAlias = (e: React.MouseEvent) => {
    e.stopPropagation();
    const newAlias = window.prompt(`Enter new display name for "${fileName}":`, alias || "");
    // Check if prompt was cancelled (null) or cleared (empty string)
    if (newAlias !== null) {
        // Update the node attribute using the provided Tiptap function
        updateAttributes({
            alias: newAlias.trim() || null, // Store null if empty
        });
    }
  };

  return (
    <NodeViewWrapper className="file-embed-nodeview my-2 p-2 border rounded-md bg-muted inline-flex items-center gap-2 cursor-default group" data-drag-handle>
      <FileText className="h-5 w-5 text-muted-foreground flex-shrink-0" />
      <span className="text-sm font-medium truncate" title={fileName || undefined}>
        {displayName}
      </span>
      {showEditButton && (
<Button
        variant="outline"
        size="icon"
        className="h-6 w-6"
        onClick={handleEditAlias}
        title="Edit display name"
        type="button"
      >
        <Edit className="h-4 w-4" />
      </Button>
      )}

      {showDownloadButton && enableDownload && fileId && fileName && (
        <Button
          variant="ghost"
          size="icon"
          className="h-6 w-6"
          onClick={handleDownloadClick}
          title={`Download ${fileName}`}
          type="button"
        >
          <Download className="h-4 w-4" />
        </Button>
      )}
    </NodeViewWrapper>
  );
};

export default FileEmbedComponent;
