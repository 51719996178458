import { z } from "zod";
import { Decimal } from "decimal.js"
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BaseDbObject } from "../models/db";
import {
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import { PossibleRole } from "lhc_permissions";

const ENTITY_KEY = "users";

// Validation schema for updating user permissions
export const UpdateUserPermissionsValidation = z.object({
  user_id: z.string().uuid(),
  permissions: z.array(z.string()),
});

export interface DbUserWithPackages extends BaseDbObject {
  email: string;
  name: string;
  packages: string[];
  role: PossibleRole;
}

export interface ContractQuantityAlertRow {
  id: string;
  customer: {
    id: string;
    client_id: string;
    long_name: string;
  },
  threshold: string | Decimal,
  remaining_balance: string | Decimal,
  row: {
    contract_no: string;
    commodity: string;
    market_zone: string;
    release: string;
  }
}

export function useContractQuantityAlertsInReview() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["contract_quantity"],
    queryFn: () => fetchApiGet<ContractQuantityAlertRow[]>("contract/quantity/staged", queryParams),
    retry: 1,
  });
}

export function useContractQuantityAlertSummary() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["contract_quantity_summary"],
    queryFn: () => fetchApiGet<{ status: ExceedRecordStatus; count: number }[]>("contract/quantity/summary", queryParams),
    retry: 1,
  });
}

export function useUpdateQuantityAlertStatus() {
  const queryClient = useQueryClient();

  return async (data: typeof UpdateStatusValidation._type) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPut<ThresholdExceededRecord>(
      `contract/quantity/${data.id}/status`,
      data,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["contract_quantity"] });
    queryClient.invalidateQueries({ queryKey: ["contract_quantity_summary"] });

    return result;
  };
}

export interface ThresholdSetting extends BaseDbObject {
  threshold_type: string;
  threshold: string | Decimal;

  is_enabled: boolean;

  meta_data: Record<string, any>;

  ExceededRecords?: ThresholdExceededRecord[]
}

export enum ExceedRecordStatus {
  NormalSend = "normal_send",
  InReview = "in_review",
  Ignored = "ignored",
  ForceSend = "forced_send",
}

export interface ThresholdExceededRecord extends BaseDbObject {
  threshold_id: string;
  record_id: string;

  is_staged: boolean;
  status: ExceedRecordStatus;

  meta_data: Record<string, any>

  Threshold?: ThresholdSetting;
}

export const UpdateStatusValidation = z.object({
  id: z.string().uuid(),
  status: z.enum([
    ExceedRecordStatus.ForceSend,
    ExceedRecordStatus.Ignored,
  ]),
});
