import { useState, useEffect } from "react";
import moment from 'moment-timezone';
import { useStytchMember } from "@stytch/react/b2b";
import { useNavigate } from "react-router-dom";
import { useGetCreateJobStatus, useCreatePreliminaryUpload } from "../../lib/api/dorman";
import { FormGridHelper } from "../reports/helper";
import {
  InputRequiredStatus,
  InputFormType,
  InputType,
  ImportType,
  ExportType,
  InputFilter,
  InputRequiredStatusType,
} from "../../lib/form";
import * as FormHelper from "../../lib/form";
import { downloadFromS3, UploadPresignedFile } from "../../lib/file";
import * as FormLayout from "../../lib/form/layout";
import {
  PrimitiveGridLayout,
  PrimitiveManagedTable,
  PrimitiveDialogForm,
} from "../../lib/form/layout";
import { SuccessResult, ServerError, ValidationError } from "../../lib/responseResults";
import { useToast } from "../../components/ui/use-toast";
import { Loader } from "../../components/ui/loader";
import { doesUserHavePackages, PossiblePackage } from "lhc_permissions";
import { useGenerateInventoryMapDownloadLink, useCreateInventoryMapUpload } from "../../lib/api/inventoryMap";
import { CommonDestinationTimezone } from "@/lib/utils";
import { createZipFrom } from "@/lib/browser/download";
import { useUploadBulkPaymentRemittanceJob } from "@/lib/api/customer";

export const CreateJobFormSchema = {
  remittance_files: { label: "Payment Remittance Files", allowMany: true, fileExtensions: [".pdf"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Required },
};

export function PaymentRemittanceUploadSection() {
  const { member } = useStytchMember();
  const isAvailable = doesUserHavePackages({
    requiredPackages: [PossiblePackage.CustomerPaymentPdf],
    member,
  });

  if (!isAvailable) {
    return null;
  }

  return (
    <div className="container">
      <div className="flex flex-col">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl mb-4">
          Payment Remittance Upload
        </h1>
        <CreateJobForm />
      </div>
    </div>
  );
}

const createJobState = FormHelper.generateBaseState({
  schemas: [CreateJobFormSchema],
  baseSchema: CreateJobFormSchema,
});

function CreateJobForm({ }: {}) {
  const inputState: any = createJobState.useInputsState((state: any) => state);
  const submissionState: any = createJobState.useSubmissionState((state: any) => state);
  const onCreateJob = useUploadBulkPaymentRemittanceJob();
  const { toast } = useToast();

  return FormLayout.RenderFullPrimitiveGridLayout({
    hideSkipButton: true,
    id: "CreatePaymentPdfUploadJob",
    activeSchema: CreateJobFormSchema,
    totalFields: inputState.totalFields,
    formValues: inputState.formValues,
    updateFormValue: inputState.updateFormValue,
    formRelationships: inputState.formRelationships,
    updateFormRelationship: inputState.updateFormRelationship,
    submissionState,
    externalReferences: {},
    onSubmitTo: async (item: any) => {
      if (!item.remittance_files) {
        toast({
          title: "Payment Remittance Files",
          description: "Must be at least one PDF file",
        });
        return new ValidationError("", {});
      }

      if (!Array.isArray(item.remittance_files) || item.remittance_files.some((file: File) => {
        return ![".pdf"].some((extension: string) => file?.name?.toLocaleLowerCase()?.endsWith(extension));
      })) {
        toast({
          title: "Payment Remittance Files",
          description: "PDF Files only",
        });
        return new ValidationError("", {});
      }

      const datetimestamp = moment.tz(CommonDestinationTimezone).format("YYYYMMDDHHmmss");
      const createBody = {
        zip_file: {
          filename: `payment_remittance_zip_${datetimestamp}.zip`,
          mimetype: "application/zip",
        }
      };

      const createResult = await onCreateJob(createBody);

      if (!createResult.success) {
        return createResult;
      }

      const resultData = createResult.data;

      try {
        const zip = await createZipFrom(item.remittance_files);
        const upload_result = await UploadPresignedFile(resultData.link, zip, "application/zip");
        if (!upload_result.success) {
          return upload_result;
        }
      } catch (error) {
        console.error("Failed to upload zip file", error);
        return new ServerError("", { error });
      }

      return createResult;
    },
    handleSubmitResponse: (result) => {
      if (result.statusCode === 400) {
        toast({
          title: "Failed to upload files",
          description: "",
        });
        return;
      } else if (!result.success) {
        toast({
          title: "Failed to upload files",
          description: "",
        });
      } else {
        toast({
          title: "Uploaded - Processing",
          description: "Errors will be emailed.",
        });
      }
    }
  });
}
